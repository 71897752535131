/*------------------------------------
  Background Gradient Colors
------------------------------------*/
/* Simple Linear Gradients
------------------------------------*/
/* Gray Light v1 */
.g-bg-gray-light-gradient-v1 {
  &--after::after {
    background: linear-gradient(to bottom, rgba($g-color-gray-light-v5,.5), rgba($g-color-gray-light-v5,1));
  }
}

/* White v1 */
.g-bg-white-gradient-v1 {
  &--after::after {
    background: linear-gradient(to bottom, rgba($g-color-white,.5), rgba($g-color-white,.9));
  }
}


/* Primary Gradient */
.g-bg-primary-gradient-opacity-v1,
.g-bg-primary-gradient-opacity-v1--after::after {
  @include gradient-directional($start-color: rgba(#72c02c, 1), $end-color: rgba(#3398dc, .7), $deg: 150deg);
}

/* Gray Gradient */
.g-bg-gray-gradient-opacity-v1,
.g-bg-gray-gradient-opacity-v1--after::after {
  @include gradient-y-top($start-color: rgba($g-color-gray-light-v5, 1), $end-color: rgba(#fff, 1));
}

/* White Gradient */
.g-bg-white-gradient-opacity-v1,
.g-bg-white-gradient-opacity-v1--after::after {
  @include gradient-y-top($start-color: rgba(#000, .7), $end-color: rgba(#000, 0));
}



/* White Gradient */
.g-bg-white-gradient-opacity-v4,
.g-bg-white-gradient-opacity-v4--after::after {
  @include gradient-x-right($start-color: rgba(#fff, 1), $end-color: rgba(#fff, .3), $start-percent: 30%);
}

/* White Gradient */
.g-bg-white-gradient-opacity-v5,
.g-bg-white-gradient-opacity-v5--after::after {
  @include gradient-x-right($start-color: rgba(#fff, 1), $end-color: rgba(#fff, 0), $start-percent: 47%, $end-percent: 75%);
}


//
// Complementary Gradients
//

.g-bg-blue-radialgradient-circle-endless {
  background-image: repeating-radial-gradient(circle at 50% 50%, #b9ecfe, #b9ecfe 10px, #82ddff 10px, #82ddff 20px);
}

.g-bg-gray-verticalstripes-endless {
  background: repeating-linear-gradient(to bottom, #fff, #fff 5px, #eee 5px, #eee 10px);
}
