@charset "UTF-8";

/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: button;
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

.h1,
h1 {
  font-size: 2.5rem;
}

.h2,
h2 {
  font-size: 2rem;
}

.h3,
h3 {
  font-size: 1.75rem;
}

.h4,
h4 {
  font-size: 1.5rem;
}

.h5,
h5 {
  font-size: 1.25rem;
}

.h6,
h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.333333%;
  }

  .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }

  .offset-md-2 {
    margin-left: 16.666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.333333%;
  }

  .offset-md-5 {
    margin-left: 41.666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.333333%;
  }

  .offset-md-8 {
    margin-left: 66.666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.333333%;
  }

  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.333333%;
  }

  .offset-lg-2 {
    margin-left: 16.666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.333333%;
  }

  .offset-lg-5 {
    margin-left: 41.666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.333333%;
  }

  .offset-lg-8 {
    margin-left: 66.666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.333333%;
  }

  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.333333%;
  }

  .offset-xl-2 {
    margin-left: 16.666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.333333%;
  }

  .offset-xl-5 {
    margin-left: 41.666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.333333%;
  }

  .offset-xl-8 {
    margin-left: 66.666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.333333%;
  }

  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > td,
.table-success > th {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > td,
.table-info > th {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[multiple],
select.form-control[size] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: #28a745;
}

.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip {
  display: block;
}

.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip,
.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip {
  display: block;
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}

.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}

.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}

.custom-file-input.is-valid ~ .custom-file-label::after,
.was-validated .custom-file-input:valid ~ .custom-file-label::after {
  border-color: inherit;
}

.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block;
}

.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip,
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip {
  display: block;
}

.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}

.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.custom-file-input.is-invalid ~ .custom-file-label::after,
.was-validated .custom-file-input:invalid ~ .custom-file-label::after {
  border-color: inherit;
}

.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:focus,
.btn:hover {
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info.focus,
.btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning.focus,
.btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger.focus,
.btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark.focus,
.btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.btn-group-vertical > .btn:hover,
.btn-group > .btn:hover {
  z-index: 1;
}

.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control {
  margin-left: -1px;
}

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-append,
.input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}

.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}

.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=http://www.w3.org/2000/svg viewBox=0 0 8 8%3E%3Cpath fill=%23fff d=M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=http://www.w3.org/2000/svg viewBox=0 0 4 4%3E%3Cpath stroke=%23fff d=M0 2h4/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=http://www.w3.org/2000/svg viewBox=-4 -4 8 8%3E%3Ccircle r=3 fill=%23fff/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=http://www.w3.org/2000/svg viewBox=0 0 4 5%3E%3Cpath fill=%23343a40 d=M2 0L0 2h4zm0 5L0 3h4z/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #80bdff;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:first-child .card-header,
  .card-group > .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group > .card:first-child .card-footer,
  .card-group > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:last-child .card-header,
  .card-group > .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group > .card:last-child .card-footer,
  .card-group > .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }

  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }

  .card-group > .card:only-child .card-header,
  .card-group > .card:only-child .card-img-top {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .card-group > .card:only-child .card-footer,
  .card-group > .card:only-child .card-img-bottom {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }

  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:focus,
.badge-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:focus,
.badge-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:focus,
.badge-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:focus,
.badge-warning[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:focus,
.badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:focus,
.badge-light[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:focus,
.badge-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:focus,
.list-group-item:hover {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto[x-placement^=top],
.bs-popover-top {
  margin-bottom: 0.5rem;
}

.bs-popover-auto[x-placement^=top] .arrow,
.bs-popover-top .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-auto[x-placement^=top] .arrow::after,
.bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-top .arrow::before {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^=top] .arrow::after,
.bs-popover-top .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-auto[x-placement^=right],
.bs-popover-right {
  margin-left: 0.5rem;
}

.bs-popover-auto[x-placement^=right] .arrow,
.bs-popover-right .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^=right] .arrow::after,
.bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-right .arrow::before {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^=right] .arrow::after,
.bs-popover-right .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-auto[x-placement^=bottom],
.bs-popover-bottom {
  margin-top: 0.5rem;
}

.bs-popover-auto[x-placement^=bottom] .arrow,
.bs-popover-bottom .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-auto[x-placement^=bottom] .arrow::after,
.bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-bottom .arrow::before {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^=bottom] .arrow::after,
.bs-popover-bottom .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-auto[x-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-auto[x-placement^=left],
.bs-popover-left {
  margin-right: 0.5rem;
}

.bs-popover-auto[x-placement^=left] .arrow,
.bs-popover-left .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^=left] .arrow::after,
.bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-left .arrow::before {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^=left] .arrow::after,
.bs-popover-left .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.active.carousel-item-right,
.carousel-item-next {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .active.carousel-item-right,
  .carousel-item-next {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.active.carousel-item-left,
.carousel-item-prev {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .active.carousel-item-left,
  .carousel-item-prev {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: 0.6s;
  transition-property: opacity;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev,
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev,
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=http://www.w3.org/2000/svg fill=%23fff viewBox=0 0 8 8%3E%3Cpath d=M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=http://www.w3.org/2000/svg fill=%23fff viewBox=0 0 8 8%3E%3Cpath d=M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #1d2124 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote,
  pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

@page {
    size: a3;
}

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}
@charset "UTF-8";

/* Slider */

.slick-loading .slick-list {
  background: #fff;
}

/* Icons */

@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;
  src: url("fonts/slick.eot");
  src: url("fonts/slick.eot?#iefix") format("embedded-opentype"), url("fonts/slick.woff") format("woff"), url("fonts/slick.ttf") format("truetype"), url("fonts/slick.svg#slick") format("svg");
}

/* Arrows */

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "←";
}

[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir=rtl] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "→";
}

[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}
@font-face {
  font-family: "hs-icons";
  src: url("assets/vendor/icon-hs/fonts/hs-icons.ttf?xa77py") format("truetype"), url("assets/vendor/icon-hs/fonts/hs-icons.woff?xa77py") format("woff"), url("assets/vendor/icon-hs/fonts/hs-icons.svg?xa77py#hs-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

.hs-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "hs-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hs-icon-arrow-bottom:before {
  content: "\e900";
}

.hs-icon-arrow-left:before {
  content: "\e901";
}

.hs-icon-arrow-right:before {
  content: "\e902";
}

.hs-icon-arrow-top:before {
  content: "\e903";
}

.hs-icon-close:before {
  content: "\e904";
}

.hs-icon-hamburger-2:before {
  content: "\e905";
}

.hs-icon-hamburger:before {
  content: "\e906";
}

.hs-icon-lula-kebab-h:before {
  content: "\e907";
}

.hs-icon-lula-kebab-v:before {
  content: "\e908";
}

.hs-icon-magnifier:before {
  content: "\e909";
}

.hs-icon-music:before {
  content: "\e90a";
}

.hs-icon-photo:before {
  content: "\e90b";
}

.hs-icon-play:before {
  content: "\e90c";
}

.hs-icon-plus:before {
  content: "\e90d";
}

.hs-icon-unzoom:before {
  content: "\e90e";
}

.hs-icon-video:before {
  content: "\e90f";
}

.hs-icon-zoom:before {
  content: "\e910";
}
@charset "UTF-8";

/*------------------------------------
  Default Styles
------------------------------------*/

html {
  font-size: 14px;
}

body {
  font-weight: 400;
  font-size: 1rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  line-height: 1.6;
  color: #555;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga", "kern";
  text-rendering: optimizelegibility;
  background-color: #fff;
}

a {
  color: #22211f;
  outline: none;
}

a:focus,
a:hover {
  color: #151413;
}

.nav-link {
  color: #555;
}

.nav-link:focus,
.nav-link:hover {
  color: #555;
}

figure {
  margin-bottom: 0;
}

/*------------------------------------
  Headings
------------------------------------*/

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.h7,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
}

.h7 {
  font-size: 0.75rem;
}

/*------------------------------------
  Displays
------------------------------------*/

.display-5 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.1;
}

/*------------------------------------
  Highlight Color
------------------------------------*/

::-moz-selection {
  color: #fff;
  background-color: #22211f;
}

::selection {
  color: #fff;
  background-color: #22211f;
}

.g-bg-primary ::-moz-selection {
  color: #22211f;
  background-color: #fff;
}

.g-bg-primary ::selection {
  color: #22211f;
  background-color: #fff;
}

/*------------------------------------
  Components
------------------------------------*/

/*@import "base/alerts";*/

/*------------------------------------
  Arrows
------------------------------------*/

.u-arrow-v1::before {
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  vertical-align: top;
  transform: translateX(-50%) translateY(-50%);
}

.u-arrow-v1[class*=abs]::before {
  position: absolute;
}

.u-arrow-custom-v1 .u-arrow-icon {
  display: block;
}

.u-arrow-custom-v1 .u-arrow-text {
  position: absolute;
  top: 50%;
  transition: transform 0.3s;
}

.u-arrow-custom-v1.js-prev .u-arrow-text {
  transform: translateY(-50%) translateX(-100%);
}

.u-arrow-custom-v1.js-next .u-arrow-text {
  transform: translateY(-50%) translateX(100%);
}

.u-arrow-custom-v1:hover .u-arrow-text {
  transform: translateY(-50%) translateX(0);
}

.u-arrow-custom-v2 {
  overflow: hidden;
  transition: background-color 0.3s;
}

.u-arrow-custom-v2 .u-arrow-icon {
  transition: transform 0.3s;
}

.u-arrow-custom-v2 .u-arrow-img {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}

.u-arrow-custom-v2.js-prev .u-arrow-icon {
  transform: translateY(-50%) translateX(10px);
}

.u-arrow-custom-v2.js-prev .u-arrow-img {
  transform: translateX(100%);
}

.u-arrow-custom-v2.js-next .u-arrow-icon {
  transform: translateY(-50%) translateX(-10px);
}

.u-arrow-custom-v2.js-next .u-arrow-img {
  transform: translateX(-100%);
}

.u-arrow-custom-v2:hover .u-arrow-icon {
  transform: translateY(-50%) translateX(0);
}

.u-arrow-custom-v2:hover .u-arrow-img {
  opacity: 0.6;
  transform: translateX(0);
}

.u-arrow-custom-v3 .u-arrow-helper {
  content: "";
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  transform: scale(0.9);
}

.u-arrow-custom-v3 .u-arrow-icon {
  display: block;
}

.u-arrow-custom-v3 .u-arrow-icon-before,
.u-arrow-custom-v3 .u-arrow-icon-after {
  position: absolute;
  left: 25%;
  transition: transform 0.3s, background-color 0.3s;
  backface-visibility: hidden;
}

.u-arrow-custom-v3 .u-arrow-icon-before {
  transform: translateX(-50%) rotate(30deg);
  transform-origin: 0 100%;
}

.u-arrow-custom-v3 .u-arrow-icon-after {
  top: 50%;
  transform: translateX(-50%) rotate(-30deg);
  transform-origin: 0 0;
}

.u-arrow-custom-v3.js-next .u-arrow-icon {
  transform: rotate(180deg);
}

.u-arrow-custom-v3:hover .u-arrow-helper {
  background-color: #fff;
  opacity: 1;
  transform: scale(1);
}

.u-arrow-custom-v3:hover .u-arrow-icon-before,
.u-arrow-custom-v3:hover .u-arrow-icon-after {
  background-color: #22211f;
}

.u-arrow-custom-v3:hover .u-arrow-icon-before {
  transform: translateX(-50%) rotate(45deg);
}

.u-arrow-custom-v3:hover .u-arrow-icon-after {
  transform: translateX(-50%) rotate(-45deg);
}

.u-arrow-custom-v4 {
  width: 62px;
  height: 62px;
  transition: width 0.3s, background-color 0.3s;
}

.u-arrow-custom-v4 h4 {
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}

.u-arrow-custom-v4.js-prev h4 {
  transform: translateX(100%);
}

.u-arrow-custom-v4.js-next h4 {
  transform: translateX(-100%);
}

.u-arrow-custom-v4:hover {
  width: 200px;
}

.u-arrow-custom-v4:hover .u-arrow-icon {
  color: #22211f;
}

.u-arrow-custom-v4:hover h4 {
  opacity: 1;
  transition-delay: 0.1s;
  transform: translateX(0);
}

.u-arrow-custom-v5 .u-arrow-icon::before {
  transition: transform 0.3s 0.3s;
}

.u-arrow-custom-v5 .u-arrow-text {
  transition: transform 0.3s 0.3s;
  perspective: 1000px;
}

.u-arrow-custom-v5 .u-arrow-title {
  position: absolute;
  top: 100%;
  width: 100%;
  height: 30%;
  transition: transform 0.3s;
  backface-visibility: hidden;
  transform-origin: 50% 0;
  transform: rotateX(-90deg);
  margin: 0;
}

.u-arrow-custom-v5 .u-arrow-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.u-arrow-custom-v5.js-prev .u-arrow-text {
  transform: translateX(-100%);
}

.u-arrow-custom-v5.js-next .u-arrow-text {
  transform: translateX(100%);
}

.u-arrow-custom-v5:hover .u-arrow-text {
  transform: translateX(0);
}

.u-arrow-custom-v5:hover .u-arrow-title {
  transition-delay: 0.6s;
  transform: rotateX(0deg);
}

.u-arrow-custom-v5:hover .u-arrow-icon::before {
  transition-delay: 0s;
}

.u-arrow-custom-v5:hover.js-prev .u-arrow-icon::before {
  transform: translateX(-100%);
}

.u-arrow-custom-v5:hover.js-next .u-arrow-icon::before {
  transform: translateX(100%);
}

.u-arrow-custom-v6 {
  overflow: hidden;
  width: 40px;
  transition: width 0.4s, background-color 0.4s, z-index 0.4s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}

.u-arrow-custom-v6 .u-arrow-text {
  width: 400px;
}

.u-arrow-custom-v6:hover {
  width: 400px;
  z-index: 11;
}

.u-arrow-custom-v7 {
  perspective: 1000px;
}

.u-arrow-custom-v7 .u-arrow-icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.u-arrow-custom-v7 .u-arrow-img {
  backface-visibility: hidden;
  transition: transform 0.3s;
}

.u-arrow-custom-v7.js-prev {
  perspective-origin: 100% 50%;
}

.u-arrow-custom-v7.js-prev .u-arrow-img {
  transform-origin: 0 50%;
  transform: rotateY(90deg);
}

.u-arrow-custom-v7.js-next {
  perspective-origin: 0 50%;
}

.u-arrow-custom-v7.js-next .u-arrow-img {
  transform-origin: 100% 50%;
  transform: rotateY(-90deg);
}

.u-arrow-custom-v7:hover .u-arrow-icon {
  color: #22211f;
}

.u-arrow-custom-v7:hover .u-arrow-img {
  transform: rotateY(0deg);
}

.u-arrow-custom-v8 {
  perspective: 1000px;
}

.u-arrow-custom-v8 .u-arrow-icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.u-arrow-custom-v8 .u-arrow-title,
.u-arrow-custom-v8 .u-arrow-img {
  backface-visibility: hidden;
}

.u-arrow-custom-v8 .u-arrow-title {
  transition: transform 0.3s;
}

.u-arrow-custom-v8 .u-arrow-text {
  transition: transform 0.3s 0.3s;
  transform-style: preserve-3d;
}

.u-arrow-custom-v8.js-prev {
  perspective-origin: 100% 50%;
}

.u-arrow-custom-v8.js-prev .u-arrow-text,
.u-arrow-custom-v8.js-prev .u-arrow-title {
  transform-origin: 0 50%;
  transform: rotateY(90deg);
}

.u-arrow-custom-v8.js-next {
  perspective-origin: 0 50%;
}

.u-arrow-custom-v8.js-next .u-arrow-text,
.u-arrow-custom-v8.js-next .u-arrow-title {
  transform-origin: 100% 50%;
  transform: rotateY(-90deg);
}

.u-arrow-custom-v8:hover .u-arrow-icon {
  color: #22211f;
  transition-delay: 0s;
}

.u-arrow-custom-v8:hover .u-arrow-icon::before {
  transition-delay: 0s;
}

.u-arrow-custom-v8:hover .u-arrow-text,
.u-arrow-custom-v8:hover .u-arrow-title {
  transform: rotateY(0deg);
}

.u-arrow-custom-v8:hover .u-arrow-text {
  transition-delay: 0s;
}

.u-arrow-custom-v8:hover .u-arrow-title {
  transition-delay: 0.3s;
}

.u-arrow-custom-v9 {
  width: 60px;
}

.u-arrow-custom-v9 .u-arrow-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 20px;
  height: 1px;
  background: #fff;
  transition: width 0.3s 0.2s;
  backface-visibility: hidden;
}

.u-arrow-custom-v9 .u-arrow-text::after {
  content: attr(data-title);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  color: #fff;
  text-transform: uppercase;
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  margin: 20px 5px 0;
}

.u-arrow-custom-v9 .u-arrow-img {
  opacity: 0;
  box-shadow: 0 1px 0 #fff, 0 -1px 0 #fff;
  transition: transform 0.3s, opacity 0.3s;
  transform: scale(0.3);
}

.u-arrow-custom-v9.js-prev .u-arrow-icon::after {
  left: calc(100% - 25px);
}

.u-arrow-custom-v9.js-next .u-arrow-icon::after {
  right: calc(100% - 25px);
}

.u-arrow-custom-v9.js-next .u-arrow-text::after {
  text-align: right;
}

.u-arrow-custom-v9:hover {
  width: 200px;
}

.u-arrow-custom-v9:hover .u-arrow-icon::after {
  width: 200px;
  transition-delay: 0s;
}

.u-arrow-custom-v9:hover .u-arrow-text {
  pointer-events: auto;
}

.u-arrow-custom-v9:hover .u-arrow-text::after {
  opacity: 1;
  transition-delay: 0.2s;
  transform: translateY(0);
}

.u-arrow-custom-v9:hover .u-arrow-img {
  opacity: 1;
  transform: scale(1);
}

.u-arrow-custom-v9:hover .u-arrow-img:first-child {
  transition-delay: 0.3s;
}

.u-arrow-custom-v9:hover .u-arrow-img:nth-child(2) {
  transition-delay: 0.35s;
}

.u-arrow-custom-v9:hover .u-arrow-img:nth-child(3) {
  transition-delay: 0.4s;
}

.u-arrow-custom-v10 .u-arrow-text {
  opacity: 0;
  overflow: hidden;
  transform: scale(0.7);
  transition: transform 0.3s, opacity 0.3s, background-color 0.1s 0s;
}

.u-arrow-custom-v10 .u-arrow-img {
  transition: transform 0.3s;
}

.u-arrow-custom-v10.js-prev .u-arrow-img {
  transform: translateX(-100%);
}

.u-arrow-custom-v10.js-next .u-arrow-img {
  transform: translateX(100%);
}

.u-arrow-custom-v10:hover .u-arrow-text {
  opacity: 1;
  background-color: transparent;
  transform: scale(1);
  transition: transform 0.3s, opacity 0.3s, background-color 0.1s 0.2s;
}

.u-arrow-custom-v10:hover .u-arrow-img {
  transform: translateX(0);
}

.u-arrow-custom-v11 .u-arrow-icon {
  border-width: 0;
  transition: border-width 0.3s 0.15s;
}

.u-arrow-custom-v11 .u-arrow-text {
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
}

.u-arrow-custom-v11.js-prev .u-arrow-text {
  transform: translateY(-50%) translateX(-100%) scale(0.75);
}

.u-arrow-custom-v11.js-next .u-arrow-text {
  transform: translateY(-50%) translateX(100%) scale(0.75);
}

.u-arrow-custom-v11:hover .u-arrow-icon {
  border-top-width: 40px;
  border-bottom-width: 40px;
  transition-delay: 0s;
}

.u-arrow-custom-v11:hover .u-arrow-text {
  opacity: 1;
  transition-delay: 0.3s;
  transform: translateY(-50%) translateX(0) scale(1);
}

.u-arrow-custom-v12 .u-arrow-icon {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.u-arrow-custom-v12 .u-arrow-icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.u-arrow-custom-v12 .u-arrow-text {
  overflow: hidden;
  transition: transform 0.3s;
  backface-visibility: hidden;
  transform: scale(0.6);
}

.u-arrow-custom-v12 .u-arrow-img {
  opacity: 0;
  backface-visibility: hidden;
  transition: opacity 0.3s, transform 0.3s;
}

.u-arrow-custom-v12:hover .u-arrow-img {
  opacity: 0.8;
}

.u-arrow-custom-v12.js-prev .u-arrow-img {
  transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(2);
}

.u-arrow-custom-v12.js-prev:hover .u-arrow-text {
  transform: scale(1) rotate(-45deg);
}

.u-arrow-custom-v12.js-prev:hover .u-arrow-img {
  transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(1);
}

.u-arrow-custom-v12.js-next .u-arrow-img {
  transform: translateX(-50%) translateY(-50%) rotate(-45deg) scale(2);
}

.u-arrow-custom-v12.js-next:hover .u-arrow-text {
  transform: scale(1) rotate(45deg);
}

.u-arrow-custom-v12.js-next:hover .u-arrow-img {
  transform: translateX(-50%) translateY(-50%) rotate(-45deg) scale(1);
}

.u-arrow-custom-v13 .u-arrow-icon {
  overflow: hidden;
  backface-visibility: hidden;
}

.u-arrow-custom-v13 .u-arrow-icon::before {
  position: relative;
  z-index: 2;
}

.u-arrow-custom-v13 .u-arrow-icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background: #fff;
  backface-visibility: hidden;
  transition: transform 0.3s 0.3s;
}

.u-arrow-custom-v13 .u-arrow-text {
  transition: transform 0.3s;
}

.u-arrow-custom-v13.js-prev .u-arrow-icon::after {
  transform: translateX(-100%);
}

.u-arrow-custom-v13.js-prev .u-arrow-text {
  transform: translateX(-100%);
}

.u-arrow-custom-v13.js-next .u-arrow-icon::after {
  transform: translateX(100%);
}

.u-arrow-custom-v13.js-next .u-arrow-text {
  transform: translateX(100%);
}

.u-arrow-custom-v13:hover .u-arrow-icon::after {
  transform: translateX(0);
  transition-delay: 0s;
}

.u-arrow-custom-v13:hover .u-arrow-text {
  transition-delay: 0.3s;
  transform: translateX(0);
}

.u-arrow-custom-v14::before,
.u-arrow-custom-v14::after,
.u-arrow-custom-v14 .u-arrow-icon::before,
.u-arrow-custom-v14 .u-arrow-icon::after {
  content: "";
  position: absolute;
  left: 50%;
  width: 3px;
  height: 50%;
  background: #22211f;
  transition: transform 0.3s;
  backface-visibility: hidden;
}

.u-arrow-custom-v14::before,
.u-arrow-custom-v14 .u-arrow-icon::before {
  top: 50%;
  transform: translateX(-50%) rotate(-135deg);
  transform-origin: 50% 0;
}

.u-arrow-custom-v14::after,
.u-arrow-custom-v14 .u-arrow-icon::after {
  top: 50%;
  transform: translateX(-50%) rotate(-45deg);
  transform-origin: 0 0;
}

.u-arrow-custom-v14 .u-arrow-icon::before,
.u-arrow-custom-v14 .u-arrow-icon::after {
  z-index: 2;
  height: 0;
  background: #fff;
  transition: height 0.3s, transform 0.3s;
}

.u-arrow-custom-v14 .u-arrow-title {
  position: absolute;
  top: 50%;
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  margin: 0;
}

.u-arrow-custom-v14.js-prev .u-arrow-title {
  left: 100%;
  transform: translateY(-50%) translateX(-50%);
}

.u-arrow-custom-v14.js-next::before,
.u-arrow-custom-v14.js-next .u-arrow-icon::before {
  transform: translateX(-50%) rotate(135deg);
  transform-origin: 50% 0;
}

.u-arrow-custom-v14.js-next::after,
.u-arrow-custom-v14.js-next .u-arrow-icon::after {
  transform: translateX(-50%) rotate(45deg);
  transform-origin: 100% 0;
}

.u-arrow-custom-v14.js-next .u-arrow-title {
  right: 100%;
  text-align: right;
  transform: translateY(-50%) translateX(50%);
}

.u-arrow-custom-v14:hover::before,
.u-arrow-custom-v14:hover .u-arrow-icon::before {
  transform: translateX(-50%) rotate(-125deg);
}

.u-arrow-custom-v14:hover::after,
.u-arrow-custom-v14:hover .u-arrow-icon::after {
  transform: translateX(-50%) rotate(-55deg);
}

.u-arrow-custom-v14:hover .u-arrow-icon::before,
.u-arrow-custom-v14:hover .u-arrow-icon::after {
  height: 50%;
}

.u-arrow-custom-v14:hover .u-arrow-title {
  opacity: 1;
  transform: translateY(-50%) translateX(0);
}

.u-arrow-custom-v14:hover.js-next::before,
.u-arrow-custom-v14:hover.js-next .u-arrow-icon::before {
  transform: translateX(-50%) rotate(125deg);
}

.u-arrow-custom-v14:hover.js-next::after,
.u-arrow-custom-v14:hover.js-next .u-arrow-icon::after {
  transform: translateX(-50%) rotate(55deg);
}

/*@import "base/badges/badges";
@import "base/badges/badges-v1";
@import "base/badges/badges-v2";
@import "base/badges/badges-v3";*/

/*@import "base/dividers/dividers";
@import "base/dividers/divider-style-solid";
@import "base/dividers/divider-style-dotted";
@import "base/dividers/divider-style-dashed";
@import "base/dividers/divider-style-db-solid";
@import "base/dividers/divider-style-db-dashed";
@import "base/dividers/divider-style-db-dotted";
@import "base/dividers/divider-style-linear-gradient";
@import "base/dividers/divider-type-icon";*/

/*@import "base/go-to/go-to-v1";
@import "base/go-to/go-to-v2";
@import "base/go-to/go-to-v3";
@import "base/go-to/go-to-v4";*/

/*@import "base/headers/headers";
@import "base/headers/header-togglers";
@import "base/headers/header-fullscreen";
@import "base/headers/header-side";*/

/* Button Styles
------------------------------------*/

/* General Button Styles */

.btn {
  position: relative;
  transition: 0.2s ease;
  cursor: pointer;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: 0 none;
  box-shadow: none;
}

/* Button Content
------------------------------------*/

.u-btn-content {
  white-space: normal;
}

/* Buttons Only Icon (O)
------------------------------------*/

.u-btn-only-icon {
  position: relative;
}

.u-btn-only-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/* Button Sizes
------------------------------------*/

/* Extra Small */

.btn-xs {
  line-height: 1.4;
  padding: 0.1428571429rem 0.5rem;
  font-size: 0.7857142857rem;
}

/* Medium */

.btn-md {
  line-height: 1.4;
  padding: 0.5714285714rem 1.4285714286rem;
  font-size: 1.0714285714rem;
}

/* Extra Large */

.btn-xl {
  line-height: 1.4;
  padding: 0.9285714286rem 1.8571428571rem;
  font-size: 1.2857142857rem;
}

/* Extramly Large */

.btn-xxl {
  line-height: 1.4;
  padding: 1.0714285714rem 2.1428571429rem;
  font-size: 1.5rem;
}

/* Button Types
------------------------------------*/

/* Inset Buttons */

.u-btn-inset {
  position: relative;
}

.u-btn-inset::before {
  position: absolute;
  top: 0.1428571429rem;
  right: 0.1428571429rem;
  bottom: 0.1428571429rem;
  left: 0.1428571429rem;
  content: "";
  border: solid 1px #fff;
  border-radius: 1px;
}

.u-btn-inset--rounded::before {
  border-radius: 50px;
}

/* 3d Buttons */

.u-btn-3d {
  border-bottom: solid 3px rgba(0, 0, 0, 0.2);
}

.u-btn-3d:hover {
  border-bottom-color: rgba(0, 0, 0, 0.3);
}

/* Skew Button */

.u-btn-skew {
  transform: skewX(-20deg);
}

.u-btn-skew__inner {
  transform: skewX(20deg);
  display: block;
}

/* Button Hovers
------------------------------------*/

[class*=u-btn-hover] {
  z-index: 1;
}

[class*=u-btn-hover]:hover {
  text-decoration: none;
}

[class*=u-btn-hover]:focus {
  text-decoration: none;
}

[class*=u-btn-hover]::after {
  position: absolute;
  content: "";
  z-index: -1;
  transition: all 0.3s;
}

/* Hover v1-1 */

.u-btn-hover-v1-1::after {
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
}

.u-btn-hover-v1-1:hover::after {
  height: 100%;
}

/* Hover v1-2 */

.u-btn-hover-v1-2::after {
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
}

.u-btn-hover-v1-2:hover::after {
  width: 100%;
}

/* Hover v1-3 */

.u-btn-hover-v1-3::after {
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
}

.u-btn-hover-v1-3:hover::after {
  height: 100%;
}

/* Hover v1-4 */

.u-btn-hover-v1-4::after {
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
}

.u-btn-hover-v1-4:hover::after {
  width: 100%;
}

/* Hover v2-1 */

.u-btn-hover-v2-1::after {
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
}

.u-btn-hover-v2-1:hover::after {
  left: 0;
  width: 100%;
}

/* Hover v2-2 */

.u-btn-hover-v2-2::after {
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
}

.u-btn-hover-v2-2:hover::after {
  top: 0;
  height: 100%;
}

/* Button Primary */

.u-btn-primary {
  color: #fff;
  background-color: #22211f;
}

.u-btn-primary:hover,
.u-btn-primary.active {
  border-color: #3d3b37;
  background-color: #3d3b37;
}

.u-btn-primary:hover,
.u-btn-primary:focus,
.u-btn-primary.active {
  color: #fff;
}

.u-btn-primary.g-btn-hover-reset:hover,
.u-btn-primary.g-btn-hover-reset.active {
  background-color: #22211f;
  border-color: #22211f;
}

.u-btn-primary.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-1::after,
.u-btn-primary.u-btn-hover-v1-1:hover::after {
  background-color: #2f2e2b;
}

.u-btn-primary.u-btn-hover-v1-1:hover {
  background-color: #22211f;
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-2::after,
.u-btn-primary.u-btn-hover-v1-2:hover::after {
  background-color: #2f2e2b;
}

.u-btn-primary.u-btn-hover-v1-2:hover {
  background-color: #22211f;
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-3::after,
.u-btn-primary.u-btn-hover-v1-3:hover::after {
  background-color: #2f2e2b;
}

.u-btn-primary.u-btn-hover-v1-3:hover {
  background-color: #22211f;
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-4::after,
.u-btn-primary.u-btn-hover-v1-4:hover::after {
  background-color: #2f2e2b;
}

.u-btn-primary.u-btn-hover-v1-4:hover {
  background-color: #22211f;
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v2-1::after,
.u-btn-primary.u-btn-hover-v2-1:hover::after {
  background-color: #2f2e2b;
}

.u-btn-primary.u-btn-hover-v2-1:hover {
  background-color: #22211f;
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v2-2::after,
.u-btn-primary.u-btn-hover-v2-2:hover::after {
  background-color: #2f2e2b;
}

.u-btn-primary.u-btn-hover-v2-2:hover {
  background-color: #22211f;
  overflow: hidden;
}

/* Button White */

.u-btn-white {
  color: #555;
  background-color: #fff;
}

.u-btn-white:hover,
.u-btn-white.active {
  border-color: white;
  background-color: white;
}

.u-btn-white:hover,
.u-btn-white:focus,
.u-btn-white.active {
  color: #555;
}

.u-btn-white.g-btn-hover-reset:hover,
.u-btn-white.g-btn-hover-reset.active {
  background-color: #fff;
  border-color: #fff;
}

.u-btn-white.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-1::after,
.u-btn-white.u-btn-hover-v1-1:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v1-1:hover {
  background-color: #fff;
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-2::after,
.u-btn-white.u-btn-hover-v1-2:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v1-2:hover {
  background-color: #fff;
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-3::after,
.u-btn-white.u-btn-hover-v1-3:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v1-3:hover {
  background-color: #fff;
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-4::after,
.u-btn-white.u-btn-hover-v1-4:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v1-4:hover {
  background-color: #fff;
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v2-1::after,
.u-btn-white.u-btn-hover-v2-1:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-white.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v2-2::after,
.u-btn-white.u-btn-hover-v2-2:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Button Black */

.u-btn-black {
  color: #fff;
  background-color: #000;
}

.u-btn-black:hover,
.u-btn-black.active {
  border-color: #1a1a1a;
  background-color: #1a1a1a;
}

.u-btn-black:hover,
.u-btn-black:focus,
.u-btn-black.active {
  color: #fff;
}

.u-btn-black.g-btn-hover-reset:hover,
.u-btn-black.g-btn-hover-reset.active {
  background-color: #000;
  border-color: #000;
}

.u-btn-black.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-1::after,
.u-btn-black.u-btn-hover-v1-1:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v1-1:hover {
  background-color: #000;
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-2::after,
.u-btn-black.u-btn-hover-v1-2:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v1-2:hover {
  background-color: #000;
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-3::after,
.u-btn-black.u-btn-hover-v1-3:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v1-3:hover {
  background-color: #000;
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-4::after,
.u-btn-black.u-btn-hover-v1-4:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v1-4:hover {
  background-color: #000;
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v2-1::after,
.u-btn-black.u-btn-hover-v2-1:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-black.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v2-2::after,
.u-btn-black.u-btn-hover-v2-2:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Button Dark Gray */

.u-btn-darkgray {
  color: #fff;
  background-color: #333;
}

.u-btn-darkgray:hover,
.u-btn-darkgray.active {
  border-color: #4d4d4d;
  background-color: #4d4d4d;
}

.u-btn-darkgray:hover,
.u-btn-darkgray:focus,
.u-btn-darkgray.active {
  color: #fff;
}

.u-btn-darkgray.g-btn-hover-reset:hover,
.u-btn-darkgray.g-btn-hover-reset.active {
  background-color: #333;
  border-color: #333;
}

.u-btn-darkgray.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-1::after,
.u-btn-darkgray.u-btn-hover-v1-1:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v1-1:hover {
  background-color: #333;
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-2::after,
.u-btn-darkgray.u-btn-hover-v1-2:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v1-2:hover {
  background-color: #333;
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-3::after,
.u-btn-darkgray.u-btn-hover-v1-3:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v1-3:hover {
  background-color: #333;
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-4::after,
.u-btn-darkgray.u-btn-hover-v1-4:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v1-4:hover {
  background-color: #333;
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v2-1::after,
.u-btn-darkgray.u-btn-hover-v2-1:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v2-1:hover {
  background-color: #333;
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v2-2::after,
.u-btn-darkgray.u-btn-hover-v2-2:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v2-2:hover {
  background-color: #333;
  overflow: hidden;
}

/* Button Red */

.u-btn-red {
  color: #fff;
  background-color: #f00;
}

.u-btn-red:hover,
.u-btn-red.active {
  border-color: #ff3333;
  background-color: #ff3333;
}

.u-btn-red:hover,
.u-btn-red:focus,
.u-btn-red.active {
  color: #fff;
}

.u-btn-red.g-btn-hover-reset:hover,
.u-btn-red.g-btn-hover-reset.active {
  background-color: #f00;
  border-color: #f00;
}

.u-btn-red.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-1::after,
.u-btn-red.u-btn-hover-v1-1:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v1-1:hover {
  background-color: #f00;
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-2::after,
.u-btn-red.u-btn-hover-v1-2:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v1-2:hover {
  background-color: #f00;
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-3::after,
.u-btn-red.u-btn-hover-v1-3:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v1-3:hover {
  background-color: #f00;
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-4::after,
.u-btn-red.u-btn-hover-v1-4:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v1-4:hover {
  background-color: #f00;
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v2-1::after,
.u-btn-red.u-btn-hover-v2-1:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v2-1:hover {
  background-color: #f00;
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v2-2::after,
.u-btn-red.u-btn-hover-v2-2:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v2-2:hover {
  background-color: #f00;
  overflow: hidden;
}

/* Button Red Tomato */

.u-btn-lightred {
  color: #fff;
  background-color: #e64b3b;
}

.u-btn-lightred:hover,
.u-btn-lightred.active {
  border-color: #ec7568;
  background-color: #ec7568;
}

.u-btn-lightred:hover,
.u-btn-lightred:focus,
.u-btn-lightred.active {
  color: #fff;
}

.u-btn-lightred.g-btn-hover-reset:hover,
.u-btn-lightred.g-btn-hover-reset.active {
  background-color: #e64b3b;
  border-color: #e64b3b;
}

.u-btn-lightred.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-1::after,
.u-btn-lightred.u-btn-hover-v1-1:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v1-1:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-2::after,
.u-btn-lightred.u-btn-hover-v1-2:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v1-2:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-3::after,
.u-btn-lightred.u-btn-hover-v1-3:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v1-3:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-4::after,
.u-btn-lightred.u-btn-hover-v1-4:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v1-4:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v2-1::after,
.u-btn-lightred.u-btn-hover-v2-1:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v2-1:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v2-2::after,
.u-btn-lightred.u-btn-hover-v2-2:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v2-2:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

/* Button Dark Red */

.u-btn-darkred {
  color: #fff;
  background-color: #a10f2b;
}

.u-btn-darkred:hover,
.u-btn-darkred.active {
  border-color: #d01337;
  background-color: #d01337;
}

.u-btn-darkred:hover,
.u-btn-darkred:focus,
.u-btn-darkred.active {
  color: #fff;
}

.u-btn-darkred.g-btn-hover-reset:hover,
.u-btn-darkred.g-btn-hover-reset.active {
  background-color: #a10f2b;
  border-color: #a10f2b;
}

.u-btn-darkred.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-1::after,
.u-btn-darkred.u-btn-hover-v1-1:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v1-1:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-2::after,
.u-btn-darkred.u-btn-hover-v1-2:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v1-2:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-3::after,
.u-btn-darkred.u-btn-hover-v1-3:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v1-3:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-4::after,
.u-btn-darkred.u-btn-hover-v1-4:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v1-4:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v2-1::after,
.u-btn-darkred.u-btn-hover-v2-1:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v2-1:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v2-2::after,
.u-btn-darkred.u-btn-hover-v2-2:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v2-2:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

/* Button Blue */

.u-btn-blue {
  color: #fff;
  background-color: #3398dc;
}

.u-btn-blue:hover,
.u-btn-blue.active {
  border-color: #5faee3;
  background-color: #5faee3;
}

.u-btn-blue:hover,
.u-btn-blue:focus,
.u-btn-blue.active {
  color: #fff;
}

.u-btn-blue.g-btn-hover-reset:hover,
.u-btn-blue.g-btn-hover-reset.active {
  background-color: #3398dc;
  border-color: #3398dc;
}

.u-btn-blue.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-1::after,
.u-btn-blue.u-btn-hover-v1-1:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v1-1:hover {
  background-color: #3398dc;
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-2::after,
.u-btn-blue.u-btn-hover-v1-2:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v1-2:hover {
  background-color: #3398dc;
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-3::after,
.u-btn-blue.u-btn-hover-v1-3:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v1-3:hover {
  background-color: #3398dc;
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-4::after,
.u-btn-blue.u-btn-hover-v1-4:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v1-4:hover {
  background-color: #3398dc;
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v2-1::after,
.u-btn-blue.u-btn-hover-v2-1:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v2-1:hover {
  background-color: #3398dc;
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v2-2::after,
.u-btn-blue.u-btn-hover-v2-2:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v2-2:hover {
  background-color: #3398dc;
  overflow: hidden;
}

/* Button Indigo */

.u-btn-indigo {
  color: #fff;
  background-color: #4263a3;
}

.u-btn-indigo:hover,
.u-btn-indigo.active {
  border-color: #5b7cbd;
  background-color: #5b7cbd;
}

.u-btn-indigo:hover,
.u-btn-indigo:focus,
.u-btn-indigo.active {
  color: #fff;
}

.u-btn-indigo.g-btn-hover-reset:hover,
.u-btn-indigo.g-btn-hover-reset.active {
  background-color: #4263a3;
  border-color: #4263a3;
}

.u-btn-indigo.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-1::after,
.u-btn-indigo.u-btn-hover-v1-1:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v1-1:hover {
  background-color: #4263a3;
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-2::after,
.u-btn-indigo.u-btn-hover-v1-2:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v1-2:hover {
  background-color: #4263a3;
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-3::after,
.u-btn-indigo.u-btn-hover-v1-3:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v1-3:hover {
  background-color: #4263a3;
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-4::after,
.u-btn-indigo.u-btn-hover-v1-4:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v1-4:hover {
  background-color: #4263a3;
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v2-1::after,
.u-btn-indigo.u-btn-hover-v2-1:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v2-1:hover {
  background-color: #4263a3;
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v2-2::after,
.u-btn-indigo.u-btn-hover-v2-2:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v2-2:hover {
  background-color: #4263a3;
  overflow: hidden;
}

/* Button Purple */

.u-btn-purple {
  color: #fff;
  background-color: #9a69cb;
}

.u-btn-purple:hover,
.u-btn-purple.active {
  border-color: #b48fd8;
  background-color: #b48fd8;
}

.u-btn-purple:hover,
.u-btn-purple:focus,
.u-btn-purple.active {
  color: #fff;
}

.u-btn-purple.g-btn-hover-reset:hover,
.u-btn-purple.g-btn-hover-reset.active {
  background-color: #9a69cb;
  border-color: #9a69cb;
}

.u-btn-purple.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-1::after,
.u-btn-purple.u-btn-hover-v1-1:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v1-1:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-2::after,
.u-btn-purple.u-btn-hover-v1-2:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v1-2:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-3::after,
.u-btn-purple.u-btn-hover-v1-3:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v1-3:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-4::after,
.u-btn-purple.u-btn-hover-v1-4:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v1-4:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v2-1::after,
.u-btn-purple.u-btn-hover-v2-1:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v2-1:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v2-2::after,
.u-btn-purple.u-btn-hover-v2-2:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v2-2:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

/* Button Dark Purple */

.u-btn-darkpurple {
  color: #fff;
  background-color: #6639b6;
}

.u-btn-darkpurple:hover,
.u-btn-darkpurple.active {
  border-color: #8157cb;
  background-color: #8157cb;
}

.u-btn-darkpurple:hover,
.u-btn-darkpurple:focus,
.u-btn-darkpurple.active {
  color: #fff;
}

.u-btn-darkpurple.g-btn-hover-reset:hover,
.u-btn-darkpurple.g-btn-hover-reset.active {
  background-color: #6639b6;
  border-color: #6639b6;
}

.u-btn-darkpurple.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-1::after,
.u-btn-darkpurple.u-btn-hover-v1-1:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v1-1:hover {
  background-color: #6639b6;
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-2::after,
.u-btn-darkpurple.u-btn-hover-v1-2:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v1-2:hover {
  background-color: #6639b6;
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-3::after,
.u-btn-darkpurple.u-btn-hover-v1-3:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v1-3:hover {
  background-color: #6639b6;
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-4::after,
.u-btn-darkpurple.u-btn-hover-v1-4:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v1-4:hover {
  background-color: #6639b6;
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v2-1::after,
.u-btn-darkpurple.u-btn-hover-v2-1:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v2-1:hover {
  background-color: #6639b6;
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v2-2::after,
.u-btn-darkpurple.u-btn-hover-v2-2:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v2-2:hover {
  background-color: #6639b6;
  overflow: hidden;
}

/* Button Pink */

.u-btn-pink {
  color: #fff;
  background-color: #e81c62;
}

.u-btn-pink:hover,
.u-btn-pink.active {
  border-color: #ed4a82;
  background-color: #ed4a82;
}

.u-btn-pink:hover,
.u-btn-pink:focus,
.u-btn-pink.active {
  color: #fff;
}

.u-btn-pink.g-btn-hover-reset:hover,
.u-btn-pink.g-btn-hover-reset.active {
  background-color: #e81c62;
  border-color: #e81c62;
}

.u-btn-pink.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-1::after,
.u-btn-pink.u-btn-hover-v1-1:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v1-1:hover {
  background-color: #e81c62;
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-2::after,
.u-btn-pink.u-btn-hover-v1-2:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v1-2:hover {
  background-color: #e81c62;
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-3::after,
.u-btn-pink.u-btn-hover-v1-3:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v1-3:hover {
  background-color: #e81c62;
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-4::after,
.u-btn-pink.u-btn-hover-v1-4:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v1-4:hover {
  background-color: #e81c62;
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v2-1::after,
.u-btn-pink.u-btn-hover-v2-1:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v2-1:hover {
  background-color: #e81c62;
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v2-2::after,
.u-btn-pink.u-btn-hover-v2-2:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v2-2:hover {
  background-color: #e81c62;
  overflow: hidden;
}

/* Button Orange */

.u-btn-orange {
  color: #fff;
  background-color: #e57d20;
}

.u-btn-orange:hover,
.u-btn-orange.active {
  border-color: #ea984e;
  background-color: #ea984e;
}

.u-btn-orange:hover,
.u-btn-orange:focus,
.u-btn-orange.active {
  color: #fff;
}

.u-btn-orange.g-btn-hover-reset:hover,
.u-btn-orange.g-btn-hover-reset.active {
  background-color: #e57d20;
  border-color: #e57d20;
}

.u-btn-orange.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-1::after,
.u-btn-orange.u-btn-hover-v1-1:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v1-1:hover {
  background-color: #e57d20;
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-2::after,
.u-btn-orange.u-btn-hover-v1-2:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v1-2:hover {
  background-color: #e57d20;
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-3::after,
.u-btn-orange.u-btn-hover-v1-3:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v1-3:hover {
  background-color: #e57d20;
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-4::after,
.u-btn-orange.u-btn-hover-v1-4:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v1-4:hover {
  background-color: #e57d20;
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v2-1::after,
.u-btn-orange.u-btn-hover-v2-1:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v2-1:hover {
  background-color: #e57d20;
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v2-2::after,
.u-btn-orange.u-btn-hover-v2-2:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v2-2:hover {
  background-color: #e57d20;
  overflow: hidden;
}

/* Button Deep Orange */

.u-btn-deeporange {
  color: #fff;
  background-color: #fe541e;
}

.u-btn-deeporange:hover,
.u-btn-deeporange.active {
  border-color: #fe7b51;
  background-color: #fe7b51;
}

.u-btn-deeporange:hover,
.u-btn-deeporange:focus,
.u-btn-deeporange.active {
  color: #fff;
}

.u-btn-deeporange.g-btn-hover-reset:hover,
.u-btn-deeporange.g-btn-hover-reset.active {
  background-color: #fe541e;
  border-color: #fe541e;
}

.u-btn-deeporange.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-1::after,
.u-btn-deeporange.u-btn-hover-v1-1:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v1-1:hover {
  background-color: #fe541e;
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-2::after,
.u-btn-deeporange.u-btn-hover-v1-2:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v1-2:hover {
  background-color: #fe541e;
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-3::after,
.u-btn-deeporange.u-btn-hover-v1-3:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v1-3:hover {
  background-color: #fe541e;
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-4::after,
.u-btn-deeporange.u-btn-hover-v1-4:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v1-4:hover {
  background-color: #fe541e;
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v2-1::after,
.u-btn-deeporange.u-btn-hover-v2-1:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v2-1:hover {
  background-color: #fe541e;
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v2-2::after,
.u-btn-deeporange.u-btn-hover-v2-2:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v2-2:hover {
  background-color: #fe541e;
  overflow: hidden;
}

/* Button Yellow */

.u-btn-yellow {
  color: #fff;
  background-color: #ebc71d;
}

.u-btn-yellow:hover,
.u-btn-yellow.active {
  border-color: #efd34c;
  background-color: #efd34c;
}

.u-btn-yellow:hover,
.u-btn-yellow:focus,
.u-btn-yellow.active {
  color: #fff;
}

.u-btn-yellow.g-btn-hover-reset:hover,
.u-btn-yellow.g-btn-hover-reset.active {
  background-color: #ebc71d;
  border-color: #ebc71d;
}

.u-btn-yellow.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-1::after,
.u-btn-yellow.u-btn-hover-v1-1:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v1-1:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-2::after,
.u-btn-yellow.u-btn-hover-v1-2:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v1-2:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-3::after,
.u-btn-yellow.u-btn-hover-v1-3:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v1-3:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-4::after,
.u-btn-yellow.u-btn-hover-v1-4:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v1-4:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v2-1::after,
.u-btn-yellow.u-btn-hover-v2-1:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v2-1:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v2-2::after,
.u-btn-yellow.u-btn-hover-v2-2:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v2-2:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

/* Button Aqua */

.u-btn-aqua {
  color: #fff;
  background-color: #29d6e6;
}

.u-btn-aqua:hover,
.u-btn-aqua.active {
  border-color: #57dfeb;
  background-color: #57dfeb;
}

.u-btn-aqua:hover,
.u-btn-aqua:focus,
.u-btn-aqua.active {
  color: #fff;
}

.u-btn-aqua.g-btn-hover-reset:hover,
.u-btn-aqua.g-btn-hover-reset.active {
  background-color: #29d6e6;
  border-color: #29d6e6;
}

.u-btn-aqua.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-1::after,
.u-btn-aqua.u-btn-hover-v1-1:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v1-1:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-2::after,
.u-btn-aqua.u-btn-hover-v1-2:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v1-2:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-3::after,
.u-btn-aqua.u-btn-hover-v1-3:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v1-3:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-4::after,
.u-btn-aqua.u-btn-hover-v1-4:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v1-4:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v2-1::after,
.u-btn-aqua.u-btn-hover-v2-1:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v2-1:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v2-2::after,
.u-btn-aqua.u-btn-hover-v2-2:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v2-2:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

/* Button Cyan */

.u-btn-cyan {
  color: #fff;
  background-color: #00bed6;
}

.u-btn-cyan:hover,
.u-btn-cyan.active {
  border-color: #0ae4ff;
  background-color: #0ae4ff;
}

.u-btn-cyan:hover,
.u-btn-cyan:focus,
.u-btn-cyan.active {
  color: #fff;
}

.u-btn-cyan.g-btn-hover-reset:hover,
.u-btn-cyan.g-btn-hover-reset.active {
  background-color: #00bed6;
  border-color: #00bed6;
}

.u-btn-cyan.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-1::after,
.u-btn-cyan.u-btn-hover-v1-1:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v1-1:hover {
  background-color: #00bed6;
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-2::after,
.u-btn-cyan.u-btn-hover-v1-2:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v1-2:hover {
  background-color: #00bed6;
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-3::after,
.u-btn-cyan.u-btn-hover-v1-3:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v1-3:hover {
  background-color: #00bed6;
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-4::after,
.u-btn-cyan.u-btn-hover-v1-4:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v1-4:hover {
  background-color: #00bed6;
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v2-1::after,
.u-btn-cyan.u-btn-hover-v2-1:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v2-1:hover {
  background-color: #00bed6;
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v2-2::after,
.u-btn-cyan.u-btn-hover-v2-2:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v2-2:hover {
  background-color: #00bed6;
  overflow: hidden;
}

/* Button Teal */

.u-btn-teal {
  color: #fff;
  background-color: #18ba9b;
}

.u-btn-teal:hover,
.u-btn-teal.active {
  border-color: #22e3be;
  background-color: #22e3be;
}

.u-btn-teal:hover,
.u-btn-teal:focus,
.u-btn-teal.active {
  color: #fff;
}

.u-btn-teal.g-btn-hover-reset:hover,
.u-btn-teal.g-btn-hover-reset.active {
  background-color: #18ba9b;
  border-color: #18ba9b;
}

.u-btn-teal.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-1::after,
.u-btn-teal.u-btn-hover-v1-1:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v1-1:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-2::after,
.u-btn-teal.u-btn-hover-v1-2:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v1-2:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-3::after,
.u-btn-teal.u-btn-hover-v1-3:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v1-3:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-4::after,
.u-btn-teal.u-btn-hover-v1-4:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v1-4:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v2-1::after,
.u-btn-teal.u-btn-hover-v2-1:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v2-1:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v2-2::after,
.u-btn-teal.u-btn-hover-v2-2:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v2-2:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

/* Button Brown */

.u-btn-brown {
  color: #fff;
  background-color: #9c8061;
}

.u-btn-brown:hover,
.u-btn-brown.active {
  border-color: #b09980;
  background-color: #b09980;
}

.u-btn-brown:hover,
.u-btn-brown:focus,
.u-btn-brown.active {
  color: #fff;
}

.u-btn-brown.g-btn-hover-reset:hover,
.u-btn-brown.g-btn-hover-reset.active {
  background-color: #9c8061;
  border-color: #9c8061;
}

.u-btn-brown.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-1::after,
.u-btn-brown.u-btn-hover-v1-1:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v1-1:hover {
  background-color: #9c8061;
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-2::after,
.u-btn-brown.u-btn-hover-v1-2:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v1-2:hover {
  background-color: #9c8061;
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-3::after,
.u-btn-brown.u-btn-hover-v1-3:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v1-3:hover {
  background-color: #9c8061;
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-4::after,
.u-btn-brown.u-btn-hover-v1-4:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v1-4:hover {
  background-color: #9c8061;
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v2-1::after,
.u-btn-brown.u-btn-hover-v2-1:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v2-1:hover {
  background-color: #9c8061;
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v2-2::after,
.u-btn-brown.u-btn-hover-v2-2:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v2-2:hover {
  background-color: #9c8061;
  overflow: hidden;
}

/* Button Bluegrey */

.u-btn-bluegray {
  color: #fff;
  background-color: #585f69;
}

.u-btn-bluegray:hover,
.u-btn-bluegray.active {
  border-color: #6f7885;
  background-color: #6f7885;
}

.u-btn-bluegray:hover,
.u-btn-bluegray:focus,
.u-btn-bluegray.active {
  color: #fff;
}

.u-btn-bluegray.g-btn-hover-reset:hover,
.u-btn-bluegray.g-btn-hover-reset.active {
  background-color: #585f69;
  border-color: #585f69;
}

.u-btn-bluegray.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-1::after,
.u-btn-bluegray.u-btn-hover-v1-1:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v1-1:hover {
  background-color: #585f69;
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-2::after,
.u-btn-bluegray.u-btn-hover-v1-2:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v1-2:hover {
  background-color: #585f69;
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-3::after,
.u-btn-bluegray.u-btn-hover-v1-3:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v1-3:hover {
  background-color: #585f69;
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-4::after,
.u-btn-bluegray.u-btn-hover-v1-4:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v1-4:hover {
  background-color: #585f69;
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v2-1::after,
.u-btn-bluegray.u-btn-hover-v2-1:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v2-1:hover {
  background-color: #585f69;
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v2-2::after,
.u-btn-bluegray.u-btn-hover-v2-2:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v2-2:hover {
  background-color: #585f69;
  overflow: hidden;
}

/* Button Facebook */

.u-btn-facebook {
  color: #fff;
  background-color: #3b5998;
}

.u-btn-facebook:hover,
.u-btn-facebook.active {
  border-color: #4c70ba;
  background-color: #4c70ba;
}

.u-btn-facebook:hover,
.u-btn-facebook:focus,
.u-btn-facebook.active {
  color: #fff;
}

.u-btn-facebook.g-btn-hover-reset:hover,
.u-btn-facebook.g-btn-hover-reset.active {
  background-color: #3b5998;
  border-color: #3b5998;
}

/* Button Twitter */

.u-btn-twitter {
  color: #fff;
  background-color: #00acee;
}

.u-btn-twitter:hover,
.u-btn-twitter.active {
  border-color: #22c2ff;
  background-color: #22c2ff;
}

.u-btn-twitter:hover,
.u-btn-twitter:focus,
.u-btn-twitter.active {
  color: #fff;
}

.u-btn-twitter.g-btn-hover-reset:hover,
.u-btn-twitter.g-btn-hover-reset.active {
  background-color: #00acee;
  border-color: #00acee;
}

/* Button Instagram */

.u-btn-instagram {
  color: #fff;
  background-color: #3f729b;
}

.u-btn-instagram:hover,
.u-btn-instagram.active {
  border-color: #548cb9;
  background-color: #548cb9;
}

.u-btn-instagram:hover,
.u-btn-instagram:focus,
.u-btn-instagram.active {
  color: #fff;
}

.u-btn-instagram.g-btn-hover-reset:hover,
.u-btn-instagram.g-btn-hover-reset.active {
  background-color: #3f729b;
  border-color: #3f729b;
}

/* Button VK */

.u-btn-vk {
  color: #fff;
  background-color: #2b587a;
}

.u-btn-vk:hover,
.u-btn-vk.active {
  border-color: #3873a0;
  background-color: #3873a0;
}

.u-btn-vk:hover,
.u-btn-vk:focus,
.u-btn-vk.active {
  color: #fff;
}

.u-btn-vk.g-btn-hover-reset:hover,
.u-btn-vk.g-btn-hover-reset.active {
  background-color: #2b587a;
  border-color: #2b587a;
}

/* Button Google Plus */

.u-btn-google-plus {
  color: #fff;
  background-color: #dd4b39;
}

.u-btn-google-plus:hover,
.u-btn-google-plus.active {
  border-color: #e47365;
  background-color: #e47365;
}

.u-btn-google-plus:hover,
.u-btn-google-plus:focus,
.u-btn-google-plus.active {
  color: #fff;
}

.u-btn-google-plus.g-btn-hover-reset:hover,
.u-btn-google-plus.g-btn-hover-reset.active {
  background-color: #dd4b39;
  border-color: #dd4b39;
}

/* Outline Button Primary */

.u-btn-outline-primary {
  color: #22211f;
  border-color: #22211f;
  background-color: transparent;
}

.u-btn-outline-primary:focus,
.u-btn-outline-primary.active {
  color: #fff;
  background-color: #22211f;
}

.u-btn-outline-primary:hover {
  color: #fff;
  background-color: #22211f;
}

.u-btn-outline-primary.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v1-1::after,
.u-btn-outline-primary.u-btn-hover-v1-1:hover::after {
  background-color: #2f2e2b;
}

.u-btn-outline-primary.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v1-2::after,
.u-btn-outline-primary.u-btn-hover-v1-2:hover::after {
  background-color: #2f2e2b;
}

.u-btn-outline-primary.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v1-3::after,
.u-btn-outline-primary.u-btn-hover-v1-3:hover::after {
  background-color: #2f2e2b;
}

.u-btn-outline-primary.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v1-4::after,
.u-btn-outline-primary.u-btn-hover-v1-4:hover::after {
  background-color: #2f2e2b;
}

.u-btn-outline-primary.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v2-1::after,
.u-btn-outline-primary.u-btn-hover-v2-1:hover::after {
  background-color: #2f2e2b;
}

.u-btn-outline-primary.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v2-2::after,
.u-btn-outline-primary.u-btn-hover-v2-2:hover::after {
  background-color: #2f2e2b;
}

.u-btn-outline-primary.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v3-1 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v3-1::after,
.u-btn-outline-primary.u-btn-hover-v3-1:hover::after {
  background-color: #2f2e2b;
}

.u-btn-outline-primary.u-btn-hover-v3-1:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button White */

.u-btn-outline-white {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
}

.u-btn-outline-white:focus,
.u-btn-outline-white.active {
  color: #555;
  background-color: #fff;
}

.u-btn-outline-white:hover {
  color: #555;
  background-color: #fff;
}

.u-btn-outline-white.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v1-1::after,
.u-btn-outline-white.u-btn-hover-v1-1:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-white.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v1-2::after,
.u-btn-outline-white.u-btn-hover-v1-2:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-white.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v1-3::after,
.u-btn-outline-white.u-btn-hover-v1-3:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-white.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v1-4::after,
.u-btn-outline-white.u-btn-hover-v1-4:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-white.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v2-1::after,
.u-btn-outline-white.u-btn-hover-v2-1:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-white.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v2-2::after,
.u-btn-outline-white.u-btn-hover-v2-2:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Black */

.u-btn-outline-black {
  color: #000;
  border-color: #000;
  background-color: transparent;
}

.u-btn-outline-black:focus,
.u-btn-outline-black.active {
  color: #fff;
  background-color: #000;
}

.u-btn-outline-black:hover {
  color: #fff;
  background-color: #000;
}

.u-btn-outline-black.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v1-1::after,
.u-btn-outline-black.u-btn-hover-v1-1:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-black.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v1-2::after,
.u-btn-outline-black.u-btn-hover-v1-2:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-black.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v1-3::after,
.u-btn-outline-black.u-btn-hover-v1-3:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-black.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v1-4::after,
.u-btn-outline-black.u-btn-hover-v1-4:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-black.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v2-1::after,
.u-btn-outline-black.u-btn-hover-v2-1:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-black.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v2-2::after,
.u-btn-outline-black.u-btn-hover-v2-2:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Dark Gray */

.u-btn-outline-darkgray {
  color: #333;
  border-color: #333;
  background-color: transparent;
}

.u-btn-outline-darkgray:focus,
.u-btn-outline-darkgray.active {
  color: #fff;
  background-color: #333;
}

.u-btn-outline-darkgray:hover {
  color: #fff;
  background-color: #333;
}

.u-btn-outline-darkgray.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v1-1::after,
.u-btn-outline-darkgray.u-btn-hover-v1-1:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkgray.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v1-2::after,
.u-btn-outline-darkgray.u-btn-hover-v1-2:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkgray.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v1-3::after,
.u-btn-outline-darkgray.u-btn-hover-v1-3:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkgray.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v1-4::after,
.u-btn-outline-darkgray.u-btn-hover-v1-4:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkgray.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v2-1::after,
.u-btn-outline-darkgray.u-btn-hover-v2-1:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkgray.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v2-2::after,
.u-btn-outline-darkgray.u-btn-hover-v2-2:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Light Gray */

.u-btn-outline-lightgray {
  color: #555;
  border-color: #eee;
  background-color: transparent;
}

.u-btn-outline-lightgray:focus,
.u-btn-outline-lightgray.active {
  color: #555;
  background-color: #eee;
}

.u-btn-outline-lightgray:hover {
  color: #555;
  background-color: #eee;
}

.u-btn-outline-lightgray.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v1-1::after,
.u-btn-outline-lightgray.u-btn-hover-v1-1:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightgray.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v1-2::after,
.u-btn-outline-lightgray.u-btn-hover-v1-2:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightgray.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v1-3::after,
.u-btn-outline-lightgray.u-btn-hover-v1-3:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightgray.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v1-4::after,
.u-btn-outline-lightgray.u-btn-hover-v1-4:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightgray.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v2-1::after,
.u-btn-outline-lightgray.u-btn-hover-v2-1:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightgray.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v2-2::after,
.u-btn-outline-lightgray.u-btn-hover-v2-2:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Button Outline Red */

.u-btn-outline-red {
  color: #f00;
  border-color: #f00;
  background-color: transparent;
}

.u-btn-outline-red:focus,
.u-btn-outline-red.active {
  color: #fff;
  background-color: #f00;
}

.u-btn-outline-red:hover {
  color: #fff;
  background-color: #f00;
}

.u-btn-outline-red.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v1-1::after,
.u-btn-outline-red.u-btn-hover-v1-1:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-red.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v1-2::after,
.u-btn-outline-red.u-btn-hover-v1-2:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-red.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v1-3::after,
.u-btn-outline-red.u-btn-hover-v1-3:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-red.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v1-4::after,
.u-btn-outline-red.u-btn-hover-v1-4:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-red.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v2-1::after,
.u-btn-outline-red.u-btn-hover-v2-1:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-red.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v2-2::after,
.u-btn-outline-red.u-btn-hover-v2-2:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Button Outline Red Tomato */

.u-btn-outline-lightred {
  color: #e64b3b;
  border-color: #e64b3b;
  background-color: transparent;
}

.u-btn-outline-lightred:focus,
.u-btn-outline-lightred.active {
  color: #fff;
  background-color: #e64b3b;
}

.u-btn-outline-lightred:hover {
  color: #fff;
  background-color: #e64b3b;
}

.u-btn-outline-lightred.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v1-1::after,
.u-btn-outline-lightred.u-btn-hover-v1-1:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightred.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v1-2::after,
.u-btn-outline-lightred.u-btn-hover-v1-2:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightred.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v1-3::after,
.u-btn-outline-lightred.u-btn-hover-v1-3:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightred.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v1-4::after,
.u-btn-outline-lightred.u-btn-hover-v1-4:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightred.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v2-1::after,
.u-btn-outline-lightred.u-btn-hover-v2-1:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightred.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v2-2::after,
.u-btn-outline-lightred.u-btn-hover-v2-2:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Dark Red */

.u-btn-outline-darkred {
  color: #a10f2b;
  border-color: #a10f2b;
  background-color: transparent;
}

.u-btn-outline-darkred:focus,
.u-btn-outline-darkred.active {
  color: #fff;
  background-color: #a10f2b;
}

.u-btn-outline-darkred:hover {
  color: #fff;
  background-color: #a10f2b;
}

.u-btn-outline-darkred.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v1-1::after,
.u-btn-outline-darkred.u-btn-hover-v1-1:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkred.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v1-2::after,
.u-btn-outline-darkred.u-btn-hover-v1-2:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkred.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v1-3::after,
.u-btn-outline-darkred.u-btn-hover-v1-3:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkred.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v1-4::after,
.u-btn-outline-darkred.u-btn-hover-v1-4:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkred.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v2-1::after,
.u-btn-outline-darkred.u-btn-hover-v2-1:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkred.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v2-2::after,
.u-btn-outline-darkred.u-btn-hover-v2-2:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Blue */

.u-btn-outline-blue {
  color: #3398dc;
  border-color: #3398dc;
  background-color: transparent;
}

.u-btn-outline-blue:focus,
.u-btn-outline-blue.active {
  color: #fff;
  background-color: #3398dc;
}

.u-btn-outline-blue:hover {
  color: #fff;
  background-color: #3398dc;
}

.u-btn-outline-blue.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v1-1::after,
.u-btn-outline-blue.u-btn-hover-v1-1:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-blue.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v1-2::after,
.u-btn-outline-blue.u-btn-hover-v1-2:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-blue.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v1-3::after,
.u-btn-outline-blue.u-btn-hover-v1-3:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-blue.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v1-4::after,
.u-btn-outline-blue.u-btn-hover-v1-4:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-blue.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v2-1::after,
.u-btn-outline-blue.u-btn-hover-v2-1:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-blue.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v2-2::after,
.u-btn-outline-blue.u-btn-hover-v2-2:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Indigo */

.u-btn-outline-indigo {
  color: #4263a3;
  border-color: #4263a3;
  background-color: transparent;
}

.u-btn-outline-indigo:focus,
.u-btn-outline-indigo.active {
  color: #fff;
  background-color: #4263a3;
}

.u-btn-outline-indigo:hover {
  color: #fff;
  background-color: #4263a3;
}

.u-btn-outline-indigo.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v1-1::after,
.u-btn-outline-indigo.u-btn-hover-v1-1:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-indigo.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v1-2::after,
.u-btn-outline-indigo.u-btn-hover-v1-2:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-indigo.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v1-3::after,
.u-btn-outline-indigo.u-btn-hover-v1-3:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-indigo.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v1-4::after,
.u-btn-outline-indigo.u-btn-hover-v1-4:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-indigo.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v2-1::after,
.u-btn-outline-indigo.u-btn-hover-v2-1:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-indigo.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v2-2::after,
.u-btn-outline-indigo.u-btn-hover-v2-2:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Purple */

.u-btn-outline-purple {
  color: #9a69cb;
  border-color: #9a69cb;
  background-color: transparent;
}

.u-btn-outline-purple:focus,
.u-btn-outline-purple.active {
  color: #fff;
  background-color: #9a69cb;
}

.u-btn-outline-purple:hover {
  color: #fff;
  background-color: #9a69cb;
}

.u-btn-outline-purple.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v1-1::after,
.u-btn-outline-purple.u-btn-hover-v1-1:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-purple.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v1-2::after,
.u-btn-outline-purple.u-btn-hover-v1-2:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-purple.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v1-3::after,
.u-btn-outline-purple.u-btn-hover-v1-3:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-purple.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v1-4::after,
.u-btn-outline-purple.u-btn-hover-v1-4:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-purple.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v2-1::after,
.u-btn-outline-purple.u-btn-hover-v2-1:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-purple.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v2-2::after,
.u-btn-outline-purple.u-btn-hover-v2-2:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Dark Purple */

.u-btn-outline-darkpurple {
  color: #6639b6;
  border-color: #6639b6;
  background-color: transparent;
}

.u-btn-outline-darkpurple:focus,
.u-btn-outline-darkpurple.active {
  color: #fff;
  background-color: #6639b6;
}

.u-btn-outline-darkpurple:hover {
  color: #fff;
  background-color: #6639b6;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-1::after,
.u-btn-outline-darkpurple.u-btn-hover-v1-1:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-2::after,
.u-btn-outline-darkpurple.u-btn-hover-v1-2:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-3::after,
.u-btn-outline-darkpurple.u-btn-hover-v1-3:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-4::after,
.u-btn-outline-darkpurple.u-btn-hover-v1-4:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-1::after,
.u-btn-outline-darkpurple.u-btn-hover-v2-1:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-2::after,
.u-btn-outline-darkpurple.u-btn-hover-v2-2:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Pink */

.u-btn-outline-pink {
  color: #e81c62;
  border-color: #e81c62;
  background-color: transparent;
}

.u-btn-outline-pink:focus,
.u-btn-outline-pink.active {
  color: #fff;
  background-color: #e81c62;
}

.u-btn-outline-pink:hover {
  color: #fff;
  background-color: #e81c62;
}

.u-btn-outline-pink.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v1-1::after,
.u-btn-outline-pink.u-btn-hover-v1-1:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-pink.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v1-2::after,
.u-btn-outline-pink.u-btn-hover-v1-2:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-pink.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v1-3::after,
.u-btn-outline-pink.u-btn-hover-v1-3:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-pink.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v1-4::after,
.u-btn-outline-pink.u-btn-hover-v1-4:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-pink.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v2-1::after,
.u-btn-outline-pink.u-btn-hover-v2-1:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-pink.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v2-2::after,
.u-btn-outline-pink.u-btn-hover-v2-2:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Orange */

.u-btn-outline-orange {
  color: #e57d20;
  border-color: #e57d20;
  background-color: transparent;
}

.u-btn-outline-orange:focus,
.u-btn-outline-orange.active {
  color: #fff;
  background-color: #e57d20;
}

.u-btn-outline-orange:hover {
  color: #fff;
  background-color: #e57d20;
}

.u-btn-outline-orange.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v1-1::after,
.u-btn-outline-orange.u-btn-hover-v1-1:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-orange.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v1-2::after,
.u-btn-outline-orange.u-btn-hover-v1-2:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-orange.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v1-3::after,
.u-btn-outline-orange.u-btn-hover-v1-3:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-orange.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v1-4::after,
.u-btn-outline-orange.u-btn-hover-v1-4:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-orange.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v2-1::after,
.u-btn-outline-orange.u-btn-hover-v2-1:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-orange.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v2-2::after,
.u-btn-outline-orange.u-btn-hover-v2-2:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Deep Orange */

.u-btn-outline-deeporange {
  color: #fe541e;
  border-color: #fe541e;
  background-color: transparent;
}

.u-btn-outline-deeporange:focus,
.u-btn-outline-deeporange.active {
  color: #fff;
  background-color: #fe541e;
}

.u-btn-outline-deeporange:hover {
  color: #fff;
  background-color: #fe541e;
}

.u-btn-outline-deeporange.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v1-1::after,
.u-btn-outline-deeporange.u-btn-hover-v1-1:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-deeporange.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v1-2::after,
.u-btn-outline-deeporange.u-btn-hover-v1-2:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-deeporange.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v1-3::after,
.u-btn-outline-deeporange.u-btn-hover-v1-3:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-deeporange.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v1-4::after,
.u-btn-outline-deeporange.u-btn-hover-v1-4:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-deeporange.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v2-1::after,
.u-btn-outline-deeporange.u-btn-hover-v2-1:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-deeporange.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v2-2::after,
.u-btn-outline-deeporange.u-btn-hover-v2-2:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Yellow */

.u-btn-outline-yellow {
  color: #555;
  border-color: #ebc71d;
  background-color: transparent;
}

.u-btn-outline-yellow:focus,
.u-btn-outline-yellow.active {
  color: #555;
  background-color: #ebc71d;
}

.u-btn-outline-yellow:hover {
  color: #555;
  background-color: #ebc71d;
}

.u-btn-outline-yellow.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v1-1::after,
.u-btn-outline-yellow.u-btn-hover-v1-1:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-yellow.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v1-2::after,
.u-btn-outline-yellow.u-btn-hover-v1-2:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-yellow.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v1-3::after,
.u-btn-outline-yellow.u-btn-hover-v1-3:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-yellow.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v1-4::after,
.u-btn-outline-yellow.u-btn-hover-v1-4:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-yellow.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v2-1::after,
.u-btn-outline-yellow.u-btn-hover-v2-1:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-yellow.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v2-2::after,
.u-btn-outline-yellow.u-btn-hover-v2-2:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Aqua */

.u-btn-outline-aqua {
  color: #29d6e6;
  border-color: #29d6e6;
  background-color: transparent;
}

.u-btn-outline-aqua:focus,
.u-btn-outline-aqua.active {
  color: #fff;
  background-color: #29d6e6;
}

.u-btn-outline-aqua:hover {
  color: #fff;
  background-color: #29d6e6;
}

.u-btn-outline-aqua.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v1-1::after,
.u-btn-outline-aqua.u-btn-hover-v1-1:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-aqua.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v1-2::after,
.u-btn-outline-aqua.u-btn-hover-v1-2:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-aqua.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v1-3::after,
.u-btn-outline-aqua.u-btn-hover-v1-3:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-aqua.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v1-4::after,
.u-btn-outline-aqua.u-btn-hover-v1-4:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-aqua.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v2-1::after,
.u-btn-outline-aqua.u-btn-hover-v2-1:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-aqua.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v2-2::after,
.u-btn-outline-aqua.u-btn-hover-v2-2:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Cyan */

.u-btn-outline-cyan {
  color: #00bed6;
  border-color: #00bed6;
  background-color: transparent;
}

.u-btn-outline-cyan:focus,
.u-btn-outline-cyan.active {
  color: #fff;
  background-color: #00bed6;
}

.u-btn-outline-cyan:hover {
  color: #fff;
  background-color: #00bed6;
}

.u-btn-outline-cyan.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v1-1::after,
.u-btn-outline-cyan.u-btn-hover-v1-1:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-cyan.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v1-2::after,
.u-btn-outline-cyan.u-btn-hover-v1-2:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-cyan.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v1-3::after,
.u-btn-outline-cyan.u-btn-hover-v1-3:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-cyan.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v1-4::after,
.u-btn-outline-cyan.u-btn-hover-v1-4:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-cyan.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v2-1::after,
.u-btn-outline-cyan.u-btn-hover-v2-1:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-cyan.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v2-2::after,
.u-btn-outline-cyan.u-btn-hover-v2-2:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Teal */

.u-btn-outline-teal {
  color: #18ba9b;
  border-color: #18ba9b;
  background-color: transparent;
}

.u-btn-outline-teal:focus,
.u-btn-outline-teal.active {
  color: #fff;
  background-color: #18ba9b;
}

.u-btn-outline-teal:hover {
  color: #fff;
  background-color: #18ba9b;
}

.u-btn-outline-teal.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v1-1::after,
.u-btn-outline-teal.u-btn-hover-v1-1:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-teal.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v1-2::after,
.u-btn-outline-teal.u-btn-hover-v1-2:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-teal.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v1-3::after,
.u-btn-outline-teal.u-btn-hover-v1-3:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-teal.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v1-4::after,
.u-btn-outline-teal.u-btn-hover-v1-4:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-teal.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v2-1::after,
.u-btn-outline-teal.u-btn-hover-v2-1:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-teal.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v2-2::after,
.u-btn-outline-teal.u-btn-hover-v2-2:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Brown */

.u-btn-outline-brown {
  color: #9c8061;
  border-color: #9c8061;
  background-color: transparent;
}

.u-btn-outline-brown:focus,
.u-btn-outline-brown.active {
  color: #fff;
  background-color: #9c8061;
}

.u-btn-outline-brown:hover {
  color: #fff;
  background-color: #9c8061;
}

.u-btn-outline-brown.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v1-1::after,
.u-btn-outline-brown.u-btn-hover-v1-1:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-brown.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v1-2::after,
.u-btn-outline-brown.u-btn-hover-v1-2:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-brown.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v1-3::after,
.u-btn-outline-brown.u-btn-hover-v1-3:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-brown.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v1-4::after,
.u-btn-outline-brown.u-btn-hover-v1-4:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-brown.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v2-1::after,
.u-btn-outline-brown.u-btn-hover-v2-1:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-brown.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v2-2::after,
.u-btn-outline-brown.u-btn-hover-v2-2:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Bluegrey */

.u-btn-outline-bluegray {
  color: #585f69;
  border-color: #585f69;
  background-color: transparent;
}

.u-btn-outline-bluegray:focus,
.u-btn-outline-bluegray.active {
  color: #fff;
  background-color: #585f69;
}

.u-btn-outline-bluegray:hover {
  color: #fff;
  background-color: #585f69;
}

.u-btn-outline-bluegray.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v1-1::after,
.u-btn-outline-bluegray.u-btn-hover-v1-1:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-bluegray.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v1-2::after,
.u-btn-outline-bluegray.u-btn-hover-v1-2:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-bluegray.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v1-3::after,
.u-btn-outline-bluegray.u-btn-hover-v1-3:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-bluegray.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v1-4::after,
.u-btn-outline-bluegray.u-btn-hover-v1-4:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-bluegray.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v2-1::after,
.u-btn-outline-bluegray.u-btn-hover-v2-1:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-bluegray.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v2-2::after,
.u-btn-outline-bluegray.u-btn-hover-v2-2:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Button Facebook */

.u-btn-outline-facebook {
  color: #3b5998;
  border-color: #3b5998;
  background-color: transparent;
}

.u-btn-outline-facebook:focus,
.u-btn-outline-facebook.active {
  color: #fff;
  background-color: #3b5998;
}

.u-btn-outline-facebook:hover {
  color: #fff;
  background-color: #3b5998;
}

/* Button Twitter */

.u-btn-outline-twitter {
  color: #00acee;
  border-color: #00acee;
  background-color: transparent;
}

.u-btn-outline-twitter:focus,
.u-btn-outline-twitter.active {
  color: #fff;
  background-color: #00acee;
}

.u-btn-outline-twitter:hover {
  color: #fff;
  background-color: #00acee;
}

/* Button Dribbble */

.u-btn-outline-dribbble {
  color: #ea4c89;
  border-color: #ea4c89;
  background-color: transparent;
}

.u-btn-outline-dribbble:focus,
.u-btn-outline-dribbble.active {
  color: #fff;
  background-color: #ea4c89;
}

.u-btn-outline-dribbble:hover {
  color: #fff;
  background-color: #ea4c89;
}

/* Gradient Style v1 */

.u-btn-gradient-v1 {
  color: #fff;
  background: linear-gradient(to right, #4776E6 0%, #8E54E9 100%) !important;
  border-image: linear-gradient(to right, #4776E6 0%, #8E54E9 100%);
  border-image-slice: 1;
}

.u-btn-gradient-v1:hover,
.u-btn-gradient-v1:focus,
.u-btn-gradient-v1.active {
  opacity: 0.9;
  color: #fff;
}

/* Gradient Style v2 */

.u-btn-gradient-v2 {
  color: #fff;
  background: linear-gradient(to right, #E040FB 0%, #00BCD4 100%) !important;
  border-image: linear-gradient(to right, #E040FB 0%, #00BCD4 100%);
  border-image-slice: 1;
}

.u-btn-gradient-v2:hover,
.u-btn-gradient-v2:focus,
.u-btn-gradient-v2.active {
  opacity: 0.9;
  color: #fff;
}

/* Gradient Style v1 */

.u-btn-outline-gradient-v1 {
  color: #555;
  background: transparent;
  border-image: linear-gradient(to right, #4776E6 0%, #8E54E9 100%);
  border-image-slice: 1;
}

.u-btn-outline-gradient-v1:hover,
.u-btn-outline-gradient-v1:focus,
.u-btn-outline-gradient-v1.active {
  background: linear-gradient(to right, #4776E6 0%, #8E54E9 100%);
  color: #fff;
}

/* Gradient Style v2 */

.u-btn-outline-gradient-v2 {
  color: #555;
  background: transparent;
  border-image: linear-gradient(to right, #E040FB 0%, #00BCD4 100%);
  border-image-slice: 1;
}

.u-btn-outline-gradient-v2:hover,
.u-btn-outline-gradient-v2:focus,
.u-btn-outline-gradient-v2.active {
  background: linear-gradient(to right, #E040FB 0%, #00BCD4 100%);
  color: #fff;
}

/* Material Styles
------------------------------------*/

.u-btn-raised,
.u-btn-raised:active:focus,
.u-btn-raised:focus {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

/*------------------------------------
  Material: Floating
------------------------------------*/

.u-btn-floating {
  width: 2.8571428571rem;
  height: 2.8571428571rem;
  padding: 0;
  border-radius: 50%;
  vertical-align: middle;
  overflow: hidden;
  line-height: 2.8571428571rem;
}

.u-btn-floating i {
  font-size: 1.6rem;
  line-height: 2.7142857143rem;
}

.u-btn-floating.u-halfway-fab {
  position: absolute;
  right: 1.7142857143rem;
  bottom: -1.4285714286rem;
}

.u-btn-floating.u-halfway-fab--left {
  right: auto;
  left: 1.7142857143rem;
}

.u-btn-floating--large {
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
}

.u-btn-floating--large i {
  font-size: 1.6rem;
  line-height: 3.8571428571rem;
}

.u-btn-floating--large.u-halfway-fab {
  bottom: -2rem;
}

button.u-btn-floating {
  border: none;
}

/*------------------------------------
  Material: Flat
------------------------------------*/

.u-btn-flat {
  color: #555;
  background-color: transparent;
}

.u-btn-flat:hover {
  background-color: rgba(221, 221, 221, 0.4);
}

.u-btn-flat:hover,
.u-btn-flat:focus {
  color: #555;
}

/*@import "base/icons/icons";
@import "base/icons/icon-v1";
@import "base/icons/icon-v2";
@import "base/icons/icon-v3";
@import "base/icons/icon-v4";
@import "base/icons/icon-v5";
@import "base/icons/icons-image";
@import "base/icons/icons-block-hover";
@import "base/icons/icon-hover-scale";
@import "base/icons/icon-hover-shadow";
@import "base/icons/icon-hover-slide-v1";
@import "base/icons/icon-hover-slide-v2";
@import "base/icons/icon-hover-zoom";
@import "base/icons/icon-hover-shape";
@import "base/icons/icon-hover-effect-1";
@import "base/icons/icon-hover-effect-2";
@import "base/icons/icon-hover-effect-3";
@import "base/icons/icon-hover-effect-4";
@import "base/icons/icon-hover-effect-5";*/

/*@import "base/box-shadows/box-shadows";
@import "base/box-shadows/box-shadows-v1";
@import "base/box-shadows/box-shadows-v2";
@import "base/box-shadows/box-shadows-v3-v4-v5";
@import "base/box-shadows/box-shadows-v6";
@import "base/box-shadows/box-shadows-v7";
@import "base/box-shadows/box-shadows-v8";
@import "base/box-shadows/box-shadows-v9";
@import "base/box-shadows/box-shadows-v10";
@import "base/box-shadows/box-shadows-v11";
@import "base/box-shadows/box-shadows-v12";
@import "base/box-shadows/box-shadows-v13";
@import "base/box-shadows/box-shadows-v14";
@import "base/box-shadows/box-shadows-v15";
@import "base/box-shadows/box-shadows-v16";
@import "base/box-shadows/box-shadows-v17";
@import "base/box-shadows/box-shadows-v18";
@import "base/box-shadows/box-shadows-v19";
@import "base/box-shadows/box-shadows-v20";
@import "base/box-shadows/box-shadows-v21";
@import "base/box-shadows/box-shadows-v22";
@import "base/box-shadows/box-shadows-v23";
@import "base/box-shadows/box-shadows-v24";
@import "base/box-shadows/box-shadows-v25";
@import "base/box-shadows/box-shadows-v26";
@import "base/box-shadows/box-shadows-v27";
@import "base/box-shadows/box-shadows-v28";
@import "base/box-shadows/box-shadows-v29";
@import "base/box-shadows/box-shadows-v30";
@import "base/box-shadows/box-shadows-v31";
@import "base/box-shadows/box-shadows-v32";
@import "base/box-shadows/box-shadows-v33";
@import "base/box-shadows/box-shadows-v34";
@import "base/box-shadows/box-shadows-v35";
@import "base/box-shadows/box-shadows-v36";
@import "base/box-shadows/box-shadows-v37";
@import "base/box-shadows/box-shadows-v38";
@import "base/box-shadows/box-shadows-v39";
@import "base/box-shadows/box-shadows-v40";
@import "base/box-shadows/box-shadows-v41";*/

/*------------------------------------
  Form Elements
------------------------------------*/

.u-form-control {
  padding: 0.8rem 1rem 0.6rem;
}

.u-form-control::placeholder {
  color: inherit;
  opacity: 0.3;
}

.u-form-control::-moz-placeholder {
  color: inherit;
  opacity: 0.3;
}

.u-form-control::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.3;
}

.u-form-control-sm {
  padding: 0.4rem 0.5rem 0.1rem;
  font-size: 0.875rem;
}

.u-form-control-lg {
  padding: 0.75rem 1.5rem 0.55rem;
  font-size: 1.25rem;
}

.u-form-control-shadow--focus:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.u-textarea-expandable {
  max-height: 42px;
  transition-property: max-height;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}

.u-textarea-expandable:focus {
  max-height: 90px;
}

.u-input-group-addon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

.form-control + .u-input-group-addon:not(:first-child) {
  border-left: 0;
}

/*@import "base/form-controls/states/states";*/

/*------------------------------------
  Fields
------------------------------------*/

[data-capitalize] {
  text-transform: uppercase;
}

label.error {
  color: #d9534f;
  margin-top: 0.25rem;
  margin-bottom: 0;
}

input:not([type=checkbox], [type=radio]).error,
textarea.error,
select.error {
  border-color: #d9534f !important;
}

.js-autocomplete--ajax {
  position: relative;
  z-index: 10;
}

/*------------------------------------
  Fields Group v1
------------------------------------*/

.u-input-group-v1 {
  position: relative;
}

.u-input-group-v1 input:not([type=checkbox], [type=radio]),
.u-input-group-v1 textarea,
.u-input-group-v1 [class*=u-select] {
  line-height: 1.75rem;
  padding: 40px 15px 10px;
}

.u-input-group-v1 input:not([type=checkbox], [type=radio]):focus + label,
.u-input-group-v1 input:not([type=checkbox], [type=radio])[value] + label,
.u-input-group-v1 input:not([type=checkbox], [type=radio])[placeholder] + label,
.u-input-group-v1 input:not([type=checkbox], [type=radio]).g-state-not-empty + label,
.u-input-group-v1 textarea:focus + label,
.u-input-group-v1 textarea[value] + label,
.u-input-group-v1 textarea[placeholder] + label,
.u-input-group-v1 textarea.g-state-not-empty + label,
.u-input-group-v1 [class*=u-select]:focus + label,
.u-input-group-v1 [class*=u-select][value] + label,
.u-input-group-v1 [class*=u-select][placeholder] + label,
.u-input-group-v1 [class*=u-select].g-state-not-empty + label {
  top: 20px;
  font-size: 90%;
}

.u-input-group-v1 label {
  position: absolute;
  top: 50%;
  left: 15px;
  transition-property: top, font-size;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transform: translateY(-50%);
  margin-bottom: 0;
}

/*------------------------------------
  Fields Group v2
------------------------------------*/

.u-input-group-v2 {
  position: relative;
}

.u-input-group-v2 input:not([type=checkbox], [type=radio]),
.u-input-group-v2 textarea,
.u-input-group-v2 [class*=u-select] {
  line-height: 1.75rem;
  padding: 10px 15px;
}

.u-input-group-v2 input:not([type=checkbox], [type=radio]):focus + label,
.u-input-group-v2 input:not([type=checkbox], [type=radio])[value] + label,
.u-input-group-v2 input:not([type=checkbox], [type=radio])[placeholder] + label,
.u-input-group-v2 input:not([type=checkbox], [type=radio]).g-state-not-empty + label,
.u-input-group-v2 textarea:focus + label,
.u-input-group-v2 textarea[value] + label,
.u-input-group-v2 textarea[placeholder] + label,
.u-input-group-v2 textarea.g-state-not-empty + label,
.u-input-group-v2 [class*=u-select]:focus + label,
.u-input-group-v2 [class*=u-select][value] + label,
.u-input-group-v2 [class*=u-select][placeholder] + label,
.u-input-group-v2 [class*=u-select].g-state-not-empty + label {
  top: 0;
  font-size: 90%;
}

.u-input-group-v2 label {
  position: absolute;
  top: 50%;
  left: 10px;
  background-color: #fff;
  padding: 0 5px;
  margin-bottom: 0;
  transition-property: top, font-size;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transform: translateY(-50%);
}

.u-input-group-v2 textarea + label {
  top: 20px;
}

/*------------------------------------
  Fields Group v3
------------------------------------*/

.u-input-group-v3 input:not([type=checkbox], [type=radio]),
.u-input-group-v3 textarea,
.u-input-group-v3 [class*=u-select] {
  margin-top: -5px;
  border-color: rgba(204, 204, 204, 0.5);
  border-style: solid;
  border-width: 0 0 1px;
  transition: all 0.3s ease 0s;
}

.u-input-group-v3 input:not([type=checkbox], [type=radio]):focus,
.u-input-group-v3 textarea:focus,
.u-input-group-v3 [class*=u-select]:focus {
  border-color: rgba(34, 33, 31, 0.5);
  box-shadow: 0 1px 0 0 #22211f;
}

.u-input-group-v3 label {
  margin-bottom: 0;
}

/*------------------------------------
  Fields Group v4
------------------------------------*/

.u-input-group-v4 {
  position: relative;
}

.u-input-group-v4 input:not([type=checkbox], [type=radio]),
.u-input-group-v4 textarea,
.u-input-group-v4 [class*=u-select] {
  background-color: transparent;
  border-color: rgba(204, 204, 204, 0.5);
  border-style: solid;
  border-width: 0 0 1px;
}

.u-input-group-v4 input:not([type=checkbox], [type=radio]):focus,
.u-input-group-v4 input:not([type=checkbox], [type=radio])[value],
.u-input-group-v4 input:not([type=checkbox], [type=radio])[placeholder],
.u-input-group-v4 input:not([type=checkbox], [type=radio]).g-state-not-empty,
.u-input-group-v4 textarea:focus,
.u-input-group-v4 textarea[value],
.u-input-group-v4 textarea[placeholder],
.u-input-group-v4 textarea.g-state-not-empty,
.u-input-group-v4 [class*=u-select]:focus,
.u-input-group-v4 [class*=u-select][value],
.u-input-group-v4 [class*=u-select][placeholder],
.u-input-group-v4 [class*=u-select].g-state-not-empty {
  background-color: transparent;
  border-color: rgba(34, 33, 31, 0.5);
  box-shadow: 0 1px 0 0 #22211f;
}

.u-input-group-v4 input:not([type=checkbox], [type=radio]):focus + label,
.u-input-group-v4 input:not([type=checkbox], [type=radio])[value] + label,
.u-input-group-v4 input:not([type=checkbox], [type=radio])[placeholder] + label,
.u-input-group-v4 input:not([type=checkbox], [type=radio]).g-state-not-empty + label,
.u-input-group-v4 textarea:focus + label,
.u-input-group-v4 textarea[value] + label,
.u-input-group-v4 textarea[placeholder] + label,
.u-input-group-v4 textarea.g-state-not-empty + label,
.u-input-group-v4 [class*=u-select]:focus + label,
.u-input-group-v4 [class*=u-select][value] + label,
.u-input-group-v4 [class*=u-select][placeholder] + label,
.u-input-group-v4 [class*=u-select].g-state-not-empty + label {
  top: 0;
  font-size: 90%;
}

.u-input-group-v4 label {
  position: absolute;
  top: 50%;
  left: 0;
  margin-bottom: 0;
  background-color: transparent;
  color: #999;
  transition-property: top, font-size;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transform: translateY(-50%);
}

.u-input-group-v4 textarea + label {
  top: 20px;
}

/*------------------------------------
  Checkboxes
------------------------------------*/

.u-check {
  position: relative;
  cursor: pointer;
}

.u-check-icon-font {
  display: inline-block;
  font-size: 0;
  padding-left: 1px;
  padding-right: 1px;
}

.u-check-icon-font i {
  font-size: 22px;
  color: #ccc;
}

.u-check-icon-font i::before {
  content: attr(data-uncheck-icon);
}

.u-check-icon-checkbox,
.u-check-icon-radio {
  display: inline-block;
}

.u-check input[type=checkbox]:checked + .u-check-icon-font i,
.u-check input[type=checkbox]:checked + * .u-check-icon-font i,
.u-check input[type=radio]:checked + .u-check-icon-font i,
.u-check input[type=radio]:checked + * .u-check-icon-font i {
  color: #22211f;
}

.u-check input[type=checkbox]:checked + .u-check-icon-font i::before,
.u-check input[type=checkbox]:checked + * .u-check-icon-font i::before,
.u-check input[type=radio]:checked + .u-check-icon-font i::before,
.u-check input[type=radio]:checked + * .u-check-icon-font i::before {
  content: attr(data-check-icon);
}

.g-hide-check {
  display: block !important;
}

.g-checked > * .g-hide-check,
input[type=checkbox]:checked + .g-hide-check,
input[type=radio]:checked + .g-hide-check,
input[type=checkbox]:checked + * .g-hide-check,
input[type=radio]:checked + * .g-hide-check {
  display: none !important;
}

.g-show-check {
  display: none !important;
}

.g-checked > * .g-show-check,
input[type=checkbox]:checked + .g-show-check,
input[type=radio]:checked + .g-show-check,
input[type=checkbox]:checked + * .g-show-check,
input[type=radio]:checked + * .g-show-check {
  display: block !important;
}

/*------------------------------------
  Checkboxes v1
------------------------------------*/

.u-check-icon-checkbox-v1,
.u-check-icon-radio-v1 {
  background-color: #fff;
  border: solid 1px #ccc;
}

.u-check-icon-checkbox-v1 {
  border-radius: 1px;
}

.u-check-icon-radio-v1 {
  border-radius: 50%;
}

.u-check input[type=checkbox]:checked + .u-check-icon-checkbox-v1,
.u-check input[type=checkbox]:checked + * .u-check-icon-checkbox-v1,
.u-check input[type=radio]:checked + .u-check-icon-checkbox-v1,
.u-check input[type=radio]:checked + * .u-check-icon-checkbox-v1 {
  color: #fff;
  background-color: #22211f;
  border-color: #22211f;
}

.u-check input[type=checkbox]:checked + .u-check-icon-checkbox-v1::before,
.u-check input[type=checkbox]:checked + * .u-check-icon-checkbox-v1::before,
.u-check input[type=radio]:checked + .u-check-icon-checkbox-v1::before,
.u-check input[type=radio]:checked + * .u-check-icon-checkbox-v1::before {
  content: attr(data-check-icon);
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12px;
  transform: translate(-50%, -50%);
}

.u-check input[type=checkbox]:checked + .u-check-icon-radio-v1,
.u-check input[type=checkbox]:checked + * .u-check-icon-radio-v1,
.u-check input[type=radio]:checked + .u-check-icon-radio-v1,
.u-check input[type=radio]:checked + * .u-check-icon-radio-v1 {
  border-width: 4px;
  border-color: #22211f;
}

/*------------------------------------
  Checkboxes v2
------------------------------------*/

.u-check-icon-checkbox-v2,
.u-check-icon-radio-v2 {
  width: 20px;
  height: 20px;
  border: solid 1px #22211f;
}

.u-check-icon-checkbox-v2 {
  border-radius: 1px;
}

.u-check-icon-radio-v2 {
  border-radius: 50%;
}

/*------------------------------------
  Checkboxes v3
------------------------------------*/

.u-check-icon-checkbox-v3,
.u-check-icon-radio-v3 {
  width: 40px;
  height: 40px;
  border: solid 1px #22211f;
  border-radius: 50%;
}

/*------------------------------------
  Checkboxes v4
------------------------------------*/

.u-check-icon-checkbox-v4,
.u-check-icon-radio-v4 {
  width: 18px;
  height: 18px;
  font-size: 12px;
  border: solid 1px #ccc;
}

.u-check-icon-checkbox-v4 i::before,
.u-check-icon-radio-v4 i::before {
  content: attr(data-uncheck-icon);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-check-icon-radio-v4 {
  border-radius: 50%;
}

.u-check-icon-radio-v4 i {
  border-radius: 50%;
}

.u-check input[type=checkbox]:checked + .u-check-icon-checkbox-v4,
.u-check input[type=checkbox]:checked + .u-check-icon-radio-v4,
.u-check input[type=checkbox]:checked + * .u-check-icon-checkbox-v4,
.u-check input[type=checkbox]:checked + * .u-check-icon-radio-v4,
.u-check input[type=radio]:checked + .u-check-icon-checkbox-v4,
.u-check input[type=radio]:checked + .u-check-icon-radio-v4,
.u-check input[type=radio]:checked + * .u-check-icon-checkbox-v4,
.u-check input[type=radio]:checked + * .u-check-icon-radio-v4 {
  color: #22211f;
  border-color: #22211f;
}

.u-check input[type=checkbox]:checked + .u-check-icon-checkbox-v4 i::before,
.u-check input[type=checkbox]:checked + .u-check-icon-radio-v4 i::before,
.u-check input[type=checkbox]:checked + * .u-check-icon-checkbox-v4 i::before,
.u-check input[type=checkbox]:checked + * .u-check-icon-radio-v4 i::before,
.u-check input[type=radio]:checked + .u-check-icon-checkbox-v4 i::before,
.u-check input[type=radio]:checked + .u-check-icon-radio-v4 i::before,
.u-check input[type=radio]:checked + * .u-check-icon-checkbox-v4 i::before,
.u-check input[type=radio]:checked + * .u-check-icon-radio-v4 i::before {
  content: attr(data-check-icon);
}

/*------------------------------------
  Checkboxes v5
------------------------------------*/

.u-check-icon-checkbox-v5,
.u-check-icon-radio-v5 {
  width: 18px;
  height: 18px;
}

.u-check-icon-checkbox-v5 i,
.u-check-icon-radio-v5 i {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: solid 1px #ccc;
  transform: translate(-50%, -50%);
}

.u-check-icon-checkbox-v5 {
  border-radius: 1px;
}

.u-check-icon-radio-v5 {
  border-radius: 50%;
}

.u-check-icon-radio-v5 i {
  border-radius: 50%;
}

.u-check input[type=checkbox]:checked + .u-check-icon-checkbox-v5 i,
.u-check input[type=checkbox]:checked + .u-check-icon-radio-v5 i,
.u-check input[type=checkbox]:checked + * .u-check-icon-checkbox-v5 i,
.u-check input[type=checkbox]:checked + * .u-check-icon-radio-v5 i,
.u-check input[type=radio]:checked + .u-check-icon-checkbox-v5 i,
.u-check input[type=radio]:checked + .u-check-icon-radio-v5 i,
.u-check input[type=radio]:checked + * .u-check-icon-checkbox-v5 i,
.u-check input[type=radio]:checked + * .u-check-icon-radio-v5 i {
  border-width: 4px;
  border-color: #22211f;
}

/*------------------------------------
  Checkboxes v6
------------------------------------*/

.u-check-icon-checkbox-v6,
.u-check-icon-radio-v6 {
  width: 18px;
  height: 18px;
  font-size: 12px;
}

.u-check-icon-checkbox-v6 i,
.u-check-icon-radio-v6 i {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 100%;
  height: 100%;
  border: solid 1px #ccc;
  transform: translate(-50%, -50%);
}

.u-check-icon-checkbox-v6 i::before,
.u-check-icon-radio-v6 i::before {
  content: attr(data-uncheck-icon);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-check-icon-radio-v6 {
  border-radius: 50%;
}

.u-check-icon-radio-v6 i {
  border-radius: 50%;
}

.u-check input[type=checkbox]:checked + .u-check-icon-checkbox-v6 i,
.u-check input[type=checkbox]:checked + .u-check-icon-radio-v6 i,
.u-check input[type=checkbox]:checked + * .u-check-icon-checkbox-v6 i,
.u-check input[type=checkbox]:checked + * .u-check-icon-radio-v6 i,
.u-check input[type=radio]:checked + .u-check-icon-checkbox-v6 i,
.u-check input[type=radio]:checked + .u-check-icon-radio-v6 i,
.u-check input[type=radio]:checked + * .u-check-icon-checkbox-v6 i,
.u-check input[type=radio]:checked + * .u-check-icon-radio-v6 i {
  color: #fff;
  background-color: #22211f;
  border-color: #22211f;
}

.u-check input[type=checkbox]:checked + .u-check-icon-checkbox-v6 i::before,
.u-check input[type=checkbox]:checked + .u-check-icon-radio-v6 i::before,
.u-check input[type=checkbox]:checked + * .u-check-icon-checkbox-v6 i::before,
.u-check input[type=checkbox]:checked + * .u-check-icon-radio-v6 i::before,
.u-check input[type=radio]:checked + .u-check-icon-checkbox-v6 i::before,
.u-check input[type=radio]:checked + .u-check-icon-radio-v6 i::before,
.u-check input[type=radio]:checked + * .u-check-icon-checkbox-v6 i::before,
.u-check input[type=radio]:checked + * .u-check-icon-radio-v6 i::before {
  content: attr(data-check-icon);
}

/*------------------------------------
  Checkboxes v7
------------------------------------*/

.u-check-icon-checkbox-v7,
.u-check-icon-radio-v7 {
  cursor: pointer;
  display: block;
  width: 43px;
  height: 22px;
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  color: #ddd;
  border: solid 1px #ddd;
  border-radius: 12px;
}

.u-check-icon-checkbox-v7 i::before,
.u-check-icon-checkbox-v7 i::after,
.u-check-icon-radio-v7 i::before,
.u-check-icon-radio-v7 i::after {
  content: "";
  display: block;
  position: absolute;
}

.u-check-icon-checkbox-v7 i::before,
.u-check-icon-radio-v7 i::before {
  content: attr(data-uncheck-icon);
  top: 0;
  left: 0;
  width: 100%;
  height: 22px;
  line-height: 18px;
  text-transform: uppercase;
  text-align: right;
  padding: 2px 7px;
}

.u-check-icon-checkbox-v7 i::after,
.u-check-icon-radio-v7 i::after {
  top: 50%;
  left: 4px;
  width: 16px;
  height: 16px;
  background-color: #ddd;
  border-radius: 50%;
  transform: translateY(-50%);
  transition-property: left;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
}

.u-check input[type=checkbox]:checked + .u-check-icon-checkbox-v7,
.u-check input[type=checkbox]:checked + .u-check-icon-radio-v7,
.u-check input[type=checkbox]:checked + * .u-check-icon-checkbox-v7,
.u-check input[type=checkbox]:checked + * .u-check-icon-radio-v7,
.u-check input[type=radio]:checked + .u-check-icon-checkbox-v7,
.u-check input[type=radio]:checked + .u-check-icon-radio-v7,
.u-check input[type=radio]:checked + * .u-check-icon-checkbox-v7,
.u-check input[type=radio]:checked + * .u-check-icon-radio-v7 {
  color: #fff;
  background-color: #22211f;
  border-color: #22211f;
}

.u-check input[type=checkbox]:checked + .u-check-icon-checkbox-v7 i:before,
.u-check input[type=checkbox]:checked + .u-check-icon-radio-v7 i:before,
.u-check input[type=checkbox]:checked + * .u-check-icon-checkbox-v7 i:before,
.u-check input[type=checkbox]:checked + * .u-check-icon-radio-v7 i:before,
.u-check input[type=radio]:checked + .u-check-icon-checkbox-v7 i:before,
.u-check input[type=radio]:checked + .u-check-icon-radio-v7 i:before,
.u-check input[type=radio]:checked + * .u-check-icon-checkbox-v7 i:before,
.u-check input[type=radio]:checked + * .u-check-icon-radio-v7 i:before {
  content: attr(data-check-icon);
  text-align: left;
}

.u-check input[type=checkbox]:checked + .u-check-icon-checkbox-v7 i::after,
.u-check input[type=checkbox]:checked + .u-check-icon-radio-v7 i::after,
.u-check input[type=checkbox]:checked + * .u-check-icon-checkbox-v7 i::after,
.u-check input[type=checkbox]:checked + * .u-check-icon-radio-v7 i::after,
.u-check input[type=radio]:checked + .u-check-icon-checkbox-v7 i::after,
.u-check input[type=radio]:checked + .u-check-icon-radio-v7 i::after,
.u-check input[type=radio]:checked + * .u-check-icon-checkbox-v7 i::after,
.u-check input[type=radio]:checked + * .u-check-icon-radio-v7 i::after {
  left: calc(100% - 19px);
  background-color: #fff;
}

/*------------------------------------
  Checkboxes v7
------------------------------------*/

.u-check-icon-checkbox-v8,
.u-check-icon-radio-v8 {
  cursor: pointer;
  display: block;
  width: 43px;
  height: 22px;
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  color: #ddd;
  border: solid 1px #ddd;
  border-radius: 12px;
}

.u-check-icon-checkbox-v8 i::before,
.u-check-icon-checkbox-v8 i::after,
.u-check-icon-radio-v8 i::before,
.u-check-icon-radio-v8 i::after {
  content: "";
  display: block;
  position: absolute;
}

.u-check-icon-checkbox-v8 i::before,
.u-check-icon-radio-v8 i::before {
  content: attr(data-uncheck-icon);
  top: 0;
  left: 0;
  width: 100%;
  height: 22px;
  line-height: 18px;
  text-transform: uppercase;
  text-align: right;
  padding: 2px 7px;
}

.u-check-icon-checkbox-v8 i::after,
.u-check-icon-radio-v8 i::after {
  top: 50%;
  left: 4px;
  width: 16px;
  height: 16px;
  background-color: #ddd;
  border-radius: 50%;
  transform: translateY(-50%);
  transition-property: left;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
}

.u-check input[type=checkbox]:checked + .u-check-icon-checkbox-v8,
.u-check input[type=checkbox]:checked + .u-check-icon-radio-v8,
.u-check input[type=checkbox]:checked + * .u-check-icon-checkbox-v8,
.u-check input[type=checkbox]:checked + * .u-check-icon-radio-v8,
.u-check input[type=radio]:checked + .u-check-icon-checkbox-v8,
.u-check input[type=radio]:checked + .u-check-icon-radio-v8,
.u-check input[type=radio]:checked + * .u-check-icon-checkbox-v8,
.u-check input[type=radio]:checked + * .u-check-icon-radio-v8 {
  color: #22211f;
  border-color: #22211f;
}

.u-check input[type=checkbox]:checked + .u-check-icon-checkbox-v8 i:before,
.u-check input[type=checkbox]:checked + .u-check-icon-radio-v8 i:before,
.u-check input[type=checkbox]:checked + * .u-check-icon-checkbox-v8 i:before,
.u-check input[type=checkbox]:checked + * .u-check-icon-radio-v8 i:before,
.u-check input[type=radio]:checked + .u-check-icon-checkbox-v8 i:before,
.u-check input[type=radio]:checked + .u-check-icon-radio-v8 i:before,
.u-check input[type=radio]:checked + * .u-check-icon-checkbox-v8 i:before,
.u-check input[type=radio]:checked + * .u-check-icon-radio-v8 i:before {
  content: attr(data-check-icon);
  text-align: left;
}

.u-check input[type=checkbox]:checked + .u-check-icon-checkbox-v8 i::after,
.u-check input[type=checkbox]:checked + .u-check-icon-radio-v8 i::after,
.u-check input[type=checkbox]:checked + * .u-check-icon-checkbox-v8 i::after,
.u-check input[type=checkbox]:checked + * .u-check-icon-radio-v8 i::after,
.u-check input[type=radio]:checked + .u-check-icon-checkbox-v8 i::after,
.u-check input[type=radio]:checked + .u-check-icon-radio-v8 i::after,
.u-check input[type=radio]:checked + * .u-check-icon-checkbox-v8 i::after,
.u-check input[type=radio]:checked + * .u-check-icon-radio-v8 i::after {
  left: calc(100% - 19px);
  background-color: #22211f;
}

/* P */

[class*=u-checkbox-v1] {
  display: none;
}

[class*=u-checkbox-v1] + label {
  cursor: pointer;
}

.u-checkbox-v1--checked-color-primary:checked + label {
  color: #22211f !important;
}

.u-checkbox-v1--checked-brd-primary:checked + label {
  border-color: #22211f !important;
}

/*@import "base/form-controls/file-attachments/file-attachments";*/

/*------------------------------------
  Selects
------------------------------------*/

.input-group select {
  -webkit-appearance: none;
}

/*------------------------------------
  Selects v1
------------------------------------*/

.u-select-v1 {
  position: relative;
  max-width: 100%;
  font-size: 1rem;
  color: rgba(85, 85, 85, 0.3);
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  padding: 0.4rem 1rem;
}

.u-select-v1 .chosen-single {
  position: static;
  height: auto;
  color: inherit;
  background-image: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  line-height: inherit;
}

.u-select-v1 .chosen-single span {
  margin-right: 0;
}

.u-select-v1 .chosen-single span img {
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.u-select-v1 .chosen-single div {
  width: 40px;
}

.u-select-v1 .chosen-single div b {
  background: none !important;
}

.u-select-v1 .chosen-single div b i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-select-v1 .chosen-single div b i:first-child {
  display: inline-block;
}

.u-select-v1 .chosen-single div b i:last-child {
  display: none;
}

.u-select-v1 .chosen-single:focus {
  outline: 0 none;
}

.u-select-v1 .chosen-drop {
  width: calc(100% + 2px);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  border-radius: 0;
  border-top-width: 1px;
  margin-left: -1px;
}

.u-select-v1 .chosen-results {
  padding: 0;
  margin: 0;
}

.u-select-v1 .chosen-results > li {
  position: relative;
  font-size: inherit;
  color: #999;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
  padding: 12px 16px;
}

.u-select-v1 .chosen-results > li img {
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.u-select-v1 .chosen-results > li div {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
}

.u-select-v1 .chosen-results > li div b {
  display: block;
  width: 100%;
  height: 100%;
}

.u-select-v1 .chosen-results > li div b i {
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  transform: translate(-50%, -50%);
}

.u-select-v1 .chosen-results > li:last-child {
  border-bottom-width: 0;
}

.u-select-v1 .chosen-results > li.highlighted {
  color: #999;
  background-image: none;
  background-color: rgba(34, 33, 31, 0.2);
  border-bottom-color: rgba(34, 33, 31, 0.2);
}

.u-select-v1 .chosen-results > li.highlighted.g-color-white--active {
  color: #fff !important;
}

.u-select-v1 .chosen-results > li.highlighted.g-bg-primary--active {
  background-color: #22211f !important;
}

.u-select-v1 .chosen-results > li.result-selected {
  color: #999;
  background-color: rgba(34, 33, 31, 0.2);
  border-bottom-color: rgba(34, 33, 31, 0.2);
}

.u-select-v1 .chosen-results > li.result-selected div b i {
  display: inline-block;
}

.u-select-v1.chosen-container-active .chosen-single {
  background-image: none;
  box-shadow: none;
}

.u-select-v1.chosen-with-drop .chosen-single {
  border: none;
  border-radius: 0;
}

.u-select-v1.chosen-with-drop .chosen-single div b i:first-child {
  display: none;
}

.u-select-v1.chosen-with-drop .chosen-single div b i:last-child {
  display: inline-block;
}

.u-select-v1.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices {
  background-image: none;
  border: none;
  box-shadow: none;
  padding: 0;
}

.u-select-v1.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-choice {
  font-size: 12px;
  color: #999;
  background-image: none;
  background-color: #fff;
  border-color: #ccc;
  border-radius: 0;
  padding: 5px 20px 5px 5px;
}

.u-select-v1.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-choice-close {
  top: 50%;
  transform: translateY(-50%);
}

.u-select-v1.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-field {
  height: 22px;
}

.u-select-v1.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-field input[type=text] {
  height: 22px;
  margin: 0;
}

.u-select-v1.u-select-multiple-custom .chosen-choices {
  display: none;
}

.u-select-v1.u-select-multiple-custom .chosen-drop {
  width: 100%;
  position: static;
  top: auto;
  left: auto;
  z-index: 3;
  border: none;
  box-shadow: none;
  margin-left: 0;
}

.u-select-v1.u-dropdown-sm {
  padding: 0.1rem 0.5rem;
  font-size: 0.875rem;
}

.u-select-v1.u-dropdown-sm .chosen-results > li {
  padding: 6px 8px;
}

.u-select-v1.u-dropdown-lg {
  padding: 0.55rem 1.5rem;
  font-size: 1.25rem;
}

.u-select-v1.u-dropdown-lg .chosen-results > li {
  padding: 14px 18px;
}

/*------------------------------------
  Selects v2
------------------------------------*/

.u-select-v2 {
  position: relative;
  max-width: 100%;
  font-size: 1rem;
  color: rgba(85, 85, 85, 0.3);
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  padding: 0.4rem 1rem;
}

.u-select-v2 .chosen-single {
  position: static;
  height: auto;
  color: inherit;
  background-image: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  line-height: inherit;
}

.u-select-v2 .chosen-single span {
  margin-right: 0;
}

.u-select-v2 .chosen-single span img {
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.u-select-v2 .chosen-single div {
  width: 50px;
}

.u-select-v2 .chosen-single div b {
  background: none !important;
}

.u-select-v2 .chosen-single div b i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-select-v2 .chosen-single div b i:first-child {
  display: inline-block;
}

.u-select-v2 .chosen-single div b i:last-child {
  display: none;
}

.u-select-v2 .chosen-single:focus {
  outline: 0 none;
}

.u-select-v2 .chosen-drop,
.u-select-v2 .chosen-results {
  border-radius: 3px;
}

.u-select-v2 .chosen-drop {
  width: calc(100% + 2px);
  box-sizing: border-box;
  border: none;
  box-shadow: 0 6px 15px -6px rgba(0, 0, 0, 0.1);
  margin-left: -1px;
  margin-top: 7px;
}

.u-select-v2 .chosen-results {
  padding: 0;
  margin: 0;
}

.u-select-v2 .chosen-results > li {
  position: relative;
  font-size: inherit;
  color: #999;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
  padding: 12px 16px;
}

.u-select-v2 .chosen-results > li img {
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.u-select-v2 .chosen-results > li div {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
}

.u-select-v2 .chosen-results > li div b {
  display: block;
  width: 100%;
  height: 100%;
}

.u-select-v2 .chosen-results > li div b i {
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  transform: translate(-50%, -50%);
}

.u-select-v2 .chosen-results > li:last-child {
  border-bottom-width: 0;
}

.u-select-v2 .chosen-results > li.highlighted {
  color: #999;
  background-image: none;
  background-color: rgba(34, 33, 31, 0.2);
  border-bottom-color: rgba(34, 33, 31, 0.2);
}

.u-select-v2 .chosen-results > li.highlighted.g-color-white--active {
  color: #fff !important;
}

.u-select-v2 .chosen-results > li.highlighted.g-bg-primary--active {
  background-color: #22211f !important;
}

.u-select-v2 .chosen-results > li.result-selected {
  color: #999;
  background-color: rgba(34, 33, 31, 0.2);
  border-bottom-color: rgba(34, 33, 31, 0.2);
}

.u-select-v2 .chosen-results > li.result-selected div b i {
  display: inline-block;
}

.u-select-v2.chosen-container-active .chosen-single {
  background-image: none;
  box-shadow: none;
}

.u-select-v2.chosen-with-drop .chosen-single {
  border: none;
  border-radius: 0;
}

.u-select-v2.chosen-with-drop .chosen-single div b i:first-child {
  display: none;
}

.u-select-v2.chosen-with-drop .chosen-single div b i:last-child {
  display: inline-block;
}

.u-select-v2.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices {
  background-image: none;
  border: none;
  box-shadow: none;
  padding: 0;
}

.u-select-v2.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-choice {
  font-size: 12px;
  color: #999;
  background-image: none;
  background-color: #fff;
  border-color: #ccc;
  border-radius: 0;
  padding: 5px 20px 5px 5px;
}

.u-select-v2.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-choice-close {
  top: 50%;
  transform: translateY(-50%);
}

.u-select-v2.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-field {
  height: 22px;
}

.u-select-v2.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-field input[type=text] {
  height: 22px;
  margin: 0;
}

.u-select-v2.u-select-multiple-custom .chosen-choices {
  display: none;
}

.u-select-v2.u-select-multiple-custom .chosen-drop {
  width: 100%;
  position: static;
  top: auto;
  left: auto;
  z-index: 3;
  border: none;
  box-shadow: none;
  margin-left: 0;
}

.u-select-v2.u-dropdown-sm {
  padding: 0.1rem 0.5rem;
  font-size: 0.875rem;
}

.u-select-v2.u-dropdown-sm .chosen-results > li {
  padding: 6px 8px;
}

.u-select-v2.u-dropdown-lg {
  padding: 0.55rem 1.5rem;
  font-size: 1.25rem;
}

.u-select-v2.u-dropdown-lg .chosen-results > li {
  padding: 14px 18px;
}

/*@import "base/form-controls/sliders/sliders";*/

/*@import "base/form-controls/datepickers/datepickers";*/

/*@import "base/form-controls/quantity";*/

/*@import "base/carousel-indicators/carousel-indicators";
@import "base/carousel-indicators/carousel-indicators-v1";
@import "base/carousel-indicators/carousel-indicators-v2";
@import "base/carousel-indicators/carousel-indicators-v3";
@import "base/carousel-indicators/carousel-indicators-v4";
@import "base/carousel-indicators/carousel-indicators-v5";
@import "base/carousel-indicators/carousel-indicators-v6";
@import "base/carousel-indicators/carousel-indicators-v7";
@import "base/carousel-indicators/carousel-indicators-v8";
@import "base/carousel-indicators/carousel-indicators-v9";
@import "base/carousel-indicators/carousel-indicators-v10";
@import "base/carousel-indicators/carousel-indicators-v11";
@import "base/carousel-indicators/carousel-indicators-v12";
@import "base/carousel-indicators/carousel-indicators-v13";
@import "base/carousel-indicators/carousel-indicators-v14";
@import "base/carousel-indicators/carousel-indicators-v15";
@import "base/carousel-indicators/carousel-indicators-v16";
@import "base/carousel-indicators/carousel-indicators-v17";
@import "base/carousel-indicators/carousel-indicators-v18";
@import "base/carousel-indicators/carousel-indicators-v19";
@import "base/carousel-indicators/carousel-indicators-v20";
@import "base/carousel-indicators/carousel-indicators-v21";
@import "base/carousel-indicators/carousel-indicators-v22";
@import "base/carousel-indicators/carousel-indicators-v23";
@import "base/carousel-indicators/carousel-indicators-v24";
@import "base/carousel-indicators/carousel-indicators-v25";
@import "base/carousel-indicators/carousel-indicators-v26";
@import "base/carousel-indicators/carousel-indicators-v27";
@import "base/carousel-indicators/carousel-indicators-v28";
@import "base/carousel-indicators/carousel-indicators-v29";
@import "base/carousel-indicators/carousel-indicators-v30";
@import "base/carousel-indicators/carousel-indicators-v31";
@import "base/carousel-indicators/carousel-indicators-v32";
@import "base/carousel-indicators/carousel-indicators-v33";
@import "base/carousel-indicators/carousel-indicators-v34";
@import "base/carousel-indicators/carousel-indicators-v35";*/

/*@import "base/breadcrumbs/breadcrumbs-v1";
@import "base/breadcrumbs/breadcrumbs-v2";*/

/*@import "base/dot-line/dot-line-v1";
@import "base/dot-line/dot-line-v2";*/

/*@import "base/headings/heading-v1";
@import "base/headings/heading-v2";
@import "base/headings/heading-v3";
@import "base/headings/heading-v4";
@import "base/headings/heading-v5";
@import "base/headings/heading-v6";
@import "base/headings/heading-v7";
@import "base/headings/heading-v8";
@import "base/headings/heading-v9";
@import "base/headings/heading-v10";
@import "base/headings/headings";*/

/*@import "base/bg-nonstandarts";*/

/*@import "base/nonstandard-bg/nonstandard-bg";
@import "base/nonstandard-bg/nonstandard-bg-v1";
@import "base/nonstandard-bg/nonstandard-bg-v2";
@import "base/nonstandard-bg/nonstandard-bg-v3";
@import "base/nonstandard-bg/nonstandard-bg-v4";
@import "base/nonstandard-bg/nonstandard-bg-v5";
@import "base/nonstandard-bg/nonstandard-bg-v6";
@import "base/nonstandard-bg/nonstandard-bg-v7";*/

/*@import "base/progress-bars/progress-bars";*/

/*@import "base/progress-bars/progress-bars-sizes";*/

/*@import "base/rating/rating-v1";*/

/*@import "base/searchforms/searchform-v1";
@import "base/searchforms/searchform-v2";
@import "base/searchforms/searchform-v3";
@import "base/searchforms/searchform-v4";
@import "base/searchforms/searchform-v5";*/

/*@import "base/steps/steps-v1";
@import "base/steps/steps-v2";
@import "base/steps/steps-v3";*/

/*@import "base/timelines/timelines";
@import "base/timelines/timelines-v1";
@import "base/timelines/timelines-v2";
@import "base/timelines/timelines-v3";*/

/*------------------------------------
  Background Colors
------------------------------------*/

.u-label {
  display: inline-block;
  padding: 0.35rem 0.58rem;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  /* Label Size
  ------------------------------------*/
  /* Label Styles
  ------------------------------------*/
  /* Label Num
  ------------------------------------*/
}

.u-label:empty {
  display: none;
}

.btn .u-label {
  position: relative;
  top: -1px;
}

.u-label-default {
  background-color: #777;
}

.u-label-default[href]:hover,
.u-label-default[href]:focus {
  background-color: #555;
}

.u-label-primary {
  background-color: #5cb85c;
}

.u-label-primary[href]:hover,
.u-label-primary[href]:focus {
  background-color: #55b555;
}

.u-label-success {
  background-color: #5cb85c;
}

.u-label-success[href]:hover,
.u-label-success[href]:focus {
  background-color: #55b555;
}

.u-label-info {
  background-color: #5bc0de;
}

.u-label-info[href]:hover,
.u-label-info[href]:focus {
  background-color: #53bddc;
}

.u-label-warning {
  background-color: #f0ad4e;
}

.u-label-warning[href]:hover,
.u-label-warning[href]:focus {
  background-color: #efa945;
}

.u-label-danger {
  background-color: #d9534f;
}

.u-label-danger[href]:hover,
.u-label-danger[href]:focus {
  background-color: #d74b47;
}

.u-label.g-rounded-10 {
  padding: 0.35rem 0.7rem;
}

.u-label--sm {
  font-size: 0.8rem;
}

.u-label--lg {
  font-size: 1.1rem;
}

.u-label.u-label-with-icon {
  padding: 0.5rem 0.85rem;
}

.u-label.u-label-with-icon i {
  margin-right: 0.5rem;
}

.u-label-num {
  min-width: 2rem;
  height: 2rem;
  padding: 0 0.35rem;
  line-height: 2rem;
}

.u-label-num.u-label--sm {
  min-width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
}

.u-label-num.u-label--lg {
  min-width: 2.2rem;
  height: 2.2rem;
  line-height: 2.2rem;
}

/*------------------------------------
  Link Styles
------------------------------------*/

.u-link-v1 {
  border-bottom: solid 1px;
}

.u-link-v1:hover,
.u-link-v1:focus {
  border-bottom: none;
  text-decoration: none;
}

.u-link-v2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-indent: -100%;
  white-space: nowrap;
  overflow: hidden;
  z-index: 2;
}

.u-link-v3 {
  text-decoration: underline;
}

.u-link-v3:hover {
  text-decoration: none;
}

.u-link-v4 {
  transition: all 0.3s;
}

.u-link-v4:hover {
  opacity: 0.8;
}

.u-link-v5 {
  text-decoration: none;
  transition: all 0.2s;
}

.u-link-v5:hover,
.u-link-v5:focus {
  text-decoration: none;
}

.u-link-v6 {
  display: block;
  overflow: hidden;
}

.u-link-v6-arrow {
  margin-left: -50px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
}

.u-link .u-block-hover:hover-v6-arrow,
.u-link-v6:hover-v6-arrow {
  margin-left: 5px;
  visibility: visible;
  opacity: 1;
}

.u-link-v7 {
  margin-right: 30px;
  transition: all 0.4s;
}

.u-link-v7-arrow {
  margin-left: -50px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
}

.u-link .u-block-hover:hover,
.u-link-v7:hover {
  margin-right: 0;
}

.u-link .u-block-hover:hover-v7-arrow,
.u-link-v7:hover-v7-arrow {
  margin-left: 10px;
  visibility: visible;
  opacity: 1;
}

/*------------------------------------
  List Styles
------------------------------------*/

.u-list-inline {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.img-bordered {
  border: solid 6px #777;
}

/*@import "base/orders";*/

/*------------------------------------
  Paginations
------------------------------------*/

/* Pagination v1 */

.u-pagination-v1__item {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border: solid 1px transparent;
  transition: all 0.3s ease;
}

.u-pagination-v1__item--active,
.u-pagination-v1__item:hover,
.u-pagination-v1__item:focus {
  text-decoration: none;
  cursor: pointer;
}

.u-pagination-v1__item-info {
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.u-pagination-v1__item--disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* Pagination Style v1 */

.u-pagination-v1-1 {
  color: #999;
  border-color: #999;
}

.u-pagination-v1-1--active,
.u-pagination-v1-1:hover,
.u-pagination-v1-1:focus {
  background-color: #22211f;
  color: #fff;
  border-color: #22211f;
}

/* Pagination Style v2 */

.u-pagination-v1-2 {
  color: #555;
  border-color: #555;
}

.u-pagination-v1-2:hover,
.u-pagination-v1-2:focus {
  color: #22211f;
  border-color: #22211f;
}

.u-pagination-v1-2--active,
.u-pagination-v1-2--nav {
  background-color: #22211f;
  color: #fff;
  border-color: #22211f;
}

.u-pagination-v1-2--active:hover,
.u-pagination-v1-2--active:focus,
.u-pagination-v1-2--nav:hover,
.u-pagination-v1-2--nav:focus {
  color: #fff;
}

.u-pagination-v1-2--nav:hover {
  background-color: rgba(34, 33, 31, 0.8);
}

/* Pagination Style v3 */

.u-pagination-v1-3 {
  color: #333;
  border-color: #333;
}

.u-pagination-v1-3--active,
.u-pagination-v1-3:hover,
.u-pagination-v1-3:focus {
  background-color: #333;
  color: #fff;
  border-color: #333;
}

/* Pagination Style v4 */

.u-pagination-v1-4 {
  color: #333;
  border-color: transparent;
}

.u-pagination-v1-4:hover,
.u-pagination-v1-4:focus {
  color: #22211f;
  border-color: #22211f;
}

.u-pagination-v1-4--active {
  color: #fff;
  background-color: #22211f;
  border-color: #22211f;
}

.u-pagination-v1-4--active:hover,
.u-pagination-v1-4--active:focus {
  color: #fff;
}

/* Pagination Style v5 */

.u-pagination-v1-5 {
  color: #999;
  border-color: #ccc;
}

.u-pagination-v1-5--active,
.u-pagination-v1-5:hover,
.u-pagination-v1-5:focus {
  background-color: #22211f;
  color: #fff;
  border-color: #22211f;
}

/*@import "base/ribbons";*/

/*@import "base/scroll-animations";*/

/*@import "base/stickers";*/

/*------------------------------------
  Sticky blocks
------------------------------------*/

[class*=sticky-block] {
  max-width: 100%;
}

/*------------------------------------
  Tables
------------------------------------*/

[class*=text][class*=center] td,
[class*=text][class*=center] th {
  text-align: center;
}

.u-table--v1 td,
.u-table--v1 th {
  padding: 8px;
}

.u-table--v1 thead th {
  border-bottom-width: 1px;
}

.u-table--v2 td,
.u-table--v2 th {
  padding: 15px;
}

.u-table--v2 thead th {
  background-color: inherit;
  border-bottom-width: 1px;
}

.g-col-border-top-0 td,
.g-col-border-top-0 th {
  border-top-width: 0;
}

.g-col-border-side-0 td,
.g-col-border-side-0 th {
  border-left-width: 0;
  border-right-width: 0;
}

/*@import "base/tabs/tabs";*/

/*------------------------------------
  Tags
------------------------------------*/

/* Pagination v1 */

.u-tags-v1 {
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s ease;
}

.u-tags-v1:hover,
.u-tags-v1:focus {
  text-decoration: none;
  cursor: pointer;
}

.u-tags-v1:focus {
  color: inherit;
}

/*@import "base/text-animations";*/

/*@import "base/text-shadows";*/

/*@import "base/bg-markers";*/

/*@import "base/tooltips";*/

/*------------------------------------
  Typography
------------------------------------*/

.popovers--no-title .popover-title {
  display: none;
}

.g-nowrap {
  white-space: nowrap;
}

/*@import "base/video-audio";*/

/*@import "base/navigation/navigation";*/

/*@import "base/basket";*/

/*@import "base/dropdown";*/

/*@import "base/compressed-form";*/

/*@import "base/dropcaps";*/

/*@import "base/bg-angles";*/

/*@import "base/block-hovers";*/

/*------------------------------------
  Blockquotes
------------------------------------*/

.u-blockquote-v1 {
  position: relative;
  background-color: #fff;
  box-shadow: 5px 6px 9px -6px rgba(0, 0, 0, 0.15);
}

.u-blockquote-v1::before {
  content: "“";
  position: absolute;
  width: 60px;
  color: #22211f;
  font-size: 60px;
  margin: -25px 0 0 -40px;
}

.u-blockquote-v1::after {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 80px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 30px 0 0;
  border-color: #fff transparent transparent transparent;
  -webkit-filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1));
}

.u-blockquote-v2::before,
.u-blockquote-v2::after {
  position: relative;
  top: 5px;
  font-size: 22px;
  line-height: 10px;
}

.u-blockquote-v2::before {
  content: "“";
  padding-right: 5px;
}

.u-blockquote-v2::after {
  content: "”";
  padding-left: 5px;
}

.u-blockquote-v3 {
  position: relative;
}

.u-blockquote-v3::before {
  content: "“";
  position: absolute;
  font-size: 60px;
  color: #22211f;
  margin: -30px 0 0 -40px;
}

.u-blockquote-v4::before,
.u-blockquote-v4::after {
  content: "“";
  position: absolute;
  font-size: 50px;
  margin-top: -12px;
}

.u-blockquote-v4::before {
  margin-left: -30px;
}

.u-blockquote-v4::after {
  margin-left: 13px;
}

.u-blockquote-v5::before {
  content: "“";
  position: absolute;
  width: 60px;
  color: rgba(255, 255, 255, 0.2);
  font-size: 70px;
  margin: -25px 0 0 -40px;
}

.u-blockquote-v6::before,
.u-blockquote-v6::after {
  content: '"';
  font-family: inherit;
  color: inherit;
}

.u-blockquote-v7 {
  position: relative;
  padding-top: 55px;
}

.u-blockquote-v7::before {
  content: "\f10d";
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 35px;
  height: 35px;
  font-size: 12px;
  font-family: "FontAwesome";
  color: #fff;
  line-height: 35px;
  background: #22211f;
  border-radius: 50%;
  margin-left: -18px;
}

.u-blockquote-v8 {
  position: relative;
  background-color: #fff;
  box-shadow: 5px 6px 9px -6px rgba(85, 85, 85, 0.08);
}

.u-blockquote-v8::after {
  content: "";
  position: absolute;
  left: 50px;
  bottom: -15px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #fff transparent transparent transparent;
  -webkit-filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.05));
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.05));
}

/*------------------------------------
  Accordions
------------------------------------*/

.u-accordion__header {
  padding: 0.7142857143rem 1.0714285714rem;
}

.u-accordion__body {
  padding: 1.0714285714rem;
}

.u-accordion__control-icon i:nth-child(1) {
  display: none;
}

.collapsed .u-accordion__control-icon i:nth-child(1) {
  display: inline;
}

.u-accordion__control-icon i:nth-child(2) {
  display: inline;
}

.collapsed .u-accordion__control-icon i:nth-child(2) {
  display: none;
}

[aria-expanded=false] .u-accordion__control-icon i:nth-child(1) {
  display: inline;
}

[aria-expanded=false] .u-accordion__control-icon i:nth-child(2) {
  display: none;
}

[aria-expanded=true] .u-accordion__control-icon i:nth-child(1) {
  display: none;
}

[aria-expanded=true] .u-accordion__control-icon i:nth-child(2) {
  display: inline;
}

.u-accordion [class*=et-icon-],
.u-accordion-line-icon-pro {
  position: relative;
  top: 3px;
}

.u-accordion-color-primary .u-accordion__header [aria-expanded=true] {
  color: #22211f !important;
}

.u-accordion-color-white .u-accordion__header [aria-expanded=true] {
  color: #fff !important;
}

.u-accordion-bg-primary .u-accordion__header [aria-expanded=true] {
  background-color: #22211f !important;
  border-color: #22211f !important;
}

.u-accordion-bg-white .u-accordion__header [aria-expanded=true] {
  background-color: #fff !important;
  border-color: #fff !important;
}

.u-accordion-brd-primary .u-accordion__header [aria-expanded=true] {
  border-color: #22211f !important;
}

.u-accordion-brd-white .u-accordion__header [aria-expanded=true] {
  border-color: #fff !important;
}

/*@import "base/carousels";
@import "base/carousels/effects";*/

/*@import "base/chart-pies";
@import "base/charts";*/

/*@import "base/counters";*/

/*@import "base/credit-cards";*/

/*------------------------------------
  Dropdowns v1
------------------------------------*/

.u-dropdown-v1 {
  position: relative;
  line-height: normal;
  font-size: 16px;
  color: #777;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  padding: 11px 40px 11px 14px;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 0.2s;
  transition-timing-function: ease;
}

.u-dropdown-v1::after {
  display: none;
}

.u-dropdown-v1-menu {
  padding: 0;
  border-color: #ccc;
  border-radius: 3px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.u-dropdown-v1-menu__item {
  line-height: normal;
  font-size: 16px;
  color: #777;
  padding: 9px 20px;
}

.u-dropdown-v1-menu__item:hover {
  color: #777;
}

.u-dropdown-v1__icon {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 40px;
  height: 100%;
}

.u-dropdown-v1__icon-open,
.u-dropdown-v1__icon-close {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-dropdown-v1__icon-open {
  opacity: 1;
}

.u-dropdown-v1__icon-close {
  opacity: 0;
}

.u-dropdown-v1[aria-expanded=true] {
  background-color: rgba(204, 204, 204, 0.1);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.u-dropdown-v1[aria-expanded=true] [class*=__icon-open] {
  opacity: 0;
}

.u-dropdown-v1[aria-expanded=true] [class*=__icon-close] {
  opacity: 1;
}

.u-dropdown-v1:focus {
  outline: 0 none;
}

/*------------------------------------
  Covers
------------------------------------*/

.u-bg-overlay {
  position: relative;
}

.u-bg-overlay::before,
.u-bg-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.u-bg-overlay--v1::after {
  position: absolute;
  top: 0.3571428571rem;
  right: 0.3571428571rem;
  bottom: 0.3571428571rem;
  left: 0.3571428571rem;
}

.u-bg-overlay__inner {
  z-index: 3;
  position: relative;
}

.u-bg-overlay--reverse::before {
  z-index: 2;
}

.u-bg-overlay--reverse::after {
  z-index: 1;
}

/*@import "base/waves";
@import "base/fab/fab";
@import "base/chips";*/

/*@import "base/outer-spaces-helper";*/

/*@import "blocks/info";*/

/*@import "vendors/vendor";*/

/*@import "js/js";*/

/*------------------------------------
  Internet Explorer (IE)
------------------------------------*/

/* Text Gradients */

.IE .g-color-cyan-gradient-opacity-v1,
.IE .g-color-blue-gradient-opacity-v1 {
  background: transparent;
  -webkit-text-fill-color: initial;
}

.IE .g-color-cyan-gradient-opacity-v1 {
  color: #00bed6;
}

.IE .g-color-blue-gradient-opacity-v1 {
  color: #8654da;
}

.IE .form-control:-ms-input-placeholder {
  opacity: 1;
}

/*@import "demo/demo.icons";*/

/* Add here all your css styles (customizations) */

/* work-sans-regular - latin_latin-ext */

@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/Content/fonts/work-sans-v18-latin_latin-ext-regular.eot");
  /* IE9 Compat Modes */
  src: url("/Content/fonts/work-sans-v18-latin_latin-ext-regular.eot?#iefix") format("embedded-opentype"), url("/Content/fonts/work-sans-v18-latin_latin-ext-regular.woff2") format("woff2"), url("/Content/fonts/work-sans-v18-latin_latin-ext-regular.woff") format("woff"), url("/Content/fonts/work-sans-v18-latin_latin-ext-regular.ttf") format("truetype"), url("/Content/fonts/work-sans-v18-latin_latin-ext-regular.svg#WorkSans") format("svg");
  /* Legacy iOS */
}

/* work-sans-500 - latin_latin-ext */

@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  src: url("/Content/fonts/work-sans-v18-latin_latin-ext-500.eot");
  /* IE9 Compat Modes */
  src: url("/Content/fonts/work-sans-v18-latin_latin-ext-500.eot?#iefix") format("embedded-opentype"), url("/Content/fonts/work-sans-v18-latin_latin-ext-500.woff2") format("woff2"), url("/Content/fonts/work-sans-v18-latin_latin-ext-500.woff") format("woff"), url("/Content/fonts/work-sans-v18-latin_latin-ext-500.ttf") format("truetype"), url("/Content/fonts/work-sans-v18-latin_latin-ext-500.svg#WorkSans") format("svg");
  /* Legacy iOS */
}

/* work-sans-600 - latin_latin-ext */

@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  src: url("/Content/fonts/work-sans-v18-latin_latin-ext-600.eot");
  /* IE9 Compat Modes */
  src: url("/Content/fonts/work-sans-v18-latin_latin-ext-600.eot?#iefix") format("embedded-opentype"), url("/Content/fonts/work-sans-v18-latin_latin-ext-600.woff2") format("woff2"), url("/Content/fonts/work-sans-v18-latin_latin-ext-600.woff") format("woff"), url("/Content/fonts/work-sans-v18-latin_latin-ext-600.ttf") format("truetype"), url("/Content/fonts/work-sans-v18-latin_latin-ext-600.svg#WorkSans") format("svg");
  /* Legacy iOS */
}

/* work-sans-700 - latin_latin-ext */

@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/Content/fonts/work-sans-v18-latin_latin-ext-700.eot");
  /* IE9 Compat Modes */
  src: url("/Content/fonts/work-sans-v18-latin_latin-ext-700.eot?#iefix") format("embedded-opentype"), url("/Content/fonts/work-sans-v18-latin_latin-ext-700.woff2") format("woff2"), url("/Content/fonts/work-sans-v18-latin_latin-ext-700.woff") format("woff"), url("/Content/fonts/work-sans-v18-latin_latin-ext-700.ttf") format("truetype"), url("/Content/fonts/work-sans-v18-latin_latin-ext-700.svg#WorkSans") format("svg");
  /* Legacy iOS */
}

body {
  font-family: "Work Sans", sans-serif !important;
  font-weight: 500;
  color: #2b2e36;
}

label,
h2,
h3,
h4 {
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
}

h2.h4 {
  color: #c6a171;
  font-family: "Work Sans", sans-serif;
}

.content-column h2,
.content-column h3,
.content-column h4 {
  font-family: "Work Sans";
}

dl,
ol,
p,
ul,
h2,
h3,
h4 {
  margin-bottom: 1.5rem;
}

a {
  color: #21347b;
}

a:hover {
  color: #21347b;
}

.staticpage h2,
.staticpage h3,
.staticpage h4 {
  margin-bottom: 20px;
}

.staticpage h2 {
  padding-top: 20px;
}

hr {
  margin-bottom: 20px;
}

.home-events .time {
  min-height: 43px;
}

.form-control {
  height: auto;
  font-family: "Work Sans", sans-serif !important;
}

.form-control::placeholder {
  color: #535354 !important;
}

.file-box {
  background: #f4f4f4;
  padding: 10px 15px;
  margin-bottom: 15px;
}

#cconsent-bar {
  color: #fff !important;
  background-color: #21347b !important;
}

#cconsent-bar button {
  line-height: normal;
  font-size: 14px;
  border: none;
  padding: 10px 10px;
  color: #ffffff;
  background-color: #c6a171 !important;
}

.filter-label {
  font-size: 15px;
}

.slick-list {
  margin-left: -16px;
  margin-right: -16px;
}

.h4,
h4 {
  font-size: 1.3rem;
}

.g-font-size-21 {
  font-size: 21px;
}

.g-top-22 {
  top: 22px;
}

.navbar-brand {
  margin: 8px 0 8px 8px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  /*width: 290px;*/
  width: 205px;
}

.g-right-165 {
  right: 145px;
}

.g-right-220 {
  right: 300px;
}

.g-top-12 {
  top: 12px;
}

.g-color-main {
  color: #242620;
}

.g-px-31 {
  padding-left: 2.3rem !important;
  padding-right: 2.3rem !important;
}

.u-arrow-custom-v7:hover .u-arrow-icon,
a,
table.table td a,
ul.ui-autocomplete li .text {
  color: #21347b;
}

.language-header a {
  background: url("assets/img/icons/arrow_right.svg") no-repeat 0 center;
  background-size: 15px auto;
  padding-left: 23px;
}

.phone {
  background: url("assets/img/icons/contact_phone.svg") no-repeat 0 center;
  background-size: auto 17px;
  padding-left: 28px;
}

.mail {
  background: url("assets/img/icons/contact_mail.svg") no-repeat 0 center;
  background-size: 17px auto;
  padding-left: 28px;
}

.slick-next.slick-disabled::before,
.slick-prev.slick-disabled::before {
  opacity: 1;
}

.language-header {
  top: 43px;
  right: 43px;
}

.card {
  border: 0;
  background: #f8f8f4;
  padding: 10px 0;
}

.u-accordion__header a {
  border-bottom: 1px solid #e5ecd0 !important;
  margin-bottom: 15px !important;
}

.u-accordion__header a:hover {
  background: #f0f0ed !important;
}

.staticpage table tfoot td {
  font-weight: 600;
  text-transform: uppercase;
}

.u-accordion i {
  line-height: 20px !important;
}

.home-info {
  background: #e4ebfb;
}

.home-info .col-sm-12 {
  background: url("assets/img/homeinfo.svg") no-repeat 0 bottom;
}

.home-info h2 {
  font-weight: 700;
  font-size: 68px;
  color: #c6a171;
  margin-bottom: 20px;
}

.home-info a {
  display: block;
  font-size: 25px;
  color: #21347b;
  font-weight: 700;
  padding-left: 35px;
  margin-bottom: 8px;
  background: url("assets/img/arrow3.svg") no-repeat left center;
  background-size: 24px 14px;
}

.staticpage .u-accordion table th,
.staticpage .u-accordion table td {
  border-bottom: 1px solid #e5ecd0 !important;
}

.navbar-collapse.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}

h3 {
  font-size: 1.45rem;
}

.navbar {
  padding: 0 !important;
}

.search-top {
  position: absolute;
  right: 25px;
  top: 22px;
}

.header-search-btn {
  cursor: pointer;
}

.header-search-btn-close {
  cursor: pointer;
}

.btn.btn-link.search-btn.btn-top {
  transition: none;
}

.ophours td {
  padding: 3px 10px;
  vertical-align: top !important;
}

.ophours tr td:first-child {
  padding-left: 0;
}

.ophours tr td:last-child {
  min-width: 250px !important;
}

.box {
  background: #f1f5fd;
  padding: 20px 15px;
  margin-bottom: 8px;
}

.footer-logo img {
  width: 300px !important;
}

.right-section a {
  font-size: 18px;
  font-weight: 600;
}

.r-news-list li {
  margin: 0 0 20px !important;
}

.right-section .fa.fa-angle-right {
  position: absolute;
  top: 5px;
  left: 0px;
}

.staticpage a[href^="http://"]::before,
.staticpage a[href^="https://"]::before {
  display: none;
}

.staticpage p,
.staticpage ul,
.staticpage ol {
  margin-bottom: 1.6rem;
}

.staticpage a[href^="http://"]::after,
.staticpage a[href^="https://"]::after {
  font-weight: 400;
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-variant: normal;
  margin-right: 8px;
  margin-left: 8px;
  font-size: 14px !important;
  color: #008bd2;
  content: "\f35d";
  text-decoration: none;
  display: inline-block;
}

.staticpage a.box-link[href^="http://"]::after,
.staticpage a.box-link[href^="https://"]::after {
  display: none;
}

.staticpage .downloads-png a[href$=".png"],
.staticpage .downloads-eps a[href$=".eps"],
.staticpage a[href$=".pdf"],
.staticpage a[href$=".zip"],
.staticpage a[href$=".doc"],
.staticpage a[href$=".docx"],
.staticpage a[href$=".rtf"],
.staticpage a[href$=".xls"],
.staticpage a[href$=".xlsx"],
.staticpage a[href$=".XLSX"] {
  padding-left: 28px;
  display: block;
}

.staticpage .downloads-png a[href$=".png"]:before {
  font-weight: 400;
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-variant: normal;
  margin-right: 10px;
  font-size: 17px;
  color: #f4ae36;
  content: "\f1c5";
  text-decoration: none;
  margin-left: -24px;
}

.staticpage .downloads-eps a[href$=".eps"]:before {
  font-weight: 400;
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-variant: normal;
  margin-right: 10px;
  font-size: 17px;
  color: #f4ae36;
  content: "\f31c";
  text-decoration: none;
  margin-left: -24px;
}

.staticpage a[href$=".pdf"]:before {
  font-weight: 400;
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-variant: normal;
  margin-right: 10px;
  font-size: 17px;
  color: #f4ae36;
  content: "\f1c1";
  text-decoration: none;
  margin-left: -24px;
}

.staticpage a[href$=".zip"]:before {
  font-weight: 400;
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-variant: normal;
  margin-right: 10px;
  font-size: 17px;
  color: #f4ae36;
  content: "\f1c6";
  text-decoration: none;
  margin-left: -24px;
}

.staticpage a[href$=".doc"]:before,
.staticpage a[href$=".docx"]:before,
.staticpage a[href$=".rtf"]:before {
  font-weight: 400;
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-variant: normal;
  margin-right: 10px;
  font-size: 17px;
  color: #f4ae36;
  content: "\f1c2";
  text-decoration: none;
  margin-left: -24px;
}

.staticpage a[href$=".xls"]:before,
.staticpage a[href$=".xlsx"]:before,
.staticpage a[href$=".XLSX"]:before {
  font-weight: 400;
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-variant: normal;
  margin-right: 10px;
  font-size: 17px;
  color: #f4ae36;
  content: "\f65b";
  text-decoration: none;
  margin-left: -24px;
}

.staticpage a[href$=".pdf"],
.staticpage a[href^="http://"],
.staticpage a[href^="https://"],
.staticpage a[href$=".doc"],
.staticpage a[href$=".docx"],
.staticpage a[href$=".xls"],
.staticpage a[href$=".xlsx"] {
  border-bottom: 0;
  text-decoration: none !important;
}

.staticpage a[href$=".pdf"]:hover,
.staticpage a[href^="http://"]:hover,
.staticpage a[href^="https://"]:hover,
.staticpage a[href$=".doc"]:hover,
.staticpage a[href$=".docx"]:hover,
.staticpage a[href$=".xls"]:hover,
.staticpage a[href$=".xlsx"]:hover {
  border-bottom: 0;
  text-decoration: underline !important;
  background: none;
}

.box-object3 p .btn.btn-download {
  margin-top: 6px;
  vertical-align: baseline;
}

.h2,
h2 {
  font-size: 1.8rem;
}

.g-width-230 {
  width: 230px;
}

.g-width-210 {
  width: 210px;
}

.g-top-28 {
  top: 28px;
}

.modal-open {
  overflow: hidden;
}

.home-info-indend {
  padding: 160px 0 30px 40px;
}

.radnica td {
  width: 25%;
  text-align: center;
}

.staticpage .radnica a[href$=".pdf"]::before {
  display: none !important;
}

.btn-primary {
  background-color: #c6a171;
  border-color: #c6a171;
  color: #fff;
}

.btn-primary:hover {
  background-color: #ba9462;
  border-color: #ba9462;
  color: #fff;
}

.u-accordion i {
  font-size: 12px;
  line-height: 24px;
  vertical-align: text-bottom;
  text-decoration: none !important;
}

.u-accordion h5 a:focus {
  border: 0 !important;
}

.u-accordion ul,
.u-accordion ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.u-accordion ul {
  margin: 0 0 0 26px;
}

.u-accordion i:hover {
  text-decoration: none !important;
}

.u-accordion-color-primary .u-accordion__header [aria-expanded=true] {
  color: #242620 !important;
}

.u-accordion-color-primary .u-accordion__header [aria-expanded=true] span {
  text-decoration: underline !important;
}

.u-accordion-color-primary .u-accordion__header [aria-expanded=true] span.u-accordion__control-icon {
  text-decoration: none !important;
}

.u-accordion-color-primary .u-accordion__header:focus {
  border: 0 !important;
}

.fa-file-rtf::before {
  content: "\f1c2";
}

.mgx0 {
  margin-left: 0;
  margin-right: 0;
}

.bg-light {
  background: #f9f4ea !important;
}

.bg-medium {
  background: #f3e8d5;
}

#contacts-section strong {
  font-family: "Work Sans";
  font-weight: normal;
}

.bgheader,
.bgheader .u-header {
  background: url("assets/img/bg/bgsub.png") no-repeat center top #334583;
  position: relative;
  z-index: 1050;
}

.layout-home .bgheader {
  background: url("assets/img/bg/bgheader.jpg") no-repeat center top #334583;
}

.layout-home .bgheader .u-header {
  background: none;
}

/*.layout-home .bgheader, .layout-home .bgheader .u-header {
    background: url("../../../assets/img/bg/bgheader.jpg") no-repeat center top #334583;
}*/

.lines {
  background: url("assets/img/lines.svg") repeat-x;
  height: 30px;
  font-size: 11px;
  margin-top: 40px;
}

.navbar-toggler.navbar-toggler-right {
  background: url("assets/img/m_menu_icon1.svg") no-repeat;
  top: 21px;
  right: 15px;
  font-size: 21px;
  padding: 0;
  width: 34px;
  height: 30px;
  font-family: "Work Sans";
  color: #2b2822;
  border: 0;
  border-radius: 0;
}

.navbar-toggler.navbar-toggler-right[aria-expanded=true] {
  background: url("assets/img/m_menu_icon2.svg") no-repeat;
  color: #fff;
  border: 0 !important;
  height: 30px;
}

.navbar-toggler.navbar-toggler-right:focus {
  border: 0 !important;
  box-shadow: none !important;
}

.modal-backdrop {
  top: 88px !important;
}

.navbar-collapse .col-sm-12 {
  padding-right: 0 !important;
}

.navbar-toggler.navbar-toggler-right.collapsed {
  background: url("assets/img/m_menu_icon1.svg") no-repeat;
  color: #2b2822;
}

.navbar-collapse.collapse.show {
  display: block !important;
  position: absolute;
  top: 86px;
  width: 100%;
}

.els {
  background: url("assets/img/arrow.png") no-repeat 0 6px;
  padding-left: 13px;
  font-family: "Work Sans";
}

.els:hover {
  color: #d9be86;
  text-decoration: none;
}

.els.mobile-els {
  background: url("assets/img/arrow.png") no-repeat 0 6px;
  padding-left: 13px;
  color: #22211f;
}

.home-alerts,
.top-search {
  background: url("assets/img/bg.png") repeat-x #f7fafd;
}

.input-group-addon .fa-search {
  background: url("assets/img/search.svg") no-repeat;
  width: 27px;
  height: 27px;
}

.search-btn {
  padding: 10px 7px 4px 7px;
}

.search-header .search-btn.btn-top {
  padding: 5px 7px 0 7px;
}

.input-group-addon .fa-search::before {
  content: none;
}

#searchform-6 .input-group {
  width: 100%;
  margin: 0 0 0 auto;
}

.home-motive #searchform-6 .input-group {
  margin: 0 auto;
}

.home-motive .row {
  align-items: center;
}

.home-motive .col-sm-6 {
  padding-left: 0;
  padding-right: 0;
  min-height: 400px;
  position: relative;
}

.home-motive .text {
  color: #c6a171;
  font-size: 96px;
  line-height: 90px;
  font-weight: 700;
  margin: 85px 0 0 0;
  text-transform: uppercase;
}

.home-motive .col-sm-6 label {
  font-size: 36px;
  color: #656565;
}

.home-motive .header-label {
  font-size: 36px;
  color: #fff;
  font-weight: 700;
  margin: 0 0 25px 0;
}

.welcome p {
  font-size: 22px;
  color: #c6a171;
  font-weight: 700;
  margin: 0 auto 75px auto;
  width: 630px;
}

.welcome p a {
  color: #fff;
}

.home-links {
  background: #f8f8f8;
  padding: 40px 0 0 0;
}

.home-links .col-md-2 {
  text-align: center;
}

.home-links .col-md-2 a {
  color: #2b2e80;
  font-weight: 600;
  font-size: 19px;
}

.home-links .col-md-2 a img {
  margin-bottom: 20px;
  width: 138px;
}

.footer-social {
  text-align: center;
}

.footer-social img {
  width: 60px;
}

.home-news {
  padding: 70px 0 50px;
}

.home-events h2,
.home-news h2,
.home-notes h2,
.home-partners h2 {
  font-size: 42px !important;
  font-weight: 700;
}

.home-partners {
  padding: 20px 0;
}

.home-partners h2 {
  text-align: center !important;
  width: 100%;
}

.home-partners img {
  height: 150px;
}

.article-detail {
  background: #21347b;
  padding: 0 15px 30px 26px;
  position: relative;
}

.sub-news .article-detail {
  background: #f8f8f8;
  padding: 30px 20px;
  position: relative;
}

.sub-news .slick-list .date {
  padding-top: 0;
}

.article-detail a {
  text-decoration: none;
}

.article-detail .news-image {
  top: -30px;
  right: 0;
  position: absolute;
}

#searchform-6 .btn.btn-link {
  background-color: #fff !important;
  color: #000 !important;
}

.home-alerts .col-sm-6:hover {
  background: #f7fbff !important;
}

.home-motive {
  background: #dceaf8 !important;
}

.home-motive .col-sm-6 #search-input {
  border: 0 !important;
  font-size: 16px;
}

.home-motive .col-sm-6 p a {
  font-size: 20px;
  color: #5f6063;
}

.g-font-size-19 {
  font-size: 19px;
}

.home-info h3 {
  font-family: "Work Sans";
}

.dropdown-item {
  font-weight: 500;
}

.u-link-v5:focus,
.u-link-v5:hover {
  box-shadow: none !important;
}

.home-notes .date {
  display: block;
}

ul.ui-autocomplete {
  padding: 10px 15px !important;
  background: #f5f5f5 !important;
}

ul.ui-autocomplete li {
  padding: 4px 15px 4px 15px;
  color: #000;
  font-size: 0.9em;
}

ul.ui-autocomplete li.ui-menu-focus,
ul.ui-autocomplete li.ui-menu-item:hover {
  background: #f5f5f5 !important;
  color: #000 !important;
  text-decoration: underline;
}

/*
.slick-track .slick-slide:first-child .col-sm-12, .slick-track .slick-slide:nth-child(4) .col-sm-12 {
    padding-left: 0;
}

.slick-track .slick-slide:nth-child(9) .col-sm-12, .slick-track .slick-slide:nth-child(6) .col-sm-12, .slick-track .slick-slide:nth-child(3) .col-sm-12 {
    padding-right: 0;
}*/

.social a {
  display: inline-block;
  margin-right: 5px;
  width: 32px;
  height: 32px;
  text-align: center;
  background: #343726;
  color: #fff;
  border-radius: 50%;
}

.social a i {
  margin-top: 9px;
  display: inline-block;
}

#contacts-section h3 {
  font-family: Work Sans;
}

.home-alerts .col-sm-6 {
  background: #e4f0fc;
  margin-left: 15px;
  flex: 0 0 48.6%;
}

.home-alerts .col-sm-6:first-child {
  margin-left: 0;
  margin-right: 15px;
}

.home-alerts .col-sm-6 a {
  display: block;
  font-size: 18px;
  text-decoration: underline;
}

.home-alerts .col-sm-6 p {
  margin-bottom: 0;
  font-size: 14px;
  color: #999690;
}

.navbar-expand-lg .navbar-toggler {
  display: none;
}

.bg-abstract {
  background-image: url("content/assets/img/bg/mirrored-squares.png");
}

.fa-pull-right,
.pull-right,
.fa-pull-right li,
.pull-right li,
.loginbar li {
  list-style-type: none;
}

.loginbar {
  margin-top: 8px;
  margin-left: 15px;
}

.g-hidden-md-up .loginbar {
  padding: 0;
  margin: 0;
}

.g-hidden-md-up .loginbar > li > a {
  color: #fff;
}

.g-hidden-md-up .loginbar li a {
  padding: 1.07143rem;
  display: inline-block;
}

.g-hidden-md-up .loginbar .dropdown-menu {
  width: 100%;
}

.g-hidden-md-up .loginbar .dropdown-menu li {
  padding-top: 0;
  padding-bottom: 0;
}

.img-responsive.full-width {
  width: 100%;
}

@media (min-width: 1025px) {
  .min-height-article {
    min-height: 380px !important;
  }
}

.input-validation-error,
.input-validation-error:focus {
  border-color: #e64b3b !important;
}

.field-validation-error {
  color: #e64b3b !important;
}

.validation-summary-errors {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin: 0 0 0.75rem 0 !important;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.validation-summary-errors ul {
  margin: 0;
  padding: 0 0 0 15px;
  list-style: none;
}

.dropdown-menu {
  padding: 40px 15px 30px;
  border-radius: 0;
}

.user-logged:hover {
  text-decoration: none;
}

@media (min-width: 1025px) {
  /*       .min-height-article {
             min-height: 280px;
         }*/

  .masonry-grid-item > article {
    height: 100%;
  }
}

@media (max-width: 991px) {
  /*.navbar-collapse {
      background-color: #274065;
  }*/

  .navbar-collapse .hs-sub-menu {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .g-color-white-xs,
  .nav-link.g-color-white-xs {
    color: #fff !important;
  }
}

.highlighted {
  font-weight: bold;
  background: #F0F0F0;
}

/* loader for jobs list loading - copied from stengl*/

.ajax-preloader {
  display: block;
  width: 100%;
  float: left;
  text-align: center;
  position: relative;
  height: 100px;
  margin: 15px 0;
}

@keyframes lds-rolling {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.lds-rolling {
  position: relative;
  display: inline-block;
}

.lds-rolling div,
.lds-rolling div:after {
  position: absolute;
  width: 90px;
  height: 90px;
  border: 10px solid #213769;
  border-top-color: transparent;
  border-radius: 50%;
}

.lds-rolling div {
  animation: lds-rolling 1s linear infinite;
  top: 100px;
  left: 100px;
}

.lds-rolling div:after {
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.lds-rolling {
  width: 100px !important;
  height: 100px !important;
  -ms-transform: translate(-50px, -50px) scale(0.5) translate(50px, 50px);
  transform: translate(-50px, -50px) scale(0.5) translate(50px, 50px);
}

.hidden {
  display: none;
}

.dropdown-menu li {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.alert > ul {
  margin-bottom: 0;
}

.alert .fa {
  margin-right: 3px;
}

.home-events article .date {
  font-weight: 700;
  display: block;
}

.event-page .col-sm-4 {
  margin-bottom: 30px;
}

ul.ui-autocomplete {
  position: absolute;
  z-index: 510;
  background: white;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.09), -2px 2px 3px rgba(0, 0, 0, 0.09);
  border-radius: 0 0 0.25rem 0.25rem !important;
  text-align: left;
  list-style: none;
  padding-left: 0;
}

ul.ui-autocomplete li {
  margin: 0;
  cursor: pointer;
}

ul.ui-autocomplete li .text {
  color: #22211f;
}

ul.ui-autocomplete li .category {
  color: lightgray;
}

ul.ui-autocomplete li .annotation {
  color: #555;
  word-wrap: normal;
}

ul.ui-autocomplete li.ui-state-hover,
ul.ui-autocomplete li.ui-state-focus,
ul.ui-autocomplete li.ui-menu-item:hover {
  background: #f7f7f7 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  font-weight: normal !important;
}

.navbar-expand-lg {
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar .container {
  position: relative;
}

.navbar .container,
.navbar .container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: start;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (min-width: 992px) {
  .navbar-expand-lg .container,
  .navbar-expand-lg .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: block !important;
  }
}

.navbar {
  position: relative;
  padding: 0.5rem 0 0 0;
  display: block;
}

.hs-sub-menu {
  left: 0;
  top: 150px;
  z-index: 2;
  margin-top: 5px;
  visibility: hidden;
  background-color: #274065;
  opacity: 1 !important;
  width: 100%;
}

.g-ml-80 {
  margin-left: 80px;
}

.sub-news .article-detail h3 {
  line-height: 1.2em !important;
  min-height: 120px !important;
  -webkit-line-clamp: 4;
}

.language button.dropdown-toggle {
  border: 0;
  background-color: #fff;
}

.language button.dropdown-toggle:active,
.language button.dropdown-toggle:focus {
  border: 0 !important;
}

.language .dropdown-menu {
  min-width: initial;
  padding: 0;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.language .dropdown-item {
  padding: 0 28px 0 8px;
}

#search-input {
  border-top: 0 !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
  border-right: 0 !important;
  background-color: #fff;
  color: #647489;
  -webkit-border-top-left-radius: 5px !important;
  -webkit-border-bottom-left-radius: 5px !important;
  -moz-border-radius-topleft: 5px !important;
  -moz-border-radius-bottomleft: 5px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  -webkit-border-top-right-radius: 0 !important;
  -webkit-border-bottom-right-radius: 0 !important;
  -moz-border-radius-topright: 0 !important;
  -moz-border-radius-bottomright: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

#searchform-6 .btn.btn-link {
  background-color: #2b2822;
  color: #fff;
  -webkit-border-top-right-radius: 5px !important;
  -webkit-border-bottom-right-radius: 5px !important;
  -moz-border-radius-topright: 5px !important;
  -moz-border-radius-bottomright: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  -webkit-border-top-left-radius: 0 !important;
  -webkit-border-bottom-left-radius: 0 !important;
  -moz-border-radius-topleft: 0 !important;
  -moz-border-radius-bottomleft: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.newsletter span {
  display: inline-block;
}

.newsletter .form-control {
  border-radius: 0 !important;
  border: 0 !important;
  font-size: 16px !important;
  -webkit-border-top-left-radius: 3px !important;
  -webkit-border-bottom-left-radius: 3px !important;
  -moz-border-radius-topleft: 3px !important;
  -moz-border-radius-bottomleft: 3px !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.newsletter .btn {
  border-radius: 0 !important;
  -webkit-border-top-right-radius: 3px !important;
  -webkit-border-bottom-right-radius: 3px !important;
  -moz-border-radius-topright: 3px !important;
  -moz-border-radius-bottomright: 3px !important;
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  background-color: #202414;
  color: #fff;
}

.file-card .download-icon,
.file-card a:hover {
  color: #21347b !important;
}

.home-links .box {
  padding: 35px 15px 35px 70px;
  font-size: 19px;
  background-color: #f3f9ff;
  line-height: 1.2em;
  display: block;
  vertical-align: middle;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-position: left center;
  font-family: "Work Sans", sans-serif !important;
}

.home-links .bx-24h {
  background-image: url("assets/img/bx24h.png");
}

.home-links .bx-mp {
  background-image: url("assets/img/bxmp.png");
}

.home-links .bx-ps {
  background-image: url("assets/img/bxps.png");
}

.home-links .bx-vt {
  background-image: url("assets/img/bxvt.png");
}

.home-links .bx-sa {
  background-image: url("assets/img/bxsa.png");
}

.home-links .bx-pat {
  background-image: url("assets/img/bxpat.png");
}

.home-links .bx-su {
  background-image: url("assets/img/bxsu.png");
}

.home-links .bx-mpg {
  background-image: url("assets/img/bxmpg.png");
}

.home-links .bx-zp {
  background-image: url("assets/img/bxzp.png");
}

.home-links .bx-mm {
  background-image: url("assets/img/bxmm.png");
}

.home-links .bx-rm {
  background-image: url("assets/img/bxrm.png");
}

.home-links .bx-um {
  background-image: url("assets/img/bxum.png");
}

.home-links .box:hover {
  color: #000000;
  text-decoration: none;
  background-color: #f3f9ff;
}

.bg {
  background-color: #f0faff;
}

.staticpage a {
  text-decoration: none !important;
}

.staticpage a:hover {
  text-decoration: underline !important;
}

.contact-item {
  padding: 20px 20px;
  background: #f8f8f4;
  border-radius: 4px;
  margin-bottom: 15px;
}

.contact-item p:last-child {
  margin-bottom: 0;
}

.article-detail img {
  max-width: 100%;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.article-detail .media-body {
  color: #5e6a78;
  font-weight: 100;
}

.newsletter,
#contacts-section,
.footer-copy,
footer {
  background-color: #21347b;
  color: #fff;
}

.newsletter a,
#contacts-section a,
.footer-copy a,
footer a {
  color: #fff;
}

.fmail {
  margin-left: 2px;
  font-family: "Work Sans", sans-serif !important;
}

.fphone {
  margin-left: 2px;
  font-family: "Work Sans", sans-serif !important;
}

.faddress,
.fmail,
.fphone {
  font-size: 18px;
  line-height: 1.5;
}

.faddress::before {
  content: "\f015";
  margin-right: 6px;
  font-weight: 900;
  font-size: 20px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

.fmail::before {
  content: "\f0e0";
  margin-right: 8px;
  font-weight: 900;
  font-size: 18px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

#topcontrol {
  bottom: 0 !important;
  width: 100% !important;
  padding: 14px 0 !important;
  position: static !important;
  right: 0 !important;
  z-index: 200;
  background: #1d2d6b !important;
  background-size: 27px 17px !important;
}

#topcontrol:hover {
  background: #1d2d6b !important;
}

#topcontrol span {
  bottom: 0 !important;
  width: 100% !important;
  height: 27px !important;
  position: static !important;
  right: 0 !important;
  z-index: 200;
  display: block;
  background: url("assets/img/arrowtop.svg") no-repeat top center !important;
}

.fphone::before {
  content: "\f095";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  margin-right: 6px;
  font-weight: 900;
  font-size: 18px;
}

.fpl {
  padding-left: 28px;
  font-size: 20px;
  line-height: 1.5;
}

li::marker {
  color: #c6a171;
}

footer {
  border-top: 1px solid #41518e;
  padding: 20px 0;
}

/*.home-services h2 {
    color: #000;
    font-weight: 300;
}

.home-services h2 span {
    color: #008bd2;
}

.home-services .box {
    border: 1px solid #DFE6EA;
    padding: 20px;
}

.home-services .col-lg-3:last-child h3 {
    color: #C7034A;
}

.home-services ul li::marker {
    font-size: 10px;
}

.home-services ul {
    margin-left: 0;
    padding-left: 10px;
}

.home-services ul li {
    margin-bottom: 4px;
}

.home-services ul li a {
    color: #455262;
}*/

/*.home-events .home-events-title {
    border-left: 1px solid #DFE6EA;
    border-right: 1px solid #DFE6EA;
}

.home-events .home-events-date {
    border-left: 1px solid #DFE6EA;
    border-right: 1px solid #DFE6EA;
    border-bottom: 1px solid #DFE6EA;
    -webkit-border-bottom-right-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.home-events {
    border-top: 1px solid #DFE6EA;
    border-bottom: 1px solid #DFE6EA;
}*/

#ui-datepicker-div {
  background: #fff;
  padding: 15px;
  border: 1px solid #dfe6ea;
  border-radius: 4px;
  display: none;
}

.hasDatepicker {
  border: 1px solid #DFE6EA;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 20px 20px 10px 20px;
}

.ui-datepicker-header {
  padding-bottom: 10px;
  padding-top: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #e9e9e8;
}

.ui-datepicker-calendar {
  width: 100%;
  margin-bottom: 10px;
}

.ui-datepicker-calendar th {
  color: #22211f;
  text-align: center;
  text-transform: uppercase;
}

.ui-datepicker-calendar td {
  color: #455262;
  text-align: center;
  padding: 0;
}

.ui-datepicker-calendar td a,
.ui-datepicker-calendar td span {
  padding: 3px 7px;
  width: 30px;
  display: inline-block;
}

.ui-datepicker-calendar td .ui-state-active {
  color: #fff;
  background: #027cb9;
  border-radius: 15px;
}

/*.all-events {
    padding: 16px 20px 16px 20px;
    border-bottom: 1px solid #DFE6EA;
    border-left: 1px solid #DFE6EA;
    border-right: 1px solid #DFE6EA;
    -webkit-border-bottom-right-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.all-events .btn {
    width: 100%;
    text-align: center;
    border: 1px solid #DFE6EA;
    border-radius: 3px;
    padding: 10px 0;
}*/

.ui-datepicker-prev {
  float: left;
  cursor: pointer;
  margin-top: 0;
}

.ui-datepicker-next {
  float: right;
  cursor: pointer;
  margin-top: 0;
}

.ui-datepicker-title {
  text-align: center;
  font-size: 18px;
  margin-top: 4px;
}

/*.home-quicklinks {
    text-align: center;
}

.home-quicklinks a {
    text-align: center;
    color: #000;
    font-size: 16px;
    display: block;
    font-weight: 600;
}

.home-quicklinks a:hover {
    color: #008bd2;
}

.home-quicklinks span {
    text-align: center;
    display: inline-block;
    width: 61px;
    height: 61px;
}*/

.right-section.right-menu.panel.panel-default.panel-search .float-right a {
  font-size: 17px;
}

.custom-control-label span {
  font-size: 15px;
  font-weight: 400;
  color: #8c8c8c;
}

@media (max-width: 767px) {
  /* .home-quicklinks .col-xs-6 {
          -ms-flex: 0 0 50%;
          flex: 0 0 50%;
          max-width: 50%;
          position: relative;
          width: 100%;
          min-height: 1px;
          padding-right: 15px;
          padding-left: 15px;
          margin-bottom: 30px;
      }
  */

  .newsletter span,
  .newsletter .input-group {
    display: block;
  }

  .newsletter {
    border-bottom: 0;
    margin-bottom: 0;
  }

  .newsletter span {
    margin-bottom: 20px;
  }

  .newsletter .input-group,
  .newsletter .input-group span {
    text-align: center;
  }

  .newsletter .btn {
    width: 100%;
    display: block;
  }

  .newsletter .input-group-addon {
    width: 100%;
  }

  .newsletter .input-group > .form-control {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 20px;
  }

  #contacts-section .col-lg-3.col-md-6 {
    margin-bottom: 35px;
  }

  #contacts-section .col-lg-3.col-md-6.g-mt-40 {
    margin-bottom: 0;
  }

  .footer-copy .d-none {
    display: block !important;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .home-links .box b {
    width: 130px;
  }
}

@media (max-width: 960px) {
  .home-services .col-md-12 {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .navbar-nav > .hs-mega-menu-opened > a:after {
    content: " ";
    display: inline-block;
    border: 10px solid #fff;
    border-bottom-color: #024f93;
    bottom: -36px;
    height: 0;
    left: 50%;
    margin-left: -11px;
    position: absolute;
    width: 0;
  }

  .hs-mega-menu {
    height: 400px !important;
  }

  .hs-mega-menu:after {
    content: " ";
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    width: 100vw;
    transform: translateX(-50%);
    z-index: -10;
    box-sizing: border-box;
    background: #274065;
  }

  .g-bg-kpas-megamenu-lightblue > .hs-has-sub-menu > ul.list-unstyled {
    height: 390px;
  }
}

.hs-mega-menu,
.hs-sub-menu {
  margin-top: 0 !important;
}

.navbar-nav .nav-item > .nav-link {
  font-size: 20px;
}

#navBar {
  border-bottom: 3px solid #c6a171;
  margin-bottom: -1px;
}

.navbar-nav li {
  color: #fff;
  padding-bottom: 10px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.navbar-nav li.active a {
  color: #c6a171 !important;
}

.navbar-nav li:hover {
  color: #c6a171;
}

.navbar-nav li.active .dropdown-menu a {
  color: #fff !important;
}

.navbar-nav li.active .dropdown-menu a:hover {
  color: #fff !important;
  text-decoration: underline !important;
}

.navbar-nav li ul li:hover {
  border-bottom: 0;
}

.navbar-nav li ul li.active {
  width: auto;
  text-align: left;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.navbar-nav li ul li {
  width: auto;
  border-right: 0;
  border-bottom: 0;
  text-align: left;
}

.navbar-nav li ul li a {
  width: auto;
  border-right: 0;
  text-align: left;
}

.navbar-nav li:last-child {
  border-right: 0;
}

/*.slider-border {
    border: 1px solid #DFE6EA;
    border-radius: 4px;
    margin-top: 25px;
}

.slider-border .col-sm-3 ul li {
    border-bottom: 1px solid #DFE6EA;
    padding: 15px 25px;
    display: block;
}

.slider-border .col-sm-3 a {
    font-size: 16px;
    font-weight: 600;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.slider-border .col-sm-3 a:hover {
    color: #008bd2;
}

.slider-border ul li:last-child {
    border-bottom: 0;
}*/

.carousel-inner img {
  -webkit-border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

h1 {
  font-size: 40px;
  font-weight: 700 !important;
  color: #2b3b73;
  margin-bottom: 35px;
  font-family: "Work Sans";
}

.bread-crumb {
  font-size: 16px;
  color: #2b2e36;
  margin-bottom: 50px;
}

.bread-crumb li {
  background: url("assets/img/arrowbc.svg") no-repeat right center;
  background-size: 11px 7px;
  padding-right: 22px;
  margin-right: 6px !important;
}

.bread-crumb li:last-child {
  background: none;
  padding-right: 22px;
}

article .h5 {
  font-weight: 600;
}

.bread-crumb a {
  color: #2b2e36 !important;
  text-decoration: none !important;
}

.bread-crumb span {
  color: #d7dad1 !important;
  margin-left: 3px;
}

.bread-crumb a:hover {
  text-decoration: underline !important;
}

.rounded-4 {
  border-radius: 4px;
}

.articles h2 {
  font-size: 24px;
}

.date {
  color: #575766;
  display: inline-block;
  padding-bottom: 3px;
  font-size: 14px !important;
}

.slick-next.slick-arrow {
  background: url("assets/img/icons/slider_right.svg") no-repeat;
  width: 46px;
  height: 46px;
  top: -47px;
  right: 211px;
}

.slick-next.slick-arrow.slick-disabled {
  background: url("assets/img/icons/slider_right_off.svg") no-repeat;
  cursor: default;
}

.slick-prev.slick-arrow {
  background: url("assets/img/icons/slider_left.svg") no-repeat;
  width: 46px;
  height: 46px;
  top: -47px;
  right: 266px;
  left: auto;
}

.slick-prev.slick-arrow.slick-disabled {
  background: url("assets/img/icons/slider_left_off.svg") no-repeat;
  cursor: default;
}

.home-notes .slick-next.slick-arrow {
  right: 195px;
}

.home-notes .slick-prev.slick-arrow {
  right: 251px;
}

.home-events .slick-next.slick-arrow {
  right: 215px;
}

.home-events .slick-prev.slick-arrow {
  right: 270px;
}

.articles .col-lg-4 {
  margin-bottom: 60px;
}

.event-page.articles .col-lg-4 {
  margin-bottom: 30px;
}

.articles .col-lg-4 .date {
  padding-top: 214px;
  color: #c6a171;
  font-weight: 700;
  font-size: 20px !important;
  display: block;
}

.event-page.articles .col-lg-4 .date {
  padding-top: 0 !important;
  color: inherit !important;
  font-size: 36px !important;
}

.home-events article.event-page .date {
  font-size: 36px !important;
  line-height: 36px !important;
}

#ui-datepicker-div {
  background: #f2f2f2;
}

.ui-datepicker-calendar th,
.ui-datepicker-calendar td {
  background: #fff;
  padding: 1px 3px;
  border-top: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
}

.ui-datepicker-calendar th {
  font-weight: 600;
  font-size: 16px;
}

.ui-datepicker-calendar td:hover {
  background: #c6a171;
}

.ui-datepicker-prev.ui-corner-all span,
.ui-datepicker-next.ui-corner-all span {
  display: none;
}

.ui-datepicker-prev.ui-corner-all:after {
  content: "<";
  font-weight: 600;
  font-size: 19px;
}

.ui-datepicker-next.ui-corner-all:after {
  content: ">";
  font-weight: 600;
  font-size: 19px;
}

.home-events .slick-next.slick-arrow,
.home-events .slick-prev.slick-arrow,
.home-notes .slick-next.slick-arrow,
.home-notes .slick-prev.slick-arrow {
  top: -62px;
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  border: 0 !important;
}

.home-notes {
  background: #f8f8f8;
  padding: 70px;
}

.home-notes article {
  background: #fff;
  padding: 20px;
  min-height: 200px !important;
}

.home-notes article a {
  font-size: 20px;
  display: block;
  font-weight: 600;
  line-height: 1.4em;
  min-height: 108px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.home-notes article a:hover {
  color: #21347b;
}

.home-events {
  background: #fff;
  padding: 70px 0 30px 0;
}

.home-events article {
  background: #f8f8f8;
  padding: 20px;
  min-height: 200px !important;
}

.home-events article a {
  font-size: 24px;
  display: block;
  font-weight: 600;
  color: inherit;
  line-height: 1.4em;
  min-height: 101px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.event-culture,
.event-culture-sport {
  color: #62c2d0;
}

.event-samosprava,
.event-government {
  color: #73a4f5;
}

.event-udalosti,
.event-events {
  color: #f58273;
}

.home-events article .date,
.home-events article a {
  color: inherit;
}

.home-events span {
  display: block;
}

.home-events article .time {
  color: #5a5a5a;
  padding-top: 15px;
  font-size: 18px;
}

.event-culture article {
  border-top: 3px solid #62c2d0;
}

.event-samosprava article {
  border-top: 3px solid #f58373;
}

.event-udalosti article {
  border-top: 3px solid #88bb96;
}

.home-events article .date {
  font-size: 30px !important;
  line-height: 30px !important;
}

.home-events article .category {
  font-size: 16px;
  padding-bottom: 15px;
  font-weight: 500;
  text-transform: uppercase;
}

.home-events article a {
  font-weight: 700;
}

.slick-next.slick-disabled,
.slick-prev.slick-disabled {
  opacity: 1;
}

.slick-dots li button::before,
.slick-next::before,
.slick-prev::before {
  display: none;
}

header .float-right a {
  text-decoration: none;
  line-height: 1em;
  display: block;
  font-size: 24px;
  font-weight: 700;
  margin-top: 21px;
}

header .float-right a {
  text-decoration: none;
  border-bottom: 0;
}

header .float-right a:hover {
  text-decoration: underline;
  color: #21347b;
}

.file-doc:hover {
  text-decoration: none;
}

.event-list .date {
  vertical-align: middle;
}

.search-term {
  font-size: 18px;
}

.event-type {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3em;
  border-radius: 3px;
  text-transform: uppercase;
  padding: 1px 7px;
  margin-left: 10px;
  vertical-align: top;
  margin-top: 5px;
  display: inline-block;
}

.articles p {
  color: #455262;
  font-size: 16px;
  line-height: 1.8em;
}

.right-section {
  margin-bottom: 30px;
}

.right-section .panel-body {
  padding: 10px 0 10px 10px !important;
}

.right-section ul li .dropdown-toggle::after {
  content: "\f107";
  font: 14px/1 FontAwesome !important;
  margin-top: 0;
  float: right;
  text-rendering: auto;
}

.right-section ul li.show.active .dropdown-toggle::after {
  content: "\f106";
}

.right-section ul li.show .dropdown-toggle::after {
  content: "\f106";
}

.right-section ul li.show ul li .dropdown-toggle::after {
  content: "\f107" !important;
}

.right-section ul li.show ul li.show .dropdown-toggle::after {
  content: "\f106" !important;
}

.right-section ul li ul li .dropdown-toggle::after {
  margin-right: -15px;
}

.right-section ul li {
  margin: 0;
  padding: 0;
}

.right-section ul li.level3 {
  margin: 0;
  padding: 0;
  list-style: square disc !important;
}

.right-section ul li ul li a {
  padding-top: 0 !important;
  padding-bottom: 0.57143rem !important;
}

.side-menu {
  font-size: 16px;
}

.side-menu a:hover {
  text-decoration: underline !important;
}

.side-menu ul {
  padding-left: 20px;
}

.panel-search {
  padding: 0 0 20px 0;
}

.panel-search .btn,
.panel-search select,
.panel-search input {
  margin-right: 20px;
}

.btn {
  text-transform: uppercase;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after,
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.panel-search .btn,
.panel-search .form-control {
  padding: 0.575rem 0.65rem;
}

.panel-search .btn {
  font-weight: 700;
}

.panel-search select.form-control {
  padding: 0.775rem 0.65rem;
}

.panel-search input.form-control {
  padding: 0.675rem 0.65rem 0.505rem;
}

.form-control.col-sm-4 {
  height: auto;
}

.panel-search .btn {
  padding: 0.475rem 0.65rem;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-decoration: none !important;
}

.form-control {
  border-color: #bebebe;
  font-size: 16px;
}

.line-item {
  border-bottom: 1px solid #DFE6EA;
  padding: 15px 0;
  font-size: 18px;
}

.line-item span {
  text-transform: uppercase;
}

.description {
  border-bottom: 1px solid #DFE6EA;
  padding: 0 0 10px 0;
  margin-bottom: 25px;
}

.staticpage ol,
.staticpage ul {
  margin-bottom: 25px;
}

.staticpage ol li,
.staticpage ul li {
  margin-bottom: 13px;
}

.staticpage ul li::marker {
  font-size: 13px;
  color: #c6a171;
}

.hs-has-sub-menu div {
  width: 25%;
  text-align: left;
  color: #fff;
}

.hs-has-sub-menu div strong {
  font-size: 16px;
  display: block;
  margin-bottom: 6px;
  padding-top: 15px;
}

.navbar-expand-lg .navbar-nav ul li .nav-link {
  padding: 5px 25px 5px 0 !important;
  font-size: 15px;
}

.u-header {
  width: 100%;
  position: relative;
  z-index: 1050;
}

.control-label {
  color: #22211f;
  text-transform: uppercase;
}

.align-bottom .col-sm-4,
.align-bottom .col-sm-6 {
  align-items: flex-end;
  align-self: flex-end;
}

table.table th {
  background: #f1f5fd;
  text-transform: uppercase;
  border-top: 0;
  border-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  padding: 9px 20px;
}

table.table td {
  border-top: 0;
  padding: 9px 20px;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 0;
}

table.table a {
  color: #2b3b73;
  text-decoration: none;
}

table.table a:hover {
  color: #2b3b73;
  text-decoration: underline;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #fff;
}

table.table tr:nth-child(even) {
  background: #f4f4f4;
}

.right-section.right-menu .nav {
  display: block;
}

.right-section.right-menu .nav .active {
  font-weight: 600 !important;
}

.ui-datepicker-title select {
  margin-left: 4px;
  margin-right: 4px;
}

body {
  font-size: 18px;
}

.right-section ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.right-section ul li {
  margin: 0 0 6px 0;
  padding: 0;
}

.slider-tabs {
  height: 50px;
  margin-bottom: 30px;
  text-transform: uppercase;
  border-bottom: 4px solid #f8f8f4;
}

.slider-tabs div a {
  padding: 7px 20px;
  font-family: "Work Sans";
  font-size: 1.1em;
  height: 50px;
}

.slider-tabs div a:focus {
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  border-bottom: 4px solid #e7f2fd !important;
}

.slider-tabs div a.active {
  font-family: "Work Sans";
  height: 50px;
  border-bottom: 4px solid #e7f2fd;
}

.staticpage a.box-link,
.box-link {
  font-size: 20px;
  font-weight: 700;
  color: #21347b;
  font-family: "Work Sans";
  display: block;
  padding: 32px 28px 22px 18px;
  background: #f1f5fd;
  height: 100%;
  text-decoration: none !important;
  border-left: 4px solid #f1f5fd;
  line-height: 1.3em;
  width: 100%;
  text-decoration: none !important;
}

.staticpage a.box-link p {
  line-height: 1.1em;
}

.staticpage a.box-link:hover,
.box-link:hover {
  border-left: 4px solid #2b3b73;
  background: #f1f5fd;
}

.staticpage a.box-link span,
.box-link span {
  display: inline-block;
  text-decoration: none !important;
}

.staticpage a.box-link span,
.box-link:hover span {
  text-decoration: none !important;
}

.box-link p {
  font-family: Work Sans;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 6px;
  font-weight: 400;
}

.box-link:hover {
  text-decoration: none;
  background: #f5f5e4;
}

/*.box-link::after {
    content: "\f054";
    font-weight: 400;
    float: right;
    font-size: 14px;
    margin-top: 4px;
    font-family: "Font Awesome 5 Pro";
}
*/

.pagination .disabled svg .arrowpath {
  fill: #f4f4f4 !important;
}

.paginationBox {
  text-align: center;
  font-size: 16px;
  margin: 0 0 25px 0;
  padding-top: 30px;
}

.paginationBox a {
  display: inline-block;
  padding: 0px 15px;
  color: #2b3b73;
  margin: 0 5px;
  font-weight: 600;
  font-size: 20px;
  text-decoration: none !important;
  border-radius: 4px;
}

.paginationBox .arrow.prev.disabled,
.disabled.arrow.prev {
  padding: 0px 15px;
}

.paginationBox .active a {
  background: #f4f4f4;
  border-radius: 3px;
  color: #2b3b73;
}

.paginationBox a:hover {
  color: #2b3b73;
  text-decoration: underline;
}

.paginationBox .active a:hover {
  color: #2b3b73;
  text-decoration: underline;
}

.pagination,
.pagination li {
  display: inline-block;
}

.pagination svg {
  width: 25px;
}

.pagination svg .arrowpath {
  fill: #2b3b73;
}

.pagination .disabled svg .arrowpath {
  fill: gray;
}

.box-object {
  display: block;
  padding: 40px 30px;
  background: #f8f8f4;
  border-radius: 4px;
}

.box-object3 {
  display: block;
  padding: 10px 15px;
  background: #f8f8f4;
  border-radius: 4px;
}

.box-object3 .btn-download {
  display: block;
  padding: 1px 10px;
  display: inline-block;
  background-color: #e7f2fd;
  border-radius: 4px;
  border-color: #e7f2fd;
  color: #22211f;
  width: auto !important;
  text-transform: uppercase;
  font-size: 14px;
}

.box-object3 p {
  margin-bottom: 6px;
  font-size: 14px;
}

.box-object3 p a {
  font-size: 14px;
}

.box-object3 a {
  font-size: 18px;
}

.navbar-expand-lg .navbar-collapse {
  position: relative;
  z-index: 500;
}

.hs-mega-menu::after {
  left: calc(50% - 9px);
}

.w-100.hs-mega-menu.u-shadow-v2.g-text-transform-none.hs-position-right {
  top: 56px;
}

.navbar-nav li:first-child div div div div div:last-child {
  width: 50%;
}

.navbar-nav li:first-child div div div div div:last-child li {
  width: 50%;
  float: left;
}

.related-content table.table td {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

/* .related-content h4 {
     margin-bottom: 0 !important;
 }*/

.related-content table {
  margin-bottom: 10px !important;
}

.divimage {
  -webkit-box-shadow: inset 0px -119px 21px -27px rgba(0, 0, 0, 0.56);
  -moz-box-shadow: inset 0px -119px 21px -27px rgba(0, 0, 0, 0.56);
  box-shadow: inset 0px -119px 21px -27px rgba(0, 0, 0, 0.56);
}

.g-pt-200 {
  padding-top: 260px !important;
}

.carousel-item h2 {
  line-height: 1.2em !important;
  font-weight: 600 !important;
  padding-left: 20px !important;
}

.news-image {
  background-repeat: no-repeat;
  background-size: cover;
  width: 325px;
  height: 230px;
  background-position: center;
}

.home-news .col-sm-12 {
  padding-top: 30px;
}

.staticpage img {
  max-width: 100%;
}

blockquote.hero {
  border-left: 2px solid #2b3b73;
  color: #2b3b73;
  font-style: normal;
  padding: 0 0 0 15px;
  margin: 0 0 0 30px;
}

.carousel-indicators {
  left: auto;
  margin-right: 25px;
  margin-left: 0;
}

.slide-mobile {
  padding-bottom: 50px;
}

.slide-mobile a {
  display: block;
  padding: 10px 0 0 0;
  font-size: 18px;
  text-decoration: underline;
}

.slide-mobile .carousel-indicators {
  left: 0;
  margin-right: 0;
  margin-left: 0;
  bottom: 0;
}

.slide-mobile .carousel-indicators li {
  background-color: #ccd7de;
  height: 8px;
}

.slide-mobile .carousel-indicators .active {
  background-color: #274065;
}

.slick-list .date {
  padding-top: 214px;
  color: #c6a171;
  font-weight: 700;
  font-size: 20px !important;
  display: block;
}

.home-events .slick-list .date,
.home-notes .slick-list .date {
  padding-top: 0px;
}

.sitemap ul {
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
}

.sitemap ul li {
  background-color: #f3e8d5;
  border-bottom: 1px solid #fff;
}

.sitemap ul li:last-child {
  border-bottom: 0;
}

.sitemap ul li a {
  display: inline-block;
  font-weight: 600;
  padding: 6px 20px 6px 20px;
}

.sitemap ul li ul li a {
  font-weight: 300;
}

.sitemap ul li ul li ul li a {
  font-weight: 300;
}

.sitemap ul li ul li a {
  padding: 6px 20px 6px 40px;
}

.sitemap ul li ul li ul li a {
  padding: 6px 20px 6px 60px;
}

.sitemap ul li ul li ul li ul li a {
  padding: 6px 20px 6px 80px;
}

.sitemap ul li ul li ul li ul li ul li a {
  padding: 6px 20px 6px 100px;
}

.sitemap ul ul li {
  background-color: #f4f4f4;
}

.sitemap ul ul ul li {
  background-color: #fff;
}

.sitemap ul ul ul ul li {
  background-color: #fff;
}

.sitemap ul ul ul ul ul li {
  background-color: #fff;
}

.file-doc i {
  color: #565656;
}

.file-doc i.fa-file-pdf {
  color: #e30613;
}

.file-doc i.fa-file-rtf,
.file-doc i.fa-file-doc,
.file-doc i.fa-file-docx {
  color: #2b2e80;
}

.file-doc i.fa-file-xls,
.file-doc i.fa-file-xlsx {
  color: #2b802d;
}

.fa-file-docx::before,
.fa-file-doc::before {
  content: "\f1c2";
}

.fa-file-xlsx::before,
.fa-file-xls::before {
  content: "\f1c3";
}

.fa-file-pptx::before,
.fa-file-ppt::before {
  content: "\f1c3";
}

.advanced.btn {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

@media (max-width: 401px) {
  .home-links .box {
    padding: 21px 15px;
    height: 90px;
  }

  .image-slider {
    background-color: #f3f3f3 !important;
    text-align: center;
  }

  .panel-search .btn,
  .panel-search .form-control {
    padding: 0.475rem 0.95rem;
  }

  .form-group {
    margin-bottom: 0.4rem;
  }

  .home-links .g-mb-30 {
    margin-bottom: 1.44286rem !important;
  }

  .home-links .box strong {
    margin-top: 0.8rem !important;
    display: inline-block;
  }

  .article-detail h3 {
    min-height: inherit;
  }

  .panel-search .form-control {
    margin-bottom: 8px;
    margin-right: 0;
  }

  .panel-search .btn.btn-primary {
    margin-bottom: 8px;
    margin-right: 0;
  }

  .panel-search .btn.btn-light {
    width: 70%;
    display: inline-block;
    margin-bottom: 8px;
    margin-right: 20px;
    padding: 0.375rem 0.95rem;
  }

  .panel-search .advanced.btn {
    width: 23%;
    display: inline-block;
    margin-bottom: 8px;
    margin-right: 0;
    padding: 0.375rem 0.95rem;
  }

  .panel-search .advancedSearchBox .form-control {
    width: 100%;
    display: block;
    margin-bottom: 8px;
  }
}

.show-mobile,
.show-mobile .right-menu {
  display: none;
}

@media (max-width: 767px) {
  .show-mobile,
  .show-mobile .right-menu {
    display: block !important;
  }

  .bread-crumb {
    margin-bottom: 10px;
  }

  .wrapper.g-pt-45 {
    padding-top: 2.21429rem !important;
  }

  h1 {
    margin-bottom: 15px;
  }

  .panel-search {
    padding: 0 0 0;
  }

  .filter-label {
    padding: 0 !important;
  }

  .article-detail h3 {
    line-height: 1.1em !important;
    min-height: 1px !important;
    overflow: unset !important;
    text-overflow: unset !important;
    display: block !important;
    -webkit-line-clamp: unset !important;
    -webkit-box-orient: unset !important;
    margin-bottom: 10px !important;
  }

  .table-responsive {
    z-index: 1;
    position: relative;
    padding-top: 30px;
  }

  .table-responsive::after {
    display: block;
    width: 24px;
    height: 24px;
    content: " ";
    background: url("/Content/assets/img/icons/swipe.png") 0 0 no-repeat;
    position: absolute;
    top: 1px;
    right: 5px;
    z-index: 300;
  }

  .navbar-brand {
    margin-left: 15px !important;
  }

  /*.bread-crumb,*/

  .right-menu {
    display: none !important;
  }

  .bg-menu .container {
    padding-right: 0;
    padding-left: 0;
  }

  .bg-menu {
    border-bottom: 0;
    height: 0;
  }

  .navbar-nav li {
    width: 100%;
    text-align: left !important;
    padding: 0 15px !important;
    border-bottom: 1px solid #3b577f;
    border-right: 0 !important;
  }

  .hs-mega-menu,
  .hs-sub-menu {
    background: rgb(51, 77, 115) !important;
  }

  .hs-has-sub-menu div {
    width: 100%;
    display: block;
    float: none;
    background: rgb(51, 77, 115) !important;
  }

  .hs-mobile-state .hs-mega-menu,
  .hs-mobile-state .hs-sub-menu {
    position: relative !important;
    visibility: visible;
    top: 0 !important;
    background: rgb(51, 77, 115) !important;
    left: 0 !important;
  }

  .hs-has-sub-menu.d-flex {
    display: block !important;
  }

  .navbar-nav li:first-child div div div div div:last-child {
    width: 100%;
  }

  .navbar-nav li:first-child div div div div div:last-child li {
    width: 100%;
    float: none;
  }

  .g-bg-size-cover {
    display: none !important;
  }

  .hs-mega-menu .col-lg-3.g-mb-30 {
    margin-bottom: 0 !important;
  }

  #contacts-section {
    padding-top: 25px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.masonry-grid-item img.u-block-hover__main--mover-down {
  min-height: 220px;
}

.header-search {
  width: 705px;
}

.share-icons {
  border-top: 1px solid #f1e7db;
  border-bottom: 1px solid #f1e7db;
  padding: 25px 0 25px 0;
  margin-top: 20px;
}

.share-icons b {
  vertical-align: top;
  padding-right: 10px;
  margin-top: 5px;
  display: inline-block;
  color: #000;
}

.flag-icon-en {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.2.1/flags/4x3/us.svg") !important;
}

.share-icons a {
  display: inline-block;
}

.side-menu li.active > a {
  font-weight: 600 !important;
}

@media (max-width: 1100px) {
  .header-search {
    width: 270px;
  }
}

@media (max-width: 991px) {
  #navBar {
    background: #2c407d;
    margin-top: 1px;
  }

  .navbar-expand-lg .navbar-toggler {
    display: block;
  }

  .language-header {
    top: 40px;
    right: 93px;
  }

  .u-header {
    border-bottom: 1px solid #555b7e;
  }

  /*.bread-crumb {
      display: none !important;
  }*/

  .bg-menu {
    border-bottom: 0;
    background: #2c407d;
    height: auto !important;
  }

  .bg-menu .container {
    padding-right: 0;
    padding-left: 0;
  }

  .bg-menu {
    border-bottom: 0;
    height: 0;
  }

  .navbar-nav li {
    width: 100%;
    text-align: left !important;
    padding: 0 15px !important;
    border-bottom: 1px solid #3b577f;
    border-right: 0 !important;
  }

  .hs-mega-menu,
  .hs-sub-menu {
    background: rgb(51, 77, 115) !important;
  }

  .hs-has-sub-menu div {
    width: 100%;
    display: block;
    float: none;
    background: rgb(51, 77, 115) !important;
  }

  .hs-mobile-state .hs-mega-menu,
  .hs-mobile-state .hs-sub-menu {
    position: relative !important;
    visibility: visible;
    top: 0 !important;
    background: rgb(51, 77, 115) !important;
    left: 0 !important;
  }

  .hs-has-sub-menu.d-flex {
    display: block !important;
  }

  .navbar-nav li:first-child div div div div div:last-child {
    width: 100%;
  }

  .navbar-nav li:first-child div div div div div:last-child li {
    width: 100%;
    float: none;
  }

  .g-bg-size-cover {
    display: none !important;
  }

  .hs-mega-menu .col-lg-3.g-mb-30 {
    margin-bottom: 0 !important;
  }

  .hs-mobile-state .hs-mega-menu,
  .hs-mobile-state .hs-sub-menu {
    position: relative !important;
    visibility: visible;
    top: 0 !important;
    background: #334d73 !important;
    left: 0 !important;
  }

  .navbar-nav li:hover {
    border-bottom: 1px solid #3b577f;
  }

  .dropdown-menu {
    min-width: auto;
  }

  .articles h2 {
    font-size: 17px;
  }

  .masonry-grid-item img.u-block-hover__main--mover-down {
    min-height: 184px;
  }
}

.navbar-collapse.collapse.show .form-control {
  height: 50px !important;
}

@media (max-width: 574px) {
  .language {
    position: absolute !important;
    margin: 0 !important;
    top: 15px !important;
    right: 57px !important;
  }

  .print-text span {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hs-has-sub-menu div strong {
    padding-left: 15px !important;
  }

  .navbar-nav li.active {
    color: #c6a171;
  }

  .navbar-nav li,
  .navbar-nav li.active {
    width: 100%;
  }

  .navbar-collapse.collapse.show .form-control {
    height: 39px !important;
  }
}

@media (max-width: 991px) {
  .navbar-nav > li > a {
    padding-left: 0 !important;
    padding-right: 15px !important;
  }

  .dropdown-menu {
    padding: 15px 15px 0;
    border-radius: 0;
  }

  .navbar-nav li a {
    font-size: 1.2em !important;
  }

  .u-header {
    border: 0 !important;
  }

  .dropdown-item,
  .dropdown-item:hover {
    margin-bottom: 13px !important;
  }

  .navbar-nav li li a {
    font-size: 1.1em !important;
  }

  .navbar-nav ul {
    min-width: 100% !important;
  }

  .hs-has-sub-menu .hs-has-sub-menu.hs-sub-menu-opened > a::after {
    content: "\e900";
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: inherit;
    background: 0 0 !important;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    background: inherit;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .navbar-toggler.g-top-10 {
    top: 24px !important;
  }

  .min-height-article {
    min-height: 280px;
  }

  .article-detail h3 {
    line-height: 1.1em !important;
    min-height: 87px !important;
  }

  .sub-news .article-detail h3 {
    line-height: 0.9em !important;
    min-height: 90px !important;
    -webkit-line-clamp: 4;
  }
}

@media (min-width: 992px) {
  .navbar-nav li ul li a {
    background: #274065;
  }
}

@media (max-width: 400px) {
  .box-object img {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 36px;
  }

  .sub-news .article-detail h3 {
    line-height: 0.9em !important;
    min-height: 1px !important;
    -webkit-line-clamp: unset;
  }

  .sub-news .article-detail {
    padding: 20px 20px;
  }

  .sub-news.home-news .col-sm-12 {
    padding-top: 0 !important;
  }

  .sub-news.home-news .container {
    padding-right: 0;
    padding-left: 0;
  }
}

.sub-news.home-news .col-sm-12 {
  padding-top: 0 !important;
}

.collapse.navbar-collapse label {
  display: none;
}

.collapse.navbar-collapse label {
  display: none;
}

.hamburger-box {
  width: 30px;
  height: 25px;
}

.list-block label {
  display: block !important;
  font-size: 18px !important;
}

.list-block label input {
  margin-right: 10px;
}

.custom-control {
  padding-left: 2rem;
}

.custom-control-label {
  font-size: 17px !important;
}

.custom-control-label::before {
  border: 1px solid #b9b9b9;
  background-color: #fff;
}

.custom-control-label::after,
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -2rem;
  display: block;
  width: 1.4rem;
  height: 1.4rem;
  content: "";
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2b3b73;
  border: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  margin-left: 4px;
}

.ui-datepicker-prev,
.ui-datepicker-next {
  font-size: 14px;
}

@media (min-width: 576px) and (max-width: 768px) {
  .navbar-toggler.g-top-10 {
    top: 21px !important;
  }

  .navbar-brand {
    margin: 15px 0 0 8px;
  }

  .logo-mobile.g-width-150 {
    width: 190px !important;
  }

  .home-links {
    padding: 40px 0 20px;
  }

  .language-header {
    top: 35px;
  }
}

.event-mobile {
  display: none;
}

@media (min-width: 575px) and (max-width: 991px) {
  .language {
    top: 26px !important;
    right: 57px !important;
    position: absolute !important;
  }

  .hasDatepicker {
    padding: 4px 20px;
  }

  #contacts-section.g-py-30--md {
    padding-bottom: 1.14286rem !important;
  }

  .newsletter.g-py-0 {
    padding-bottom: 1.54286rem !important;
  }

  .home-quicklinks .col-xs-6 {
    padding: 0 0 25px 0;
  }

  .slider-border .col-sm-3 ul li {
    padding: 15px 20px;
  }

  .carousel-inner .g-pt-200 {
    padding-top: 200px !important;
  }
}

@media (max-width: 575px) {
  .event-mobile {
    display: block !important;
  }

  .search-top {
    display: none !important;
  }

  .header-search {
    visibility: visible !important;
  }

  .header-search {
    width: 69% !important;
    margin-left: 0 !important;
    padding-left: 20px !important;
    padding-right: 30px !important;
  }

  .navbar-brand {
    display: flex !important;
  }

  .slick-list .container {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
  }

  .btn-more,
  .home-info a,
  .home-notes article a,
  .home-events article a {
    font-size: 24px !important;
  }

  .home-events article .date {
    font-size: 29px !important;
    line-height: 29px !important;
  }

  .home-events article .time {
    padding-top: 11px;
    font-size: 20px;
  }

  .slide-mobile a {
    min-height: 91px !important;
  }

  .releted-contacts {
    text-align: center !important;
  }

  .releted-contacts .box-object {
    padding: 20px 10px;
  }

  .releted-contacts .box-object h3 {
    margin-top: 10px;
  }

  .navbar-toggler.g-top-10 {
    top: 31px !important;
  }

  .navbar-brand {
    margin: 15px 0 0 8px;
  }

  .logo-mobile.g-width-150 {
    width: 190px !important;
  }

  .language-header {
    top: 35px;
  }
}

@media print {
  header,
  .content .col-md-4,
  .newsletter,
  #contacts-section,
  #disqus_thread {
    display: none !important;
  }

  .content .col-md-8 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .paginationBox a {
    border: 0;
    border-radius: 0;
  }
}

/*fix menucka*/

@media (min-width: 992px) {
  .js-mega-menu.navbar.navbar-expand-lg {
    color: white;
  }

  .js-mega-menu.navbar.navbar-expand-lg .dropdown-item:focus,
  .js-mega-menu.navbar.navbar-expand-lg .dropdown-item:hover {
    background: transparent;
  }

  .js-mega-menu.navbar.navbar-expand-lg .g-bg-kpas-megamenu-lightblue {
    flex: none;
    max-width: unset;
  }

  .js-mega-menu.navbar.navbar-expand-lg .hs-has-sub-menu .hs-has-sub-menu > a::after {
    content: none;
  }

  .js-mega-menu.navbar.navbar-expand-lg ul[data-level="0"] {
    display: flex;
    pointer-events: none;
  }

  .js-mega-menu.navbar.navbar-expand-lg ul[data-level="0"] > li {
    flex: 1;
  }

  .js-mega-menu.navbar.navbar-expand-lg ul[data-level="0"] > li > a.nav-link {
    font-size: 16px !important;
    display: block;
    margin-bottom: 3px;
    padding-top: 15px !important;
    font-weight: bolder;
  }

  .js-mega-menu.navbar.navbar-expand-lg ul[data-level="0"] > li > a.nav-link:active {
    color: red;
  }

  .js-mega-menu.navbar.navbar-expand-lg li.hs-mega-menu-opened[data-level="0"] ul[data-level="1"] {
    pointer-events: all;
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative !important;
    left: auto !important;
    right: auto !important;
    visibility: visible;
    max-height: 350px;
  }

  .js-mega-menu.navbar.navbar-expand-lg li.hs-mega-menu-opened[data-level="0"] ul[data-level="1"] > li {
    min-width: 50%;
  }

  .js-mega-menu.navbar.navbar-expand-lg ul[data-level="2"],
  .js-mega-menu.navbar.navbar-expand-lg ul[data-level="3"],
  .js-mega-menu.navbar.navbar-expand-lg ul[data-level="4"] {
    display: none !important;
  }
}

.footer-copy a {
  text-decoration: underline !important;
}

.language span {
  text-transform: uppercase;
  font-size: 0.8em !important;
  color: #274065;
}

.language .dropdown-toggle::after {
  color: #274065;
  margin-left: 0.2rem;
}

.staticpage table {
  width: 99%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.staticpage table td {
  border-top: 0;
  padding: 10px 20px;
  font-size: 16px;
  border-bottom: 1px solid #dfe6ea;
}

.staticpage table th {
  background: #e7f2fd;
  color: #242620;
  text-transform: uppercase;
  border-top: 0;
  border-bottom: 0;
  font-size: 14px;
  padding: 10px 20px;
}

.staticpage table tr:nth-child(2n) {
  background: #f8f8f4;
}

.article-detail h3 {
  line-height: 1.4em;
  font-size: 24px;
  min-height: 73px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.article-detail h3 a:hover {
  color: #fff !important;
  text-decoration: underline !important;
}

.slick-dots li button::before {
  font-size: 14px !important;
  color: #e2cfa6;
  opacity: 0.45;
}

.slick-dots li.slick-active button::before {
  opacity: 1;
  color: #e2cfa6;
}

.home-events .article-detail h3 {
  min-height: 68px;
  -webkit-line-clamp: 2;
  line-height: 1.1em !important;
}

.pagination li {
  margin-bottom: 10px;
}

@media (max-width: 700px) {
  .contact-item .col-xs-3 .g-width-100 {
    height: 50px !important;
    width: 50px !important;
  }

  .contact-item .col-xs-9 {
    width: 76% !important;
  }
}

.staticpage img {
  height: auto !important;
  max-width: 100% !important;
}

.hs-has-sub-menu a:hover,
.right-menu .dropdown-menu a:hover {
  text-decoration: underline !important;
}

.event-type {
  background: #f8f8f8 !important;
  color: #a6a7a9 !important;
}

@media print {
  #cconsent-bar,
  .share-icons,
  footer {
    display: none !important;
  }

  .header-print {
    display: block !important;
    border-bottom: 1px solid #000 !important;
    margin-bottom: 15px !important;
    padding-bottom: 15px !important;
    border-bottom: 2px solid #ddd !important;
  }

  .header-print p {
    margin-bottom: 0 !important;
  }

  footer .col-md-12,
  footer .col-md-12 a {
    color: #000 !important;
  }

  .header-print h1 {
    font-size: 22px !important;
    padding-top: 10px !important;
  }
}

@media (max-width: 700px) {
  .pagination li.arrow a,
  .pagination li.active a {
    display: inline-block !important;
  }
}

.btn-primary:focus {
  color: #22211f;
}

.articles h2 a.u-link-v5 {
  text-decoration: none !important;
}

.articles h2 a.u-link-v5:hover {
  text-decoration: underline !important;
}

.staticpage a {
  text-decoration: underline;
}

.right-section h3 {
  padding-bottom: 3px;
  font-size: 24px !important;
  color: #2b3b73;
  margin-bottom: 15px !important;
}

.right-section h3.subtitle {
  padding-bottom: 3px;
  margin-top: 68px;
  font-size: 24px !important;
  color: #c6a171;
  font-weight: 700;
  margin-bottom: 35px !important;
}

.right-section h3 .float-right {
  font-size: 16px !important;
  margin-top: 5px !important;
}

.right-section h3 i {
  font-size: 14px !important;
  color: #2b3b73;
  padding-left: 7px;
  vertical-align: baseline;
}

.right-section .fa.fa-angle-right {
  font-size: 14px !important;
}

.staticpage a[href^="http://"]:before,
.staticpage a[href^="https://"]:before {
  font-weight: 400;
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-variant: normal;
  content: "\f15b";
  margin-right: 8px;
  margin-left: 8px;
  font-size: 15px;
  color: #008bd2;
  content: "\f35d";
}

.staticpage a[href^="www.trnava.sk"]:before,
.staticpage a[href^="http://www.trnava.sk"]:before,
.staticpage a[href^="https://www.trnava.sk"]:before,
.staticpage a[href^="http: //www.trnava.sk"]:before,
.staticpage a[href^="https: //www.trnava.sk"]:before,
.staticpage a.box-link[href^="http://"]:before,
.staticpage a.box-link[href^="https://"]:before {
  display: none;
}

.staticpage a.box-link[href^="http://"]:after,
.staticpage a.box-link[href^="https://"]:after {
  background: url("assets/img/external.svg") no-repeat top right !important;
  background-size: 23px auto !important;
  display: block !important;
  position: absolute !important;
  top: 7px;
  right: 14px;
  width: 23px;
  height: 23px;
  content: "";
}

#map {
  z-index: 1;
}

.lifes a[href$=".pdf"]:before,
.lifes a[href$=".doc"]:before,
.lifes a[href$=".docx"]:before {
  font-weight: 400;
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-variant: normal;
}

.file:before {
  font-weight: 400;
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-variant: normal;
  content: "\f15b";
  margin-right: 8px;
  margin-left: 8px;
  color: #f4ae36;
  display: inline-block;
}

.file.file-pdf:before,
.lifes a[href$=".pdf"]:before {
  content: "\f1c1";
}

.file.file-word:before,
.lifes a[href$=".doc"]:before,
.lifes a[href$=".docx"]:before {
  content: "\f1c2";
}

.file.file-powerpoint:before {
  content: "\f1c4";
}

.file.file-excel:before {
  content: "\f1c3";
}

.file.file-event:before {
  content: "\f133";
}

.file.file-link:before {
  content: "\f0c1";
}

.file.file-gallery:before {
  content: "\f1c5";
}

.file.file-video:before {
  content: "\f1c8";
}

.file.file-file:before {
  content: "\f0c5";
}

.file.file-life:before {
  content: "\f183";
}

.file.file-ordinance:before {
  content: "\f15c";
}

.file.file-articles:before {
  content: "\f1ea";
}

@media (max-width: 767px) {
  #contacts-section h3 {
    margin-bottom: 0.8rem !important;
  }

  #contacts-section .col-lg-3 {
    margin-bottom: 0 !important;
    margin-top: 1.2rem !important;
  }

  #contacts-section.g-py-30--md {
    padding-bottom: 0 !important;
  }
}

table a,
.content-column a,
.home-services a,
.footer-menu a {
  text-decoration: none;
}

table a:hover,
.content-column a:hover,
.home-services a:hover,
.footer-menu a:hover {
  text-decoration: underline;
}

.footer-menu a:hover {
  text-decoration: underline;
}

.footer-menu {
  line-height: 21px;
}

.footer-menu a {
  font-size: 15px;
}

.form-control::placeholder {
  color: #a9a8a8;
  opacity: 1 !important;
}

a:focus,
button:focus,
img:focus {
  border: 0 !important;
  box-shadow: 0 0 0 0.1rem #e7f2fd !important;
}

#navBar a:focus {
  border: 0 !important;
  box-shadow: none !important;
  color: #c6a171 !important;
}

.u-header {
  border-bottom: 1px solid #4e5678 !important;
}

.dropdown-menu {
  border: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.dropdown-item,
.dropdown-item:hover {
  padding: 0.25rem 35px;
  font-size: 20px;
  margin-bottom: 20px;
  background: url("assets/img/icons/arrow_right.svg") no-repeat 1px 13px !important;
  background-size: 23px 13px !important;
}

.dropdown-item:hover {
  color: #fff !important;
  text-decoration: underline !important;
}

.form-control:focus,
#search-input:focus,
#searchform-6 .btn.btn-link:focus {
  border-color: #000 !important;
}

.navbar-nav .nav-link:focus {
  border: 0 !important;
}

.filter-label {
  color: #000;
  padding: 8px 15px 0 0;
  text-transform: uppercase;
  display: block;
  width: 100%;
}

.header-label {
  padding: 0;
  margin-bottom: 0;
}

.btn {
  font-size: 1.4rem;
}

#search-input {
  color: #252525;
}

.collapse.navbar-collapse label {
  color: #fff;
  font-weight: 600;
  padding: 14px 15px 0 0;
  text-transform: uppercase;
  display: block;
}

.footer-breadcrumb {
  background: #e7f2fd;
}

.footer-breadcrumb .container .col-md-12 {
  border-bottom: 1px solid #c3def9;
}

.form-control {
  color: #535354;
}

@media (min-width: 992px) {
  .navbar-nav li:focus {
    color: #fff !important;
  }
}

.skip-link {
  display: none;
}

@media (min-width: 1201px) {
  #searchform-6 .input-group {
    width: 503px;
  }

  .home-motive #searchform-6 .input-group {
    width: 700px;
  }

  .navbar-brand {
    margin: 17px 0 17px 8px !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .event-page.articles .col-lg-4 .date {
    font-size: 34px !important;
  }

  .navbar-brand {
    margin: 17px 0 20px 8px !important;
  }

  .home-links .box {
    display: table;
    height: 100%;
    width: 100%;
  }

  .navbar-nav .g-px-31 {
    padding-left: 1.35rem !important;
    padding-right: 1.35rem !important;
  }

  .faddress,
  .fmail,
  .fphone,
  .fpl {
    font-size: 16px;
  }

  .layout-home .bgheader {
    background-size: auto 100%;
  }

  .layout-home .bgheader .u-header {
    background-size: auto 418%;
  }

  #searchform-6 .input-group {
    width: 525px;
  }

  .header-search {
    width: 525px;
  }

  .navbar-nav .nav-item > .nav-link {
    font-size: 19px;
  }

  .home-links .col-md-2 a img {
    width: 100%;
  }

  .news-image {
    width: 262px;
  }

  .social a {
    margin-right: 0px;
  }

  .home-info h2 {
    font-size: 56px;
  }

  .home-info .col-sm-12 {
    background-size: 100% auto;
  }

  .home-info a {
    font-size: 19px;
  }

  .home-info-indend {
    padding: 120px 0 30px 40px;
  }

  #contacts-section .col-lg-4:first-child img {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .social a {
    margin-right: 0px;
  }

  .search-top {
    right: 80px;
    top: 22px;
  }

  .header-search .input-group > .form-control {
    display: block;
    width: auto;
  }

  .header-search {
    width: 286px !important;
  }

  .layout-home .bgheader {
    background-size: auto 100%;
  }

  .layout-home .bgheader .u-header {
    background-size: auto 656%;
  }

  .home-motive .text {
    font-size: 76px;
    margin: 25px 0 0;
  }

  .welcome p {
    font-size: 19px;
    width: 530px;
  }

  .welcome .btn {
    font-size: 1.5rem;
  }

  #searchform-6 .input-group {
    width: 380px;
  }

  .logo-mobile {
    display: block !important;
  }

  .navbar-brand {
    width: 191px;
  }

  .logo-desktop {
    display: none !important;
  }

  .header-search.g-ml-80 {
    margin-left: 40px;
  }

  .home-links .col-md-2 a img {
    width: 100%;
  }

  .news-image {
    width: 94%;
    /*height: 350px;*/
  }

  .slick-list .date {
    padding-top: 220px;
    font-size: 18px !important;
  }

  .min-height-article.article-detail .g-font-size-24 {
    font-size: 1.21429rem !important;
  }

  .home-info h2 {
    font-size: 45px;
  }

  .home-info .col-sm-12 {
    background-size: 100% auto;
  }

  .home-info a {
    font-size: 16px;
  }

  .home-info-indend {
    padding: 50px 0 30px 40px;
  }

  .article-detail {
    padding: 0 15px 15px 15px;
  }

  .home-links .box {
    display: table;
    height: 100%;
    width: 100%;
  }

  .home-links .box {
    padding: 20px 15px 20px 45px;
    font-size: 15px;
    background-size: auto 86%;
  }

  .home-motive .col-sm-6 p a {
    font-size: 16px;
  }

  header .float-right a i {
    line-height: 13px;
  }

  .home-alerts .col-sm-6 {
    flex: 0 0 47.7%;
  }

  .home-alerts .col-sm-6 a {
    font-size: 16px;
  }

  .home-notes article {
    padding: 15px;
  }

  .home-notes article a {
    font-size: 17px;
  }

  .slick-list .date {
    font-size: 15px !important;
  }

  .home-events article .date {
    font-size: 41px !important;
    line-height: 41px !important;
  }

  .home-events article .category {
    font-size: 27px;
  }

  .home-events article a {
    font-size: 19px;
    -webkit-line-clamp: 3;
  }

  .home-events article .time {
    padding-top: 11px;
    font-size: 18px;
  }

  #contacts-section .col-lg-4:first-child img {
    width: 100% !important;
  }

  #contacts-section .col-lg-2 a img {
    width: 40px;
  }

  #contacts-section .col-lg-2 a {
    margin-top: 8px;
  }

  .faddress,
  .fmail,
  .fphone,
  .fpl {
    font-size: 15px;
  }

  .footer-social img {
    width: 40px;
    margin-bottom: 4px;
  }

  .footer-menu a {
    font-size: 14px;
  }

  .faddress::before,
  .fmail::before,
  .fphone::before {
    font-size: 15px;
  }

  .home-events h2,
  .home-news h2,
  .home-notes h2 {
    font-size: 34px !important;
  }

  header .float-right a {
    font-size: 21px;
    margin-top: 11px;
  }

  .home-events,
  .home-notes {
    padding: 40px;
  }

  .home-news {
    padding: 40px 0 20px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .news-image {
    height: 230px;
  }

  .panel-search .btn {
    font-size: 14px;
  }
}

#menu-btn {
  z-index: 1050;
}

.navbar-collapse.collapse.show {
  overflow-y: auto;
  max-height: calc(100vh - 106px);
  z-index: 999;
}

@media (min-width: 992px) {
  .navbar-collapse.collapse.show {
    position: absolute;
    right: 0;
    top: 88px;
    width: 100%;
  }

  .navbar-nav li,
  .navbar-nav li.active {
    width: auto;
    float: left;
  }

  .navbar-collapse h2 {
    font-size: 17px;
    font-family: "Work Sans";
    text-transform: uppercase;
    padding-left: 10px;
  }

  .navbar-collapse ul li {
    padding-bottom: 4px;
    padding-left: 10px;
  }

  .navbar-collapse .bg-medium {
    border-top: 1px solid #eaebe6;
  }

  .navbar-collapse .bg-medium p {
    margin-bottom: 0;
  }

  .navbar-collapse .first-level {
    font-size: 18px;
  }

  .navbar-collapse .first-level a {
    display: block;
    padding-left: 53px;
    padding-top: 11px;
    padding-bottom: 16px;
  }

  .navbar-collapse .first-level a.samosprava {
    background: url("assets/img/samosprava.png") no-repeat 3px 0;
  }

  .navbar-collapse .first-level a.transparentnost {
    background: url("assets/img/transparentnost.png") no-repeat 9px 0;
  }

  .navbar-collapse .first-level a.volby {
    background: url("assets/img/volby.png") no-repeat 18px 0;
  }

  .navbar-collapse .first-level a.aktualne {
    background: url("assets/img/aktualne.png") no-repeat 12px 0;
  }

  .navbar-collapse .first-level a.ako {
    background: url("assets/img/ako.png") no-repeat 12px 0;
  }

  .navbar-collapse .first-level a.omeste {
    background: url("assets/img/omeste.png") no-repeat 3px 0;
  }

  .navbar-collapse .first-level a.adresare {
    background: url("assets/img/adresare.png") no-repeat 18px 0;
  }

  .navbar-collapse .first-level a.trnava {
    background: url("assets/img/trnava.png") no-repeat 16px 0;
  }
}

@media (max-width: 767px) {
  .social a {
    margin-right: 0px;
  }

  .home-links .g-mb-20 {
    margin-bottom: 25px !important;
  }

  .home-links .col-md-2 a img {
    margin-bottom: 10px;
  }

  .search-top {
    right: 80px;
    top: 32px;
  }

  .footer-social {
    text-align: left;
  }

  .navbar-brand,
  .navbar-brand img {
    width: auto;
  }

  .logo-mobileSmall {
    display: block !important;
    height: 46px !important;
    width: auto;
    margin-left: 30px !important;
  }

  .navbar-brand {
    margin: 13px 0 0 8px;
  }

  .logo-mobile {
    display: none !important;
  }

  .home-info .col-sm-6 {
    padding-left: 15px;
  }

  .bgheader {
    background: url("assets/img/bg/bgsub.png") no-repeat right top #334583;
  }

  .layout-home .bgheader {
    background: url("assets/img/bg/bgheader.jpg") no-repeat right top #334583;
    background-size: auto 100%;
  }

  .home-motive .text {
    font-size: 46px;
    margin: 0 0 0;
    line-height: 55px;
  }

  .slick-slide {
    float: none;
    margin-bottom: 30px;
  }

  .news-image {
    width: 94%;
    /*height: 350px;*/
  }

  .article-detail .news-image {
    top: -30px;
  }

  .welcome p {
    font-size: 16px;
    width: 100%;
    margin: 0 auto 25px;
  }

  .welcome .btn {
    font-size: 1.5rem;
  }

  #searchform-6 .input-group {
    width: 100%;
  }

  .home-links .col-md-2 {
    width: 50%;
  }

  .home-motive .header-label {
    font-size: 26px;
  }

  .slick-list .date {
    font-size: 18px !important;
  }

  .min-height-article.article-detail .g-font-size-24 {
    font-size: 1.21429rem !important;
  }

  .home-info h2 {
    font-size: 47px;
  }

  .home-info .col-sm-12 {
    background-size: 100% auto;
  }

  .home-info-indend {
    padding: 40px 0 0 0;
  }

  .article-detail {
    padding: 0 15px 15px 27px;
  }

  .home-links .box {
    display: table;
    height: 100%;
    width: 100%;
  }

  .home-links .box {
    padding: 20px 15px 20px 45px;
    font-size: 15px;
    background-size: auto 86%;
  }

  .home-motive .col-sm-6 p a {
    font-size: 16px;
  }

  header .float-right a i {
    line-height: 13px;
  }

  .home-alerts .col-sm-6 {
    flex: 0 0 47.7%;
  }

  .home-alerts .col-sm-6 a {
    font-size: 16px;
  }

  .home-notes article {
    padding: 15px;
  }

  .home-notes article a {
    font-size: 34px;
  }

  .home-info a {
    font-size: 40px;
  }

  .home-info .col-sm-3 {
    display: none;
  }

  .slick-list .date {
    font-size: 30px !important;
    margin-bottom: 0;
  }

  .home-events article .date {
    font-size: 61px !important;
    line-height: 61px !important;
  }

  .home-events article .category {
    font-size: 27px;
  }

  .home-events article a {
    font-size: 41px;
    -webkit-line-clamp: 3;
  }

  .home-events article .time {
    padding-top: 11px;
    font-size: 30px;
  }

  #contacts-section .col-lg-4:first-child img {
    margin: 0 auto 20px auto;
  }

  #contacts-section .col-lg-2 a img {
    width: 40px;
  }

  #contacts-section .col-lg-2 a {
    display: inline-block;
  }

  .faddress,
  .fmail,
  .fphone,
  .fpl {
    font-size: 15px;
  }

  .footer-menu a {
    font-size: 14px;
  }

  .faddress::before,
  .fmail::before,
  .fphone::before {
    font-size: 15px;
  }

  .home-events h2,
  .home-news h2,
  .home-notes h2 {
    font-size: 34px !important;
  }

  header .float-right a {
    font-size: 21px;
    margin-top: 11px;
  }

  .home-events,
  .home-notes {
    padding: 40px 0;
  }

  .home-news {
    padding: 40px 0 20px;
  }

  .navbar-brand {
    margin-left: 0 !important;
  }

  .home-alerts .col-sm-6 {
    margin-left: 0;
    margin-right: 0;
    flex: 0 0 100%;
  }

  #contacts-section .col-lg-4 img {
    max-width: 250px;
    margin: 0 auto 20px auto;
  }

  .home-links .box {
    display: table;
    height: 100%;
    width: 100%;
  }

  .home-links .box {
    padding: 20px 15px 20px 50px;
    font-size: 18px;
    background-size: auto 86%;
  }

  h2.h4 {
    font-size: 46px !important;
  }

  .home-events .float-right,
  .home-notes .float-right,
  #skiplink-news .float-right {
    display: none !important;
  }

  .home-events .d-none,
  .home-notes .d-none,
  #skiplink-news .d-none {
    display: block !important;
  }

  .slick-next.slick-arrow {
    right: 0;
    display: none !important;
  }

  .slick-prev.slick-arrow {
    right: 53px;
    display: none !important;
  }

  .image-mobile {
    display: block !important;
    margin-bottom: 0 !important;
  }

  .image-mobile img {
    width: 100% !important;
  }

  .home-info .col-sm-12 {
    background: none;
  }

  .slick-dots {
    bottom: -5px;
  }

  .home-events .slick-next.slick-arrow,
  .home-events .slick-prev.slick-arrow,
  .home-notes .slick-next.slick-arrow,
  .home-notes .slick-prev.slick-arrow {
    top: -44px;
  }

  .newsletter span {
    margin-bottom: 3px;
  }

  .newsletter .input-group {
    display: -ms-flexbox;
    display: flex;
  }

  .newsletter .input-group > .form-control {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
  }

  .newsletter .input-group-addon {
    width: auto;
  }

  #contacts-section h3 {
    font-size: 1.54286rem !important;
  }

  .home-notes .g-mb-30,
  .home-events .g-mb-30 {
    margin-bottom: 1rem !important;
  }

  .date {
    padding-bottom: 0;
  }

  .home-notes a {
    margin-bottom: 12px;
  }

  #contacts-section .col-lg-3.footer-menu {
    margin-bottom: 1rem !important;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .home-alerts .col-sm-6 {
    max-width: 48.4%;
  }

  .home-motive .col-sm-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .home-links .col-sm-3 {
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    max-width: 33%;
  }

  .home-info h2 {
    font-size: 26px;
  }

  .home-info-indend {
    padding: 30px 0 30px 0;
  }

  .home-info .col-sm-12 {
    background-size: 50% auto;
  }

  .home-info a {
    font-weight: 600;
  }
}

.btn-more {
  background: #fff !important;
  border-radius: 2px;
  font-weight: 700;
  font-size: 38px;
  text-transform: none;
  border: 1px solid #21347b;
  text-decoration: none !important;
}

.btn-more:hover {
  background: #d5dff7;
}

@media (min-width: 992px) {
  .modal-open {
    overflow: scroll;
  }
}

@media (max-width: 991px) {
  .header-search #search-input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .search-header .search-btn.btn-top {
    padding: 3px 7px 0px 0 !important;
    line-height: 23px !important;
  }

  .input-group-addon .fa-search {
    width: 22px;
    height: 22px;
  }

  .layout-home .input-group-addon .fa-search {
    width: 27px;
    height: 27px;
  }

  .g-mt-23 {
    margin-top: 23px;
  }

  .dropdown-item,
  .dropdown-item:hover {
    background: url("assets/img/icons/arrow_right.svg") no-repeat 1px 11px !important;
    background-size: 23px 13px !important;
  }

  .u-header .container.position-relative {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    background: #293a72;
    margin-top: 1px;
    box-shadow: none !important;
  }

  .u-header .navbar-brand,
  .navbar-collapse .col-sm-12 {
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .home-alerts .col-sm-6 {
    background: #f9f4ea;
    margin-left: 15px;
    flex: 0 0 48.2%;
  }
}

@media (max-width: 460px) {
  .navbar-brand img {
    width: 190px;
  }

  .navbar-toggler.navbar-toggler-right {
    top: 12px;
  }

  h2.h4 {
    font-size: 30px !important;
  }

  .slick-next.slick-arrow {
    right: 0;
  }

  .slick-prev.slick-arrow {
    right: 53px;
  }

  .home-info-indend {
    padding: 10px 0 0;
  }

  .home-info h2 {
    font-size: 30px;
  }

  .home-info .col-sm-12 {
    background: none;
  }

  .home-events,
  .home-notes {
    padding: 30px 0 10px 0;
  }

  .home-events .slick-next.slick-arrow,
  .home-events .slick-prev.slick-arrow,
  .home-notes .slick-next.slick-arrow,
  .home-notes .slick-prev.slick-arrow {
    top: -44px;
  }

  .home-links {
    padding: 30px 0 10px;
  }

  .g-mb-20.home-news {
    margin-bottom: 0 !important;
  }

  .home-news {
    padding: 30px 0 20px;
  }
}

.logo-mobile {
  display: none;
}

.logo-desktop {
  display: block;
}

@media (max-width: 768px) {
  .mobile-els {
    display: block !important;
  }

  .navbar .g-top-12 {
    top: 4px;
  }

  .panel h3 {
    padding: 5px 15px !important;
    background: #f8f8f4;
    cursor: pointer;
    /*margin-top: 10px !important;*/
  }

  .paginationBox {
    margin: 0 0 0;
    padding-top: 30px;
  }

  .paginationBox ul {
    margin: 0 0 0;
  }

  .panel h3 .float-right {
    margin-top: 4px !important;
  }

  .validation-summary-errors ul {
    padding-left: 0 !important;
  }

  .panel .card {
    padding: 0 15px !important;
    /*margin-top: 10px !important;*/
  }

  .panel .card .g-mb-30 {
    margin-bottom: 15px !important;
  }

  .header-search.g-mt-35 {
    margin-top: 1.9rem !important;
    flex: 1 !important;
  }

  .search-top {
    display: none !important;
  }

  .header-search {
    visibility: visible !important;
    opacity: 1 !important;
    width: auto !important;
    padding-right: 90px !important;
    transform: translateY(0px) !important;
  }

  .header-search {
    width: 340px;
  }

  .header-search.g-ml-80 {
    margin-left: 40px;
  }

  .g-right-165 {
    right: 33px;
  }

  #menu-btn {
    text-indent: -9999px;
  }

  .navbar-toggler.navbar-toggler-right {
    padding: 12px 15px 10px 15px;
  }

  .logo-mobile {
    margin-left: 20px !important;
    margin-bottom: 20px !important;
    width: 190px !important;
  }

  .logo-desktop,
  .els {
    display: none;
  }

  #skiplink-header .g-pos-abs.g-right-220 {
    right: 145px;
    top: 20px;
  }

  .els.mobile-els {
    background: url("assets/img/arrow.png") no-repeat 0 11px;
  }

  .navbar-toggler.navbar-toggler-right {
    top: 13px;
  }

  .navbar-collapse.collapse.show {
    top: 1px;
  }

  body.modal-open {
    padding-right: 0 !important;
  }

  .top-search.g-mb-30 {
    margin-bottom: 1.57143rem !important;
  }

  .home-alerts.g-mb-30 {
    margin-bottom: 15px !important;
  }

  .home-motive {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    padding-bottom: 15px;
  }

  .home-links.g-pb-10 {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding-bottom: 0 !important;
  }

  .home-notes .text-center.g-pb-20 {
    padding-bottom: 0 !important;
  }

  .home-notes .text-center.g-pb-20 a {
    margin-bottom: 0;
  }

  .home-notes.g-pb-10 {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    padding-bottom: 15px !important;
    margin-bottom: 15px;
  }

  .home-info.g-mb-40 {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    padding-bottom: 15px;
    margin-bottom: 15px !important;
  }

  .slick-dots {
    display: none !important;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }
}

@media (max-width: 400px) {
  #menu-btn {
    text-indent: -9999px;
  }

  .navbar-toggler.navbar-toggler-right {
    padding: 12px 15px 10px 29px;
  }

  #skiplink-header .g-pos-abs.g-right-220 {
    right: 145px;
  }
}

.g-font-weight-600,
td.g-font-weight-600 {
  font-weight: 700 !important;
  font-family: Work Sans;
}

strong,
b,
p.g-font-weight-600 {
  font-weight: 600 !important;
  font-family: Work Sans;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

@media (min-width: 768px) and (max-width: 991px) {
  .right-menu {
    font-size: 0.9em !important;
  }

  .right-section h3 {
    font-size: 1.4em !important;
    border-bottom: 1px solid #e5ecd0;
    padding-bottom: 6px;
    margin-bottom: 11px !important;
  }

  .g-right-165 {
    right: 105px;
  }

  .g-right-220 {
    right: 221px;
  }
}

#lang-dropdown {
  z-index: 1100;
}

details summary::marker,
details summary::-webkit-details-marker {
  display: none;
  content: "";
}

details summary {
  text-transform: uppercase;
}

details {
  background: #f4f4f4;
  padding: 10px 16px 10px 16px;
  margin-bottom: 5px;
  position: relative;
}

details[open] {
  /*experiment - pasik*/
  /*     &::before {
      width: 10px;
      border-left: 1em solid #efefef;
      content: ' ';
      position: absolute;
      top: 2em;
      left: 0;
      bottom: 0;
  }*/
}

details[open] > summary {
  margin-bottom: 20px;
}

details[open] > *:not(summary):not(ul) {
  padding-left: 1em;
}

details[open] > details {
  padding: 0;
}

details summary {
  position: relative;
}

summary::marker {
  color: #f4f4f4;
}

summary:after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 22px;
  display: block;
  float: right;
  color: #25397d;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  margin-top: 3px;
}

details[open] > summary:after {
  content: "\f106";
}

.file-card {
  /*background: #f8f8f4;*/
  border-radius: 8px;
  border: 1px solid #e9e9e8;
}

.file-card a:hover {
  color: #82B383;
}

.file-card .file-icon {
  color: #c6a171;
}

.file-card .download-icon {
  color: #82B383;
}

.file-card .media-body {
  overflow: hidden;
  white-space: nowrap;
}

.hs-mega-menu {
  display: none;
}

.home-motive {
  background: none !important;
}

.u-header__section--light .navbar-nav:not([class*=u-main-nav-v]) .nav-link:hover {
  color: #c6a171 !important;
}

.fade-default {
  opacity: 0;
}

.come-in {
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-animation: come-in 0.6s ease forwards;
  animation: come-in 0.6s ease forwards;
}

.come-in.from-right {
  -webkit-transform: translateY(0) translateX(30px);
  transform: translateY(0) translateX(30px);
}

.come-in.from-left {
  -webkit-transform: translateY(0) translateX(-30px);
  transform: translateY(0) translateX(-30px);
}

.come-in.from-top {
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}

.come-in:nth-child(odd) {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  /* So they look staggered */
}

@-webkit-keyframes come-in {
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes come-in {
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 575px) {
  .logo-mobile {
    margin-left: 30px !important;
  }

  #navBar {
    margin-left: -1px;
    margin-right: -1px;
  }

  .share-icons b {
    display: none;
  }
}

.js-mega-menu .dropdown,
.dropleft,
.dropright,
.dropup {
  position: unset;
}

.js-mega-menu .dropdown .dropdown-item,
.dropleft .dropdown-item,
.dropright .dropdown-item,
.dropup .dropdown-item {
  color: white;
  white-space: unset;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  background: #23366f;
  margin-top: 1px;
  box-shadow: 0px 40px 14px -3px rgba(0, 0, 0, 0.27);
}

.home-motive .form-control {
  font-size: 1.5rem;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    left: 0;
    right: 0;
    color: white;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
    /*grid-gap: 1em;*/
  }
}

.navbar .container {
  position: unset;
}

#navBar {
  position: unset;
}

.content-column .content-column {
  padding-top: 0px;
}

@media (max-width: 575px) {
  .home-events article .date {
    font-size: 31px !important;
    line-height: 31px !important;
  }

  .home-events article .time {
    font-size: 20px;
    min-height: 1px;
  }

  .home-events article {
    min-height: 1px !important;
  }

  .home-events article a,
  .home-notes article a {
    min-height: 1px;
    overflow: visible;
    -webkit-line-clamp: unset;
  }

  .home-notes article {
    min-height: 2px !important;
  }

  .slick-list .date {
    font-size: 23px !important;
    margin-bottom: 0px;
  }

  .home-events article .category {
    font-size: 19px;
  }
}

@media (max-width: 400px) {
  h1 {
    font-size: 26px;
  }

  .bread-crumb {
    margin-bottom: 20px;
  }

  .right-section h3.subtitle {
    margin-top: 18px;
    margin-bottom: 20px !important;
  }

  .box-object,
  .box-object3 {
    padding: 20px 15px;
  }
}

@media (max-width: 575px) {
  .logo-mobile {
    display: none !important;
  }

  .logo-mobileSmall {
    display: block !important;
    height: 46px !important;
    width: auto;
    margin-left: 30px !important;
  }

  .navbar-brand,
  .navbar-brand img {
    width: auto;
  }

  .navbar-toggler.g-top-10 {
    top: 21px !important;
  }
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop {
  top: 0px !important;
}

.modal-backdrop {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;
  background-color: #000;
}

@media (min-width: 1200px) {
  .form-horizontal .form-control.col-sm-4,
  .filter-line .form-control.col-sm-4 {
    max-width: 31.7%;
  }

  .panel-search .btn,
  .panel-search input,
  .panel-search select {
    margin-right: 30px !important;
  }

  .panel-search input.filter-fulltext {
    margin-right: 20px !important;
  }

  .panel-search .align-bottom input,
  .panel-search .align-bottom select {
    margin-right: 0 !important;
  }

  .panel-search .align-bottom .btn {
    padding: 0.465rem 1.65rem;
  }

  .panel-search .align-bottom .btn.btn-primary {
    width: 100%;
  }

  .panel-search .align-bottom .btn.btn-primary.autow {
    width: auto;
  }

  .filter-line .form-control.col-sm-6 {
    max-width: 48.1%;
  }

  .filter-line .form-control.col-sm-4 {
    max-width: 30.8% !important;
  }

  .filter-line-short .form-control.col-sm-6 {
    max-width: 47.1%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .form-horizontal .form-control.col-sm-4 {
    max-width: 31.3%;
  }

  .panel-search .btn,
  .panel-search input,
  .panel-search select {
    margin-right: 30px !important;
  }

  .filter-line .form-control.col-sm-6 {
    max-width: 47.8%;
  }

  .filter-line .form-control.col-sm-4 {
    max-width: 30.1% !important;
  }

  .panel-search .align-bottom input,
  .panel-search .align-bottom select {
    margin-right: 0 !important;
  }

  .panel-search .align-bottom .btn {
    padding: 0.475rem 1.65rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .form-horizontal .form-control.col-sm-4 {
    max-width: 30.3%;
  }

  .bread-crumb {
    margin-bottom: 25px;
  }

  .right-section.panel-search {
    margin-top: 103px;
  }

  .panel-search .btn,
  .panel-search input,
  .panel-search select {
    margin-right: 30px !important;
  }

  .right-section.panel-search .btn {
    margin-right: 0 !important;
    font-size: 16px !important;
  }

  .right-section.panel-search .custom-control-label {
    font-size: 14px !important;
  }

  .filter-line .form-control.col-sm-6 {
    max-width: 47%;
  }

  .filter-line .form-control.col-sm-4 {
    max-width: 29.1% !important;
  }

  .panel-search .align-bottom input,
  .panel-search .align-bottom select {
    margin-right: 0 !important;
  }

  .panel-search .align-bottom .btn {
    padding: 0.475rem 1.65rem;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .form-horizontal .form-control.col-sm-4 {
    max-width: 29.5%;
  }

  .panel-search .btn,
  .panel-search input,
  .panel-search select {
    margin-right: 30px !important;
  }

  .filter-line .form-control.col-sm-6 {
    max-width: 47%;
  }
}

@media (max-width: 575px) {
  .panel-search .btn,
  .panel-search input,
  .panel-search select {
    margin-right: 0 !important;
  }
}

select.form-control {
  background: url("assets/img/icons/selectarrow.png") no-repeat right center #fff !important;
  -webkit-appearance: none;
  padding: 0.585rem 0.65rem;
}

.panel-search select.form-control {
  padding: 0.585rem 0.65rem;
}

.print-text {
  cursor: pointer;
  float: right;
  margin-top: 3px;
  display: block;
  text-decoration: none !important;
  color: #242620;
}

.print-text:hover {
  color: #242620;
}

.sub-news .article-detail h3 a {
  color: #21347b !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.sub-news .min-height-article {
  min-height: 1px !important;
}

#navBar .container {
  position: relative !important;
}

[data-category=elektronicke-sluzby] {
  grid-column: 1;
  margin-top: 20px;
}

[data-category=formulare-a-tlaciva] {
  margin-top: 20px;
}

[data-category=elektronicke-sluzby]:after {
  display: inline-block;
  content: " ";
  height: 1px;
  background: #4c5478;
  opacity: 1;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: -21px;
  margin-right: 15px;
  margin-left: 15px;
}

@media (min-width: 411px) and (max-width: 510px) {
  #cconsent-bar button {
    padding: 6px 6px !important;
    font-size: 12px !important;
  }

  #cconsent-bar {
    text-align: left !important;
  }
}

@media (max-width: 410px) {
  #cconsent-bar .ccb__right {
    white-space: normal !important;
  }

  #cconsent-bar {
    text-align: left !important;
  }

  #cconsent-bar button {
    margin-right: 0;
    margin-bottom: 5px;
    width: 100%;
  }
}

@media (min-width: 511px) and (max-width: 800px) {
  #cconsent-bar {
    text-align: left !important;
  }
}

.in-page-navigation ul {
  padding-left: 0;
  list-style-type: none;
}

.in-page-navigation ul li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 15px;
}

.in-page-navigation ul li.in-page-navigation__list-item--active a {
  font-weight: bold;
}

.in-page-navigation ul li.in-page-navigation__list-item--active::before {
  display: inline-block;
  color: #21347b;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  -webkit-clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  border-width: 5px 0 5px 8.66px;
  border-left-color: inherit;
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
}

.in-page-navigation--sticky {
  position: sticky;
  top: 160px;
  overflow: auto;
  height: 100vh;
}

.in-page-navigation.mobile li,
.in-page-navigation.mobile a {
  color: white;
}

.in-page-navigation.mobile.in-page-navigation--sticky {
  position: fixed;
  height: auto;
  overflow: unset;
  top: 62px;
  left: 0;
  right: 0;
  width: 100%;
}

.in-page-navigation.mobile .idsk-in-page-navigation__link-panel-button {
  font-weight: 400;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.11111;
  border: none;
  color: #ffffff;
  font-weight: bold;
  padding: 15px 50px 15px 15px;
  text-align: left;
  width: 100%;
  background: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.in-page-navigation.mobile .idsk-in-page-navigation__link-panel-button::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  color: #ffffff;
  content: "";
  display: inline-block;
  height: 0.45em;
  right: 25px;
  position: absolute;
  top: 18px;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  vertical-align: top;
  width: 0.45em;
}

.in-page-navigation.mobile .idsk-in-page-navigation__link-panel {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 100%;
}

.in-page-navigation.mobile .idsk-in-page-navigation__list {
  transition: all 0.2s ease-out;
  overflow-y: hidden;
  max-height: 0;
}

.in-page-navigation.mobile .idsk-in-page-navigation__list li:first-child {
  margin-top: 15px;
}

.in-page-navigation.mobile.idsk-in-page-navigation--expand .idsk-in-page-navigation__list {
  transition: all 0.2s ease-in;
  overflow-y: auto;
  max-height: 300px;
}

.in-page-navigation.mobile.idsk-in-page-navigation--expand .idsk-in-page-navigation__link-panel-button::before {
  top: 20px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.bg-main {
  background-color: #21347b;
}

@media (max-width: 768px) {
  .header-search {
    visibility: visible !important;
    opacity: 1 !important;
    width: auto !important;
    display: flex;
    transform: translateY(0) !important;
    flex: 1 !important;
    padding-right: 0 !important;
  }

  .navbar-toggler.g-top-10 {
    top: 25px !important;
  }

  .navbar-toggler.navbar-toggler-right {
    width: 32px;
    height: 27px;
  }
}

@media (max-width: 476px) {
  .header-search {
    visibility: visible !important;
    opacity: 1 !important;
    width: auto !important;
    display: flex;
    transform: translateY(0) !important;
    flex: 1 !important;
    padding-right: 80px !important;
  }
}

@media (max-width: 376px) {
  .search-btn {
    padding: 10px 7px 5px;
  }
}

@media (max-width: 991px) {
  #navBar {
    position: absolute;
  }

  .modal-backdrop {
    top: 63px !important;
  }

  .layout-home .modal-backdrop {
    top: 67px !important;
  }

  .navbar-collapse.collapse.show {
    top: 0;
  }

  .navbar .container {
    position: relative;
  }
}

@media (max-width: 768px) {
  .layout-home .modal-backdrop {
    top: 64px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .modal-backdrop {
    top: 67px !important;
  }
}

@media (min-width: 992px) {
  .modal-backdrop {
    top: 137px !important;
  }

  .search-top {
    top: 29px;
  }

  .header-search {
    margin-top: 25px;
  }
}

@media (min-width: 1200px) {
  .modal-backdrop {
    top: 139px !important;
  }
}

.bgheader {
  position: sticky !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

@media (max-width: 992px) {
  .bgheader {
    position: fixed !important;
  }

  .content-column {
    padding-top: 50px;
  }
}

.layout-home .home-motive {
  margin-top: 136px !important;
}

.layout-home .bgheader {
  position: static !important;
  background: url("assets/img/bg/bgheader.jpg") no-repeat center -138px #334583 !important;
}

@media (max-width: 992px) {
  .layout-home .bgheader {
    background: url("assets/img/bg/bgheader.jpg") no-repeat center -67px #334583 !important;
  }

  .layout-home .home-motive {
    margin-top: 67px !important;
  }
}

@media (max-width: 767px) {
  .layout-home .bgheader {
    background: url("assets/img/bg/bgheader.jpg") no-repeat center -63px #334583 !important;
  }

  .layout-home .home-motive {
    margin-top: 63px !important;
    padding-top: 30px;
  }
}

.layout-home .bgheader header {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: url("assets/img/bg/bgheader.jpg") no-repeat center top #334583 !important;
}

.tab-content .card {
  padding: 0 !important;
}

.u-accordion__header a {
  margin-bottom: 0 !important;
}

/*fix for h2 anchors, when header is fixed*/

.staticpage h2[id],
.container h1[id] {
  scroll-margin-top: 160px;
}

.container h1[id] {
  scroll-margin-top: 170px;
}

@media (max-width: 991px) {
  .staticpage h2[id],
  .container h1[id] {
    scroll-margin-top: 100px;
  }

  .container h1[id] {
    scroll-margin-top: 170px;
  }
}

.card .vote {
  font-size: 0.7em !important;
  color: #b5b5af !important;
  text-transform: uppercase !important;
}

.card .status-not_voting {
  color: #d8d8d6;
  margin-left: 10px;
}

.card .status-aye {
  color: #38ad00;
  margin-left: 10px;
}

.card .status-no {
  color: #e90000;
  margin-left: 10px;
}

.card .status-abstained {
  color: #f6c96c;
  margin-left: 10px;
}

.card .status-missing {
  color: #c0b498;
  margin-left: 10px;
}

.card .col-lg-6 {
  padding-bottom: 10px !important;
}

.card .col-sm-12 {
  border-top: 1px solid #eff2e3 !important;
  padding-top: 10px !important;
  text-transform: uppercase !important;
}

.staticpage h5 > a:hover {
  text-decoration: none !important;
}

.staticpage h5 > a:hover > span:not(:first-child) {
  text-decoration: underline !important;
}

@media (max-width: 767px) {
  .mobile-search,
  .mobile-search .search-top {
    display: block !important;
  }

  .mobile-search .search-top {
    position: absolute;
    right: 65px;
    top: 23px;
    z-index: 4111;
  }

  .mobile-search .home-motive .row {
    display: block;
    width: 100%;
  }

  .mobile-search .close {
    float: right;
    font-size: 2.2rem;
    margin-right: 20px;
    margin-top: 10px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-shadow: none !important;
    opacity: 1;
  }

  .header-search-btn,
  .header-search-btn-close {
    cursor: pointer;
  }

  .desktop-search {
    display: none !important;
  }

  .mobile-search .fas.fa-search {
    color: #fff !important;
    position: relative;
    z-index: 1111;
  }

  .mobile-search .modal-body {
    position: relative;
    display: block;
    padding: 0;
  }

  .modal-content {
    background-color: none;
    border: 0;
    display: block;
    border-radius: 0;
  }

  .mobile-search .modal-dialog {
    max-width: inherit;
    width: 100%;
    margin: 1.75rem auto;
  }

  .panel h3[aria-expanded=false]:before {
    content: "\f107";
    font-weight: 400 !important;
    font-family: "Font Awesome 5 Pro";
  }

  .panel h3[aria-expanded=true]:before {
    content: "\f106";
    font-weight: 400 !important;
    font-family: "Font Awesome 5 Pro";
  }
}

.ophours-mobile {
  display: none;
}

@media (max-width: 768px) {
  .ophours-mobile {
    display: block;
  }

  .ophours-desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  #cconsent-bar .ccb__left {
    padding-right: 15px;
  }
}

#tag-list .row {
  margin-right: -25px;
  margin-left: -25px;
}

#tag-list .row .col-sm-6 {
  margin-bottom: 20px;
}

#tag-list .row .col-sm-6 div {
  background: #f8f8f8;
  padding: 20px 15px;
}

#tag-list .row .col-sm-6 a {
  color: #21347b !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

#tag-list .date {
  color: #c6a171;
  padding-bottom: 0;
  font-size: 15px !important;
}

#tag-list h3 {
  margin-bottom: 9px;
  line-height: 1.2;
}

@media (min-width: 576px) {
  #tag-list h3 {
    min-height: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-height: 1.4;
    -webkit-box-orient: vertical;
  }

  #tag-list .row .col-sm-6 div {
    padding: 30px 20px;
    height: 100%;
  }

  #tag-list .row .col-sm-6 {
    margin-bottom: 30px;
  }

  #tag-list .row .col-sm-6 a {
    font-size: 20px !important;
  }
}

.staticpage a.box-link:focus {
  border-left: 4px solid #2b3b73 !important;
}

.g-bg-gray-light-v4 {
  background: #f9f9f9 !important;
}

.filter-dnone {
  display: block;
}

@media (max-width: 575px) {
  .filter-dnone {
    display: none;
  }

  .btn-filter5 {
    margin-left: 15px;
    margin-right: 15px;
    width: 100%;
  }

  .btn-filter5 .btn {
    width: 100%;
    display: block;
  }
}

@media (min-width: 576px) {
  .filter-fulltext.col-sm-8 {
    flex: 0 0 65.666667%;
    max-width: 65.666667%;
  }
}

@media (min-width: 992px) {
  .btn-filter5 {
    margin-left: 15px;
  }
}

.home-events .news-image {
  top: -30px;
  right: 0;
  position: absolute;
}

.event-page.articles .col-lg-4 .date,
.home-events .slick-list .date {
  padding-top: 214px !important;
  display: block;
}

.home-events article {
  position: relative;
  padding: 0 20px 20px 20px;
}

.home-events .col-sm-12 {
  padding-top: 30px;
}

.event-corner {
  top: 0;
  left: 0;
  width: 26px;
  height: 200px;
  background: #62c2d0;
  position: absolute;
}

.event-events .event-corner {
  background: #f58273;
}

.event-government .event-corner {
  background: #73a4f5;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .event-corner {
    width: 29px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .event-corner {
    width: 13px;
  }
}

@media (max-width: 767px) {
  .event-corner {
    width: 31px;
  }
}

@media (max-width: 444px) {
  .event-corner {
    width: 25px;
  }
}

@media (max-width: 400px) {
  .event-corner {
    width: 22px;
  }
}

[data-category=odkaz-pre-starostu] {
  margin-top: 20px;
}

.staticpage blockquote {
  margin: 0 0 1rem 25px;
  padding: 15px 25px 15px;
  font-style: italic;
  border-left: 4px solid #cccccc;
}

.staticpage blockquote p {
  margin: 0 !important;
}

.file::before,
.lifes a[href$=".doc"]::before,
.lifes a[href$=".docx"]::before,
.lifes a[href$=".pdf"]::before {
  color: #c6a171;
  margin-right: 6px;
}

/*------------------------------------
  Globals
------------------------------------*/

/* Text */

.g-word-break {
  word-wrap: break-word;
}

/* Puller */

.g-pull-50x-up {
  transform: translateY(-51%);
}

.g-pull-50x-bottom {
  transform: translateY(51%);
}

.g-pull-50x-left {
  transform: translateX(-51%);
}

.g-pull-50x-right {
  transform: translateX(51%);
}

/* Link overlay */

/*@import "globals/bg-attachments";*/

/*------------------------------------
  Background Gradient Colors
------------------------------------*/

/* Simple Linear Gradients
------------------------------------*/

/* Gray Light v1 */

.g-bg-gray-light-gradient-v1--after::after {
  background: linear-gradient(to bottom, rgba(247, 247, 247, 0.5), #f7f7f7);
}

/* White v1 */

.g-bg-white-gradient-v1--after::after {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.9));
}

/* Primary Gradient */

.g-bg-primary-gradient-opacity-v1,
.g-bg-primary-gradient-opacity-v1--after::after {
  background-repeat: repeat-x;
  background-image: linear-gradient(150deg, #72c02c, rgba(51, 152, 220, 0.7));
}

/* Gray Gradient */

.g-bg-gray-gradient-opacity-v1,
.g-bg-gray-gradient-opacity-v1--after::after {
  background-image: linear-gradient(to top, #f7f7f7 0%, white 100%);
  background-repeat: repeat-x;
}

/* White Gradient */

.g-bg-white-gradient-opacity-v1,
.g-bg-white-gradient-opacity-v1--after::after {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  background-repeat: repeat-x;
}

/* White Gradient */

.g-bg-white-gradient-opacity-v4,
.g-bg-white-gradient-opacity-v4--after::after {
  background-image: linear-gradient(to right, white 30%, rgba(255, 255, 255, 0.3) 100%);
  background-repeat: repeat-x;
}

/* White Gradient */

.g-bg-white-gradient-opacity-v5,
.g-bg-white-gradient-opacity-v5--after::after {
  background-image: linear-gradient(to right, white 47%, rgba(255, 255, 255, 0) 75%);
  background-repeat: repeat-x;
}

.g-bg-blue-radialgradient-circle-endless {
  background-image: repeating-radial-gradient(circle at 50% 50%, #b9ecfe, #b9ecfe 10px, #82ddff 10px, #82ddff 20px);
}

.g-bg-gray-verticalstripes-endless {
  background: repeating-linear-gradient(to bottom, #fff, #fff 5px, #eee 5px, #eee 10px);
}

/*------------------------------------
  Background Colors
------------------------------------*/

/* Basic Colors
------------------------------------*/

/* Main Colors */

.g-bg-main {
  background-color: #fff !important;
}

/* Main Secondary */

.g-bg-secondary {
  background-color: #fafafa !important;
}

/* Primary Colors */

.g-bg-primary {
  background-color: #22211f !important;
}

.g-bg-primary--hover:hover {
  background-color: #22211f !important;
}

.u-block-hover:hover .g-bg-primary--hover {
  background-color: #22211f;
}

.g-parent:hover .g-bg-primary--parent-hover {
  background-color: #22211f !important;
}

.g-bg-primary--active.active,
.active .g-bg-primary--active {
  background-color: #22211f !important;
}

.g-parent.active .g-bg-primary--parent-active {
  background-color: #22211f !important;
}

.g-bg-primary--before::before,
.g-bg-primary--after::after {
  background-color: #22211f !important;
}

.g-bg-primary-dark-v1 {
  background-color: #1d1c1a !important;
}

.g-bg-primary-dark-v2 {
  background-color: #151413 !important;
}

.g-bg-primary-dark-v3 {
  background-color: #070707 !important;
}

/* Black Colors */

.g-bg-black {
  background-color: #000 !important;
}

.g-bg-black--hover:hover {
  background-color: #000 !important;
}

.g-bg-black--after::after {
  background-color: #000 !important;
}

.g-bg-black-opacity-0_1 {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.g-bg-black-opacity-0_1--after::after {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.g-bg-black-opacity-0_2 {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.g-bg-black-opacity-0_2--after::after {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.g-bg-black-opacity-0_3 {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.g-bg-black-opacity-0_3--hover:hover {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.g-bg-black-opacity-0_3--after::after,
.g-bg-black-opacity-0_3--before::before {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.g-bg-black-opacity-0_3--hover--after:hover::after {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.g-bg-black-opacity-0_4 {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.g-bg-black-opacity-0_4--after::after {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.g-bg-black-opacity-0_5 {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.g-bg-black-opacity-0_5--hover:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.g-bg-black-opacity-0_5--after::after {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.g-bg-black-opacity-0_6 {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.g-bg-black-opacity-0_6--hover:hover {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.g-bg-black-opacity-0_6--after::after {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.g-bg-black-opacity-0_7 {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.g-bg-black-opacity-0_7--hover:hover {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.g-bg-black-opacity-0_7--after::after {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.g-bg-black-opacity-0_8 {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.g-bg-black-opacity-0_8--after::after {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.g-bg-black-opacity-0_8--hover:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.g-bg-black-opacity-0_9 {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.g-bg-black-opacity-0_9--after::after {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.g-bg-black-opacity-0_9--hover:hover {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.u-block-hover:hover .g-bg-black--hover {
  background-color: #000 !important;
}

/* White Colors */

.g-bg-white {
  background-color: #fff !important;
}

.g-bg-white--before::before,
.g-bg-white--after::after {
  background-color: #fff !important;
}

.g-bg-white--hover:hover,
.g-bg-white--active.active {
  background-color: #fff !important;
}

.g-parent:hover .g-bg-white--parent-hover {
  background-color: #fff !important;
}

.g-bg-white--disabled:disabled {
  background-color: #fff !important;
}

.g-bg-white--before::before,
.g-bg-white--after::after {
  background-color: #fff !important;
}

.g-parent:hover .g-bg-white-opacity-0--after--parent-hover::after {
  background-color: rgba(255, 255, 255, 0) !important;
}

.g-bg-white-opacity-0_05 {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.g-bg-white-opacity-0_1 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.g-bg-white-opacity-0_1--after::after {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.g-bg-white-opacity-0_2 {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.g-bg-white-opacity-0_2--hover:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.g-bg-white-opacity-0_3 {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.g-bg-white-opacity-0_3--hover:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.g-bg-white-opacity-0_3--after::after {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.g-bg-white-opacity-0_4 {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.g-bg-white-opacity-0_4--hover:hover {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.g-bg-white-opacity-0_5 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.g-bg-white-opacity-0_5--after::after {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.g-bg-white-opacity-0_7 {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.g-bg-white-opacity-0_7--hover:hover {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.g-bg-white-opacity-0_7--after::after {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.g-bg-white-opacity-0_8 {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.g-bg-white-opacity-0_8--hover:hover {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.g-bg-white-opacity-0_8--after::after {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.g-bg-white-opacity-0_8--hover--after:hover::after {
  background-color: rgba(34, 33, 31, 0.8) !important;
}

.g-bg-white-opacity-0_9 {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.g-bg-white-opacity-0_9--hover:hover {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.g-bg-white-opacity-0_9--after::after {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

/* Transparent */

.g-bg-transparent {
  background-color: transparent !important;
}

.g-bg-transparent--hover:hover {
  background-color: transparent !important;
}

.g-bg-transparent--hover--after:hover::after {
  background-color: transparent !important;
}

.g-parent:hover .g-bg-transparent--parent-hover {
  background-color: transparent !important;
}

.g-grayscale-100x {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=http://www.w3.org/2000/svg><filter id=grayscale><feColorMatrix type=matrix values=0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0/></filter></svg>#grayscale");
  /* Firefox 10+, Firefox on Android */
  filter: gray;
  -webkit-filter: grayscale(100%);
}

.g-parent:hover .g-grayscale-0--parent-hover {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=http://www.w3.org/2000/svg><filter id=grayscale><feColorMatrix type=matrix values=1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}

.g-grayscale-0--hover:hover {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=http://www.w3.org/2000/svg><filter id=grayscale><feColorMatrix type=matrix values=1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}

/* O */

@media (min-width: 576px) {
  .g-bg-transparent--sm {
    background-color: transparent !important;
  }
}

/*@import "globals/bg-colors-extended";*/

/*@import "globals/bg-color-socials";*/

/*@import "globals/bg-grids";*/

/*------------------------------------
  Covers
------------------------------------*/

.g-bg-cover {
  position: relative;
}

.g-bg-cover::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.g-bg-cover-v1::after {
  position: absolute;
  top: 0.3571428571rem;
  right: 0.3571428571rem;
  bottom: 0.3571428571rem;
  left: 0.3571428571rem;
}

.g-bg-cover__inner {
  z-index: 1;
  position: relative;
}

/*------------------------------------
  Background Image Style
------------------------------------*/

.g-bg-img-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/*@import "globals/bg-patterns";*/

/*------------------------------------
  Background Position
------------------------------------*/

.g-bg-pos-center {
  background-position: center;
}

.g-bg-pos-left-center {
  background-position: left center;
}

.g-bg-pos-top-left {
  background-position: top left;
}

.g-bg-pos-top-right {
  background-position: top right;
}

.g-bg-pos-top-center {
  background-position: top center;
}

.g-bg-pos-bottom-center {
  background-position: bottom center;
}

/*------------------------------------
  Background Repeat
------------------------------------*/

.g-bg-no-repeat {
  background-repeat: no-repeat;
}

/*------------------------------------
  Background Size
------------------------------------*/

.g-bg-size-cover {
  background-size: cover !important;
}

.g-bg-size-100x {
  background-size: 100% !important;
}

.g-bg-size-100x--hover:hover {
  background-size: 100% !important;
}

.g-parent:hover .g-bg-size-100x--parent-hover {
  background-size: 100% !important;
}

.g-bg-size-120x {
  background-size: 120% !important;
}

.g-bg-size-120x--hover {
  background-size: 120% !important;
}

.g-parent:hover .g-bg-size-120x--parent-hover {
  background-size: 120% !important;
}

.g-bg-repeat {
  background-repeat: repeat !important;
  /* P */
  background-size: auto !important;
}

/*@import "globals/fill-colors";*/

/*@import "globals/borders";
@import "globals/border-none";
@import "globals/border-styles";
@import "globals/border-radiuses";
@import "globals/border-dashed";
@import "globals/border-dotted";
@import "globals/border-widths";
@import "globals/border-colors";
@import "globals/border-color-socials";
@import "globals/border-color-gradients";*/

/*------------------------------------
  Typography Font Family
------------------------------------*/

.g-font-secondary {
  font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
}

.g-font-code {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.g-font-niconne {
  font-family: "Niconne", cursive;
}

/*------------------------------------
  Typography Font Size
------------------------------------*/

.g-font-size-default {
  font-size: 1rem !important;
}

.g-font-size-70x {
  font-size: 70% !important;
}

.g-font-size-75x {
  font-size: 75% !important;
}

.g-font-size-80x {
  font-size: 80% !important;
}

.g-font-size-85x {
  font-size: 85% !important;
}

.g-font-size-90x {
  font-size: 90% !important;
}

.g-font-size-95x {
  font-size: 95% !important;
}

.g-font-size-0 {
  font-size: 0;
}

.g-font-size-5 {
  font-size: 0.3571428571rem !important;
}

.g-font-size-8 {
  font-size: 0.5714285714rem !important;
}

.g-font-size-9 {
  font-size: 0.6428571429rem !important;
}

.g-font-size-10 {
  font-size: 0.7142857143rem !important;
}

.g-font-size-11 {
  font-size: 0.7857142857rem !important;
}

.g-font-size-12 {
  font-size: 0.8571428571rem !important;
}

.g-font-size-13 {
  font-size: 0.9285714286rem !important;
}

.g-font-size-14 {
  font-size: 1rem !important;
}

.g-font-size-15 {
  font-size: 1.0714285714rem !important;
}

.g-font-size-16 {
  font-size: 1.1428571429rem !important;
}

.g-font-size-17 {
  font-size: 1.2142857143rem !important;
}

.g-font-size-18 {
  font-size: 1.2857142857rem !important;
}

.g-font-size-20 {
  font-size: 1.4285714286rem !important;
}

.g-font-size-22 {
  font-size: 1.5714285714rem !important;
}

.g-font-size-23 {
  font-size: 1.6428571429rem !important;
}

.g-font-size-24 {
  font-size: 1.7142857143rem !important;
}

.g-font-size-25 {
  font-size: 1.7857142857rem !important;
}

.g-font-size-26 {
  font-size: 1.8571428571rem !important;
}

.g-font-size-27 {
  font-size: 1.9285714286rem !important;
}

.g-font-size-28 {
  font-size: 2rem !important;
}

.g-font-size-30 {
  font-size: 2.1428571429rem !important;
}

.g-font-size-32 {
  font-size: 2.2857142857rem !important;
}

.g-font-size-33 {
  font-size: 2.3571428571rem !important;
}

.g-font-size-35 {
  font-size: 2.5rem !important;
}

.g-font-size-36 {
  font-size: 2.5714285714rem !important;
}

.g-font-size-38 {
  font-size: 2.7142857143rem !important;
}

.g-font-size-40 {
  font-size: 2.8571428571rem !important;
}

.g-font-size-42 {
  font-size: 3rem !important;
}

.g-font-size-45 {
  font-size: 3.2142857143rem !important;
}

.g-font-size-46 {
  font-size: 3.2857142857rem !important;
}

.g-font-size-48 {
  font-size: 3.4285714286rem !important;
}

.g-font-size-50 {
  font-size: 3.5714285714rem !important;
}

.g-font-size-55 {
  font-size: 3.9285714286rem !important;
}

.g-font-size-56 {
  font-size: 4rem !important;
}

.g-font-size-60 {
  font-size: 4.2857142857rem !important;
}

.g-font-size-65 {
  font-size: 4.6428571429rem !important;
}

.g-font-size-70 {
  line-height: 70px;
  font-size: 5rem !important;
}

.g-font-size-75 {
  font-size: 5.3571428571rem !important;
}

.g-font-size-76 {
  font-size: 5.4285714286rem !important;
}

.g-font-size-80 {
  font-size: 5.7142857143rem !important;
}

.g-font-size-86 {
  font-size: 6.1428571429rem !important;
}

.g-font-size-90 {
  font-size: 6.4285714286rem !important;
}

.g-font-size-120 {
  font-size: 8.5714285714rem !important;
}

.g-font-size-200 {
  line-height: 210px;
  font-size: 14.2857142857rem !important;
}

.g-font-size-180 {
  font-size: 12.8571428571rem !important;
}

@media (min-width: 576px) {
  .g-font-size-20--sm {
    font-size: 1.4285714286rem !important;
  }

  .g-font-size-25--sm {
    font-size: 1.7857142857rem !important;
  }

  .g-font-size-40--sm {
    font-size: 2.8571428571rem !important;
  }

  .g-font-size-50--sm {
    font-size: 3.5714285714rem !important;
  }

  .g-font-size-240--sm {
    font-size: 17.1428571429rem !important;
  }
}

@media (min-width: 768px) {
  .g-font-size-default--md {
    font-size: 1rem !important;
  }

  .g-font-size-12--md {
    font-size: 0.8571428571rem !important;
  }

  .g-font-size-16--md {
    font-size: 1.1428571429rem !important;
  }

  .g-font-size-18--md {
    font-size: 1.2857142857rem !important;
  }

  .g-font-size-20--md {
    font-size: 1.4285714286rem !important;
  }

  .g-font-size-24--md {
    font-size: 1.7142857143rem !important;
  }

  .g-font-size-25--md {
    font-size: 1.7857142857rem !important;
  }

  .g-font-size-26--md {
    font-size: 1.8571428571rem !important;
  }

  .g-font-size-27--md {
    font-size: 1.9285714286rem !important;
  }

  .g-font-size-28--md {
    font-size: 2rem !important;
  }

  .g-font-size-30--md {
    font-size: 2.1428571429rem !important;
  }

  .g-font-size-32--md {
    font-size: 2.2857142857rem !important;
  }

  .g-font-size-35--md {
    font-size: 2.5rem !important;
  }

  .g-font-size-36--md {
    font-size: 2.5714285714rem !important;
  }

  .g-font-size-40--md {
    font-size: 2.8571428571rem !important;
  }

  .g-font-size-45--md {
    font-size: 3.2142857143rem !important;
  }

  .g-font-size-46--md {
    font-size: 3.2857142857rem !important;
  }

  .g-font-size-48--md {
    font-size: 3.4285714286rem !important;
  }

  .g-font-size-50--md {
    font-size: 3.5714285714rem !important;
  }

  .g-font-size-55--md {
    font-size: 3.9285714286rem !important;
  }

  .g-font-size-56--md {
    font-size: 4rem !important;
  }

  .g-font-size-60--md {
    font-size: 4.2857142857rem !important;
  }

  .g-font-size-65--md {
    font-size: 4.6428571429rem !important;
  }

  .g-font-size-70--md {
    font-size: 5rem !important;
  }

  .g-font-size-75--md {
    font-size: 5.3571428571rem !important;
  }

  .g-font-size-76--md {
    font-size: 5.4285714286rem !important;
  }

  .g-font-size-90--md {
    font-size: 6.4285714286rem !important;
  }

  .g-font-size-130--md {
    font-size: 9.2857142857rem !important;
  }
}

@media (min-width: 992px) {
  .g-font-size-default--lg {
    font-size: 1rem !important;
  }

  .g-font-size-16--lg {
    font-size: 1.1428571429rem !important;
  }

  .g-font-size-18--lg {
    font-size: 1.2857142857rem !important;
  }

  .g-font-size-26--lg {
    font-size: 1.8571428571rem !important;
  }

  .g-font-size-32--lg {
    font-size: 2.2857142857rem !important;
  }

  .g-font-size-35--lg {
    font-size: 2.5rem !important;
  }

  .g-font-size-36--lg {
    font-size: 2.5714285714rem !important;
  }

  .g-font-size-42--lg {
    font-size: 3rem !important;
  }

  .g-font-size-60--lg {
    font-size: 4.2857142857rem !important;
  }

  .g-font-size-75--lg {
    font-size: 5.3571428571rem !important;
  }

  .g-font-size-76--lg {
    font-size: 5.4285714286rem !important;
  }

  .g-font-size-420--lg {
    font-size: 30rem !important;
  }
}

@media (min-width: 1200px) {
  .g-font-size-17--xl {
    font-size: 1.2142857143rem !important;
  }
}

@media (max-width: 576px-1) {
  .g-font-size-25 {
    font-size: 1.7857142857rem !important;
  }
}

/*------------------------------------
  Typography Font Weight
------------------------------------*/

.g-font-weight-100 {
  font-weight: 100 !important;
}

.g-font-weight-200 {
  font-weight: 200 !important;
}

.g-font-weight-300 {
  font-weight: 300 !important;
}

.g-font-weight-400 {
  font-weight: 400 !important;
}

.g-font-weight-500 {
  font-weight: 500 !important;
}

.g-font-weight-600 {
  font-weight: 600 !important;
}

.g-font-weight-700 {
  font-weight: 700 !important;
}

.g-font-weight-800 {
  font-weight: 800 !important;
}

.g-font-weight-900 {
  font-weight: 900 !important;
}

@media (min-width: 768px) {
  .g-font-weight-100--md {
    font-weight: 100 !important;
  }

  .g-font-weight-200--md {
    font-weight: 200 !important;
  }

  .g-font-weight-300--md {
    font-weight: 300 !important;
  }

  .g-font-weight-400--md {
    font-weight: 400 !important;
  }

  .g-font-weight-500--md {
    font-weight: 500 !important;
  }

  .g-font-weight-600--md {
    font-weight: 600 !important;
  }

  .g-font-weight-700--md {
    font-weight: 700 !important;
  }

  .g-font-weight-800--md {
    font-weight: 800 !important;
  }

  .g-font-weight-900--md {
    font-weight: 900 !important;
  }
}

/*------------------------------------
  Typography Text Transform
------------------------------------*/

.g-text-transform-none {
  text-transform: none !important;
}

/*------------------------------------
  Typography Text Decoration
------------------------------------*/

.g-text-underline {
  text-decoration: underline;
}

.g-text-underline--none--hover:focus,
.g-text-underline--none--hover:hover {
  text-decoration: none;
}

.g-text-strike {
  text-decoration: line-through;
}

/*------------------------------------
  Typography Letter Spacing
------------------------------------*/

.g-letter-spacing-minus-2 {
  letter-spacing: -0.1428571429rem;
}

.g-letter-spacing-0_5 {
  letter-spacing: 0.0357142857rem;
}

.g-letter-spacing-1_5 {
  letter-spacing: 0.1071428571rem;
}

.g-letter-spacing-0 {
  letter-spacing: 0px;
}

.g-letter-spacing-1 {
  letter-spacing: 0.0714285714rem;
}

.g-letter-spacing-2 {
  letter-spacing: 0.1428571429rem;
}

.g-letter-spacing-3 {
  letter-spacing: 0.2142857143rem;
}

.g-letter-spacing-4 {
  letter-spacing: 0.2857142857rem;
}

.g-letter-spacing-5 {
  letter-spacing: 0.3571428571rem;
}

.g-letter-spacing-6 {
  letter-spacing: 0.4285714286rem;
}

.g-letter-spacing-7 {
  letter-spacing: 0.5rem;
}

.g-letter-spacing-8 {
  letter-spacing: 0.5714285714rem;
}

.g-letter-spacing-9 {
  letter-spacing: 0.6428571429rem;
}

.g-letter-spacing-10 {
  letter-spacing: 0.7142857143rem;
}

.g-letter-spacing-11 {
  letter-spacing: 0.7857142857rem;
}

.g-letter-spacing-12 {
  letter-spacing: 0.8571428571rem;
}

/*------------------------------------
  Typography Line Height
------------------------------------*/

.g-line-height-0 {
  line-height: 0 !important;
}

.g-line-height-0_7 {
  line-height: 0.7 !important;
}

.g-line-height-0_8 {
  line-height: 0.8 !important;
}

.g-line-height-0_9 {
  line-height: 0.9 !important;
}

.g-line-height-1 {
  line-height: 1 !important;
}

.g-line-height-1_1 {
  line-height: 1.1 !important;
}

.g-line-height-1_2 {
  line-height: 1.2 !important;
}

.g-line-height-1_3 {
  line-height: 1.3 !important;
}

.g-line-height-1_4 {
  line-height: 1.4 !important;
}

.g-line-height-1_5 {
  line-height: 1.5 !important;
}

.g-line-height-1_6 {
  line-height: 1.6 !important;
}

.g-line-height-1_8 {
  line-height: 1.8 !important;
}

.g-line-height-2 {
  line-height: 2 !important;
}

/*------------------------------------
  Typography Font Style
------------------------------------*/

.g-font-style-normal {
  font-style: normal;
}

.g-font-style-italic {
  font-style: italic;
}

/*------------------------------------
  List Style Types
------------------------------------*/

.g-list-style-circle {
  list-style-type: circle;
}

.g-list-style-disc {
  list-style-type: disc;
}

.g-list-style-square {
  list-style-type: square;
}

.g-list-style-lower-roman {
  list-style-type: lower-roman;
}

.g-list-style-upper-roman {
  list-style-type: upper-roman;
}

.g-list-style-lower-latin {
  list-style-type: lower-latin;
}

.g-list-style-upper-latin {
  list-style-type: upper-latin;
}

/*------------------------------------
  Text Types
------------------------------------*/

.g-text-break-word {
  word-wrap: break-word;
}

/*------------------------------------
  Quotes
------------------------------------*/

/* Quote v1 */

.u-quote-v1::before {
  content: "“";
  font-size: 36px;
  line-height: 0.75em;
  text-align: center;
  font-weight: 600;
  display: block;
}

.u-quote-v1::after {
  display: none;
}

/*------------------------------------
  Positions
------------------------------------*/

.g-pos-rel {
  position: relative !important;
}

.g-pos-abs {
  position: absolute !important;
}

.g-pos-stc {
  position: static !important;
}

.g-pos-fix {
  position: fixed !important;
}

@media (min-width: 576px) {
  .g-pos-rel--sm {
    position: relative !important;
  }

  .g-pos-fix--sm {
    position: fixed !important;
  }

  .g-pos-abs--sm {
    position: absolute !important;
  }

  .g-pos-stc--sm {
    position: static !important;
  }
}

@media (min-width: 768px) {
  .g-pos-rel--md {
    position: relative !important;
  }

  .g-pos-fix--md {
    position: fixed !important;
  }

  .g-pos-abs--md {
    position: absolute !important;
  }

  .g-pos-stc--sm {
    position: static !important;
  }
}

@media (min-width: 992px) {
  .g-pos-rel--lg {
    position: relative !important;
  }

  .g-pos-fix--lg {
    position: fixed !important;
  }

  .g-pos-abs--lg {
    position: absolute !important;
  }

  .g-pos-stc--lg {
    position: static !important;
  }
}

/*------------------------------------
  Position Spaces
------------------------------------*/

/* Top */

.g-top-auto {
  /* P */
  top: auto;
}

.g-parent:hover .g-top-auto--parent-hover {
  top: auto;
}

.g-top-0 {
  top: 0 !important;
}

.g-top-0--hover:hover {
  top: 0 !important;
}

.g-parent:hover .g-top-0--parent-hover {
  top: 0 !important;
}

.g-top-1 {
  top: 0.0714285714rem;
}

.g-top-2 {
  top: 0.1428571429rem;
}

.g-top-3 {
  top: 0.2142857143rem;
}

.g-top-5 {
  top: 0.3571428571rem !important;
}

.g-top-7 {
  top: 0.5rem !important;
}

.g-top-10 {
  top: 0.7142857143rem !important;
}

.g-top-15 {
  top: 1.0714285714rem;
}

.g-top-20 {
  top: 1.4285714286rem;
}

.g-top-25 {
  top: 1.7857142857rem;
}

.g-top-30 {
  top: 2.1428571429rem;
}

.g-top-35 {
  top: 2.5rem;
}

.g-top-55 {
  top: 3.9285714286rem;
}

.g-top-65 {
  top: 4.6428571429rem;
}

.g-top-100 {
  top: 7.1428571429rem;
}

.g-top-15x {
  top: 15%;
}

.g-top-20x {
  top: 20%;
}

.g-top-25x {
  top: 25%;
}

.g-top-30x {
  top: 30%;
}

.g-top-35x {
  top: 35%;
}

.g-top-50x {
  top: 50%;
}

.g-top-100x {
  top: 100%;
}

/* Top Minis */

.g-top-minus-1 {
  top: -0.0714285714rem;
}

.g-top-minus-2 {
  top: -0.1428571429rem;
}

.g-top-minus-3 {
  top: -0.2142857143rem !important;
}

.g-top-minus-4 {
  top: -0.2857142857rem;
}

.g-top-minus-5 {
  top: -0.3571428571rem;
}

.g-top-minus-6 {
  top: -0.4285714286rem;
}

.g-top-minus-8 {
  top: -0.5714285714rem;
}

.g-top-minus-10 {
  top: -0.7142857143rem;
}

.g-top-minus-15 {
  top: -1.0714285714rem;
}

.g-top-minus-20 {
  top: -1.4285714286rem;
}

.g-top-minus-30 {
  top: -2.1428571429rem;
}

.g-top-minus-35 {
  top: -2.5rem;
}

.g-top-minus-40 {
  top: -2.8571428571rem;
}

.g-top-minus-70 {
  top: -5rem;
}

.g-top-minus-120 {
  top: -8.5714285714rem;
}

.g-top-minus-25x {
  top: -25%;
}

.g-top-minus-80x {
  top: -80%;
}

/* Left */

.g-left-auto {
  /* P */
  left: auto !important;
}

.g-left-0 {
  left: 0;
}

.g-left-0--hover:hover {
  left: 0;
}

.g-parent:hover .g-left-0--parent-hover {
  left: 0;
}

.g-left-2 {
  left: 0.1428571429rem;
}

.g-left-5 {
  left: 0.3571428571rem;
}

.g-left-10 {
  left: 0.7142857143rem;
}

.g-left-15 {
  left: 1.0714285714rem !important;
}

.g-left-20 {
  left: 1.4285714286rem;
}

.g-left-30 {
  left: 2.1428571429rem;
}

.g-left-40 {
  left: 2.8571428571rem;
  /* O */
}

.g-left-45 {
  left: 3.2142857143rem;
  /* P */
}

.g-left-75 {
  left: 5.3571428571rem;
}

.g-left-110 {
  left: 7.8571428571rem;
}

.g-left-130 {
  left: 9.2857142857rem;
}

.g-left-200 {
  left: 14.2857142857rem;
}

.g-left-15x {
  left: 15%;
}

.g-left-35x {
  left: 35%;
}

.g-left-50x {
  left: 50%;
}

.g-left-100x {
  left: 100%;
}

/* Left Minus */

.g-left-minus-3 {
  left: -0.2142857143rem;
}

.g-left-minus-6 {
  left: -0.4285714286rem;
}

.g-left-minus-7 {
  left: -0.5rem;
}

.g-left-minus-10 {
  left: -0.7142857143rem;
}

.g-left-minus-15 {
  left: -1.0714285714rem;
}

.g-left-minus-20 {
  left: -1.4285714286rem;
}

.g-left-minus-25 {
  left: -1.7857142857rem;
}

.g-left-minus-30 {
  left: -2.1428571429rem;
}

.g-left-minus-40 {
  left: -2.8571428571rem;
}

.g-left-minus-50 {
  left: -3.5714285714rem;
}

.g-parent:hover .g-left-minus-50--parent-hover {
  left: -3.5714285714rem;
}

/* Right */

.g-right-auto {
  /* P */
  right: auto;
}

.g-right-0 {
  right: 0 !important;
}

.g-right-0--hover:hover {
  right: 0 !important;
}

.g-parent:hover .g-right-0--parent-hover {
  right: 0 !important;
}

.g-right-5 {
  right: 0.3571428571rem !important;
}

.g-right-7 {
  right: 0.5rem !important;
}

.g-right-10 {
  right: 0.7142857143rem !important;
}

.g-right-14 {
  right: 1rem !important;
}

.g-right-15 {
  right: 1.0714285714rem !important;
}

.g-right-20 {
  right: 1.4285714286rem;
}

.g-right-30 {
  right: 2.1428571429rem;
}

.g-right-40 {
  right: 2.8571428571rem;
}

.g-right-45 {
  right: 3.2142857143rem;
  /* P */
}

.g-right-55 {
  right: 3.9285714286rem;
  /* O */
}

.g-right-65 {
  right: 4.6428571429rem;
}

.g-right-100 {
  right: 7.1428571429rem;
}

.g-right-110 {
  right: 7.8571428571rem;
}

.g-right-130 {
  right: 9.2857142857rem;
}

.g-right-15x {
  right: 15%;
}

.g-right-35x {
  right: 35%;
}

.g-right-50x {
  right: 50%;
}

.g-right-100x {
  right: 100%;
}

/* Right Minus */

.g-right-minus-3 {
  right: -0.2142857143rem !important;
}

.g-right-minus-6 {
  right: -0.4285714286rem;
}

.g-right-minus-5 {
  right: -0.3571428571rem;
}

.g-right-minus-10 {
  right: -0.7142857143rem;
}

.g-right-minus-13 {
  right: -0.9285714286rem;
}

.g-right-minus-15 {
  right: -1.0714285714rem;
}

.g-right-minus-20 {
  right: -1.4285714286rem;
}

.g-right-minus-25 {
  right: -1.7857142857rem;
}

.g-right-minus-40 {
  right: -2.8571428571rem;
}

.g-right-minus-50 {
  right: -3.5714285714rem;
}

/* Bottom */

.g-bottom-auto {
  /* P */
  bottom: auto;
}

.g-bottom-0 {
  bottom: 0;
}

.g-bottom-0--hover:hover {
  bottom: 0;
}

.g-parent:hover .g-bottom-0--parent-hover {
  bottom: 0;
}

.g-bottom-6 {
  bottom: 0.4285714286rem;
}

.g-bottom-10 {
  bottom: 0.7142857143rem;
}

.g-bottom-20 {
  bottom: 1.4285714286rem;
}

.g-bottom-30 {
  bottom: 2.1428571429rem;
}

.g-bottom-40 {
  bottom: 2.8571428571rem;
}

.g-bottom-50 {
  bottom: 3.5714285714rem;
}

.g-parent:hover .g-bottom-50--parent-hover {
  bottom: 3.5714285714rem;
}

.g-bottom-60 {
  bottom: 4.2857142857rem;
}

.g-bottom-80 {
  bottom: 5.7142857143rem;
}

.g-bottom-minus-20 {
  bottom: -1.4285714286rem;
}

.g-bottom-minus-30 {
  bottom: -2.1428571429rem;
}

.g-bottom-minus-40 {
  bottom: -2.8571428571rem;
}

.g-bottom-minus-70 {
  bottom: -5rem;
}

.g-bottom-minus-35x {
  bottom: -35%;
}

.g-bottom-15x {
  bottom: 15%;
}

.g-bottom-minus-25x {
  bottom: -25%;
}

.g-bottom-100x {
  bottom: 100%;
}

/* Z */

@media (min-width: 576px) {
  .g-top-auto--sm {
    top: auto;
  }

  .g-left-auto--sm {
    left: auto;
  }

  .g-left-minus-20--sm {
    left: -20px;
  }

  .g-right-minus-20--sm {
    right: -20px;
  }
}

/* O */

@media (min-width: 768px) {
  .g-right-0--md {
    right: 0 !important;
  }

  .g-left-0--md {
    left: 0 !important;
  }

  .g-right-minus-15--md {
    right: -1.0714285714rem;
  }

  .g-left-minus-15--md {
    left: -1.0714285714rem;
  }

  .g-top-minus-20--md {
    top: -1.4285714286rem;
  }

  .g-right-minus-25--md {
    right: -1.7857142857rem;
  }

  .g-right-100--md {
    right: 7.1428571429rem;
  }

  .g-bottom-minus-50--md {
    bottom: -3.5714285714rem;
  }

  .g-left-auto--md {
    left: auto !important;
  }

  .g-right-auto--md {
    right: auto !important;
  }

  .g-left-minus-25--md {
    left: -1.7857142857rem;
  }

  .g-left-130--md {
    left: 9.2857142857rem !important;
  }

  .g-left-35x--md {
    left: 35%;
  }

  .g-left-50x--md {
    left: 50%;
  }

  .g-right-130--md {
    right: 9.2857142857rem !important;
  }

  .g-right-35x--md {
    right: 35%;
  }

  .g-right-50x--md {
    right: 50%;
  }
}

@media (min-width: 992px) {
  .g-top-0--lg {
    top: 0 !important;
  }

  .g-right-0--lg {
    right: 0;
  }

  .g-left-minus-35--lg {
    left: -2.5rem;
  }

  .g-left-40--lg {
    left: 2.8571428571rem;
  }

  .g-right-minus-35--lg {
    right: -2.5rem;
  }

  .g-right-40--lg {
    right: 2.8571428571rem;
  }
}

/*------------------------------------
  Block Alignments
------------------------------------*/

/* Absolute (Position, X, Y) */

.g-absolute-centered {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  -webkit-backface-visibility: hidden;
}

.g-absolute-centered--x {
  position: absolute !important;
  left: 50% !important;
  transform: translateX(-50%);
  -webkit-backface-visibility: hidden;
}

@media (min-width: 576px) {
  .g-absolute-centered--x--sm {
    position: absolute !important;
    left: 50% !important;
    transform: translateX(-50%);
    -webkit-backface-visibility: hidden;
  }
}

@media (min-width: 768px) {
  .g-absolute-centered--x--md {
    position: absolute !important;
    left: 50% !important;
    transform: translateX(-50%);
    -webkit-backface-visibility: hidden;
  }
}

.g-absolute-centered--y {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden;
}

@media (min-width: 768px) {
  .g-absolute-centered--y--md {
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%);
    -webkit-backface-visibility: hidden;
  }
}

@media (min-width: 992px) {
  .g-absolute-centered--y--lg {
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%);
    -webkit-backface-visibility: hidden;
  }
}

/* O */

.g-transform-origin--top-left {
  transform-origin: top left;
}

.g-transform-origin--top-right {
  transform-origin: top right;
}

.g-absolute-centered--y--scl-0_6 {
  position: absolute;
  top: 50%;
  transform: scale(0.6) translateY(-50%);
}

/* Relative (Position, X, Y) */

.g-relative-centered {
  position: relative;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  -webkit-backface-visibility: hidden;
}

.g-relative-centered--x {
  position: relative;
  left: 50% !important;
  transform: translateX(-50%);
  -webkit-backface-visibility: hidden;
}

.g-relative-centered--y {
  position: relative;
  top: 50% !important;
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden;
}

/* Flex centered */

.g-flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.g-flex-centered-item {
  flex: 1;
}

.g-flex-centered-item--top {
  align-self: flex-start;
}

.g-flex-centered-item--bottom {
  align-self: flex-end;
}

/* Flex right */

.g-flex-right--xs {
  justify-content: flex-end;
}

/* Flex middle */

.g-flex-middle {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.g-flex-middle-item {
  margin-top: auto;
  margin-bottom: auto;
}

.g-flex-middle-item--top {
  margin-top: 0;
  margin-bottom: 0;
}

.g-flex-middle-item--bottom {
  margin-top: auto;
  margin-bottom: 0;
}

.g-flex-middle-item--fixed {
  flex: none;
  max-width: 50%;
}

@media (min-width: 768px) {
  /* Flex right */

  .g-flex-right--md {
    justify-content: flex-end;
  }

  .g-flex-centered--md {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .g-absolute-centered--y--scl-1--md {
    position: absolute;
    top: 50%;
    transform: scale(1) translateY(-50%);
  }
}

@media (min-width: 576px) {
  .g-absolute-centered--sm {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    -webkit-backface-visibility: hidden;
  }

  .g-absolute-centered--x-sm--reset {
    position: static !important;
    left: auto !important;
    transform: translateX(0) !important;
  }
}

@media (min-width: 768px) {
  .g-absolute-centered--md {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    -webkit-backface-visibility: hidden;
  }
}

@media (min-width: 992px) {
  .g-absolute-centered--x--lg {
    position: absolute !important;
    left: 50% !important;
    transform: translateX(-50%);
    -webkit-backface-visibility: hidden;
  }

  .g-flex-centered--lg {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/*------------------------------------
  Alignments
------------------------------------*/

.g-valign-super {
  vertical-align: super !important;
}

.g-valign-sub {
  vertical-align: sub !important;
}

.g-valign-top {
  vertical-align: top !important;
}

.g-valign-middle {
  vertical-align: middle !important;
}

.g-valign-bottom {
  vertical-align: bottom !important;
}

/*------------------------------------
  Blur
------------------------------------*/

.g-blur-30 {
  filter: blur(30px);
}

.g-blur-30--hover:hover {
  filter: blur(30px);
}

.g-parent:hover .g-blur-30--parent-hover {
  filter: blur(30px);
}

/*------------------------------------
  Box-shadows
------------------------------------*/

.g-box-shadow-none {
  box-shadow: none !important;
}

/*------------------------------------
  Clear
------------------------------------*/

.g-clear {
  clear: both;
}

.g-clear--left {
  clear: left;
}

.g-clear--right {
  clear: right;
}

/*------------------------------------
  Cursors
------------------------------------*/

.g-cursor-pointer {
  cursor: pointer;
}

/*------------------------------------
  Overflows
------------------------------------*/

.g-overflow-hidden {
  overflow: hidden !important;
}

.g-overflow-visible {
  overflow: visible;
}

.g-overflow-x-hidden {
  overflow-x: hidden;
}

.g-overflow-x-auto {
  overflow-x: auto !important;
}

.g-overflow-x-scroll {
  overflow-x: scroll;
}

.g-overflow-y-auto {
  overflow-y: auto;
}

.g-overflow-y-hidden {
  overflow-y: hidden;
}

@media (min-width: 768px) {
  .g-overflow-x-visible--md {
    overflow-x: visible;
  }
}

@media (min-width: 992px) {
  .g-overflow-x-visible--lg {
    overflow-x: visible;
  }
}

/*------------------------------------
  Transitions
------------------------------------*/

/*.g-transition {
  &-0_2 {
    transition: .2s ease-out;
  }
  &-0_3 {
    transition: .3s ease-out;
  }
  &-0_6 {
    transition: .6s ease-out;
    &-ease {
      transition: .6s ease;
    }
  }
}

[class*="g-color-"][class*="--hover"] {
  transition: color .2s ease-out;
}
[class*="g-bg-"][class*="--hover"] {
  transition: background-color .3s ease-out;
}
[class*="g-color-"][class*="--hover"][class*="g-bg-"][class*="--hover"] {
  transition: .3s ease-out;
}*/

[class*=g-transition] {
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
}

[class*=g-transition]::before,
[class*=g-transition]::after {
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
}

svg[class*=g-transition] path,
svg[class*=g-transition] polygon {
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.g-transition-0 {
  transition-duration: 0s;
}

.g-transition-0::before,
.g-transition-0::after {
  transition-duration: 0s;
}

svg.g-transition-0 path,
svg.g-transition-0 polygon {
  transition-duration: 0s;
}

.g-transition-0_2 {
  transition-duration: 0.2s;
}

.g-transition-0_2::before,
.g-transition-0_2::after {
  transition-duration: 0.2s;
}

svg.g-transition-0_2 path,
svg.g-transition-0_2 polygon {
  transition-duration: 0.2s;
}

.g-transition-0_3 {
  transition-duration: 0.3s;
}

.g-transition-0_3::before,
.g-transition-0_3::after {
  transition-duration: 0.3s;
}

svg.g-transition-0_3 path,
svg.g-transition-0_3 polygon {
  transition-duration: 0.3s;
}

.g-transition-0_5 {
  transition-duration: 0.5s;
}

.g-transition-0_5::before,
.g-transition-0_5::after {
  transition-duration: 0.5s;
}

svg.g-transition-0_5 path,
svg.g-transition-0_5 polygon {
  transition-duration: 0.5s;
}

.g-transition-0_6 {
  transition-duration: 0.6s;
}

.g-transition-0_6::before,
.g-transition-0_6::after {
  transition-duration: 0.6s;
}

svg.g-transition-0_6 path,
svg.g-transition-0_6 polygon {
  transition-duration: 0.6s;
}

.g-transition-delay-0_11 {
  transition-delay: 0.11s;
}

.g-transition-delay-0_11::before,
.g-transition-delay-0_11::after {
  transition-delay: 0.11s;
}

svg.g-transition-0_11 path,
svg.g-transition-0_11 polygon {
  transition-duration: 0.11s;
}

.g-transition-delay-0_2 {
  transition-delay: 0.2s;
}

.g-transition-delay-0_2::before,
.g-transition-delay-0_2::after {
  transition-delay: 0.2s;
}

svg.g-transition-delay-0_2 path,
svg.g-transition-delay-0_2 polygon {
  transition-delay: 0.2s;
}

.g-transition-delay-0_45 {
  transition-delay: 0.45s;
}

.g-transition-delay-0_45::before,
.g-transition-delay-0_45::after {
  transition-delay: 0.45s;
}

svg.g-transition-delay-0_45 path,
svg.g-transition-delay-0_45 polygon {
  transition-delay: 0.45s;
}

.g-transition--ease-out {
  transition-timing-function: ease-out;
}

.g-transition--ease-out::before,
.g-transition--ease-out::after {
  transition-timing-function: ease-out;
}

svg.g-transition--ease-out path,
svg.g-transition--ease-out polygon {
  transition-timing-function: ease-out;
}

.g-transition--ease-in {
  transition-timing-function: ease-in;
}

.g-transition--ease-in::before,
.g-transition--ease-in::after {
  transition-timing-function: ease-in;
}

svg.g-transition--ease-in path,
svg.g-transition--ease-in polygon {
  transition-timing-function: ease-in;
}

.g-transition--ease-in-out {
  transition-timing-function: ease-in-out;
}

.g-transition--ease-in-out::before,
.g-transition--ease-in-out::after {
  transition-timing-function: ease-in-out;
}

svg.g-transition--ease-in-out path,
svg.g-transition--ease-in-out polygon {
  transition-timing-function: ease-in-out;
}

.g-transition--linear {
  transition-timing-function: linear;
}

.g-transition--linear::before,
.g-transition--linear::after {
  transition-timing-function: linear;
}

svg.g-transition--linear path,
svg.g-transition--linear polygon {
  transition-timing-function: linear;
}

/*------------------------------------
  Transforms
------------------------------------*/

.g-transform-scale-0_5 {
  transform: scale(0.5);
}

.g-transform-scale-0_5--hover:hover {
  transform: scale(0.5);
}

.g-parent:hover .g-transform-scale-0_5--parent-hover {
  transform: scale(0.5);
}

.g-transform-scale-0_8 {
  transform: scale(0.8);
}

.g-transform-scale-0_8--hover:hover {
  transform: scale(0.8);
}

.g-parent:hover .g-transform-scale-0_8--parent-hover {
  transform: scale(0.8);
}

.g-transform-scale-0_85 {
  transform: scale(0.85);
}

.g-transform-scale-0_85--hover:hover {
  transform: scale(0.85);
}

.g-parent:hover .g-transform-scale-0_85--parent-hover {
  transform: scale(0.85);
}

.g-transform-scale-0_9 {
  transform: scale(0.9);
}

.g-transform-scale-0_9--hover:hover {
  transform: scale(0.9);
}

.g-parent:hover .g-transform-scale-0_9--parent-hover {
  transform: scale(0.9);
}

.g-transform-scale-1 {
  transform: scale(1);
}

.g-transform-scale-1--hover:hover {
  transform: scale(1);
}

.g-parent:hover .g-transform-scale-1--parent-hover {
  transform: scale(1);
}

.g-transform-scale-1_05 {
  transform: scale3d(1.05, 1.05, 1.05);
}

.g-transform-scale-1_05--hover:hover {
  transform: scale3d(1.05, 1.05, 1.05);
  z-index: 2;
}

.g-parent:hover .g-transform-scale-1_05--parent-hover {
  transform: scale3d(1.1, 1.1, 1.1);
}

.g-transform-scale-1_1 {
  transform: scale3d(1.1, 1.1, 1.1);
}

.g-transform-scale-1_1--hover:hover {
  transform: scale3d(1.1, 1.1, 1.1);
  z-index: 2;
}

.g-parent:hover .g-transform-scale-1_1--parent-hover {
  transform: scale3d(1.1, 1.1, 1.1);
}

.g-transform-scale-1_2 {
  transform: scale(1.2);
}

.g-transform-scale-1_2--hover:hover {
  transform: scale(1.2);
}

.g-parent:hover .g-transform-scale-1_2--parent-hover {
  transform: scale(1.2);
}

.g-transform-translate-x-100x {
  transform: translateX(100%);
}

.g-transform-translate-x-100x--hover:hover {
  transform: translateX(100%);
}

.g-parent:hover .g-transform-translate-x-100x--parent-hover {
  transform: translateX(100%);
}

.g-transform-translate-x-minus-100x {
  transform: translateX(-100%);
}

.g-transform-translate-x-minus-100x--hover:hover {
  transform: translateX(-100%);
}

.g-parent:hover .g-transform-translate-x-minus-100x--parent-hover {
  transform: translateX(-100%);
}

.g-transform-translate-y-5 {
  transform: translateY(5px);
}

.g-transform-translate-y-5--hover:hover {
  transform: translateY(5px);
}

.g-parent:hover .g-transform-translate-y-5--parent-hover {
  transform: translateY(5px);
}

.g-transform-translate-y-100x {
  transform: translateY(100%);
}

.g-transform-translate-y-100x--hover:hover {
  transform: translateY(100%);
}

.g-parent:hover .g-transform-translate-y-100x--parent-hover {
  transform: translateY(100%);
}

.g-transform-translate-y-minus-5 {
  transform: translateY(-5px);
}

.g-transform-translate-y-minus-5--hover:hover {
  transform: translateY(-5px);
}

.g-parent:hover .g-transform-translate-y-minus-5--parent-hover {
  transform: translateY(-5px);
}

.g-transform-translate-y-minus-70 {
  transform: translateY(-70px);
}

.g-transform-translate-y-minus-70--hover:hover {
  transform: translateY(-70px);
}

.g-parent:hover .g-transform-translate-y-minus-70--parent-hover {
  transform: translateY(-70px);
}

.g-transform-translate-y-minus-100x {
  transform: translateY(-100%);
}

.g-transform-translate-y-minus-100x--hover:hover {
  transform: translateY(-100%);
}

.g-parent:hover .g-transform-translate-y-minus-100x--parent-hover {
  transform: translateY(-100%);
}

/*------------------------------------
  Opacity
------------------------------------*/

.opacity-0 {
  opacity: 0 !important;
}

.g-opacity-0_3 {
  opacity: 0.3 !important;
}

.g-opacity-0_4 {
  opacity: 0.4 !important;
}

.g-opacity-0_5 {
  opacity: 0.5 !important;
}

.g-opacity-0_6 {
  opacity: 0.6 !important;
}

.g-opacity-0_7 {
  opacity: 0.7 !important;
}

.g-opacity-0_8 {
  opacity: 0.8 !important;
}

.g-opacity-1 {
  opacity: 1 !important;
}

.opacity-0--hover:hover {
  opacity: 0 !important;
}

.g-parent:hover .opacity-0--parent-hover {
  opacity: 0 !important;
}

.g-opacity-0_3--hover:hover {
  opacity: 0.3 !important;
}

.g-parent:hover .g-opacity-0_3--parent-hover {
  opacity: 0.3 !important;
}

.g-opacity-0_5--hover:hover {
  opacity: 0.5 !important;
}

.g-parent:hover .g-opacity-0_5--parent-hover {
  opacity: 0.5 !important;
}

.g-opacity-0_6--hover:hover {
  opacity: 0.6 !important;
}

.g-parent:hover .g-opacity-0_6--parent-hover {
  opacity: 0.6 !important;
}

.g-opacity-0_7--hover:hover {
  opacity: 0.7 !important;
}

.g-parent:hover .g-opacity-0_7--parent-hover {
  opacity: 0.7 !important;
}

.g-opacity-0_8--hover:hover {
  opacity: 0.8 !important;
}

.g-parent:hover .g-opacity-0_8--parent-hover {
  opacity: 0.8 !important;
}

.u-block-hover:hover .g-opacity-1--hover,
.g-opacity-1--hover:hover {
  opacity: 1 !important;
}

.g-parent:hover .g-opacity-1--parent-hover {
  opacity: 1 !important;
}

.selected .g-opacity-1--parents-selected {
  opacity: 1 !important;
}

/*------------------------------------
  Z-Index
------------------------------------*/

.g-z-index-auto {
  z-index: auto;
}

.g-z-index-1 {
  z-index: 1;
}

.g-z-index-2 {
  z-index: 2;
}

.g-z-index-3 {
  z-index: 3;
}

.g-z-index-3--hover:hover {
  z-index: 3;
}

.g-z-index-4 {
  z-index: 4;
}

.g-z-index-4--hover:hover {
  z-index: 4;
}

.g-z-index-99 {
  z-index: 99;
}

.g-z-index-9999 {
  z-index: 9999;
}

.g-z-index-minus-1 {
  z-index: -1;
}

/*------------------------------------
  Resize
------------------------------------*/

.g-resize-none {
  resize: none;
}

/*------------------------------------
  Placeholder
------------------------------------*/

.g-placeholder-white::placeholder {
  opacity: 1;
  color: #fff;
}

.g-placeholder-white::-moz-placeholder {
  opacity: 1;
  color: #fff;
}

.g-placeholder-white::-webkit-input-placeholder {
  opacity: 1;
  color: #fff;
}

.g-placeholder-primary::placeholder {
  color: #fff;
}

.g-placeholder-inherit::placeholder {
  color: inherit;
  opacity: 1;
}

.g-placeholder-inherit::-moz-placeholder {
  color: inherit;
  opacity: 1;
}

.g-placeholder-inherit::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

/*------------------------------------
  Offsets
------------------------------------*/

@media (min-width: 768px) {
  .g-offset-md-1 {
    margin-left: 8.333333%;
  }
}

@media (min-width: 992px) {
  .g-offset-lg-1 {
    margin-left: 8.333333%;
  }

  .g-offset-lg-4 {
    margin-left: 33.333333%;
  }
}

/*------------------------------------
  Colors
------------------------------------*/

/* Basic Colors
------------------------------------*/

/* Inherit Colors */

.g-color-inherit {
  color: inherit !important;
}

/* Main Colors */

.g-color-main {
  color: #555 !important;
}

.g-color-main--hover:hover {
  color: #555 !important;
}

/* Primary Colors */

.g-color-primary {
  color: #22211f !important;
}

.u-block-hover:hover .g-color-primary--hover,
.g-color-primary--hover:hover {
  color: #22211f !important;
}

.g-parent:hover .g-color-primary--parent-hover {
  color: #22211f !important;
}

.g-color-primary-opacity-0_3 {
  color: rgba(34, 33, 31, 0.3) !important;
}

.g-color-primary-opacity-0_4 {
  color: rgba(34, 33, 31, 0.4) !important;
}

.g-color-primary--active.active {
  color: #22211f !important;
}

.active .g-color-primary--parent-active {
  color: #22211f !important;
}

.g-color-primary--before::before,
.g-color-primary--after::after {
  color: #22211f;
}

/* Secondary Colors */

.g-color-secondary {
  color: #e74b3c !important;
}

.u-block-hover:hover .g-color-secondary--hover,
.g-color-secondary--hover:hover {
  color: #e74b3c !important;
}

.g-parent:hover .g-color-secondary--parent-hover {
  color: #e74b3c !important;
}

/* Black Colors */

.g-color-black {
  color: #000 !important;
}

.g-color-black--hover:hover {
  color: #000 !important;
}

.g-parent:hover .g-color-black--parent-hover {
  color: #000 !important;
}

.g-color-black--active.active {
  color: #000 !important;
}

.g-color-black-opacity-0_1 {
  color: rgba(0, 0, 0, 0.1) !important;
}

.g-color-black-opacity-0_3 {
  color: rgba(0, 0, 0, 0.3) !important;
}

.g-color-black-opacity-0_5 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.g-color-black-opacity-0_6 {
  color: rgba(0, 0, 0, 0.6) !important;
}

.g-color-black-opacity-0_7 {
  color: rgba(0, 0, 0, 0.7) !important;
}

.g-color-black-opacity-0_8 {
  color: rgba(0, 0, 0, 0.8) !important;
}

.g-color-black-opacity-0_8--child * {
  color: rgba(0, 0, 0, 0.8) !important;
}

.g-color-black-opacity-0_9 {
  color: rgba(0, 0, 0, 0.9) !important;
}

/* White Colors */

.g-color-white {
  color: #fff !important;
}

.g-color-white--opened-menu:not(.collapsed) {
  color: #fff !important;
}

.u-block-hover:hover .g-color-white--hover,
.g-color-white--hover:hover {
  color: #fff !important;
}

.g-parent:hover .g-color-white--parent-hover {
  color: #fff !important;
}

.g-color-white--active.active {
  color: #fff !important;
}

.active .g-color-white--parent-active {
  color: #fff !important;
}

.g-color-white--child * {
  color: #fff !important;
}

.g-color-white-opacity-0_1 {
  color: rgba(255, 255, 255, 0.1) !important;
}

.g-color-white-opacity-0_2 {
  color: rgba(255, 255, 255, 0.2) !important;
}

.g-color-white-opacity-0_3 {
  color: rgba(255, 255, 255, 0.3) !important;
}

.g-color-white-opacity-0_5,
.g-color-white-opacity-0_5--hover:hover {
  color: rgba(255, 255, 255, 0.5) !important;
}

.g-color-white-opacity-0_6 {
  color: rgba(255, 255, 255, 0.6) !important;
}

.g-parent:hover .g-color-white-opacity-0_6--parent-hover {
  color: rgba(255, 255, 255, 0.6) !important;
}

.g-color-white-opacity-0_7,
.g-color-white-opacity-0_7--hover:hover {
  color: rgba(255, 255, 255, 0.7) !important;
}

.g-color-white-opacity-0_75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

.g-color-white-opacity-0_8 {
  color: rgba(255, 255, 255, 0.8) !important;
}

.g-color-white-opacity-0_8--child * {
  color: rgba(255, 255, 255, 0.8) !important;
}

.g-color-white-opacity-0_9,
.g-color-white-opacity-0_9--hover:hover {
  color: rgba(255, 255, 255, 0.9) !important;
}

.u-block-hover:hover .g-color-white-opacity-0_7--hover {
  color: rgba(255, 255, 255, 0.7) !important;
}

/* Gray Colors */

.g-color-gray-light-v1 {
  color: #bbb !important;
}

.g-color-gray-light-v1--hover:hover {
  color: #bbb !important;
}

.g-color-gray-light-v2 {
  color: #ccc !important;
}

.g-color-gray-light-v2--hover:hover {
  color: #ccc !important;
}

.g-color-gray-light-v3 {
  color: #ddd !important;
}

.g-color-gray-light-v3--hover:hover {
  color: #ddd !important;
}

.g-color-gray-light-v4 {
  color: #eee !important;
}

.g-color-gray-light-v4--hover:hover {
  color: #eee !important;
}

.g-color-gray-light-v4-opacity-0_6 {
  color: rgba(238, 238, 238, 0.6) !important;
}

.g-color-gray-light-v5 {
  color: #f7f7f7 !important;
}

.g-color-gray-light-v5--hover:hover {
  color: #f7f7f7 !important;
}

.g-color-gray-dark-v1 {
  color: #111 !important;
}

.g-color-gray-dark-v1--hover:hover {
  color: #111 !important;
}

.g-color-gray-dark-v2 {
  color: #333 !important;
}

.g-color-gray-dark-v2--hover:hover {
  color: #333 !important;
}

.g-color-gray-dark-v2-opacity-0_75 {
  color: rgba(51, 51, 51, 0.75) !important;
}

.g-color-gray-dark-v3 {
  color: #555 !important;
}

.g-color-gray-dark-v3--hover:hover {
  color: #555 !important;
}

.g-color-gray-dark-v4 {
  color: #777 !important;
}

.g-color-gray-dark-v4--hover:hover {
  color: #777 !important;
}

.g-color-gray-dark-v5 {
  color: #999 !important;
}

.g-color-gray-dark-v5--hover:hover {
  color: #999 !important;
}

.g-parent:hover .g-color-gray-dark-v5--parent-hover {
  color: #999 !important;
}

/* Complementary Colors
------------------------------------*/

/* Color Green */

.g-color-green {
  color: #72c02c !important;
}

.g-color-green--hover:hover {
  color: #72c02c !important;
}

/* Color Blue */

.g-color-blue {
  color: #3398dc !important;
}

.g-color-blue--hover:hover {
  color: #3398dc !important;
}

.g-color-blue-dark-v1 {
  color: #175a88 !important;
}

/* Color Light Blue */

.g-color-lightblue {
  color: #edf2f8 !important;
}

.g-color-lightblue--hover:hover {
  color: #edf2f8 !important;
}

.g-color-lightblue-v1 {
  color: #d6e2ee !important;
}

.g-color-lightblue-v1--hover:hover {
  color: #d6e2ee !important;
}

/* Color Dark Blue */

.g-color-darkblue {
  color: #009 !important;
}

.g-color-darkblue--hover:hover {
  color: #009 !important;
}

/* Color Indigo */

.g-color-indigo {
  color: #4263a3 !important;
}

.g-color-indigo--hover:hover {
  color: #4263a3 !important;
}

/* Color Red */

.g-color-red {
  color: #f00 !important;
}

.g-color-red--hover:hover {
  color: #f00 !important;
}

*:hover > .g-color-red--parent-hover {
  color: #f00 !important;
}

/* Color Light Red */

.g-color-lightred {
  color: #e64b3b !important;
}

.g-color-lightred--hover:hover {
  color: #e64b3b !important;
}

/* Color Dark Red */

.g-color-darkred {
  color: #a10f2b !important;
}

.g-color-darkred--hover:hover {
  color: #a10f2b !important;
}

/* Color Purple */

.g-color-purple {
  color: #9a69cb;
}

.g-color-purple--hover:hover {
  color: #9a69cb !important;
}

.g-color-purple-dark-v1 {
  color: #552c7e !important;
}

/* Color Dark Purple */

.g-color-darkpurple {
  color: #6639b6 !important;
}

.g-color-darkpurple--hover:hover {
  color: #6639b6 !important;
}

/* Color Pink */

.g-color-pink {
  color: #e81c62;
}

.g-color-pink--hover:hover {
  color: #e81c62 !important;
}

.g-color-pink-dark-v1 {
  color: #6f0b2d !important;
}

/* Color Orange */

.g-color-orange {
  color: #e57d20 !important;
}

.g-color-orange--hover:hover {
  color: #e57d20 !important;
}

/* Color Deep Orange */

.g-color-deeporange {
  color: #fe541e !important;
}

.g-color-deeporange--hover:hover {
  color: #fe541e !important;
}

/* Color Yellow */

.g-color-yellow {
  color: #ebc71d !important;
}

.g-color-yellow--hover:hover {
  color: #ebc71d !important;
}

/* Color Aqua */

.g-color-aqua {
  color: #29d6e6;
}

.g-color-aqua--hover:hover {
  color: #29d6e6 !important;
}

.g-color-aqua-dark-v1 {
  color: #11848e !important;
}

/* Color Cyan */

.g-color-cyan {
  color: #00bed6 !important;
}

.g-color-cyan--hover:hover {
  color: #00bed6 !important;
}

/* Color Teal */

.g-color-teal {
  color: #18ba9b !important;
}

.g-color-teal--hover:hover {
  color: #18ba9b !important;
}

/* Color Brown */

.g-color-brown {
  color: #9c8061 !important;
}

.g-color-brown--hover:hover {
  color: #9c8061 !important;
}

/* Color Blue Gray */

.g-color-bluegray {
  color: #585f69 !important;
}

.g-color-bluegray--hover:hover {
  color: #585f69 !important;
}

/*@import "globals/color-socials";*/

/*------------------------------------
  Gradient Colors
------------------------------------*/

/* Cyan Gradient */

.g-color-cyan-gradient-opacity-v1 {
  background-repeat: repeat-x;
  background-image: linear-gradient(160deg, rgba(0, 0, 153, 0.55), rgba(0, 190, 214, 0.6));
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

/* Blue Gradient */

.g-color-blue-gradient-opacity-v1 {
  background-repeat: repeat-x;
  background-image: linear-gradient(-260deg, #8654da, rgba(66, 229, 248, 0.8));
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

/*------------------------------------
  Widths
------------------------------------*/

/* Width in Percentage (%) */

.g-width-30x {
  width: 30% !important;
  /* P */
}

.g-width-40x {
  width: 40% !important;
  /* P */
}

.g-width-50x {
  width: 50% !important;
  /* P */
}

.g-width-60x {
  width: 60% !important;
  /* P */
}

.g-width-70x {
  width: 70% !important;
  /* P */
}

.g-width-80x {
  width: 80% !important;
  /* P */
}

.g-width-90x {
  width: 90% !important;
  /* P */
}

@media (max-width: 446px-1) {
  .w-100--2xs {
    width: 100% !important;
  }
}

@media (min-width: 576px) {
  .g-width-20x--sm {
    width: 20% !important;
  }

  .g-width-25x--sm {
    width: 25% !important;
  }

  .g-width-33_3x--sm {
    width: 33.333333% !important;
  }

  .g-width-40x--sm {
    width: 40% !important;
  }

  .g-width-60x--sm {
    width: 60% !important;
  }

  .g-width-16_6x--sm {
    width: 16.666666% !important;
  }

  .g-width-66_6x--sm {
    width: 66.666666% !important;
  }
}

@media (min-width: 768px) {
  /* P */

  .g-width-20x--md {
    width: 20% !important;
    /* P */
  }

  .g-width-25x--md {
    width: 25% !important;
    /* P */
  }

  .g-width-30x--md {
    width: 30% !important;
    /* P */
  }

  .g-width-35x--md {
    width: 35% !important;
    /* P */
  }

  .g-width-40x--md {
    width: 40% !important;
    /* P */
  }

  .g-width-45x--md {
    width: 45% !important;
    /* P */
  }

  .g-width-50x--md {
    width: 50% !important;
    /* P */
  }

  .g-width-55x--md {
    width: 55% !important;
    /* P */
  }

  .g-width-60x--md {
    width: 60% !important;
    /* P */
  }

  .g-width-65x--md {
    width: 65% !important;
    /* P */
  }

  .g-width-70x--md {
    width: 70% !important;
    /* P */
  }

  .g-width-75x--md {
    width: 75% !important;
    /* P */
  }

  .g-width-80x--md {
    width: 80% !important;
    /* P */
  }

  .g-width-85x--md {
    width: 85% !important;
    /* P */
  }

  .g-width-90x--md {
    width: 90% !important;
    /* P */
  }

  .g-width-33_3x--md {
    /* P */
    width: 33.333333% !important;
  }

  .g-width-16_6x--md {
    /* P */
    width: 16.666666% !important;
  }

  .g-width-66_6x--md {
    /* P */
    width: 66.666666% !important;
  }
}

.g-width-auto {
  width: auto !important;
  /* P */
}

/* Max Width in Percentage (%) */

.g-width-1x {
  max-width: 1%;
}

.g-width-80x {
  max-width: 80%;
}

.g-width-90x {
  max-width: 90%;
}

.g-width-95x {
  width: 95%;
}

.g-max-width-60x {
  max-width: 60%;
}

.g-max-width-100x {
  max-width: 100%;
}

/* Min Width in Percentage (%) */

.g-min-width-100x {
  min-width: 100%;
}

/* Width Viewport Width (vw) */

.g-width-50vw {
  width: 50vw !important;
  /* P */
}

.g-width-100vw {
  width: 100vw !important;
  /* P */
}

/* Width in Pixels (px) */

.g-width-3 {
  width: 3px !important;
  /* O */
}

.g-width-10 {
  width: 10px !important;
  /* O */
}

.g-width-12 {
  width: 12px !important;
  /* Z */
}

.g-width-16 {
  width: 16px !important;
  /* O */
}

.g-width-18 {
  width: 18px !important;
  /* O */
}

.g-width-20 {
  width: 20px !important;
  /* O */
}

.g-width-24 {
  width: 24px !important;
  /* O */
}

.g-width-25 {
  width: 25px !important;
}

.g-width-26 {
  width: 26px !important;
  /* P */
}

.g-width-28 {
  width: 28px !important;
  /* O */
}

.g-width-30 {
  width: 30px !important;
  /* P */
}

.g-width-32 {
  width: 32px !important;
  /* O */
}

.g-width-35 {
  width: 35px !important;
  /* P */
}

.g-width-36 {
  width: 36px !important;
  /* O */
}

.g-width-40 {
  width: 40px !important;
  /* P */
}

.g-width-45 {
  width: 45px !important;
  /* P */
}

.g-width-48 {
  width: 48px !important;
  /* P */
}

.g-width-50 {
  width: 50px !important;
  /* P */
}

.g-width-54 {
  width: 54px !important;
  /* P */
}

.g-width-55 {
  width: 55px !important;
  /* P */
}

.g-width-60 {
  width: 60px !important;
  /* P */
}

.g-width-64 {
  width: 64px !important;
  /* P */
}

.g-width-70 {
  width: 70px !important;
  /* P */
}

.g-width-75 {
  width: 75px !important;
  /* O */
}

.g-width-80 {
  width: 80px !important;
  /* P */
}

.g-width-85 {
  width: 85px !important;
  /* P */
}

.g-width-95 {
  width: 95px !important;
  /* P */
}

.g-width-100 {
  width: 100px !important;
  /* P */
}

.g-width-105 {
  width: 105px;
}

.g-width-110 {
  width: 110px !important;
  /* O */
}

.g-width-115 {
  width: 115px !important;
  /* O */
}

.g-width-120 {
  width: 120px !important;
  /* P */
}

.g-width-125 {
  width: 125px !important;
  /* P */
}

.g-width-130 {
  width: 130px !important;
  /* P */
}

.g-width-135 {
  width: 135px !important;
  /* O */
}

.g-width-140 {
  width: 140px !important;
  /* P */
}

.g-width-150 {
  width: 150px !important;
}

.g-width-160 {
  width: 160px !important;
  /* P */
}

.g-width-170 {
  width: 170px !important;
  /* P */
}

.g-width-180 {
  width: 180px !important;
  /* O */
}

.g-width-200 {
  width: 200px !important;
  /* P */
}

.g-width-220 {
  width: 220px !important;
}

.g-width-215 {
  width: 215px !important;
}

.g-width-235 {
  width: 235px !important;
}

.g-width-250 {
  /* RG-Q */
  width: 250px !important;
  /* P */
  max-width: 100%;
}

.g-width-270 {
  width: 270px !important;
  /* O */
  max-width: 100%;
}

.g-width-300 {
  width: 300px !important;
  /* P */
  max-width: 100%;
}

.g-width-400 {
  width: 400px !important;
  /* O */
  max-width: 100%;
}

.g-width-340 {
  width: 340px !important;
  /* O */
  max-width: 100%;
}

.g-width-360 {
  width: 360px !important;
  /* O */
  max-width: 100%;
}

.g-width-370 {
  width: 370px !important;
  /* P */
  max-width: 100%;
}

.g-width-380 {
  width: 380px !important;
  /* O */
  max-width: 100%;
}

.g-width-410 {
  width: 410px !important;
  /* O */
}

.g-width-460 {
  width: 460px !important;
  /* O */
  max-width: 100%;
}

.g-width-465 {
  width: 465px !important;
  /* O */
  max-width: 100%;
}

.g-width-485 {
  width: 485px !important;
  /* O */
  max-width: 100%;
}

.g-width-560 {
  width: 560px !important;
  /* O */
  max-width: 100%;
}

.g-width-590 {
  width: 590px !important;
  max-width: 100%;
}

.g-width-600 {
  width: 600px !important;
  /* O */
  max-width: 100%;
}

.g-width-630 {
  width: 630px !important;
  /* O */
  max-width: 100%;
}

.g-width-680 {
  width: 680px !important;
  /* O */
  max-width: 100%;
}

.g-width-720 {
  width: 720px !important;
  /* O */
  max-width: 100%;
}

.g-width-760 {
  width: 760px !important;
  max-width: 100%;
}

.g-width-770 {
  width: 770px !important;
  max-width: 100%;
}

.g-width-780 {
  /* RG-Q */
  width: 780px !important;
  /* P */
  max-width: 100%;
}

.g-width-900 {
  width: 900px !important;
  /* P */
  max-width: 100%;
}

.g-width-945 {
  width: 945px !important;
  max-width: 100%;
}

.g-width-1025 {
  width: 1025px !important;
  max-width: 100%;
}

/* Max Width in Pixels (px) */

.g-max-width-100 {
  max-width: 100px;
}

.g-max-width-120 {
  max-width: 120px;
}

.g-max-width-170 {
  max-width: 170px;
  /* O */
}

.g-max-width-190 {
  max-width: 190px;
}

.g-max-width-200 {
  max-width: 200px;
}

.g-max-width-250 {
  max-width: 250px;
}

.g-max-width-300 {
  max-width: 300px;
}

.g-max-width-400 {
  max-width: 400px;
}

.g-max-width-500 {
  max-width: 500px;
}

.g-max-width-540 {
  max-width: 540px;
}

.g-max-width-550 {
  max-width: 550px;
}

.g-max-width-570 {
  max-width: 570px;
}

.g-max-width-600 {
  max-width: 600px;
}

.g-max-width-645 {
  max-width: 645px;
}

.g-max-width-670 {
  max-width: 670px;
}

.g-max-width-700 {
  max-width: 700px;
}

.g-max-width-750 {
  max-width: 750px;
}

.g-max-width-770 {
  max-width: 770px;
}

.g-max-width-780 {
  max-width: 780px;
}

.g-max-width-800 {
  max-width: 800px;
}

.g-max-width-840 {
  max-width: 840px;
}

.g-max-width-870 {
  max-width: 870px;
}

.g-max-width-960 {
  max-width: 960px;
}

/* Min Width in Pixels (px) */

.g-min-width-35 {
  min-width: 35px;
}

.g-min-width-40 {
  min-width: 40px;
}

.g-min-width-65 {
  min-width: 65px;
}

.g-min-width-110 {
  min-width: 110px;
}

.g-min-width-130 {
  min-width: 130px;
}

.g-min-width-150 {
  min-width: 150px;
}

.g-min-width-170 {
  min-width: 170px;
}

.g-min-width-200 {
  min-width: 200px;
}

.g-min-width-220 {
  min-width: 220px;
}

.g-min-width-300 {
  min-width: 300px;
}

.g-min-width-400 {
  min-width: 400px;
}

@media (min-width: 576px) {
  .g-width-50x--sm {
    width: 50% !important;
    /* P */
  }

  .g-width-80x--sm {
    width: 80% !important;
    /* R */
  }

  .g-width-100--sm {
    width: 100px !important;
    /* P */
  }

  .g-width-150--sm {
    width: 150px !important;
    /* O */
  }

  .g-width-170--sm {
    width: 170px !important;
    /* O */
  }

  .g-width-180--sm {
    width: 180px !important;
  }

  .g-min-width-180--sm {
    min-width: 180px;
  }

  .g-width-485--sm {
    width: 485px !important;
    /* O */
    max-width: 100%;
  }

  .g-width-auto--sm {
    width: auto !important;
  }

  .g-max-width-300--sm {
    max-width: 300px;
  }
}

@media (min-width: 768px) {
  .g-width-40--md {
    width: 40px !important;
    /* O */
  }

  .g-width-45--md {
    width: 45px !important;
    /* O */
  }

  .g-width-60--md {
    width: 60px !important;
    /* O */
  }

  .g-width-80--md {
    width: 80px !important;
    /* O */
  }

  .g-width-50x--md {
    width: 50% !important;
    /* P */
  }

  .g-width-60x--md {
    width: 60% !important;
    /* P */
  }

  .g-width-80x--md {
    width: 80% !important;
    /* R */
  }

  .g-width-45--md {
    width: 45px !important;
  }

  .g-width-100--md {
    width: 100px !important;
    /* O */
  }

  .g-width-125--md {
    width: 125px !important;
    /* P */
  }

  .g-width-135--md {
    width: 135px !important;
  }

  .g-width-140--md {
    width: 140px !important;
    /* R */
  }

  .g-width-155--md {
    width: 155px !important;
    /* R */
  }

  .g-width-180--md {
    width: 180px !important;
  }

  .g-width-225--md {
    width: 225px !important;
  }

  .g-width-160--md {
    width: 160px !important;
  }

  .g-width-165--md {
    width: 165px !important;
  }

  .g-width-170--md {
    width: 170px !important;
    /* P */
  }

  .g-width-185--md {
    width: 185px !important;
    /* O */
  }

  .g-width-280--md {
    width: 280px !important;
    /* O */
  }

  .g-width-780--md {
    width: 780px !important;
    /* O */
    max-width: 100%;
  }

  .g-max-width-200--md {
    max-width: 200px;
  }

  .g-max-width-400--md {
    max-width: 400px;
  }

  .g-min-width-110--md {
    min-width: 110px;
  }

  .g-width-auto--md {
    width: auto !important;
    /* P */
  }
}

@media (min-width: 992px) {
  .g-width-60x--lg {
    width: 60% !important;
    /* J */
  }

  .g-width-100x--lg {
    width: 100% !important;
    /* P */
  }

  .g-width-155--lg {
    width: 155px !important;
    /* O */
  }

  .g-width-165--lg {
    width: 165px !important;
  }

  .g-width-200--lg {
    width: 200px !important;
    /* O */
    max-width: 100%;
  }

  .g-width-250--lg {
    /* RG-Q */
    width: 250px !important;
    /* P */
    max-width: 100%;
  }

  .g-width-485--lg {
    width: 485px !important;
    /* O */
    max-width: 100%;
  }

  .g-width-auto--lg {
    width: auto !important;
    /* P */
  }

  .g-max-width-200--lg {
    max-width: 200px;
  }
}

@media (min-width: 1200px) {
  .g-min-width-315--xl {
    min-width: 315px;
  }
}

* > .g-width-70--hover {
  transition-property: width;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}

*:hover > .g-width-70--hover {
  width: 70px;
}

/*------------------------------------
  Heights
------------------------------------*/

/* Height in Percentage (%) */

.g-height-50x {
  height: 50%;
}

.g-height-80x {
  height: 80%;
}

.g-height-90x {
  height: 90%;
}

.g-height-95x {
  height: 95%;
}

.g-height-100x {
  height: 100%;
}

/* Max Height in Percentage (%) */

.g-max-height-100x {
  max-height: 100%;
}

/* Max Height in Viewport Height (vh) */

.g-max-height-70vh {
  max-height: 70vh !important;
}

.g-max-height-90vh {
  height: 90vh !important;
}

/* Min Height in Percentage (%) */

.g-min-height-100x {
  min-height: 100%;
}

/* Height  in Viewport Height (vh) */

.g-height-50vh {
  height: 50vh !important;
}

.g-height-70vh {
  height: 70vh;
}

.g-height-75vh {
  height: 75vh;
}

.g-height-100vh {
  height: 100vh !important;
}

/* Min Height in Viewport Height (vh) */

.g-min-height-50vh {
  min-height: 50vh;
}

.g-min-height-60vh {
  min-height: 60vh;
}

.g-min-height-70vh {
  min-height: 70vh;
}

.g-min-height-80vh {
  min-height: 80vh;
}

.g-min-height-90vh {
  min-height: 90vh;
}

.g-min-height-100vh {
  min-height: 100vh;
}

/* Auto Height */

.g-height-auto {
  height: auto;
}

@media (min-width: 576px) {
  .g-height-100vh--sm {
    height: 100vh !important;
  }

  .g-height-auto--sm {
    height: auto;
  }

  .g-height-100x--sm {
    height: 100%;
  }
}

@media (min-width: 768px) {
  .g-height-auto--md {
    height: auto;
  }

  .g-min-height-100vh--md {
    min-height: 100vh;
  }
}

@media (min-width: 992px) {
  .g-min-height-90vh--lg {
    min-height: 90vh;
  }

  .g-height-100vh--lg {
    height: 100vh !important;
  }

  .g-height-auto--lg {
    height: auto;
  }
}

@media (min-width: 1200px) {
  .g-height-auto--xl {
    height: auto;
  }
}

/* Height in Pixels (px) */

.g-height-1 {
  height: 1px;
}

.g-height-2 {
  height: 2px;
}

.g-height-4 {
  height: 4px;
}

.g-height-5 {
  height: 5px !important;
}

.g-height-6 {
  height: 6px !important;
}

.g-height-10 {
  height: 10px !important;
}

.g-height-12 {
  height: 12px !important;
}

.g-height-16 {
  height: 16px !important;
}

.g-height-18 {
  height: 18px;
}

.g-height-20 {
  height: 20px !important;
}

.g-height-22 {
  height: 22px !important;
}

.g-height-24 {
  height: 24px;
}

.g-height-25 {
  height: 25px;
}

.g-height-26 {
  height: 26px;
}

.g-height-28 {
  height: 28px !important;
  /* O */
}

.g-height-30 {
  height: 30px !important;
}

.g-height-32 {
  height: 32px;
  /* O */
}

.g-height-35 {
  height: 35px !important;
}

.g-height-40 {
  height: 40px;
}

.g-height-45 {
  height: 45px;
}

.g-height-48 {
  height: 48px;
  /* O */
}

.g-height-50 {
  height: 50px;
}

.g-height-55 {
  height: 55px;
}

.g-height-60 {
  height: 60px;
}

.g-height-64 {
  height: 64px;
}

.g-height-70 {
  height: 70px;
}

.g-height-75 {
  height: 75px;
  /* O */
}

.g-height-80 {
  height: 80px !important;
}

.g-height-85 {
  height: 85px;
}

.g-height-88 {
  height: 88px;
}

.g-height-90 {
  height: 90px;
}

.g-height-95 {
  height: 95px !important;
}

.g-height-100 {
  height: 100px !important;
}

.g-height-105 {
  height: 105px;
}

.g-height-115 {
  height: 115px !important;
  /* O */
}

.g-height-120 {
  height: 120px;
}

.g-height-125 {
  height: 125px;
}

.g-height-130 {
  height: 130px;
}

.g-height-140 {
  height: 140px;
}

.g-height-150 {
  height: 150px;
}

.g-height-160 {
  height: 160px;
}

.g-height-170 {
  height: 170px;
  /* O */
}

.g-height-180 {
  height: 180px !important;
  /* O */
}

.g-height-200 {
  height: 200px;
}

.g-height-220 {
  height: 220px;
}

.g-height-250 {
  height: 250px;
}

.g-height-280 {
  height: 280px;
}

.g-height-300 {
  height: 300px;
}

.g-height-350 {
  height: 350px;
}

.g-height-400 {
  height: 400px;
}

.g-height-450 {
  height: 450px;
}

.g-height-460 {
  height: 460px;
  /* O */
  max-height: 100% !important;
}

.g-height-500 {
  height: 500px;
}

.g-height-600 {
  height: 600px;
}

.g-height-680 {
  height: 680px;
}

/* Min Height in Pixels (px) */

.g-min-height-4 {
  min-height: 4px;
}

.g-min-height-35 {
  min-height: 35px;
}

.g-min-height-170 {
  min-height: 170px;
}

.g-min-height-200 {
  min-height: 200px;
}

.g-min-height-250 {
  min-height: 250px;
}

.g-min-height-275 {
  min-height: 275px;
}

.g-min-height-300 {
  min-height: 300px;
}

.g-min-height-312 {
  min-height: 312px;
}

.g-min-height-360 {
  min-height: 360px;
}

.g-min-height-400 {
  min-height: 400px;
}

.g-min-height-450 {
  min-height: 450px;
}

.g-min-height-500 {
  min-height: 500px;
}

.g-min-height-600 {
  min-height: 600px;
}

@media (min-width: 576px) {
  .g-height-350--sm {
    height: 350px;
  }

  .g-height-680--sm {
    height: 680px;
  }
}

@media (min-width: 768px) {
  .g-height-40--md {
    height: 40px !important;
  }

  .g-height-45--md {
    height: 45px !important;
  }

  .g-height-60--md {
    height: 60px !important;
  }

  .g-height-100--md {
    height: 100px !important;
  }

  .g-height-170--md {
    height: 170px !important;
  }

  .g-height-350--md {
    height: 350px;
  }

  .g-height-500--md {
    height: 500px;
  }

  .g-height-680--md {
    height: 680px;
  }

  .g-height-100x--md {
    height: 100%;
  }

  .g-height-100vh--md {
    height: 100vh;
  }

  .g-min-height-230--md {
    min-height: 230px;
  }

  .g-min-height-500--md {
    min-height: 500px;
  }
}

@media (min-width: 992px) {
  .g-height-80--lg {
    height: 80px !important;
  }

  .g-height-200--lg {
    height: 200px !important;
  }

  .g-height-230--lg {
    height: 230px;
  }

  .g-height-350--lg {
    height: 350px;
  }

  .g-height-680--lg {
    height: 680px;
  }
}

@media (min-width: 1200px) {
  .g-height-350--xl {
    height: 350px;
  }

  .g-height-680--xl {
    height: 680px;
  }
}

/*------------------------------------
  Margin Spaces
------------------------------------*/

/* Margin Spaces (xs)
------------------------------------*/

@media (min-width: 0) {
  .g-ma-0 {
    margin: 0 !important;
  }

  .g-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .g-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .g-ml-0 {
    margin-left: 0 !important;
  }

  .g-mr-0 {
    margin-right: 0 !important;
  }

  .g-mt-0 {
    margin-top: 0 !important;
  }

  .g-mb-0 {
    margin-bottom: 0 !important;
  }

  .g-mx-1 {
    margin-left: 0.0714285714rem !important;
    margin-right: 0.0714285714rem !important;
  }

  .g-mx-2 {
    margin-left: 0.1428571429rem !important;
    margin-right: 0.1428571429rem !important;
  }

  .g-mx-3 {
    margin-left: 0.2142857143rem !important;
    margin-right: 0.2142857143rem !important;
  }

  .g-mx-4 {
    margin-left: 0.2857142857rem !important;
    margin-right: 0.2857142857rem !important;
  }

  .g-mx-5 {
    margin-left: 0.3571428571rem !important;
    margin-right: 0.3571428571rem !important;
  }

  .g-mx-6 {
    margin-left: 0.4285714286rem !important;
    margin-right: 0.4285714286rem !important;
  }

  .g-mx-7 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .g-mx-8 {
    margin-left: 0.5714285714rem !important;
    margin-right: 0.5714285714rem !important;
  }

  .g-mx-9 {
    margin-left: 0.6428571429rem !important;
    margin-right: 0.6428571429rem !important;
  }

  .g-mx-10 {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }

  .g-mx-10 {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }

  .g-mx-15 {
    margin-left: 1.0714285714rem !important;
    margin-right: 1.0714285714rem !important;
  }

  .g-mx-20 {
    margin-left: 1.4285714286rem !important;
    margin-right: 1.4285714286rem !important;
  }

  .g-mx-25 {
    margin-left: 1.7857142857rem !important;
    margin-right: 1.7857142857rem !important;
  }

  .g-mx-30 {
    margin-left: 2.1428571429rem !important;
    margin-right: 2.1428571429rem !important;
  }

  .g-mx-35 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .g-mx-40 {
    margin-left: 2.8571428571rem !important;
    margin-right: 2.8571428571rem !important;
  }

  .g-mx-45 {
    margin-left: 3.2142857143rem !important;
    margin-right: 3.2142857143rem !important;
  }

  .g-mx-50 {
    margin-left: 3.5714285714rem !important;
    margin-right: 3.5714285714rem !important;
  }

  .g-mx-55 {
    margin-left: 3.9285714286rem !important;
    margin-right: 3.9285714286rem !important;
  }

  .g-mx-60 {
    margin-left: 4.2857142857rem !important;
    margin-right: 4.2857142857rem !important;
  }

  .g-mx-65 {
    margin-left: 4.6428571429rem !important;
    margin-right: 4.6428571429rem !important;
  }

  .g-mx-70 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .g-mx-75 {
    margin-left: 5.3571428571rem !important;
    margin-right: 5.3571428571rem !important;
  }

  .g-mx-80 {
    margin-left: 5.7142857143rem !important;
    margin-right: 5.7142857143rem !important;
  }

  .g-mx-85 {
    margin-left: 6.0714285714rem !important;
    margin-right: 6.0714285714rem !important;
  }

  .g-mx-90 {
    margin-left: 6.4285714286rem !important;
    margin-right: 6.4285714286rem !important;
  }

  .g-mx-95 {
    margin-left: 6.7857142857rem !important;
    margin-right: 6.7857142857rem !important;
  }

  .g-mx-100 {
    margin-left: 7.1428571429rem !important;
    margin-right: 7.1428571429rem !important;
  }

  .g-my-1 {
    margin-top: 0.0714285714rem !important;
    margin-bottom: 0.0714285714rem !important;
  }

  .g-my-2 {
    margin-top: 0.1428571429rem !important;
    margin-bottom: 0.1428571429rem !important;
  }

  .g-my-3 {
    margin-top: 0.2142857143rem !important;
    margin-bottom: 0.2142857143rem !important;
  }

  .g-my-4 {
    margin-top: 0.2857142857rem !important;
    margin-bottom: 0.2857142857rem !important;
  }

  .g-my-5 {
    margin-top: 0.3571428571rem !important;
    margin-bottom: 0.3571428571rem !important;
  }

  .g-my-6 {
    margin-top: 0.4285714286rem !important;
    margin-bottom: 0.4285714286rem !important;
  }

  .g-my-7 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .g-my-8 {
    margin-top: 0.5714285714rem !important;
    margin-bottom: 0.5714285714rem !important;
  }

  .g-my-9 {
    margin-top: 0.6428571429rem !important;
    margin-bottom: 0.6428571429rem !important;
  }

  .g-my-10 {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }

  .g-my-10 {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }

  .g-my-15 {
    margin-top: 1.0714285714rem !important;
    margin-bottom: 1.0714285714rem !important;
  }

  .g-my-20 {
    margin-top: 1.4285714286rem !important;
    margin-bottom: 1.4285714286rem !important;
  }

  .g-my-25 {
    margin-top: 1.7857142857rem !important;
    margin-bottom: 1.7857142857rem !important;
  }

  .g-my-30 {
    margin-top: 2.1428571429rem !important;
    margin-bottom: 2.1428571429rem !important;
  }

  .g-my-35 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .g-my-40 {
    margin-top: 2.8571428571rem !important;
    margin-bottom: 2.8571428571rem !important;
  }

  .g-my-45 {
    margin-top: 3.2142857143rem !important;
    margin-bottom: 3.2142857143rem !important;
  }

  .g-my-50 {
    margin-top: 3.5714285714rem !important;
    margin-bottom: 3.5714285714rem !important;
  }

  .g-my-55 {
    margin-top: 3.9285714286rem !important;
    margin-bottom: 3.9285714286rem !important;
  }

  .g-my-60 {
    margin-top: 4.2857142857rem !important;
    margin-bottom: 4.2857142857rem !important;
  }

  .g-my-65 {
    margin-top: 4.6428571429rem !important;
    margin-bottom: 4.6428571429rem !important;
  }

  .g-my-70 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .g-my-75 {
    margin-top: 5.3571428571rem !important;
    margin-bottom: 5.3571428571rem !important;
  }

  .g-my-80 {
    margin-top: 5.7142857143rem !important;
    margin-bottom: 5.7142857143rem !important;
  }

  .g-my-85 {
    margin-top: 6.0714285714rem !important;
    margin-bottom: 6.0714285714rem !important;
  }

  .g-my-90 {
    margin-top: 6.4285714286rem !important;
    margin-bottom: 6.4285714286rem !important;
  }

  .g-my-95 {
    margin-top: 6.7857142857rem !important;
    margin-bottom: 6.7857142857rem !important;
  }

  .g-my-100 {
    margin-top: 7.1428571429rem !important;
    margin-bottom: 7.1428571429rem !important;
  }

  .g-mt-1 {
    margin-top: 0.0714285714rem !important;
  }

  .g-mt-minus-1 {
    margin-top: -0.0714285714rem !important;
  }

  .g-mt-2 {
    margin-top: 0.1428571429rem !important;
  }

  .g-mt-minus-2 {
    margin-top: -0.1428571429rem !important;
  }

  .g-mt-3 {
    margin-top: 0.2142857143rem !important;
  }

  .g-mt-minus-3 {
    margin-top: -0.2142857143rem !important;
  }

  .g-mt-4 {
    margin-top: 0.2857142857rem !important;
  }

  .g-mt-minus-4 {
    margin-top: -0.2857142857rem !important;
  }

  .g-mt-5 {
    margin-top: 0.3571428571rem !important;
  }

  .g-mt-minus-5 {
    margin-top: -0.3571428571rem !important;
  }

  .g-mt-6 {
    margin-top: 0.4285714286rem !important;
  }

  .g-mt-minus-6 {
    margin-top: -0.4285714286rem !important;
  }

  .g-mt-7 {
    margin-top: 0.5rem !important;
  }

  .g-mt-minus-7 {
    margin-top: -0.5rem !important;
  }

  .g-mt-8 {
    margin-top: 0.5714285714rem !important;
  }

  .g-mt-minus-8 {
    margin-top: -0.5714285714rem !important;
  }

  .g-mt-9 {
    margin-top: 0.6428571429rem !important;
  }

  .g-mt-minus-9 {
    margin-top: -0.6428571429rem !important;
  }

  .g-mt-10 {
    margin-top: 0.7142857143rem !important;
  }

  .g-mt-minus-10 {
    margin-top: -0.7142857143rem !important;
  }

  .g-mt-11 {
    margin-top: 0.7857142857rem !important;
  }

  .g-mt-minus-11 {
    margin-top: -0.7857142857rem !important;
  }

  .g-mt-12 {
    margin-top: 0.8571428571rem !important;
  }

  .g-mt-minus-12 {
    margin-top: -0.8571428571rem !important;
  }

  .g-mt-13 {
    margin-top: 0.9285714286rem !important;
  }

  .g-mt-minus-13 {
    margin-top: -0.9285714286rem !important;
  }

  .g-mt-14 {
    margin-top: 1rem !important;
  }

  .g-mt-minus-14 {
    margin-top: -1rem !important;
  }

  .g-mt-15 {
    margin-top: 1.0714285714rem !important;
  }

  .g-mt-minus-15 {
    margin-top: -1.0714285714rem !important;
  }

  .g-mt-16 {
    margin-top: 1.1428571429rem !important;
  }

  .g-mt-minus-16 {
    margin-top: -1.1428571429rem !important;
  }

  .g-mt-17 {
    margin-top: 1.2142857143rem !important;
  }

  .g-mt-minus-17 {
    margin-top: -1.2142857143rem !important;
  }

  .g-mt-18 {
    margin-top: 1.2857142857rem !important;
  }

  .g-mt-minus-18 {
    margin-top: -1.2857142857rem !important;
  }

  .g-mt-19 {
    margin-top: 1.3571428571rem !important;
  }

  .g-mt-minus-19 {
    margin-top: -1.3571428571rem !important;
  }

  .g-mt-20 {
    margin-top: 1.4285714286rem !important;
  }

  .g-mt-minus-20 {
    margin-top: -1.4285714286rem !important;
  }

  .g-mt-10 {
    margin-top: 0.7142857143rem !important;
  }

  .g-mt-minus-10 {
    margin-top: -0.7142857143rem !important;
  }

  .g-mt-15 {
    margin-top: 1.0714285714rem !important;
  }

  .g-mt-minus-15 {
    margin-top: -1.0714285714rem !important;
  }

  .g-mt-20 {
    margin-top: 1.4285714286rem !important;
  }

  .g-mt-minus-20 {
    margin-top: -1.4285714286rem !important;
  }

  .g-mt-25 {
    margin-top: 1.7857142857rem !important;
  }

  .g-mt-minus-25 {
    margin-top: -1.7857142857rem !important;
  }

  .g-mt-30 {
    margin-top: 2.1428571429rem !important;
  }

  .g-mt-minus-30 {
    margin-top: -2.1428571429rem !important;
  }

  .g-mt-35 {
    margin-top: 2.5rem !important;
  }

  .g-mt-minus-35 {
    margin-top: -2.5rem !important;
  }

  .g-mt-40 {
    margin-top: 2.8571428571rem !important;
  }

  .g-mt-minus-40 {
    margin-top: -2.8571428571rem !important;
  }

  .g-mt-45 {
    margin-top: 3.2142857143rem !important;
  }

  .g-mt-minus-45 {
    margin-top: -3.2142857143rem !important;
  }

  .g-mt-50 {
    margin-top: 3.5714285714rem !important;
  }

  .g-mt-minus-50 {
    margin-top: -3.5714285714rem !important;
  }

  .g-mt-55 {
    margin-top: 3.9285714286rem !important;
  }

  .g-mt-minus-55 {
    margin-top: -3.9285714286rem !important;
  }

  .g-mt-60 {
    margin-top: 4.2857142857rem !important;
  }

  .g-mt-minus-60 {
    margin-top: -4.2857142857rem !important;
  }

  .g-mt-65 {
    margin-top: 4.6428571429rem !important;
  }

  .g-mt-minus-65 {
    margin-top: -4.6428571429rem !important;
  }

  .g-mt-70 {
    margin-top: 5rem !important;
  }

  .g-mt-minus-70 {
    margin-top: -5rem !important;
  }

  .g-mt-75 {
    margin-top: 5.3571428571rem !important;
  }

  .g-mt-minus-75 {
    margin-top: -5.3571428571rem !important;
  }

  .g-mt-80 {
    margin-top: 5.7142857143rem !important;
  }

  .g-mt-minus-80 {
    margin-top: -5.7142857143rem !important;
  }

  .g-mt-85 {
    margin-top: 6.0714285714rem !important;
  }

  .g-mt-minus-85 {
    margin-top: -6.0714285714rem !important;
  }

  .g-mt-90 {
    margin-top: 6.4285714286rem !important;
  }

  .g-mt-minus-90 {
    margin-top: -6.4285714286rem !important;
  }

  .g-mt-95 {
    margin-top: 6.7857142857rem !important;
  }

  .g-mt-minus-95 {
    margin-top: -6.7857142857rem !important;
  }

  .g-mt-100 {
    margin-top: 7.1428571429rem !important;
  }

  .g-mt-minus-100 {
    margin-top: -7.1428571429rem !important;
  }

  .g-mt-105 {
    margin-top: 7.5rem !important;
  }

  .g-mt-minus-105 {
    margin-top: -7.5rem !important;
  }

  .g-mt-110 {
    margin-top: 7.8571428571rem !important;
  }

  .g-mt-minus-110 {
    margin-top: -7.8571428571rem !important;
  }

  .g-mt-115 {
    margin-top: 8.2142857143rem !important;
  }

  .g-mt-minus-115 {
    margin-top: -8.2142857143rem !important;
  }

  .g-mt-120 {
    margin-top: 8.5714285714rem !important;
  }

  .g-mt-minus-120 {
    margin-top: -8.5714285714rem !important;
  }

  .g-mt-125 {
    margin-top: 8.9285714286rem !important;
  }

  .g-mt-minus-125 {
    margin-top: -8.9285714286rem !important;
  }

  .g-mt-130 {
    margin-top: 9.2857142857rem !important;
  }

  .g-mt-minus-130 {
    margin-top: -9.2857142857rem !important;
  }

  .g-mt-135 {
    margin-top: 9.6428571429rem !important;
  }

  .g-mt-minus-135 {
    margin-top: -9.6428571429rem !important;
  }

  .g-mt-140 {
    margin-top: 10rem !important;
  }

  .g-mt-minus-140 {
    margin-top: -10rem !important;
  }

  .g-mt-145 {
    margin-top: 10.3571428571rem !important;
  }

  .g-mt-minus-145 {
    margin-top: -10.3571428571rem !important;
  }

  .g-mt-150 {
    margin-top: 10.7142857143rem !important;
  }

  .g-mt-minus-150 {
    margin-top: -10.7142857143rem !important;
  }

  .g-mt-155 {
    margin-top: 11.0714285714rem !important;
  }

  .g-mt-minus-155 {
    margin-top: -11.0714285714rem !important;
  }

  .g-mt-160 {
    margin-top: 11.4285714286rem !important;
  }

  .g-mt-minus-160 {
    margin-top: -11.4285714286rem !important;
  }

  .g-mt-165 {
    margin-top: 11.7857142857rem !important;
  }

  .g-mt-minus-165 {
    margin-top: -11.7857142857rem !important;
  }

  .g-mt-170 {
    margin-top: 12.1428571429rem !important;
  }

  .g-mt-minus-170 {
    margin-top: -12.1428571429rem !important;
  }

  .g-mb-1 {
    margin-bottom: 0.0714285714rem !important;
  }

  .g-mb-minus-1 {
    margin-bottom: -0.0714285714rem !important;
  }

  .g-mb-2 {
    margin-bottom: 0.1428571429rem !important;
  }

  .g-mb-minus-2 {
    margin-bottom: -0.1428571429rem !important;
  }

  .g-mb-3 {
    margin-bottom: 0.2142857143rem !important;
  }

  .g-mb-minus-3 {
    margin-bottom: -0.2142857143rem !important;
  }

  .g-mb-4 {
    margin-bottom: 0.2857142857rem !important;
  }

  .g-mb-minus-4 {
    margin-bottom: -0.2857142857rem !important;
  }

  .g-mb-5 {
    margin-bottom: 0.3571428571rem !important;
  }

  .g-mb-minus-5 {
    margin-bottom: -0.3571428571rem !important;
  }

  .g-mb-6 {
    margin-bottom: 0.4285714286rem !important;
  }

  .g-mb-minus-6 {
    margin-bottom: -0.4285714286rem !important;
  }

  .g-mb-7 {
    margin-bottom: 0.5rem !important;
  }

  .g-mb-minus-7 {
    margin-bottom: -0.5rem !important;
  }

  .g-mb-8 {
    margin-bottom: 0.5714285714rem !important;
  }

  .g-mb-minus-8 {
    margin-bottom: -0.5714285714rem !important;
  }

  .g-mb-9 {
    margin-bottom: 0.6428571429rem !important;
  }

  .g-mb-minus-9 {
    margin-bottom: -0.6428571429rem !important;
  }

  .g-mb-10 {
    margin-bottom: 0.7142857143rem !important;
  }

  .g-mb-minus-10 {
    margin-bottom: -0.7142857143rem !important;
  }

  .g-mb-11 {
    margin-bottom: 0.7857142857rem !important;
  }

  .g-mb-minus-11 {
    margin-bottom: -0.7857142857rem !important;
  }

  .g-mb-12 {
    margin-bottom: 0.8571428571rem !important;
  }

  .g-mb-minus-12 {
    margin-bottom: -0.8571428571rem !important;
  }

  .g-mb-13 {
    margin-bottom: 0.9285714286rem !important;
  }

  .g-mb-minus-13 {
    margin-bottom: -0.9285714286rem !important;
  }

  .g-mb-14 {
    margin-bottom: 1rem !important;
  }

  .g-mb-minus-14 {
    margin-bottom: -1rem !important;
  }

  .g-mb-15 {
    margin-bottom: 1.0714285714rem !important;
  }

  .g-mb-minus-15 {
    margin-bottom: -1.0714285714rem !important;
  }

  .g-mb-16 {
    margin-bottom: 1.1428571429rem !important;
  }

  .g-mb-minus-16 {
    margin-bottom: -1.1428571429rem !important;
  }

  .g-mb-17 {
    margin-bottom: 1.2142857143rem !important;
  }

  .g-mb-minus-17 {
    margin-bottom: -1.2142857143rem !important;
  }

  .g-mb-18 {
    margin-bottom: 1.2857142857rem !important;
  }

  .g-mb-minus-18 {
    margin-bottom: -1.2857142857rem !important;
  }

  .g-mb-19 {
    margin-bottom: 1.3571428571rem !important;
  }

  .g-mb-minus-19 {
    margin-bottom: -1.3571428571rem !important;
  }

  .g-mb-20 {
    margin-bottom: 1.4285714286rem !important;
  }

  .g-mb-minus-20 {
    margin-bottom: -1.4285714286rem !important;
  }

  .g-mb-10 {
    margin-bottom: 0.7142857143rem !important;
  }

  .g-mb-15 {
    margin-bottom: 1.0714285714rem !important;
  }

  .g-mb-20 {
    margin-bottom: 1.4285714286rem !important;
  }

  .g-mb-25 {
    margin-bottom: 1.7857142857rem !important;
  }

  .g-mb-30 {
    margin-bottom: 2.1428571429rem !important;
  }

  .g-mb-35 {
    margin-bottom: 2.5rem !important;
  }

  .g-mb-40 {
    margin-bottom: 2.8571428571rem !important;
  }

  .g-mb-45 {
    margin-bottom: 3.2142857143rem !important;
  }

  .g-mb-50 {
    margin-bottom: 3.5714285714rem !important;
  }

  .g-mb-55 {
    margin-bottom: 3.9285714286rem !important;
  }

  .g-mb-60 {
    margin-bottom: 4.2857142857rem !important;
  }

  .g-mb-65 {
    margin-bottom: 4.6428571429rem !important;
  }

  .g-mb-70 {
    margin-bottom: 5rem !important;
  }

  .g-mb-75 {
    margin-bottom: 5.3571428571rem !important;
  }

  .g-mb-80 {
    margin-bottom: 5.7142857143rem !important;
  }

  .g-mb-85 {
    margin-bottom: 6.0714285714rem !important;
  }

  .g-mb-90 {
    margin-bottom: 6.4285714286rem !important;
  }

  .g-mb-95 {
    margin-bottom: 6.7857142857rem !important;
  }

  .g-mb-100 {
    margin-bottom: 7.1428571429rem !important;
  }

  .g-mb-105 {
    margin-bottom: 7.5rem !important;
  }

  .g-mb-110 {
    margin-bottom: 7.8571428571rem !important;
  }

  .g-mb-115 {
    margin-bottom: 8.2142857143rem !important;
  }

  .g-mb-120 {
    margin-bottom: 8.5714285714rem !important;
  }

  .g-mb-125 {
    margin-bottom: 8.9285714286rem !important;
  }

  .g-mb-130 {
    margin-bottom: 9.2857142857rem !important;
  }

  .g-mb-135 {
    margin-bottom: 9.6428571429rem !important;
  }

  .g-mb-140 {
    margin-bottom: 10rem !important;
  }

  .g-mb-145 {
    margin-bottom: 10.3571428571rem !important;
  }

  .g-mb-150 {
    margin-bottom: 10.7142857143rem !important;
  }

  .g-mb-155 {
    margin-bottom: 11.0714285714rem !important;
  }

  .g-mb-160 {
    margin-bottom: 11.4285714286rem !important;
  }

  .g-mb-165 {
    margin-bottom: 11.7857142857rem !important;
  }

  .g-mb-170 {
    margin-bottom: 12.1428571429rem !important;
  }

  .g-ml-1 {
    margin-left: 0.0714285714rem !important;
  }

  .g-ml-minus-1 {
    margin-left: -0.0714285714rem !important;
  }

  .g-ml-2 {
    margin-left: 0.1428571429rem !important;
  }

  .g-ml-minus-2 {
    margin-left: -0.1428571429rem !important;
  }

  .g-ml-3 {
    margin-left: 0.2142857143rem !important;
  }

  .g-ml-minus-3 {
    margin-left: -0.2142857143rem !important;
  }

  .g-ml-4 {
    margin-left: 0.2857142857rem !important;
  }

  .g-ml-minus-4 {
    margin-left: -0.2857142857rem !important;
  }

  .g-ml-5 {
    margin-left: 0.3571428571rem !important;
  }

  .g-ml-minus-5 {
    margin-left: -0.3571428571rem !important;
  }

  .g-ml-6 {
    margin-left: 0.4285714286rem !important;
  }

  .g-ml-minus-6 {
    margin-left: -0.4285714286rem !important;
  }

  .g-ml-7 {
    margin-left: 0.5rem !important;
  }

  .g-ml-minus-7 {
    margin-left: -0.5rem !important;
  }

  .g-ml-8 {
    margin-left: 0.5714285714rem !important;
  }

  .g-ml-minus-8 {
    margin-left: -0.5714285714rem !important;
  }

  .g-ml-9 {
    margin-left: 0.6428571429rem !important;
  }

  .g-ml-minus-9 {
    margin-left: -0.6428571429rem !important;
  }

  .g-ml-10 {
    margin-left: 0.7142857143rem !important;
  }

  .g-ml-minus-10 {
    margin-left: -0.7142857143rem !important;
  }

  .g-ml-5 {
    margin-left: 0.3571428571rem !important;
  }

  .g-ml-minus-5 {
    margin-left: -0.3571428571rem !important;
  }

  .g-ml-10 {
    margin-left: 0.7142857143rem !important;
  }

  .g-ml-minus-10 {
    margin-left: -0.7142857143rem !important;
  }

  .g-ml-15 {
    margin-left: 1.0714285714rem !important;
  }

  .g-ml-minus-15 {
    margin-left: -1.0714285714rem !important;
  }

  .g-ml-20 {
    margin-left: 1.4285714286rem !important;
  }

  .g-ml-minus-20 {
    margin-left: -1.4285714286rem !important;
  }

  .g-ml-25 {
    margin-left: 1.7857142857rem !important;
  }

  .g-ml-minus-25 {
    margin-left: -1.7857142857rem !important;
  }

  .g-ml-30 {
    margin-left: 2.1428571429rem !important;
  }

  .g-ml-minus-30 {
    margin-left: -2.1428571429rem !important;
  }

  .g-ml-35 {
    margin-left: 2.5rem !important;
  }

  .g-ml-minus-35 {
    margin-left: -2.5rem !important;
  }

  .g-ml-40 {
    margin-left: 2.8571428571rem !important;
  }

  .g-ml-minus-40 {
    margin-left: -2.8571428571rem !important;
  }

  .g-ml-45 {
    margin-left: 3.2142857143rem !important;
  }

  .g-ml-minus-45 {
    margin-left: -3.2142857143rem !important;
  }

  .g-ml-50 {
    margin-left: 3.5714285714rem !important;
  }

  .g-ml-minus-50 {
    margin-left: -3.5714285714rem !important;
  }

  .g-mr-1 {
    margin-right: 0.0714285714rem !important;
  }

  .g-mr-minus-1 {
    margin-right: -0.0714285714rem !important;
  }

  .g-mr-2 {
    margin-right: 0.1428571429rem !important;
  }

  .g-mr-minus-2 {
    margin-right: -0.1428571429rem !important;
  }

  .g-mr-3 {
    margin-right: 0.2142857143rem !important;
  }

  .g-mr-minus-3 {
    margin-right: -0.2142857143rem !important;
  }

  .g-mr-4 {
    margin-right: 0.2857142857rem !important;
  }

  .g-mr-minus-4 {
    margin-right: -0.2857142857rem !important;
  }

  .g-mr-5 {
    margin-right: 0.3571428571rem !important;
  }

  .g-mr-minus-5 {
    margin-right: -0.3571428571rem !important;
  }

  .g-mr-6 {
    margin-right: 0.4285714286rem !important;
  }

  .g-mr-minus-6 {
    margin-right: -0.4285714286rem !important;
  }

  .g-mr-7 {
    margin-right: 0.5rem !important;
  }

  .g-mr-minus-7 {
    margin-right: -0.5rem !important;
  }

  .g-mr-8 {
    margin-right: 0.5714285714rem !important;
  }

  .g-mr-minus-8 {
    margin-right: -0.5714285714rem !important;
  }

  .g-mr-9 {
    margin-right: 0.6428571429rem !important;
  }

  .g-mr-minus-9 {
    margin-right: -0.6428571429rem !important;
  }

  .g-mr-10 {
    margin-right: 0.7142857143rem !important;
  }

  .g-mr-minus-10 {
    margin-right: -0.7142857143rem !important;
  }

  .g-mr-5 {
    margin-right: 0.3571428571rem !important;
  }

  .g-mr-10 {
    margin-right: 0.7142857143rem !important;
  }

  .g-mr-15 {
    margin-right: 1.0714285714rem !important;
  }

  .g-mr-20 {
    margin-right: 1.4285714286rem !important;
  }

  .g-mr-25 {
    margin-right: 1.7857142857rem !important;
  }

  .g-mr-30 {
    margin-right: 2.1428571429rem !important;
  }

  .g-mr-35 {
    margin-right: 2.5rem !important;
  }

  .g-mr-40 {
    margin-right: 2.8571428571rem !important;
  }

  .g-mr-45 {
    margin-right: 3.2142857143rem !important;
  }

  .g-mr-50 {
    margin-right: 3.5714285714rem !important;
  }
}

/* Margin Spaces (sm)
------------------------------------*/

@media (min-width: 576px) {
  .g-ma-0--sm {
    margin: 0 !important;
  }

  .g-mx-0--sm {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .g-my-0--sm {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .g-ml-0--sm {
    margin-left: 0 !important;
  }

  .g-mr-0--sm {
    margin-right: 0 !important;
  }

  .g-mt-0--sm {
    margin-top: 0 !important;
  }

  .g-mb-0--sm {
    margin-bottom: 0 !important;
  }

  .g-mx-1--sm {
    margin-left: 0.0714285714rem !important;
    margin-right: 0.0714285714rem !important;
  }

  .g-mx-2--sm {
    margin-left: 0.1428571429rem !important;
    margin-right: 0.1428571429rem !important;
  }

  .g-mx-3--sm {
    margin-left: 0.2142857143rem !important;
    margin-right: 0.2142857143rem !important;
  }

  .g-mx-4--sm {
    margin-left: 0.2857142857rem !important;
    margin-right: 0.2857142857rem !important;
  }

  .g-mx-5--sm {
    margin-left: 0.3571428571rem !important;
    margin-right: 0.3571428571rem !important;
  }

  .g-mx-6--sm {
    margin-left: 0.4285714286rem !important;
    margin-right: 0.4285714286rem !important;
  }

  .g-mx-7--sm {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .g-mx-8--sm {
    margin-left: 0.5714285714rem !important;
    margin-right: 0.5714285714rem !important;
  }

  .g-mx-9--sm {
    margin-left: 0.6428571429rem !important;
    margin-right: 0.6428571429rem !important;
  }

  .g-mx-10--sm {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }

  .g-mx-10--sm {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }

  .g-mx-15--sm {
    margin-left: 1.0714285714rem !important;
    margin-right: 1.0714285714rem !important;
  }

  .g-mx-20--sm {
    margin-left: 1.4285714286rem !important;
    margin-right: 1.4285714286rem !important;
  }

  .g-mx-25--sm {
    margin-left: 1.7857142857rem !important;
    margin-right: 1.7857142857rem !important;
  }

  .g-mx-30--sm {
    margin-left: 2.1428571429rem !important;
    margin-right: 2.1428571429rem !important;
  }

  .g-mx-35--sm {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .g-mx-40--sm {
    margin-left: 2.8571428571rem !important;
    margin-right: 2.8571428571rem !important;
  }

  .g-mx-45--sm {
    margin-left: 3.2142857143rem !important;
    margin-right: 3.2142857143rem !important;
  }

  .g-mx-50--sm {
    margin-left: 3.5714285714rem !important;
    margin-right: 3.5714285714rem !important;
  }

  .g-mx-55--sm {
    margin-left: 3.9285714286rem !important;
    margin-right: 3.9285714286rem !important;
  }

  .g-mx-60--sm {
    margin-left: 4.2857142857rem !important;
    margin-right: 4.2857142857rem !important;
  }

  .g-mx-65--sm {
    margin-left: 4.6428571429rem !important;
    margin-right: 4.6428571429rem !important;
  }

  .g-mx-70--sm {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .g-mx-75--sm {
    margin-left: 5.3571428571rem !important;
    margin-right: 5.3571428571rem !important;
  }

  .g-mx-80--sm {
    margin-left: 5.7142857143rem !important;
    margin-right: 5.7142857143rem !important;
  }

  .g-mx-85--sm {
    margin-left: 6.0714285714rem !important;
    margin-right: 6.0714285714rem !important;
  }

  .g-mx-90--sm {
    margin-left: 6.4285714286rem !important;
    margin-right: 6.4285714286rem !important;
  }

  .g-mx-95--sm {
    margin-left: 6.7857142857rem !important;
    margin-right: 6.7857142857rem !important;
  }

  .g-mx-100--sm {
    margin-left: 7.1428571429rem !important;
    margin-right: 7.1428571429rem !important;
  }

  .g-my-1--sm {
    margin-top: 0.0714285714rem !important;
    margin-bottom: 0.0714285714rem !important;
  }

  .g-my-2--sm {
    margin-top: 0.1428571429rem !important;
    margin-bottom: 0.1428571429rem !important;
  }

  .g-my-3--sm {
    margin-top: 0.2142857143rem !important;
    margin-bottom: 0.2142857143rem !important;
  }

  .g-my-4--sm {
    margin-top: 0.2857142857rem !important;
    margin-bottom: 0.2857142857rem !important;
  }

  .g-my-5--sm {
    margin-top: 0.3571428571rem !important;
    margin-bottom: 0.3571428571rem !important;
  }

  .g-my-6--sm {
    margin-top: 0.4285714286rem !important;
    margin-bottom: 0.4285714286rem !important;
  }

  .g-my-7--sm {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .g-my-8--sm {
    margin-top: 0.5714285714rem !important;
    margin-bottom: 0.5714285714rem !important;
  }

  .g-my-9--sm {
    margin-top: 0.6428571429rem !important;
    margin-bottom: 0.6428571429rem !important;
  }

  .g-my-10--sm {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }

  .g-my-10--sm {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }

  .g-my-15--sm {
    margin-top: 1.0714285714rem !important;
    margin-bottom: 1.0714285714rem !important;
  }

  .g-my-20--sm {
    margin-top: 1.4285714286rem !important;
    margin-bottom: 1.4285714286rem !important;
  }

  .g-my-25--sm {
    margin-top: 1.7857142857rem !important;
    margin-bottom: 1.7857142857rem !important;
  }

  .g-my-30--sm {
    margin-top: 2.1428571429rem !important;
    margin-bottom: 2.1428571429rem !important;
  }

  .g-my-35--sm {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .g-my-40--sm {
    margin-top: 2.8571428571rem !important;
    margin-bottom: 2.8571428571rem !important;
  }

  .g-my-45--sm {
    margin-top: 3.2142857143rem !important;
    margin-bottom: 3.2142857143rem !important;
  }

  .g-my-50--sm {
    margin-top: 3.5714285714rem !important;
    margin-bottom: 3.5714285714rem !important;
  }

  .g-my-55--sm {
    margin-top: 3.9285714286rem !important;
    margin-bottom: 3.9285714286rem !important;
  }

  .g-my-60--sm {
    margin-top: 4.2857142857rem !important;
    margin-bottom: 4.2857142857rem !important;
  }

  .g-my-65--sm {
    margin-top: 4.6428571429rem !important;
    margin-bottom: 4.6428571429rem !important;
  }

  .g-my-70--sm {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .g-my-75--sm {
    margin-top: 5.3571428571rem !important;
    margin-bottom: 5.3571428571rem !important;
  }

  .g-my-80--sm {
    margin-top: 5.7142857143rem !important;
    margin-bottom: 5.7142857143rem !important;
  }

  .g-my-85--sm {
    margin-top: 6.0714285714rem !important;
    margin-bottom: 6.0714285714rem !important;
  }

  .g-my-90--sm {
    margin-top: 6.4285714286rem !important;
    margin-bottom: 6.4285714286rem !important;
  }

  .g-my-95--sm {
    margin-top: 6.7857142857rem !important;
    margin-bottom: 6.7857142857rem !important;
  }

  .g-my-100--sm {
    margin-top: 7.1428571429rem !important;
    margin-bottom: 7.1428571429rem !important;
  }

  .g-mt-1--sm {
    margin-top: 0.0714285714rem !important;
  }

  .g-mt-minus-1--sm {
    margin-top: -0.0714285714rem !important;
  }

  .g-mt-2--sm {
    margin-top: 0.1428571429rem !important;
  }

  .g-mt-minus-2--sm {
    margin-top: -0.1428571429rem !important;
  }

  .g-mt-3--sm {
    margin-top: 0.2142857143rem !important;
  }

  .g-mt-minus-3--sm {
    margin-top: -0.2142857143rem !important;
  }

  .g-mt-4--sm {
    margin-top: 0.2857142857rem !important;
  }

  .g-mt-minus-4--sm {
    margin-top: -0.2857142857rem !important;
  }

  .g-mt-5--sm {
    margin-top: 0.3571428571rem !important;
  }

  .g-mt-minus-5--sm {
    margin-top: -0.3571428571rem !important;
  }

  .g-mt-6--sm {
    margin-top: 0.4285714286rem !important;
  }

  .g-mt-minus-6--sm {
    margin-top: -0.4285714286rem !important;
  }

  .g-mt-7--sm {
    margin-top: 0.5rem !important;
  }

  .g-mt-minus-7--sm {
    margin-top: -0.5rem !important;
  }

  .g-mt-8--sm {
    margin-top: 0.5714285714rem !important;
  }

  .g-mt-minus-8--sm {
    margin-top: -0.5714285714rem !important;
  }

  .g-mt-9--sm {
    margin-top: 0.6428571429rem !important;
  }

  .g-mt-minus-9--sm {
    margin-top: -0.6428571429rem !important;
  }

  .g-mt-10--sm {
    margin-top: 0.7142857143rem !important;
  }

  .g-mt-minus-10--sm {
    margin-top: -0.7142857143rem !important;
  }

  .g-mt-11--sm {
    margin-top: 0.7857142857rem !important;
  }

  .g-mt-minus-11--sm {
    margin-top: -0.7857142857rem !important;
  }

  .g-mt-12--sm {
    margin-top: 0.8571428571rem !important;
  }

  .g-mt-minus-12--sm {
    margin-top: -0.8571428571rem !important;
  }

  .g-mt-13--sm {
    margin-top: 0.9285714286rem !important;
  }

  .g-mt-minus-13--sm {
    margin-top: -0.9285714286rem !important;
  }

  .g-mt-14--sm {
    margin-top: 1rem !important;
  }

  .g-mt-minus-14--sm {
    margin-top: -1rem !important;
  }

  .g-mt-15--sm {
    margin-top: 1.0714285714rem !important;
  }

  .g-mt-minus-15--sm {
    margin-top: -1.0714285714rem !important;
  }

  .g-mt-16--sm {
    margin-top: 1.1428571429rem !important;
  }

  .g-mt-minus-16--sm {
    margin-top: -1.1428571429rem !important;
  }

  .g-mt-17--sm {
    margin-top: 1.2142857143rem !important;
  }

  .g-mt-minus-17--sm {
    margin-top: -1.2142857143rem !important;
  }

  .g-mt-18--sm {
    margin-top: 1.2857142857rem !important;
  }

  .g-mt-minus-18--sm {
    margin-top: -1.2857142857rem !important;
  }

  .g-mt-19--sm {
    margin-top: 1.3571428571rem !important;
  }

  .g-mt-minus-19--sm {
    margin-top: -1.3571428571rem !important;
  }

  .g-mt-20--sm {
    margin-top: 1.4285714286rem !important;
  }

  .g-mt-minus-20--sm {
    margin-top: -1.4285714286rem !important;
  }

  .g-mt-10--sm {
    margin-top: 0.7142857143rem !important;
  }

  .g-mt-minus-10--sm {
    margin-top: -0.7142857143rem !important;
  }

  .g-mt-15--sm {
    margin-top: 1.0714285714rem !important;
  }

  .g-mt-minus-15--sm {
    margin-top: -1.0714285714rem !important;
  }

  .g-mt-20--sm {
    margin-top: 1.4285714286rem !important;
  }

  .g-mt-minus-20--sm {
    margin-top: -1.4285714286rem !important;
  }

  .g-mt-25--sm {
    margin-top: 1.7857142857rem !important;
  }

  .g-mt-minus-25--sm {
    margin-top: -1.7857142857rem !important;
  }

  .g-mt-30--sm {
    margin-top: 2.1428571429rem !important;
  }

  .g-mt-minus-30--sm {
    margin-top: -2.1428571429rem !important;
  }

  .g-mt-35--sm {
    margin-top: 2.5rem !important;
  }

  .g-mt-minus-35--sm {
    margin-top: -2.5rem !important;
  }

  .g-mt-40--sm {
    margin-top: 2.8571428571rem !important;
  }

  .g-mt-minus-40--sm {
    margin-top: -2.8571428571rem !important;
  }

  .g-mt-45--sm {
    margin-top: 3.2142857143rem !important;
  }

  .g-mt-minus-45--sm {
    margin-top: -3.2142857143rem !important;
  }

  .g-mt-50--sm {
    margin-top: 3.5714285714rem !important;
  }

  .g-mt-minus-50--sm {
    margin-top: -3.5714285714rem !important;
  }

  .g-mt-55--sm {
    margin-top: 3.9285714286rem !important;
  }

  .g-mt-minus-55--sm {
    margin-top: -3.9285714286rem !important;
  }

  .g-mt-60--sm {
    margin-top: 4.2857142857rem !important;
  }

  .g-mt-minus-60--sm {
    margin-top: -4.2857142857rem !important;
  }

  .g-mt-65--sm {
    margin-top: 4.6428571429rem !important;
  }

  .g-mt-minus-65--sm {
    margin-top: -4.6428571429rem !important;
  }

  .g-mt-70--sm {
    margin-top: 5rem !important;
  }

  .g-mt-minus-70--sm {
    margin-top: -5rem !important;
  }

  .g-mt-75--sm {
    margin-top: 5.3571428571rem !important;
  }

  .g-mt-minus-75--sm {
    margin-top: -5.3571428571rem !important;
  }

  .g-mt-80--sm {
    margin-top: 5.7142857143rem !important;
  }

  .g-mt-minus-80--sm {
    margin-top: -5.7142857143rem !important;
  }

  .g-mt-85--sm {
    margin-top: 6.0714285714rem !important;
  }

  .g-mt-minus-85--sm {
    margin-top: -6.0714285714rem !important;
  }

  .g-mt-90--sm {
    margin-top: 6.4285714286rem !important;
  }

  .g-mt-minus-90--sm {
    margin-top: -6.4285714286rem !important;
  }

  .g-mt-95--sm {
    margin-top: 6.7857142857rem !important;
  }

  .g-mt-minus-95--sm {
    margin-top: -6.7857142857rem !important;
  }

  .g-mt-100--sm {
    margin-top: 7.1428571429rem !important;
  }

  .g-mt-minus-100--sm {
    margin-top: -7.1428571429rem !important;
  }

  .g-mt-105--sm {
    margin-top: 7.5rem !important;
  }

  .g-mt-minus-105--sm {
    margin-top: -7.5rem !important;
  }

  .g-mt-110--sm {
    margin-top: 7.8571428571rem !important;
  }

  .g-mt-minus-110--sm {
    margin-top: -7.8571428571rem !important;
  }

  .g-mt-115--sm {
    margin-top: 8.2142857143rem !important;
  }

  .g-mt-minus-115--sm {
    margin-top: -8.2142857143rem !important;
  }

  .g-mt-120--sm {
    margin-top: 8.5714285714rem !important;
  }

  .g-mt-minus-120--sm {
    margin-top: -8.5714285714rem !important;
  }

  .g-mt-125--sm {
    margin-top: 8.9285714286rem !important;
  }

  .g-mt-minus-125--sm {
    margin-top: -8.9285714286rem !important;
  }

  .g-mt-130--sm {
    margin-top: 9.2857142857rem !important;
  }

  .g-mt-minus-130--sm {
    margin-top: -9.2857142857rem !important;
  }

  .g-mt-135--sm {
    margin-top: 9.6428571429rem !important;
  }

  .g-mt-minus-135--sm {
    margin-top: -9.6428571429rem !important;
  }

  .g-mt-140--sm {
    margin-top: 10rem !important;
  }

  .g-mt-minus-140--sm {
    margin-top: -10rem !important;
  }

  .g-mt-145--sm {
    margin-top: 10.3571428571rem !important;
  }

  .g-mt-minus-145--sm {
    margin-top: -10.3571428571rem !important;
  }

  .g-mt-150--sm {
    margin-top: 10.7142857143rem !important;
  }

  .g-mt-minus-150--sm {
    margin-top: -10.7142857143rem !important;
  }

  .g-mt-155--sm {
    margin-top: 11.0714285714rem !important;
  }

  .g-mt-minus-155--sm {
    margin-top: -11.0714285714rem !important;
  }

  .g-mt-160--sm {
    margin-top: 11.4285714286rem !important;
  }

  .g-mt-minus-160--sm {
    margin-top: -11.4285714286rem !important;
  }

  .g-mt-165--sm {
    margin-top: 11.7857142857rem !important;
  }

  .g-mt-minus-165--sm {
    margin-top: -11.7857142857rem !important;
  }

  .g-mt-170--sm {
    margin-top: 12.1428571429rem !important;
  }

  .g-mt-minus-170--sm {
    margin-top: -12.1428571429rem !important;
  }

  .g-mb-1--sm {
    margin-bottom: 0.0714285714rem !important;
  }

  .g-mb-minus-1--sm {
    margin-bottom: -0.0714285714rem !important;
  }

  .g-mb-2--sm {
    margin-bottom: 0.1428571429rem !important;
  }

  .g-mb-minus-2--sm {
    margin-bottom: -0.1428571429rem !important;
  }

  .g-mb-3--sm {
    margin-bottom: 0.2142857143rem !important;
  }

  .g-mb-minus-3--sm {
    margin-bottom: -0.2142857143rem !important;
  }

  .g-mb-4--sm {
    margin-bottom: 0.2857142857rem !important;
  }

  .g-mb-minus-4--sm {
    margin-bottom: -0.2857142857rem !important;
  }

  .g-mb-5--sm {
    margin-bottom: 0.3571428571rem !important;
  }

  .g-mb-minus-5--sm {
    margin-bottom: -0.3571428571rem !important;
  }

  .g-mb-6--sm {
    margin-bottom: 0.4285714286rem !important;
  }

  .g-mb-minus-6--sm {
    margin-bottom: -0.4285714286rem !important;
  }

  .g-mb-7--sm {
    margin-bottom: 0.5rem !important;
  }

  .g-mb-minus-7--sm {
    margin-bottom: -0.5rem !important;
  }

  .g-mb-8--sm {
    margin-bottom: 0.5714285714rem !important;
  }

  .g-mb-minus-8--sm {
    margin-bottom: -0.5714285714rem !important;
  }

  .g-mb-9--sm {
    margin-bottom: 0.6428571429rem !important;
  }

  .g-mb-minus-9--sm {
    margin-bottom: -0.6428571429rem !important;
  }

  .g-mb-10--sm {
    margin-bottom: 0.7142857143rem !important;
  }

  .g-mb-minus-10--sm {
    margin-bottom: -0.7142857143rem !important;
  }

  .g-mb-11--sm {
    margin-bottom: 0.7857142857rem !important;
  }

  .g-mb-minus-11--sm {
    margin-bottom: -0.7857142857rem !important;
  }

  .g-mb-12--sm {
    margin-bottom: 0.8571428571rem !important;
  }

  .g-mb-minus-12--sm {
    margin-bottom: -0.8571428571rem !important;
  }

  .g-mb-13--sm {
    margin-bottom: 0.9285714286rem !important;
  }

  .g-mb-minus-13--sm {
    margin-bottom: -0.9285714286rem !important;
  }

  .g-mb-14--sm {
    margin-bottom: 1rem !important;
  }

  .g-mb-minus-14--sm {
    margin-bottom: -1rem !important;
  }

  .g-mb-15--sm {
    margin-bottom: 1.0714285714rem !important;
  }

  .g-mb-minus-15--sm {
    margin-bottom: -1.0714285714rem !important;
  }

  .g-mb-16--sm {
    margin-bottom: 1.1428571429rem !important;
  }

  .g-mb-minus-16--sm {
    margin-bottom: -1.1428571429rem !important;
  }

  .g-mb-17--sm {
    margin-bottom: 1.2142857143rem !important;
  }

  .g-mb-minus-17--sm {
    margin-bottom: -1.2142857143rem !important;
  }

  .g-mb-18--sm {
    margin-bottom: 1.2857142857rem !important;
  }

  .g-mb-minus-18--sm {
    margin-bottom: -1.2857142857rem !important;
  }

  .g-mb-19--sm {
    margin-bottom: 1.3571428571rem !important;
  }

  .g-mb-minus-19--sm {
    margin-bottom: -1.3571428571rem !important;
  }

  .g-mb-20--sm {
    margin-bottom: 1.4285714286rem !important;
  }

  .g-mb-minus-20--sm {
    margin-bottom: -1.4285714286rem !important;
  }

  .g-mb-10--sm {
    margin-bottom: 0.7142857143rem !important;
  }

  .g-mb-15--sm {
    margin-bottom: 1.0714285714rem !important;
  }

  .g-mb-20--sm {
    margin-bottom: 1.4285714286rem !important;
  }

  .g-mb-25--sm {
    margin-bottom: 1.7857142857rem !important;
  }

  .g-mb-30--sm {
    margin-bottom: 2.1428571429rem !important;
  }

  .g-mb-35--sm {
    margin-bottom: 2.5rem !important;
  }

  .g-mb-40--sm {
    margin-bottom: 2.8571428571rem !important;
  }

  .g-mb-45--sm {
    margin-bottom: 3.2142857143rem !important;
  }

  .g-mb-50--sm {
    margin-bottom: 3.5714285714rem !important;
  }

  .g-mb-55--sm {
    margin-bottom: 3.9285714286rem !important;
  }

  .g-mb-60--sm {
    margin-bottom: 4.2857142857rem !important;
  }

  .g-mb-65--sm {
    margin-bottom: 4.6428571429rem !important;
  }

  .g-mb-70--sm {
    margin-bottom: 5rem !important;
  }

  .g-mb-75--sm {
    margin-bottom: 5.3571428571rem !important;
  }

  .g-mb-80--sm {
    margin-bottom: 5.7142857143rem !important;
  }

  .g-mb-85--sm {
    margin-bottom: 6.0714285714rem !important;
  }

  .g-mb-90--sm {
    margin-bottom: 6.4285714286rem !important;
  }

  .g-mb-95--sm {
    margin-bottom: 6.7857142857rem !important;
  }

  .g-mb-100--sm {
    margin-bottom: 7.1428571429rem !important;
  }

  .g-mb-105--sm {
    margin-bottom: 7.5rem !important;
  }

  .g-mb-110--sm {
    margin-bottom: 7.8571428571rem !important;
  }

  .g-mb-115--sm {
    margin-bottom: 8.2142857143rem !important;
  }

  .g-mb-120--sm {
    margin-bottom: 8.5714285714rem !important;
  }

  .g-mb-125--sm {
    margin-bottom: 8.9285714286rem !important;
  }

  .g-mb-130--sm {
    margin-bottom: 9.2857142857rem !important;
  }

  .g-mb-135--sm {
    margin-bottom: 9.6428571429rem !important;
  }

  .g-mb-140--sm {
    margin-bottom: 10rem !important;
  }

  .g-mb-145--sm {
    margin-bottom: 10.3571428571rem !important;
  }

  .g-mb-150--sm {
    margin-bottom: 10.7142857143rem !important;
  }

  .g-mb-155--sm {
    margin-bottom: 11.0714285714rem !important;
  }

  .g-mb-160--sm {
    margin-bottom: 11.4285714286rem !important;
  }

  .g-mb-165--sm {
    margin-bottom: 11.7857142857rem !important;
  }

  .g-mb-170--sm {
    margin-bottom: 12.1428571429rem !important;
  }

  .g-ml-1--sm {
    margin-left: 0.0714285714rem !important;
  }

  .g-ml-minus-1--sm {
    margin-left: -0.0714285714rem !important;
  }

  .g-ml-2--sm {
    margin-left: 0.1428571429rem !important;
  }

  .g-ml-minus-2--sm {
    margin-left: -0.1428571429rem !important;
  }

  .g-ml-3--sm {
    margin-left: 0.2142857143rem !important;
  }

  .g-ml-minus-3--sm {
    margin-left: -0.2142857143rem !important;
  }

  .g-ml-4--sm {
    margin-left: 0.2857142857rem !important;
  }

  .g-ml-minus-4--sm {
    margin-left: -0.2857142857rem !important;
  }

  .g-ml-5--sm {
    margin-left: 0.3571428571rem !important;
  }

  .g-ml-minus-5--sm {
    margin-left: -0.3571428571rem !important;
  }

  .g-ml-6--sm {
    margin-left: 0.4285714286rem !important;
  }

  .g-ml-minus-6--sm {
    margin-left: -0.4285714286rem !important;
  }

  .g-ml-7--sm {
    margin-left: 0.5rem !important;
  }

  .g-ml-minus-7--sm {
    margin-left: -0.5rem !important;
  }

  .g-ml-8--sm {
    margin-left: 0.5714285714rem !important;
  }

  .g-ml-minus-8--sm {
    margin-left: -0.5714285714rem !important;
  }

  .g-ml-9--sm {
    margin-left: 0.6428571429rem !important;
  }

  .g-ml-minus-9--sm {
    margin-left: -0.6428571429rem !important;
  }

  .g-ml-10--sm {
    margin-left: 0.7142857143rem !important;
  }

  .g-ml-minus-10--sm {
    margin-left: -0.7142857143rem !important;
  }

  .g-ml-5--sm {
    margin-left: 0.3571428571rem !important;
  }

  .g-ml-minus-5--sm {
    margin-left: -0.3571428571rem !important;
  }

  .g-ml-10--sm {
    margin-left: 0.7142857143rem !important;
  }

  .g-ml-minus-10--sm {
    margin-left: -0.7142857143rem !important;
  }

  .g-ml-15--sm {
    margin-left: 1.0714285714rem !important;
  }

  .g-ml-minus-15--sm {
    margin-left: -1.0714285714rem !important;
  }

  .g-ml-20--sm {
    margin-left: 1.4285714286rem !important;
  }

  .g-ml-minus-20--sm {
    margin-left: -1.4285714286rem !important;
  }

  .g-ml-25--sm {
    margin-left: 1.7857142857rem !important;
  }

  .g-ml-minus-25--sm {
    margin-left: -1.7857142857rem !important;
  }

  .g-ml-30--sm {
    margin-left: 2.1428571429rem !important;
  }

  .g-ml-minus-30--sm {
    margin-left: -2.1428571429rem !important;
  }

  .g-ml-35--sm {
    margin-left: 2.5rem !important;
  }

  .g-ml-minus-35--sm {
    margin-left: -2.5rem !important;
  }

  .g-ml-40--sm {
    margin-left: 2.8571428571rem !important;
  }

  .g-ml-minus-40--sm {
    margin-left: -2.8571428571rem !important;
  }

  .g-ml-45--sm {
    margin-left: 3.2142857143rem !important;
  }

  .g-ml-minus-45--sm {
    margin-left: -3.2142857143rem !important;
  }

  .g-ml-50--sm {
    margin-left: 3.5714285714rem !important;
  }

  .g-ml-minus-50--sm {
    margin-left: -3.5714285714rem !important;
  }

  .g-mr-1--sm {
    margin-right: 0.0714285714rem !important;
  }

  .g-mr-minus-1--sm {
    margin-right: -0.0714285714rem !important;
  }

  .g-mr-2--sm {
    margin-right: 0.1428571429rem !important;
  }

  .g-mr-minus-2--sm {
    margin-right: -0.1428571429rem !important;
  }

  .g-mr-3--sm {
    margin-right: 0.2142857143rem !important;
  }

  .g-mr-minus-3--sm {
    margin-right: -0.2142857143rem !important;
  }

  .g-mr-4--sm {
    margin-right: 0.2857142857rem !important;
  }

  .g-mr-minus-4--sm {
    margin-right: -0.2857142857rem !important;
  }

  .g-mr-5--sm {
    margin-right: 0.3571428571rem !important;
  }

  .g-mr-minus-5--sm {
    margin-right: -0.3571428571rem !important;
  }

  .g-mr-6--sm {
    margin-right: 0.4285714286rem !important;
  }

  .g-mr-minus-6--sm {
    margin-right: -0.4285714286rem !important;
  }

  .g-mr-7--sm {
    margin-right: 0.5rem !important;
  }

  .g-mr-minus-7--sm {
    margin-right: -0.5rem !important;
  }

  .g-mr-8--sm {
    margin-right: 0.5714285714rem !important;
  }

  .g-mr-minus-8--sm {
    margin-right: -0.5714285714rem !important;
  }

  .g-mr-9--sm {
    margin-right: 0.6428571429rem !important;
  }

  .g-mr-minus-9--sm {
    margin-right: -0.6428571429rem !important;
  }

  .g-mr-10--sm {
    margin-right: 0.7142857143rem !important;
  }

  .g-mr-minus-10--sm {
    margin-right: -0.7142857143rem !important;
  }

  .g-mr-5--sm {
    margin-right: 0.3571428571rem !important;
  }

  .g-mr-10--sm {
    margin-right: 0.7142857143rem !important;
  }

  .g-mr-15--sm {
    margin-right: 1.0714285714rem !important;
  }

  .g-mr-20--sm {
    margin-right: 1.4285714286rem !important;
  }

  .g-mr-25--sm {
    margin-right: 1.7857142857rem !important;
  }

  .g-mr-30--sm {
    margin-right: 2.1428571429rem !important;
  }

  .g-mr-35--sm {
    margin-right: 2.5rem !important;
  }

  .g-mr-40--sm {
    margin-right: 2.8571428571rem !important;
  }

  .g-mr-45--sm {
    margin-right: 3.2142857143rem !important;
  }

  .g-mr-50--sm {
    margin-right: 3.5714285714rem !important;
  }
}

/* Margin Spaces (md)
------------------------------------*/

@media (min-width: 768px) {
  .g-ma-0--md {
    margin: 0 !important;
  }

  .g-mx-0--md {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .g-my-0--md {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .g-ml-0--md {
    margin-left: 0 !important;
  }

  .g-mr-0--md {
    margin-right: 0 !important;
  }

  .g-mt-0--md {
    margin-top: 0 !important;
  }

  .g-mb-0--md {
    margin-bottom: 0 !important;
  }

  .g-mx-1--md {
    margin-left: 0.0714285714rem !important;
    margin-right: 0.0714285714rem !important;
  }

  .g-mx-2--md {
    margin-left: 0.1428571429rem !important;
    margin-right: 0.1428571429rem !important;
  }

  .g-mx-3--md {
    margin-left: 0.2142857143rem !important;
    margin-right: 0.2142857143rem !important;
  }

  .g-mx-4--md {
    margin-left: 0.2857142857rem !important;
    margin-right: 0.2857142857rem !important;
  }

  .g-mx-5--md {
    margin-left: 0.3571428571rem !important;
    margin-right: 0.3571428571rem !important;
  }

  .g-mx-6--md {
    margin-left: 0.4285714286rem !important;
    margin-right: 0.4285714286rem !important;
  }

  .g-mx-7--md {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .g-mx-8--md {
    margin-left: 0.5714285714rem !important;
    margin-right: 0.5714285714rem !important;
  }

  .g-mx-9--md {
    margin-left: 0.6428571429rem !important;
    margin-right: 0.6428571429rem !important;
  }

  .g-mx-10--md {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }

  .g-mx-10--md {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }

  .g-mx-15--md {
    margin-left: 1.0714285714rem !important;
    margin-right: 1.0714285714rem !important;
  }

  .g-mx-20--md {
    margin-left: 1.4285714286rem !important;
    margin-right: 1.4285714286rem !important;
  }

  .g-mx-25--md {
    margin-left: 1.7857142857rem !important;
    margin-right: 1.7857142857rem !important;
  }

  .g-mx-30--md {
    margin-left: 2.1428571429rem !important;
    margin-right: 2.1428571429rem !important;
  }

  .g-mx-35--md {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .g-mx-40--md {
    margin-left: 2.8571428571rem !important;
    margin-right: 2.8571428571rem !important;
  }

  .g-mx-45--md {
    margin-left: 3.2142857143rem !important;
    margin-right: 3.2142857143rem !important;
  }

  .g-mx-50--md {
    margin-left: 3.5714285714rem !important;
    margin-right: 3.5714285714rem !important;
  }

  .g-mx-55--md {
    margin-left: 3.9285714286rem !important;
    margin-right: 3.9285714286rem !important;
  }

  .g-mx-60--md {
    margin-left: 4.2857142857rem !important;
    margin-right: 4.2857142857rem !important;
  }

  .g-mx-65--md {
    margin-left: 4.6428571429rem !important;
    margin-right: 4.6428571429rem !important;
  }

  .g-mx-70--md {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .g-mx-75--md {
    margin-left: 5.3571428571rem !important;
    margin-right: 5.3571428571rem !important;
  }

  .g-mx-80--md {
    margin-left: 5.7142857143rem !important;
    margin-right: 5.7142857143rem !important;
  }

  .g-mx-85--md {
    margin-left: 6.0714285714rem !important;
    margin-right: 6.0714285714rem !important;
  }

  .g-mx-90--md {
    margin-left: 6.4285714286rem !important;
    margin-right: 6.4285714286rem !important;
  }

  .g-mx-95--md {
    margin-left: 6.7857142857rem !important;
    margin-right: 6.7857142857rem !important;
  }

  .g-mx-100--md {
    margin-left: 7.1428571429rem !important;
    margin-right: 7.1428571429rem !important;
  }

  .g-my-1--md {
    margin-top: 0.0714285714rem !important;
    margin-bottom: 0.0714285714rem !important;
  }

  .g-my-2--md {
    margin-top: 0.1428571429rem !important;
    margin-bottom: 0.1428571429rem !important;
  }

  .g-my-3--md {
    margin-top: 0.2142857143rem !important;
    margin-bottom: 0.2142857143rem !important;
  }

  .g-my-4--md {
    margin-top: 0.2857142857rem !important;
    margin-bottom: 0.2857142857rem !important;
  }

  .g-my-5--md {
    margin-top: 0.3571428571rem !important;
    margin-bottom: 0.3571428571rem !important;
  }

  .g-my-6--md {
    margin-top: 0.4285714286rem !important;
    margin-bottom: 0.4285714286rem !important;
  }

  .g-my-7--md {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .g-my-8--md {
    margin-top: 0.5714285714rem !important;
    margin-bottom: 0.5714285714rem !important;
  }

  .g-my-9--md {
    margin-top: 0.6428571429rem !important;
    margin-bottom: 0.6428571429rem !important;
  }

  .g-my-10--md {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }

  .g-my-10--md {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }

  .g-my-15--md {
    margin-top: 1.0714285714rem !important;
    margin-bottom: 1.0714285714rem !important;
  }

  .g-my-20--md {
    margin-top: 1.4285714286rem !important;
    margin-bottom: 1.4285714286rem !important;
  }

  .g-my-25--md {
    margin-top: 1.7857142857rem !important;
    margin-bottom: 1.7857142857rem !important;
  }

  .g-my-30--md {
    margin-top: 2.1428571429rem !important;
    margin-bottom: 2.1428571429rem !important;
  }

  .g-my-35--md {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .g-my-40--md {
    margin-top: 2.8571428571rem !important;
    margin-bottom: 2.8571428571rem !important;
  }

  .g-my-45--md {
    margin-top: 3.2142857143rem !important;
    margin-bottom: 3.2142857143rem !important;
  }

  .g-my-50--md {
    margin-top: 3.5714285714rem !important;
    margin-bottom: 3.5714285714rem !important;
  }

  .g-my-55--md {
    margin-top: 3.9285714286rem !important;
    margin-bottom: 3.9285714286rem !important;
  }

  .g-my-60--md {
    margin-top: 4.2857142857rem !important;
    margin-bottom: 4.2857142857rem !important;
  }

  .g-my-65--md {
    margin-top: 4.6428571429rem !important;
    margin-bottom: 4.6428571429rem !important;
  }

  .g-my-70--md {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .g-my-75--md {
    margin-top: 5.3571428571rem !important;
    margin-bottom: 5.3571428571rem !important;
  }

  .g-my-80--md {
    margin-top: 5.7142857143rem !important;
    margin-bottom: 5.7142857143rem !important;
  }

  .g-my-85--md {
    margin-top: 6.0714285714rem !important;
    margin-bottom: 6.0714285714rem !important;
  }

  .g-my-90--md {
    margin-top: 6.4285714286rem !important;
    margin-bottom: 6.4285714286rem !important;
  }

  .g-my-95--md {
    margin-top: 6.7857142857rem !important;
    margin-bottom: 6.7857142857rem !important;
  }

  .g-my-100--md {
    margin-top: 7.1428571429rem !important;
    margin-bottom: 7.1428571429rem !important;
  }

  .g-mt-1--md {
    margin-top: 0.0714285714rem !important;
  }

  .g-mt-minus-1--md {
    margin-top: -0.0714285714rem !important;
  }

  .g-mt-2--md {
    margin-top: 0.1428571429rem !important;
  }

  .g-mt-minus-2--md {
    margin-top: -0.1428571429rem !important;
  }

  .g-mt-3--md {
    margin-top: 0.2142857143rem !important;
  }

  .g-mt-minus-3--md {
    margin-top: -0.2142857143rem !important;
  }

  .g-mt-4--md {
    margin-top: 0.2857142857rem !important;
  }

  .g-mt-minus-4--md {
    margin-top: -0.2857142857rem !important;
  }

  .g-mt-5--md {
    margin-top: 0.3571428571rem !important;
  }

  .g-mt-minus-5--md {
    margin-top: -0.3571428571rem !important;
  }

  .g-mt-6--md {
    margin-top: 0.4285714286rem !important;
  }

  .g-mt-minus-6--md {
    margin-top: -0.4285714286rem !important;
  }

  .g-mt-7--md {
    margin-top: 0.5rem !important;
  }

  .g-mt-minus-7--md {
    margin-top: -0.5rem !important;
  }

  .g-mt-8--md {
    margin-top: 0.5714285714rem !important;
  }

  .g-mt-minus-8--md {
    margin-top: -0.5714285714rem !important;
  }

  .g-mt-9--md {
    margin-top: 0.6428571429rem !important;
  }

  .g-mt-minus-9--md {
    margin-top: -0.6428571429rem !important;
  }

  .g-mt-10--md {
    margin-top: 0.7142857143rem !important;
  }

  .g-mt-minus-10--md {
    margin-top: -0.7142857143rem !important;
  }

  .g-mt-11--md {
    margin-top: 0.7857142857rem !important;
  }

  .g-mt-minus-11--md {
    margin-top: -0.7857142857rem !important;
  }

  .g-mt-12--md {
    margin-top: 0.8571428571rem !important;
  }

  .g-mt-minus-12--md {
    margin-top: -0.8571428571rem !important;
  }

  .g-mt-13--md {
    margin-top: 0.9285714286rem !important;
  }

  .g-mt-minus-13--md {
    margin-top: -0.9285714286rem !important;
  }

  .g-mt-14--md {
    margin-top: 1rem !important;
  }

  .g-mt-minus-14--md {
    margin-top: -1rem !important;
  }

  .g-mt-15--md {
    margin-top: 1.0714285714rem !important;
  }

  .g-mt-minus-15--md {
    margin-top: -1.0714285714rem !important;
  }

  .g-mt-16--md {
    margin-top: 1.1428571429rem !important;
  }

  .g-mt-minus-16--md {
    margin-top: -1.1428571429rem !important;
  }

  .g-mt-17--md {
    margin-top: 1.2142857143rem !important;
  }

  .g-mt-minus-17--md {
    margin-top: -1.2142857143rem !important;
  }

  .g-mt-18--md {
    margin-top: 1.2857142857rem !important;
  }

  .g-mt-minus-18--md {
    margin-top: -1.2857142857rem !important;
  }

  .g-mt-19--md {
    margin-top: 1.3571428571rem !important;
  }

  .g-mt-minus-19--md {
    margin-top: -1.3571428571rem !important;
  }

  .g-mt-20--md {
    margin-top: 1.4285714286rem !important;
  }

  .g-mt-minus-20--md {
    margin-top: -1.4285714286rem !important;
  }

  .g-mt-10--md {
    margin-top: 0.7142857143rem !important;
  }

  .g-mt-minus-10--md {
    margin-top: -0.7142857143rem !important;
  }

  .g-mt-15--md {
    margin-top: 1.0714285714rem !important;
  }

  .g-mt-minus-15--md {
    margin-top: -1.0714285714rem !important;
  }

  .g-mt-20--md {
    margin-top: 1.4285714286rem !important;
  }

  .g-mt-minus-20--md {
    margin-top: -1.4285714286rem !important;
  }

  .g-mt-25--md {
    margin-top: 1.7857142857rem !important;
  }

  .g-mt-minus-25--md {
    margin-top: -1.7857142857rem !important;
  }

  .g-mt-30--md {
    margin-top: 2.1428571429rem !important;
  }

  .g-mt-minus-30--md {
    margin-top: -2.1428571429rem !important;
  }

  .g-mt-35--md {
    margin-top: 2.5rem !important;
  }

  .g-mt-minus-35--md {
    margin-top: -2.5rem !important;
  }

  .g-mt-40--md {
    margin-top: 2.8571428571rem !important;
  }

  .g-mt-minus-40--md {
    margin-top: -2.8571428571rem !important;
  }

  .g-mt-45--md {
    margin-top: 3.2142857143rem !important;
  }

  .g-mt-minus-45--md {
    margin-top: -3.2142857143rem !important;
  }

  .g-mt-50--md {
    margin-top: 3.5714285714rem !important;
  }

  .g-mt-minus-50--md {
    margin-top: -3.5714285714rem !important;
  }

  .g-mt-55--md {
    margin-top: 3.9285714286rem !important;
  }

  .g-mt-minus-55--md {
    margin-top: -3.9285714286rem !important;
  }

  .g-mt-60--md {
    margin-top: 4.2857142857rem !important;
  }

  .g-mt-minus-60--md {
    margin-top: -4.2857142857rem !important;
  }

  .g-mt-65--md {
    margin-top: 4.6428571429rem !important;
  }

  .g-mt-minus-65--md {
    margin-top: -4.6428571429rem !important;
  }

  .g-mt-70--md {
    margin-top: 5rem !important;
  }

  .g-mt-minus-70--md {
    margin-top: -5rem !important;
  }

  .g-mt-75--md {
    margin-top: 5.3571428571rem !important;
  }

  .g-mt-minus-75--md {
    margin-top: -5.3571428571rem !important;
  }

  .g-mt-80--md {
    margin-top: 5.7142857143rem !important;
  }

  .g-mt-minus-80--md {
    margin-top: -5.7142857143rem !important;
  }

  .g-mt-85--md {
    margin-top: 6.0714285714rem !important;
  }

  .g-mt-minus-85--md {
    margin-top: -6.0714285714rem !important;
  }

  .g-mt-90--md {
    margin-top: 6.4285714286rem !important;
  }

  .g-mt-minus-90--md {
    margin-top: -6.4285714286rem !important;
  }

  .g-mt-95--md {
    margin-top: 6.7857142857rem !important;
  }

  .g-mt-minus-95--md {
    margin-top: -6.7857142857rem !important;
  }

  .g-mt-100--md {
    margin-top: 7.1428571429rem !important;
  }

  .g-mt-minus-100--md {
    margin-top: -7.1428571429rem !important;
  }

  .g-mt-105--md {
    margin-top: 7.5rem !important;
  }

  .g-mt-minus-105--md {
    margin-top: -7.5rem !important;
  }

  .g-mt-110--md {
    margin-top: 7.8571428571rem !important;
  }

  .g-mt-minus-110--md {
    margin-top: -7.8571428571rem !important;
  }

  .g-mt-115--md {
    margin-top: 8.2142857143rem !important;
  }

  .g-mt-minus-115--md {
    margin-top: -8.2142857143rem !important;
  }

  .g-mt-120--md {
    margin-top: 8.5714285714rem !important;
  }

  .g-mt-minus-120--md {
    margin-top: -8.5714285714rem !important;
  }

  .g-mt-125--md {
    margin-top: 8.9285714286rem !important;
  }

  .g-mt-minus-125--md {
    margin-top: -8.9285714286rem !important;
  }

  .g-mt-130--md {
    margin-top: 9.2857142857rem !important;
  }

  .g-mt-minus-130--md {
    margin-top: -9.2857142857rem !important;
  }

  .g-mt-135--md {
    margin-top: 9.6428571429rem !important;
  }

  .g-mt-minus-135--md {
    margin-top: -9.6428571429rem !important;
  }

  .g-mt-140--md {
    margin-top: 10rem !important;
  }

  .g-mt-minus-140--md {
    margin-top: -10rem !important;
  }

  .g-mt-145--md {
    margin-top: 10.3571428571rem !important;
  }

  .g-mt-minus-145--md {
    margin-top: -10.3571428571rem !important;
  }

  .g-mt-150--md {
    margin-top: 10.7142857143rem !important;
  }

  .g-mt-minus-150--md {
    margin-top: -10.7142857143rem !important;
  }

  .g-mt-155--md {
    margin-top: 11.0714285714rem !important;
  }

  .g-mt-minus-155--md {
    margin-top: -11.0714285714rem !important;
  }

  .g-mt-160--md {
    margin-top: 11.4285714286rem !important;
  }

  .g-mt-minus-160--md {
    margin-top: -11.4285714286rem !important;
  }

  .g-mt-165--md {
    margin-top: 11.7857142857rem !important;
  }

  .g-mt-minus-165--md {
    margin-top: -11.7857142857rem !important;
  }

  .g-mt-170--md {
    margin-top: 12.1428571429rem !important;
  }

  .g-mt-minus-170--md {
    margin-top: -12.1428571429rem !important;
  }

  .g-mb-1--md {
    margin-bottom: 0.0714285714rem !important;
  }

  .g-mb-minus-1--md {
    margin-bottom: -0.0714285714rem !important;
  }

  .g-mb-2--md {
    margin-bottom: 0.1428571429rem !important;
  }

  .g-mb-minus-2--md {
    margin-bottom: -0.1428571429rem !important;
  }

  .g-mb-3--md {
    margin-bottom: 0.2142857143rem !important;
  }

  .g-mb-minus-3--md {
    margin-bottom: -0.2142857143rem !important;
  }

  .g-mb-4--md {
    margin-bottom: 0.2857142857rem !important;
  }

  .g-mb-minus-4--md {
    margin-bottom: -0.2857142857rem !important;
  }

  .g-mb-5--md {
    margin-bottom: 0.3571428571rem !important;
  }

  .g-mb-minus-5--md {
    margin-bottom: -0.3571428571rem !important;
  }

  .g-mb-6--md {
    margin-bottom: 0.4285714286rem !important;
  }

  .g-mb-minus-6--md {
    margin-bottom: -0.4285714286rem !important;
  }

  .g-mb-7--md {
    margin-bottom: 0.5rem !important;
  }

  .g-mb-minus-7--md {
    margin-bottom: -0.5rem !important;
  }

  .g-mb-8--md {
    margin-bottom: 0.5714285714rem !important;
  }

  .g-mb-minus-8--md {
    margin-bottom: -0.5714285714rem !important;
  }

  .g-mb-9--md {
    margin-bottom: 0.6428571429rem !important;
  }

  .g-mb-minus-9--md {
    margin-bottom: -0.6428571429rem !important;
  }

  .g-mb-10--md {
    margin-bottom: 0.7142857143rem !important;
  }

  .g-mb-minus-10--md {
    margin-bottom: -0.7142857143rem !important;
  }

  .g-mb-11--md {
    margin-bottom: 0.7857142857rem !important;
  }

  .g-mb-minus-11--md {
    margin-bottom: -0.7857142857rem !important;
  }

  .g-mb-12--md {
    margin-bottom: 0.8571428571rem !important;
  }

  .g-mb-minus-12--md {
    margin-bottom: -0.8571428571rem !important;
  }

  .g-mb-13--md {
    margin-bottom: 0.9285714286rem !important;
  }

  .g-mb-minus-13--md {
    margin-bottom: -0.9285714286rem !important;
  }

  .g-mb-14--md {
    margin-bottom: 1rem !important;
  }

  .g-mb-minus-14--md {
    margin-bottom: -1rem !important;
  }

  .g-mb-15--md {
    margin-bottom: 1.0714285714rem !important;
  }

  .g-mb-minus-15--md {
    margin-bottom: -1.0714285714rem !important;
  }

  .g-mb-16--md {
    margin-bottom: 1.1428571429rem !important;
  }

  .g-mb-minus-16--md {
    margin-bottom: -1.1428571429rem !important;
  }

  .g-mb-17--md {
    margin-bottom: 1.2142857143rem !important;
  }

  .g-mb-minus-17--md {
    margin-bottom: -1.2142857143rem !important;
  }

  .g-mb-18--md {
    margin-bottom: 1.2857142857rem !important;
  }

  .g-mb-minus-18--md {
    margin-bottom: -1.2857142857rem !important;
  }

  .g-mb-19--md {
    margin-bottom: 1.3571428571rem !important;
  }

  .g-mb-minus-19--md {
    margin-bottom: -1.3571428571rem !important;
  }

  .g-mb-20--md {
    margin-bottom: 1.4285714286rem !important;
  }

  .g-mb-minus-20--md {
    margin-bottom: -1.4285714286rem !important;
  }

  .g-mb-10--md {
    margin-bottom: 0.7142857143rem !important;
  }

  .g-mb-15--md {
    margin-bottom: 1.0714285714rem !important;
  }

  .g-mb-20--md {
    margin-bottom: 1.4285714286rem !important;
  }

  .g-mb-25--md {
    margin-bottom: 1.7857142857rem !important;
  }

  .g-mb-30--md {
    margin-bottom: 2.1428571429rem !important;
  }

  .g-mb-35--md {
    margin-bottom: 2.5rem !important;
  }

  .g-mb-40--md {
    margin-bottom: 2.8571428571rem !important;
  }

  .g-mb-45--md {
    margin-bottom: 3.2142857143rem !important;
  }

  .g-mb-50--md {
    margin-bottom: 3.5714285714rem !important;
  }

  .g-mb-55--md {
    margin-bottom: 3.9285714286rem !important;
  }

  .g-mb-60--md {
    margin-bottom: 4.2857142857rem !important;
  }

  .g-mb-65--md {
    margin-bottom: 4.6428571429rem !important;
  }

  .g-mb-70--md {
    margin-bottom: 5rem !important;
  }

  .g-mb-75--md {
    margin-bottom: 5.3571428571rem !important;
  }

  .g-mb-80--md {
    margin-bottom: 5.7142857143rem !important;
  }

  .g-mb-85--md {
    margin-bottom: 6.0714285714rem !important;
  }

  .g-mb-90--md {
    margin-bottom: 6.4285714286rem !important;
  }

  .g-mb-95--md {
    margin-bottom: 6.7857142857rem !important;
  }

  .g-mb-100--md {
    margin-bottom: 7.1428571429rem !important;
  }

  .g-mb-105--md {
    margin-bottom: 7.5rem !important;
  }

  .g-mb-110--md {
    margin-bottom: 7.8571428571rem !important;
  }

  .g-mb-115--md {
    margin-bottom: 8.2142857143rem !important;
  }

  .g-mb-120--md {
    margin-bottom: 8.5714285714rem !important;
  }

  .g-mb-125--md {
    margin-bottom: 8.9285714286rem !important;
  }

  .g-mb-130--md {
    margin-bottom: 9.2857142857rem !important;
  }

  .g-mb-135--md {
    margin-bottom: 9.6428571429rem !important;
  }

  .g-mb-140--md {
    margin-bottom: 10rem !important;
  }

  .g-mb-145--md {
    margin-bottom: 10.3571428571rem !important;
  }

  .g-mb-150--md {
    margin-bottom: 10.7142857143rem !important;
  }

  .g-mb-155--md {
    margin-bottom: 11.0714285714rem !important;
  }

  .g-mb-160--md {
    margin-bottom: 11.4285714286rem !important;
  }

  .g-mb-165--md {
    margin-bottom: 11.7857142857rem !important;
  }

  .g-mb-170--md {
    margin-bottom: 12.1428571429rem !important;
  }

  .g-ml-1--md {
    margin-left: 0.0714285714rem !important;
  }

  .g-ml-minus-1--md {
    margin-left: -0.0714285714rem !important;
  }

  .g-ml-2--md {
    margin-left: 0.1428571429rem !important;
  }

  .g-ml-minus-2--md {
    margin-left: -0.1428571429rem !important;
  }

  .g-ml-3--md {
    margin-left: 0.2142857143rem !important;
  }

  .g-ml-minus-3--md {
    margin-left: -0.2142857143rem !important;
  }

  .g-ml-4--md {
    margin-left: 0.2857142857rem !important;
  }

  .g-ml-minus-4--md {
    margin-left: -0.2857142857rem !important;
  }

  .g-ml-5--md {
    margin-left: 0.3571428571rem !important;
  }

  .g-ml-minus-5--md {
    margin-left: -0.3571428571rem !important;
  }

  .g-ml-6--md {
    margin-left: 0.4285714286rem !important;
  }

  .g-ml-minus-6--md {
    margin-left: -0.4285714286rem !important;
  }

  .g-ml-7--md {
    margin-left: 0.5rem !important;
  }

  .g-ml-minus-7--md {
    margin-left: -0.5rem !important;
  }

  .g-ml-8--md {
    margin-left: 0.5714285714rem !important;
  }

  .g-ml-minus-8--md {
    margin-left: -0.5714285714rem !important;
  }

  .g-ml-9--md {
    margin-left: 0.6428571429rem !important;
  }

  .g-ml-minus-9--md {
    margin-left: -0.6428571429rem !important;
  }

  .g-ml-10--md {
    margin-left: 0.7142857143rem !important;
  }

  .g-ml-minus-10--md {
    margin-left: -0.7142857143rem !important;
  }

  .g-ml-5--md {
    margin-left: 0.3571428571rem !important;
  }

  .g-ml-minus-5--md {
    margin-left: -0.3571428571rem !important;
  }

  .g-ml-10--md {
    margin-left: 0.7142857143rem !important;
  }

  .g-ml-minus-10--md {
    margin-left: -0.7142857143rem !important;
  }

  .g-ml-15--md {
    margin-left: 1.0714285714rem !important;
  }

  .g-ml-minus-15--md {
    margin-left: -1.0714285714rem !important;
  }

  .g-ml-20--md {
    margin-left: 1.4285714286rem !important;
  }

  .g-ml-minus-20--md {
    margin-left: -1.4285714286rem !important;
  }

  .g-ml-25--md {
    margin-left: 1.7857142857rem !important;
  }

  .g-ml-minus-25--md {
    margin-left: -1.7857142857rem !important;
  }

  .g-ml-30--md {
    margin-left: 2.1428571429rem !important;
  }

  .g-ml-minus-30--md {
    margin-left: -2.1428571429rem !important;
  }

  .g-ml-35--md {
    margin-left: 2.5rem !important;
  }

  .g-ml-minus-35--md {
    margin-left: -2.5rem !important;
  }

  .g-ml-40--md {
    margin-left: 2.8571428571rem !important;
  }

  .g-ml-minus-40--md {
    margin-left: -2.8571428571rem !important;
  }

  .g-ml-45--md {
    margin-left: 3.2142857143rem !important;
  }

  .g-ml-minus-45--md {
    margin-left: -3.2142857143rem !important;
  }

  .g-ml-50--md {
    margin-left: 3.5714285714rem !important;
  }

  .g-ml-minus-50--md {
    margin-left: -3.5714285714rem !important;
  }

  .g-mr-1--md {
    margin-right: 0.0714285714rem !important;
  }

  .g-mr-minus-1--md {
    margin-right: -0.0714285714rem !important;
  }

  .g-mr-2--md {
    margin-right: 0.1428571429rem !important;
  }

  .g-mr-minus-2--md {
    margin-right: -0.1428571429rem !important;
  }

  .g-mr-3--md {
    margin-right: 0.2142857143rem !important;
  }

  .g-mr-minus-3--md {
    margin-right: -0.2142857143rem !important;
  }

  .g-mr-4--md {
    margin-right: 0.2857142857rem !important;
  }

  .g-mr-minus-4--md {
    margin-right: -0.2857142857rem !important;
  }

  .g-mr-5--md {
    margin-right: 0.3571428571rem !important;
  }

  .g-mr-minus-5--md {
    margin-right: -0.3571428571rem !important;
  }

  .g-mr-6--md {
    margin-right: 0.4285714286rem !important;
  }

  .g-mr-minus-6--md {
    margin-right: -0.4285714286rem !important;
  }

  .g-mr-7--md {
    margin-right: 0.5rem !important;
  }

  .g-mr-minus-7--md {
    margin-right: -0.5rem !important;
  }

  .g-mr-8--md {
    margin-right: 0.5714285714rem !important;
  }

  .g-mr-minus-8--md {
    margin-right: -0.5714285714rem !important;
  }

  .g-mr-9--md {
    margin-right: 0.6428571429rem !important;
  }

  .g-mr-minus-9--md {
    margin-right: -0.6428571429rem !important;
  }

  .g-mr-10--md {
    margin-right: 0.7142857143rem !important;
  }

  .g-mr-minus-10--md {
    margin-right: -0.7142857143rem !important;
  }

  .g-mr-5--md {
    margin-right: 0.3571428571rem !important;
  }

  .g-mr-10--md {
    margin-right: 0.7142857143rem !important;
  }

  .g-mr-15--md {
    margin-right: 1.0714285714rem !important;
  }

  .g-mr-20--md {
    margin-right: 1.4285714286rem !important;
  }

  .g-mr-25--md {
    margin-right: 1.7857142857rem !important;
  }

  .g-mr-30--md {
    margin-right: 2.1428571429rem !important;
  }

  .g-mr-35--md {
    margin-right: 2.5rem !important;
  }

  .g-mr-40--md {
    margin-right: 2.8571428571rem !important;
  }

  .g-mr-45--md {
    margin-right: 3.2142857143rem !important;
  }

  .g-mr-50--md {
    margin-right: 3.5714285714rem !important;
  }
}

/* Margin Spaces (lg)
------------------------------------*/

@media (min-width: 992px) {
  .g-ma-0--lg {
    margin: 0 !important;
  }

  .g-mx-0--lg {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .g-my-0--lg {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .g-ml-0--lg {
    margin-left: 0 !important;
  }

  .g-mr-0--lg {
    margin-right: 0 !important;
  }

  .g-mt-0--lg {
    margin-top: 0 !important;
  }

  .g-mb-0--lg {
    margin-bottom: 0 !important;
  }

  .g-mx-1--lg {
    margin-left: 0.0714285714rem !important;
    margin-right: 0.0714285714rem !important;
  }

  .g-mx-2--lg {
    margin-left: 0.1428571429rem !important;
    margin-right: 0.1428571429rem !important;
  }

  .g-mx-3--lg {
    margin-left: 0.2142857143rem !important;
    margin-right: 0.2142857143rem !important;
  }

  .g-mx-4--lg {
    margin-left: 0.2857142857rem !important;
    margin-right: 0.2857142857rem !important;
  }

  .g-mx-5--lg {
    margin-left: 0.3571428571rem !important;
    margin-right: 0.3571428571rem !important;
  }

  .g-mx-6--lg {
    margin-left: 0.4285714286rem !important;
    margin-right: 0.4285714286rem !important;
  }

  .g-mx-7--lg {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .g-mx-8--lg {
    margin-left: 0.5714285714rem !important;
    margin-right: 0.5714285714rem !important;
  }

  .g-mx-9--lg {
    margin-left: 0.6428571429rem !important;
    margin-right: 0.6428571429rem !important;
  }

  .g-mx-10--lg {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }

  .g-mx-10--lg {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }

  .g-mx-15--lg {
    margin-left: 1.0714285714rem !important;
    margin-right: 1.0714285714rem !important;
  }

  .g-mx-20--lg {
    margin-left: 1.4285714286rem !important;
    margin-right: 1.4285714286rem !important;
  }

  .g-mx-25--lg {
    margin-left: 1.7857142857rem !important;
    margin-right: 1.7857142857rem !important;
  }

  .g-mx-30--lg {
    margin-left: 2.1428571429rem !important;
    margin-right: 2.1428571429rem !important;
  }

  .g-mx-35--lg {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .g-mx-40--lg {
    margin-left: 2.8571428571rem !important;
    margin-right: 2.8571428571rem !important;
  }

  .g-mx-45--lg {
    margin-left: 3.2142857143rem !important;
    margin-right: 3.2142857143rem !important;
  }

  .g-mx-50--lg {
    margin-left: 3.5714285714rem !important;
    margin-right: 3.5714285714rem !important;
  }

  .g-mx-55--lg {
    margin-left: 3.9285714286rem !important;
    margin-right: 3.9285714286rem !important;
  }

  .g-mx-60--lg {
    margin-left: 4.2857142857rem !important;
    margin-right: 4.2857142857rem !important;
  }

  .g-mx-65--lg {
    margin-left: 4.6428571429rem !important;
    margin-right: 4.6428571429rem !important;
  }

  .g-mx-70--lg {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .g-mx-75--lg {
    margin-left: 5.3571428571rem !important;
    margin-right: 5.3571428571rem !important;
  }

  .g-mx-80--lg {
    margin-left: 5.7142857143rem !important;
    margin-right: 5.7142857143rem !important;
  }

  .g-mx-85--lg {
    margin-left: 6.0714285714rem !important;
    margin-right: 6.0714285714rem !important;
  }

  .g-mx-90--lg {
    margin-left: 6.4285714286rem !important;
    margin-right: 6.4285714286rem !important;
  }

  .g-mx-95--lg {
    margin-left: 6.7857142857rem !important;
    margin-right: 6.7857142857rem !important;
  }

  .g-mx-100--lg {
    margin-left: 7.1428571429rem !important;
    margin-right: 7.1428571429rem !important;
  }

  .g-my-1--lg {
    margin-top: 0.0714285714rem !important;
    margin-bottom: 0.0714285714rem !important;
  }

  .g-my-2--lg {
    margin-top: 0.1428571429rem !important;
    margin-bottom: 0.1428571429rem !important;
  }

  .g-my-3--lg {
    margin-top: 0.2142857143rem !important;
    margin-bottom: 0.2142857143rem !important;
  }

  .g-my-4--lg {
    margin-top: 0.2857142857rem !important;
    margin-bottom: 0.2857142857rem !important;
  }

  .g-my-5--lg {
    margin-top: 0.3571428571rem !important;
    margin-bottom: 0.3571428571rem !important;
  }

  .g-my-6--lg {
    margin-top: 0.4285714286rem !important;
    margin-bottom: 0.4285714286rem !important;
  }

  .g-my-7--lg {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .g-my-8--lg {
    margin-top: 0.5714285714rem !important;
    margin-bottom: 0.5714285714rem !important;
  }

  .g-my-9--lg {
    margin-top: 0.6428571429rem !important;
    margin-bottom: 0.6428571429rem !important;
  }

  .g-my-10--lg {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }

  .g-my-10--lg {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }

  .g-my-15--lg {
    margin-top: 1.0714285714rem !important;
    margin-bottom: 1.0714285714rem !important;
  }

  .g-my-20--lg {
    margin-top: 1.4285714286rem !important;
    margin-bottom: 1.4285714286rem !important;
  }

  .g-my-25--lg {
    margin-top: 1.7857142857rem !important;
    margin-bottom: 1.7857142857rem !important;
  }

  .g-my-30--lg {
    margin-top: 2.1428571429rem !important;
    margin-bottom: 2.1428571429rem !important;
  }

  .g-my-35--lg {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .g-my-40--lg {
    margin-top: 2.8571428571rem !important;
    margin-bottom: 2.8571428571rem !important;
  }

  .g-my-45--lg {
    margin-top: 3.2142857143rem !important;
    margin-bottom: 3.2142857143rem !important;
  }

  .g-my-50--lg {
    margin-top: 3.5714285714rem !important;
    margin-bottom: 3.5714285714rem !important;
  }

  .g-my-55--lg {
    margin-top: 3.9285714286rem !important;
    margin-bottom: 3.9285714286rem !important;
  }

  .g-my-60--lg {
    margin-top: 4.2857142857rem !important;
    margin-bottom: 4.2857142857rem !important;
  }

  .g-my-65--lg {
    margin-top: 4.6428571429rem !important;
    margin-bottom: 4.6428571429rem !important;
  }

  .g-my-70--lg {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .g-my-75--lg {
    margin-top: 5.3571428571rem !important;
    margin-bottom: 5.3571428571rem !important;
  }

  .g-my-80--lg {
    margin-top: 5.7142857143rem !important;
    margin-bottom: 5.7142857143rem !important;
  }

  .g-my-85--lg {
    margin-top: 6.0714285714rem !important;
    margin-bottom: 6.0714285714rem !important;
  }

  .g-my-90--lg {
    margin-top: 6.4285714286rem !important;
    margin-bottom: 6.4285714286rem !important;
  }

  .g-my-95--lg {
    margin-top: 6.7857142857rem !important;
    margin-bottom: 6.7857142857rem !important;
  }

  .g-my-100--lg {
    margin-top: 7.1428571429rem !important;
    margin-bottom: 7.1428571429rem !important;
  }

  .g-mt-1--lg {
    margin-top: 0.0714285714rem !important;
  }

  .g-mt-minus-1--lg {
    margin-top: -0.0714285714rem !important;
  }

  .g-mt-2--lg {
    margin-top: 0.1428571429rem !important;
  }

  .g-mt-minus-2--lg {
    margin-top: -0.1428571429rem !important;
  }

  .g-mt-3--lg {
    margin-top: 0.2142857143rem !important;
  }

  .g-mt-minus-3--lg {
    margin-top: -0.2142857143rem !important;
  }

  .g-mt-4--lg {
    margin-top: 0.2857142857rem !important;
  }

  .g-mt-minus-4--lg {
    margin-top: -0.2857142857rem !important;
  }

  .g-mt-5--lg {
    margin-top: 0.3571428571rem !important;
  }

  .g-mt-minus-5--lg {
    margin-top: -0.3571428571rem !important;
  }

  .g-mt-6--lg {
    margin-top: 0.4285714286rem !important;
  }

  .g-mt-minus-6--lg {
    margin-top: -0.4285714286rem !important;
  }

  .g-mt-7--lg {
    margin-top: 0.5rem !important;
  }

  .g-mt-minus-7--lg {
    margin-top: -0.5rem !important;
  }

  .g-mt-8--lg {
    margin-top: 0.5714285714rem !important;
  }

  .g-mt-minus-8--lg {
    margin-top: -0.5714285714rem !important;
  }

  .g-mt-9--lg {
    margin-top: 0.6428571429rem !important;
  }

  .g-mt-minus-9--lg {
    margin-top: -0.6428571429rem !important;
  }

  .g-mt-10--lg {
    margin-top: 0.7142857143rem !important;
  }

  .g-mt-minus-10--lg {
    margin-top: -0.7142857143rem !important;
  }

  .g-mt-11--lg {
    margin-top: 0.7857142857rem !important;
  }

  .g-mt-minus-11--lg {
    margin-top: -0.7857142857rem !important;
  }

  .g-mt-12--lg {
    margin-top: 0.8571428571rem !important;
  }

  .g-mt-minus-12--lg {
    margin-top: -0.8571428571rem !important;
  }

  .g-mt-13--lg {
    margin-top: 0.9285714286rem !important;
  }

  .g-mt-minus-13--lg {
    margin-top: -0.9285714286rem !important;
  }

  .g-mt-14--lg {
    margin-top: 1rem !important;
  }

  .g-mt-minus-14--lg {
    margin-top: -1rem !important;
  }

  .g-mt-15--lg {
    margin-top: 1.0714285714rem !important;
  }

  .g-mt-minus-15--lg {
    margin-top: -1.0714285714rem !important;
  }

  .g-mt-16--lg {
    margin-top: 1.1428571429rem !important;
  }

  .g-mt-minus-16--lg {
    margin-top: -1.1428571429rem !important;
  }

  .g-mt-17--lg {
    margin-top: 1.2142857143rem !important;
  }

  .g-mt-minus-17--lg {
    margin-top: -1.2142857143rem !important;
  }

  .g-mt-18--lg {
    margin-top: 1.2857142857rem !important;
  }

  .g-mt-minus-18--lg {
    margin-top: -1.2857142857rem !important;
  }

  .g-mt-19--lg {
    margin-top: 1.3571428571rem !important;
  }

  .g-mt-minus-19--lg {
    margin-top: -1.3571428571rem !important;
  }

  .g-mt-20--lg {
    margin-top: 1.4285714286rem !important;
  }

  .g-mt-minus-20--lg {
    margin-top: -1.4285714286rem !important;
  }

  .g-mt-10--lg {
    margin-top: 0.7142857143rem !important;
  }

  .g-mt-minus-10--lg {
    margin-top: -0.7142857143rem !important;
  }

  .g-mt-15--lg {
    margin-top: 1.0714285714rem !important;
  }

  .g-mt-minus-15--lg {
    margin-top: -1.0714285714rem !important;
  }

  .g-mt-20--lg {
    margin-top: 1.4285714286rem !important;
  }

  .g-mt-minus-20--lg {
    margin-top: -1.4285714286rem !important;
  }

  .g-mt-25--lg {
    margin-top: 1.7857142857rem !important;
  }

  .g-mt-minus-25--lg {
    margin-top: -1.7857142857rem !important;
  }

  .g-mt-30--lg {
    margin-top: 2.1428571429rem !important;
  }

  .g-mt-minus-30--lg {
    margin-top: -2.1428571429rem !important;
  }

  .g-mt-35--lg {
    margin-top: 2.5rem !important;
  }

  .g-mt-minus-35--lg {
    margin-top: -2.5rem !important;
  }

  .g-mt-40--lg {
    margin-top: 2.8571428571rem !important;
  }

  .g-mt-minus-40--lg {
    margin-top: -2.8571428571rem !important;
  }

  .g-mt-45--lg {
    margin-top: 3.2142857143rem !important;
  }

  .g-mt-minus-45--lg {
    margin-top: -3.2142857143rem !important;
  }

  .g-mt-50--lg {
    margin-top: 3.5714285714rem !important;
  }

  .g-mt-minus-50--lg {
    margin-top: -3.5714285714rem !important;
  }

  .g-mt-55--lg {
    margin-top: 3.9285714286rem !important;
  }

  .g-mt-minus-55--lg {
    margin-top: -3.9285714286rem !important;
  }

  .g-mt-60--lg {
    margin-top: 4.2857142857rem !important;
  }

  .g-mt-minus-60--lg {
    margin-top: -4.2857142857rem !important;
  }

  .g-mt-65--lg {
    margin-top: 4.6428571429rem !important;
  }

  .g-mt-minus-65--lg {
    margin-top: -4.6428571429rem !important;
  }

  .g-mt-70--lg {
    margin-top: 5rem !important;
  }

  .g-mt-minus-70--lg {
    margin-top: -5rem !important;
  }

  .g-mt-75--lg {
    margin-top: 5.3571428571rem !important;
  }

  .g-mt-minus-75--lg {
    margin-top: -5.3571428571rem !important;
  }

  .g-mt-80--lg {
    margin-top: 5.7142857143rem !important;
  }

  .g-mt-minus-80--lg {
    margin-top: -5.7142857143rem !important;
  }

  .g-mt-85--lg {
    margin-top: 6.0714285714rem !important;
  }

  .g-mt-minus-85--lg {
    margin-top: -6.0714285714rem !important;
  }

  .g-mt-90--lg {
    margin-top: 6.4285714286rem !important;
  }

  .g-mt-minus-90--lg {
    margin-top: -6.4285714286rem !important;
  }

  .g-mt-95--lg {
    margin-top: 6.7857142857rem !important;
  }

  .g-mt-minus-95--lg {
    margin-top: -6.7857142857rem !important;
  }

  .g-mt-100--lg {
    margin-top: 7.1428571429rem !important;
  }

  .g-mt-minus-100--lg {
    margin-top: -7.1428571429rem !important;
  }

  .g-mt-105--lg {
    margin-top: 7.5rem !important;
  }

  .g-mt-minus-105--lg {
    margin-top: -7.5rem !important;
  }

  .g-mt-110--lg {
    margin-top: 7.8571428571rem !important;
  }

  .g-mt-minus-110--lg {
    margin-top: -7.8571428571rem !important;
  }

  .g-mt-115--lg {
    margin-top: 8.2142857143rem !important;
  }

  .g-mt-minus-115--lg {
    margin-top: -8.2142857143rem !important;
  }

  .g-mt-120--lg {
    margin-top: 8.5714285714rem !important;
  }

  .g-mt-minus-120--lg {
    margin-top: -8.5714285714rem !important;
  }

  .g-mt-125--lg {
    margin-top: 8.9285714286rem !important;
  }

  .g-mt-minus-125--lg {
    margin-top: -8.9285714286rem !important;
  }

  .g-mt-130--lg {
    margin-top: 9.2857142857rem !important;
  }

  .g-mt-minus-130--lg {
    margin-top: -9.2857142857rem !important;
  }

  .g-mt-135--lg {
    margin-top: 9.6428571429rem !important;
  }

  .g-mt-minus-135--lg {
    margin-top: -9.6428571429rem !important;
  }

  .g-mt-140--lg {
    margin-top: 10rem !important;
  }

  .g-mt-minus-140--lg {
    margin-top: -10rem !important;
  }

  .g-mt-145--lg {
    margin-top: 10.3571428571rem !important;
  }

  .g-mt-minus-145--lg {
    margin-top: -10.3571428571rem !important;
  }

  .g-mt-150--lg {
    margin-top: 10.7142857143rem !important;
  }

  .g-mt-minus-150--lg {
    margin-top: -10.7142857143rem !important;
  }

  .g-mt-155--lg {
    margin-top: 11.0714285714rem !important;
  }

  .g-mt-minus-155--lg {
    margin-top: -11.0714285714rem !important;
  }

  .g-mt-160--lg {
    margin-top: 11.4285714286rem !important;
  }

  .g-mt-minus-160--lg {
    margin-top: -11.4285714286rem !important;
  }

  .g-mt-165--lg {
    margin-top: 11.7857142857rem !important;
  }

  .g-mt-minus-165--lg {
    margin-top: -11.7857142857rem !important;
  }

  .g-mt-170--lg {
    margin-top: 12.1428571429rem !important;
  }

  .g-mt-minus-170--lg {
    margin-top: -12.1428571429rem !important;
  }

  .g-mb-1--lg {
    margin-bottom: 0.0714285714rem !important;
  }

  .g-mb-minus-1--lg {
    margin-bottom: -0.0714285714rem !important;
  }

  .g-mb-2--lg {
    margin-bottom: 0.1428571429rem !important;
  }

  .g-mb-minus-2--lg {
    margin-bottom: -0.1428571429rem !important;
  }

  .g-mb-3--lg {
    margin-bottom: 0.2142857143rem !important;
  }

  .g-mb-minus-3--lg {
    margin-bottom: -0.2142857143rem !important;
  }

  .g-mb-4--lg {
    margin-bottom: 0.2857142857rem !important;
  }

  .g-mb-minus-4--lg {
    margin-bottom: -0.2857142857rem !important;
  }

  .g-mb-5--lg {
    margin-bottom: 0.3571428571rem !important;
  }

  .g-mb-minus-5--lg {
    margin-bottom: -0.3571428571rem !important;
  }

  .g-mb-6--lg {
    margin-bottom: 0.4285714286rem !important;
  }

  .g-mb-minus-6--lg {
    margin-bottom: -0.4285714286rem !important;
  }

  .g-mb-7--lg {
    margin-bottom: 0.5rem !important;
  }

  .g-mb-minus-7--lg {
    margin-bottom: -0.5rem !important;
  }

  .g-mb-8--lg {
    margin-bottom: 0.5714285714rem !important;
  }

  .g-mb-minus-8--lg {
    margin-bottom: -0.5714285714rem !important;
  }

  .g-mb-9--lg {
    margin-bottom: 0.6428571429rem !important;
  }

  .g-mb-minus-9--lg {
    margin-bottom: -0.6428571429rem !important;
  }

  .g-mb-10--lg {
    margin-bottom: 0.7142857143rem !important;
  }

  .g-mb-minus-10--lg {
    margin-bottom: -0.7142857143rem !important;
  }

  .g-mb-11--lg {
    margin-bottom: 0.7857142857rem !important;
  }

  .g-mb-minus-11--lg {
    margin-bottom: -0.7857142857rem !important;
  }

  .g-mb-12--lg {
    margin-bottom: 0.8571428571rem !important;
  }

  .g-mb-minus-12--lg {
    margin-bottom: -0.8571428571rem !important;
  }

  .g-mb-13--lg {
    margin-bottom: 0.9285714286rem !important;
  }

  .g-mb-minus-13--lg {
    margin-bottom: -0.9285714286rem !important;
  }

  .g-mb-14--lg {
    margin-bottom: 1rem !important;
  }

  .g-mb-minus-14--lg {
    margin-bottom: -1rem !important;
  }

  .g-mb-15--lg {
    margin-bottom: 1.0714285714rem !important;
  }

  .g-mb-minus-15--lg {
    margin-bottom: -1.0714285714rem !important;
  }

  .g-mb-16--lg {
    margin-bottom: 1.1428571429rem !important;
  }

  .g-mb-minus-16--lg {
    margin-bottom: -1.1428571429rem !important;
  }

  .g-mb-17--lg {
    margin-bottom: 1.2142857143rem !important;
  }

  .g-mb-minus-17--lg {
    margin-bottom: -1.2142857143rem !important;
  }

  .g-mb-18--lg {
    margin-bottom: 1.2857142857rem !important;
  }

  .g-mb-minus-18--lg {
    margin-bottom: -1.2857142857rem !important;
  }

  .g-mb-19--lg {
    margin-bottom: 1.3571428571rem !important;
  }

  .g-mb-minus-19--lg {
    margin-bottom: -1.3571428571rem !important;
  }

  .g-mb-20--lg {
    margin-bottom: 1.4285714286rem !important;
  }

  .g-mb-minus-20--lg {
    margin-bottom: -1.4285714286rem !important;
  }

  .g-mb-10--lg {
    margin-bottom: 0.7142857143rem !important;
  }

  .g-mb-15--lg {
    margin-bottom: 1.0714285714rem !important;
  }

  .g-mb-20--lg {
    margin-bottom: 1.4285714286rem !important;
  }

  .g-mb-25--lg {
    margin-bottom: 1.7857142857rem !important;
  }

  .g-mb-30--lg {
    margin-bottom: 2.1428571429rem !important;
  }

  .g-mb-35--lg {
    margin-bottom: 2.5rem !important;
  }

  .g-mb-40--lg {
    margin-bottom: 2.8571428571rem !important;
  }

  .g-mb-45--lg {
    margin-bottom: 3.2142857143rem !important;
  }

  .g-mb-50--lg {
    margin-bottom: 3.5714285714rem !important;
  }

  .g-mb-55--lg {
    margin-bottom: 3.9285714286rem !important;
  }

  .g-mb-60--lg {
    margin-bottom: 4.2857142857rem !important;
  }

  .g-mb-65--lg {
    margin-bottom: 4.6428571429rem !important;
  }

  .g-mb-70--lg {
    margin-bottom: 5rem !important;
  }

  .g-mb-75--lg {
    margin-bottom: 5.3571428571rem !important;
  }

  .g-mb-80--lg {
    margin-bottom: 5.7142857143rem !important;
  }

  .g-mb-85--lg {
    margin-bottom: 6.0714285714rem !important;
  }

  .g-mb-90--lg {
    margin-bottom: 6.4285714286rem !important;
  }

  .g-mb-95--lg {
    margin-bottom: 6.7857142857rem !important;
  }

  .g-mb-100--lg {
    margin-bottom: 7.1428571429rem !important;
  }

  .g-mb-105--lg {
    margin-bottom: 7.5rem !important;
  }

  .g-mb-110--lg {
    margin-bottom: 7.8571428571rem !important;
  }

  .g-mb-115--lg {
    margin-bottom: 8.2142857143rem !important;
  }

  .g-mb-120--lg {
    margin-bottom: 8.5714285714rem !important;
  }

  .g-mb-125--lg {
    margin-bottom: 8.9285714286rem !important;
  }

  .g-mb-130--lg {
    margin-bottom: 9.2857142857rem !important;
  }

  .g-mb-135--lg {
    margin-bottom: 9.6428571429rem !important;
  }

  .g-mb-140--lg {
    margin-bottom: 10rem !important;
  }

  .g-mb-145--lg {
    margin-bottom: 10.3571428571rem !important;
  }

  .g-mb-150--lg {
    margin-bottom: 10.7142857143rem !important;
  }

  .g-mb-155--lg {
    margin-bottom: 11.0714285714rem !important;
  }

  .g-mb-160--lg {
    margin-bottom: 11.4285714286rem !important;
  }

  .g-mb-165--lg {
    margin-bottom: 11.7857142857rem !important;
  }

  .g-mb-170--lg {
    margin-bottom: 12.1428571429rem !important;
  }

  .g-ml-1--lg {
    margin-left: 0.0714285714rem !important;
  }

  .g-ml-minus-1--lg {
    margin-left: -0.0714285714rem !important;
  }

  .g-ml-2--lg {
    margin-left: 0.1428571429rem !important;
  }

  .g-ml-minus-2--lg {
    margin-left: -0.1428571429rem !important;
  }

  .g-ml-3--lg {
    margin-left: 0.2142857143rem !important;
  }

  .g-ml-minus-3--lg {
    margin-left: -0.2142857143rem !important;
  }

  .g-ml-4--lg {
    margin-left: 0.2857142857rem !important;
  }

  .g-ml-minus-4--lg {
    margin-left: -0.2857142857rem !important;
  }

  .g-ml-5--lg {
    margin-left: 0.3571428571rem !important;
  }

  .g-ml-minus-5--lg {
    margin-left: -0.3571428571rem !important;
  }

  .g-ml-6--lg {
    margin-left: 0.4285714286rem !important;
  }

  .g-ml-minus-6--lg {
    margin-left: -0.4285714286rem !important;
  }

  .g-ml-7--lg {
    margin-left: 0.5rem !important;
  }

  .g-ml-minus-7--lg {
    margin-left: -0.5rem !important;
  }

  .g-ml-8--lg {
    margin-left: 0.5714285714rem !important;
  }

  .g-ml-minus-8--lg {
    margin-left: -0.5714285714rem !important;
  }

  .g-ml-9--lg {
    margin-left: 0.6428571429rem !important;
  }

  .g-ml-minus-9--lg {
    margin-left: -0.6428571429rem !important;
  }

  .g-ml-10--lg {
    margin-left: 0.7142857143rem !important;
  }

  .g-ml-minus-10--lg {
    margin-left: -0.7142857143rem !important;
  }

  .g-ml-5--lg {
    margin-left: 0.3571428571rem !important;
  }

  .g-ml-minus-5--lg {
    margin-left: -0.3571428571rem !important;
  }

  .g-ml-10--lg {
    margin-left: 0.7142857143rem !important;
  }

  .g-ml-minus-10--lg {
    margin-left: -0.7142857143rem !important;
  }

  .g-ml-15--lg {
    margin-left: 1.0714285714rem !important;
  }

  .g-ml-minus-15--lg {
    margin-left: -1.0714285714rem !important;
  }

  .g-ml-20--lg {
    margin-left: 1.4285714286rem !important;
  }

  .g-ml-minus-20--lg {
    margin-left: -1.4285714286rem !important;
  }

  .g-ml-25--lg {
    margin-left: 1.7857142857rem !important;
  }

  .g-ml-minus-25--lg {
    margin-left: -1.7857142857rem !important;
  }

  .g-ml-30--lg {
    margin-left: 2.1428571429rem !important;
  }

  .g-ml-minus-30--lg {
    margin-left: -2.1428571429rem !important;
  }

  .g-ml-35--lg {
    margin-left: 2.5rem !important;
  }

  .g-ml-minus-35--lg {
    margin-left: -2.5rem !important;
  }

  .g-ml-40--lg {
    margin-left: 2.8571428571rem !important;
  }

  .g-ml-minus-40--lg {
    margin-left: -2.8571428571rem !important;
  }

  .g-ml-45--lg {
    margin-left: 3.2142857143rem !important;
  }

  .g-ml-minus-45--lg {
    margin-left: -3.2142857143rem !important;
  }

  .g-ml-50--lg {
    margin-left: 3.5714285714rem !important;
  }

  .g-ml-minus-50--lg {
    margin-left: -3.5714285714rem !important;
  }

  .g-mr-1--lg {
    margin-right: 0.0714285714rem !important;
  }

  .g-mr-minus-1--lg {
    margin-right: -0.0714285714rem !important;
  }

  .g-mr-2--lg {
    margin-right: 0.1428571429rem !important;
  }

  .g-mr-minus-2--lg {
    margin-right: -0.1428571429rem !important;
  }

  .g-mr-3--lg {
    margin-right: 0.2142857143rem !important;
  }

  .g-mr-minus-3--lg {
    margin-right: -0.2142857143rem !important;
  }

  .g-mr-4--lg {
    margin-right: 0.2857142857rem !important;
  }

  .g-mr-minus-4--lg {
    margin-right: -0.2857142857rem !important;
  }

  .g-mr-5--lg {
    margin-right: 0.3571428571rem !important;
  }

  .g-mr-minus-5--lg {
    margin-right: -0.3571428571rem !important;
  }

  .g-mr-6--lg {
    margin-right: 0.4285714286rem !important;
  }

  .g-mr-minus-6--lg {
    margin-right: -0.4285714286rem !important;
  }

  .g-mr-7--lg {
    margin-right: 0.5rem !important;
  }

  .g-mr-minus-7--lg {
    margin-right: -0.5rem !important;
  }

  .g-mr-8--lg {
    margin-right: 0.5714285714rem !important;
  }

  .g-mr-minus-8--lg {
    margin-right: -0.5714285714rem !important;
  }

  .g-mr-9--lg {
    margin-right: 0.6428571429rem !important;
  }

  .g-mr-minus-9--lg {
    margin-right: -0.6428571429rem !important;
  }

  .g-mr-10--lg {
    margin-right: 0.7142857143rem !important;
  }

  .g-mr-minus-10--lg {
    margin-right: -0.7142857143rem !important;
  }

  .g-mr-5--lg {
    margin-right: 0.3571428571rem !important;
  }

  .g-mr-10--lg {
    margin-right: 0.7142857143rem !important;
  }

  .g-mr-15--lg {
    margin-right: 1.0714285714rem !important;
  }

  .g-mr-20--lg {
    margin-right: 1.4285714286rem !important;
  }

  .g-mr-25--lg {
    margin-right: 1.7857142857rem !important;
  }

  .g-mr-30--lg {
    margin-right: 2.1428571429rem !important;
  }

  .g-mr-35--lg {
    margin-right: 2.5rem !important;
  }

  .g-mr-40--lg {
    margin-right: 2.8571428571rem !important;
  }

  .g-mr-45--lg {
    margin-right: 3.2142857143rem !important;
  }

  .g-mr-50--lg {
    margin-right: 3.5714285714rem !important;
  }
}

/* Margin Spaces (xl)
------------------------------------*/

/* P */

@media (min-width: 1200px) {
  .g-ma-0--xl {
    margin: 0 !important;
  }

  .g-mx-0--xl {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .g-my-0--xl {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .g-ml-0--xl {
    margin-left: 0 !important;
  }

  .g-mr-0--xl {
    margin-right: 0 !important;
  }

  .g-mt-0--xl {
    margin-top: 0 !important;
  }

  .g-mb-0--xl {
    margin-bottom: 0 !important;
  }

  .g-mx-1--xl {
    margin-left: 0.0714285714rem !important;
    margin-right: 0.0714285714rem !important;
  }

  .g-mx-2--xl {
    margin-left: 0.1428571429rem !important;
    margin-right: 0.1428571429rem !important;
  }

  .g-mx-3--xl {
    margin-left: 0.2142857143rem !important;
    margin-right: 0.2142857143rem !important;
  }

  .g-mx-4--xl {
    margin-left: 0.2857142857rem !important;
    margin-right: 0.2857142857rem !important;
  }

  .g-mx-5--xl {
    margin-left: 0.3571428571rem !important;
    margin-right: 0.3571428571rem !important;
  }

  .g-mx-6--xl {
    margin-left: 0.4285714286rem !important;
    margin-right: 0.4285714286rem !important;
  }

  .g-mx-7--xl {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .g-mx-8--xl {
    margin-left: 0.5714285714rem !important;
    margin-right: 0.5714285714rem !important;
  }

  .g-mx-9--xl {
    margin-left: 0.6428571429rem !important;
    margin-right: 0.6428571429rem !important;
  }

  .g-mx-10--xl {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }

  .g-mx-10--xl {
    margin-left: 0.7142857143rem !important;
    margin-right: 0.7142857143rem !important;
  }

  .g-mx-15--xl {
    margin-left: 1.0714285714rem !important;
    margin-right: 1.0714285714rem !important;
  }

  .g-mx-20--xl {
    margin-left: 1.4285714286rem !important;
    margin-right: 1.4285714286rem !important;
  }

  .g-mx-25--xl {
    margin-left: 1.7857142857rem !important;
    margin-right: 1.7857142857rem !important;
  }

  .g-mx-30--xl {
    margin-left: 2.1428571429rem !important;
    margin-right: 2.1428571429rem !important;
  }

  .g-mx-35--xl {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .g-mx-40--xl {
    margin-left: 2.8571428571rem !important;
    margin-right: 2.8571428571rem !important;
  }

  .g-mx-45--xl {
    margin-left: 3.2142857143rem !important;
    margin-right: 3.2142857143rem !important;
  }

  .g-mx-50--xl {
    margin-left: 3.5714285714rem !important;
    margin-right: 3.5714285714rem !important;
  }

  .g-mx-55--xl {
    margin-left: 3.9285714286rem !important;
    margin-right: 3.9285714286rem !important;
  }

  .g-mx-60--xl {
    margin-left: 4.2857142857rem !important;
    margin-right: 4.2857142857rem !important;
  }

  .g-mx-65--xl {
    margin-left: 4.6428571429rem !important;
    margin-right: 4.6428571429rem !important;
  }

  .g-mx-70--xl {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .g-mx-75--xl {
    margin-left: 5.3571428571rem !important;
    margin-right: 5.3571428571rem !important;
  }

  .g-mx-80--xl {
    margin-left: 5.7142857143rem !important;
    margin-right: 5.7142857143rem !important;
  }

  .g-mx-85--xl {
    margin-left: 6.0714285714rem !important;
    margin-right: 6.0714285714rem !important;
  }

  .g-mx-90--xl {
    margin-left: 6.4285714286rem !important;
    margin-right: 6.4285714286rem !important;
  }

  .g-mx-95--xl {
    margin-left: 6.7857142857rem !important;
    margin-right: 6.7857142857rem !important;
  }

  .g-mx-100--xl {
    margin-left: 7.1428571429rem !important;
    margin-right: 7.1428571429rem !important;
  }

  .g-my-1--xl {
    margin-top: 0.0714285714rem !important;
    margin-bottom: 0.0714285714rem !important;
  }

  .g-my-2--xl {
    margin-top: 0.1428571429rem !important;
    margin-bottom: 0.1428571429rem !important;
  }

  .g-my-3--xl {
    margin-top: 0.2142857143rem !important;
    margin-bottom: 0.2142857143rem !important;
  }

  .g-my-4--xl {
    margin-top: 0.2857142857rem !important;
    margin-bottom: 0.2857142857rem !important;
  }

  .g-my-5--xl {
    margin-top: 0.3571428571rem !important;
    margin-bottom: 0.3571428571rem !important;
  }

  .g-my-6--xl {
    margin-top: 0.4285714286rem !important;
    margin-bottom: 0.4285714286rem !important;
  }

  .g-my-7--xl {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .g-my-8--xl {
    margin-top: 0.5714285714rem !important;
    margin-bottom: 0.5714285714rem !important;
  }

  .g-my-9--xl {
    margin-top: 0.6428571429rem !important;
    margin-bottom: 0.6428571429rem !important;
  }

  .g-my-10--xl {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }

  .g-my-10--xl {
    margin-top: 0.7142857143rem !important;
    margin-bottom: 0.7142857143rem !important;
  }

  .g-my-15--xl {
    margin-top: 1.0714285714rem !important;
    margin-bottom: 1.0714285714rem !important;
  }

  .g-my-20--xl {
    margin-top: 1.4285714286rem !important;
    margin-bottom: 1.4285714286rem !important;
  }

  .g-my-25--xl {
    margin-top: 1.7857142857rem !important;
    margin-bottom: 1.7857142857rem !important;
  }

  .g-my-30--xl {
    margin-top: 2.1428571429rem !important;
    margin-bottom: 2.1428571429rem !important;
  }

  .g-my-35--xl {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .g-my-40--xl {
    margin-top: 2.8571428571rem !important;
    margin-bottom: 2.8571428571rem !important;
  }

  .g-my-45--xl {
    margin-top: 3.2142857143rem !important;
    margin-bottom: 3.2142857143rem !important;
  }

  .g-my-50--xl {
    margin-top: 3.5714285714rem !important;
    margin-bottom: 3.5714285714rem !important;
  }

  .g-my-55--xl {
    margin-top: 3.9285714286rem !important;
    margin-bottom: 3.9285714286rem !important;
  }

  .g-my-60--xl {
    margin-top: 4.2857142857rem !important;
    margin-bottom: 4.2857142857rem !important;
  }

  .g-my-65--xl {
    margin-top: 4.6428571429rem !important;
    margin-bottom: 4.6428571429rem !important;
  }

  .g-my-70--xl {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .g-my-75--xl {
    margin-top: 5.3571428571rem !important;
    margin-bottom: 5.3571428571rem !important;
  }

  .g-my-80--xl {
    margin-top: 5.7142857143rem !important;
    margin-bottom: 5.7142857143rem !important;
  }

  .g-my-85--xl {
    margin-top: 6.0714285714rem !important;
    margin-bottom: 6.0714285714rem !important;
  }

  .g-my-90--xl {
    margin-top: 6.4285714286rem !important;
    margin-bottom: 6.4285714286rem !important;
  }

  .g-my-95--xl {
    margin-top: 6.7857142857rem !important;
    margin-bottom: 6.7857142857rem !important;
  }

  .g-my-100--xl {
    margin-top: 7.1428571429rem !important;
    margin-bottom: 7.1428571429rem !important;
  }

  .g-mt-1--xl {
    margin-top: 0.0714285714rem !important;
  }

  .g-mt-minus-1--xl {
    margin-top: -0.0714285714rem !important;
  }

  .g-mt-2--xl {
    margin-top: 0.1428571429rem !important;
  }

  .g-mt-minus-2--xl {
    margin-top: -0.1428571429rem !important;
  }

  .g-mt-3--xl {
    margin-top: 0.2142857143rem !important;
  }

  .g-mt-minus-3--xl {
    margin-top: -0.2142857143rem !important;
  }

  .g-mt-4--xl {
    margin-top: 0.2857142857rem !important;
  }

  .g-mt-minus-4--xl {
    margin-top: -0.2857142857rem !important;
  }

  .g-mt-5--xl {
    margin-top: 0.3571428571rem !important;
  }

  .g-mt-minus-5--xl {
    margin-top: -0.3571428571rem !important;
  }

  .g-mt-6--xl {
    margin-top: 0.4285714286rem !important;
  }

  .g-mt-minus-6--xl {
    margin-top: -0.4285714286rem !important;
  }

  .g-mt-7--xl {
    margin-top: 0.5rem !important;
  }

  .g-mt-minus-7--xl {
    margin-top: -0.5rem !important;
  }

  .g-mt-8--xl {
    margin-top: 0.5714285714rem !important;
  }

  .g-mt-minus-8--xl {
    margin-top: -0.5714285714rem !important;
  }

  .g-mt-9--xl {
    margin-top: 0.6428571429rem !important;
  }

  .g-mt-minus-9--xl {
    margin-top: -0.6428571429rem !important;
  }

  .g-mt-10--xl {
    margin-top: 0.7142857143rem !important;
  }

  .g-mt-minus-10--xl {
    margin-top: -0.7142857143rem !important;
  }

  .g-mt-11--xl {
    margin-top: 0.7857142857rem !important;
  }

  .g-mt-minus-11--xl {
    margin-top: -0.7857142857rem !important;
  }

  .g-mt-12--xl {
    margin-top: 0.8571428571rem !important;
  }

  .g-mt-minus-12--xl {
    margin-top: -0.8571428571rem !important;
  }

  .g-mt-13--xl {
    margin-top: 0.9285714286rem !important;
  }

  .g-mt-minus-13--xl {
    margin-top: -0.9285714286rem !important;
  }

  .g-mt-14--xl {
    margin-top: 1rem !important;
  }

  .g-mt-minus-14--xl {
    margin-top: -1rem !important;
  }

  .g-mt-15--xl {
    margin-top: 1.0714285714rem !important;
  }

  .g-mt-minus-15--xl {
    margin-top: -1.0714285714rem !important;
  }

  .g-mt-16--xl {
    margin-top: 1.1428571429rem !important;
  }

  .g-mt-minus-16--xl {
    margin-top: -1.1428571429rem !important;
  }

  .g-mt-17--xl {
    margin-top: 1.2142857143rem !important;
  }

  .g-mt-minus-17--xl {
    margin-top: -1.2142857143rem !important;
  }

  .g-mt-18--xl {
    margin-top: 1.2857142857rem !important;
  }

  .g-mt-minus-18--xl {
    margin-top: -1.2857142857rem !important;
  }

  .g-mt-19--xl {
    margin-top: 1.3571428571rem !important;
  }

  .g-mt-minus-19--xl {
    margin-top: -1.3571428571rem !important;
  }

  .g-mt-20--xl {
    margin-top: 1.4285714286rem !important;
  }

  .g-mt-minus-20--xl {
    margin-top: -1.4285714286rem !important;
  }

  .g-mt-10--xl {
    margin-top: 0.7142857143rem !important;
  }

  .g-mt-minus-10--xl {
    margin-top: -0.7142857143rem !important;
  }

  .g-mt-15--xl {
    margin-top: 1.0714285714rem !important;
  }

  .g-mt-minus-15--xl {
    margin-top: -1.0714285714rem !important;
  }

  .g-mt-20--xl {
    margin-top: 1.4285714286rem !important;
  }

  .g-mt-minus-20--xl {
    margin-top: -1.4285714286rem !important;
  }

  .g-mt-25--xl {
    margin-top: 1.7857142857rem !important;
  }

  .g-mt-minus-25--xl {
    margin-top: -1.7857142857rem !important;
  }

  .g-mt-30--xl {
    margin-top: 2.1428571429rem !important;
  }

  .g-mt-minus-30--xl {
    margin-top: -2.1428571429rem !important;
  }

  .g-mt-35--xl {
    margin-top: 2.5rem !important;
  }

  .g-mt-minus-35--xl {
    margin-top: -2.5rem !important;
  }

  .g-mt-40--xl {
    margin-top: 2.8571428571rem !important;
  }

  .g-mt-minus-40--xl {
    margin-top: -2.8571428571rem !important;
  }

  .g-mt-45--xl {
    margin-top: 3.2142857143rem !important;
  }

  .g-mt-minus-45--xl {
    margin-top: -3.2142857143rem !important;
  }

  .g-mt-50--xl {
    margin-top: 3.5714285714rem !important;
  }

  .g-mt-minus-50--xl {
    margin-top: -3.5714285714rem !important;
  }

  .g-mt-55--xl {
    margin-top: 3.9285714286rem !important;
  }

  .g-mt-minus-55--xl {
    margin-top: -3.9285714286rem !important;
  }

  .g-mt-60--xl {
    margin-top: 4.2857142857rem !important;
  }

  .g-mt-minus-60--xl {
    margin-top: -4.2857142857rem !important;
  }

  .g-mt-65--xl {
    margin-top: 4.6428571429rem !important;
  }

  .g-mt-minus-65--xl {
    margin-top: -4.6428571429rem !important;
  }

  .g-mt-70--xl {
    margin-top: 5rem !important;
  }

  .g-mt-minus-70--xl {
    margin-top: -5rem !important;
  }

  .g-mt-75--xl {
    margin-top: 5.3571428571rem !important;
  }

  .g-mt-minus-75--xl {
    margin-top: -5.3571428571rem !important;
  }

  .g-mt-80--xl {
    margin-top: 5.7142857143rem !important;
  }

  .g-mt-minus-80--xl {
    margin-top: -5.7142857143rem !important;
  }

  .g-mt-85--xl {
    margin-top: 6.0714285714rem !important;
  }

  .g-mt-minus-85--xl {
    margin-top: -6.0714285714rem !important;
  }

  .g-mt-90--xl {
    margin-top: 6.4285714286rem !important;
  }

  .g-mt-minus-90--xl {
    margin-top: -6.4285714286rem !important;
  }

  .g-mt-95--xl {
    margin-top: 6.7857142857rem !important;
  }

  .g-mt-minus-95--xl {
    margin-top: -6.7857142857rem !important;
  }

  .g-mt-100--xl {
    margin-top: 7.1428571429rem !important;
  }

  .g-mt-minus-100--xl {
    margin-top: -7.1428571429rem !important;
  }

  .g-mt-105--xl {
    margin-top: 7.5rem !important;
  }

  .g-mt-minus-105--xl {
    margin-top: -7.5rem !important;
  }

  .g-mt-110--xl {
    margin-top: 7.8571428571rem !important;
  }

  .g-mt-minus-110--xl {
    margin-top: -7.8571428571rem !important;
  }

  .g-mt-115--xl {
    margin-top: 8.2142857143rem !important;
  }

  .g-mt-minus-115--xl {
    margin-top: -8.2142857143rem !important;
  }

  .g-mt-120--xl {
    margin-top: 8.5714285714rem !important;
  }

  .g-mt-minus-120--xl {
    margin-top: -8.5714285714rem !important;
  }

  .g-mt-125--xl {
    margin-top: 8.9285714286rem !important;
  }

  .g-mt-minus-125--xl {
    margin-top: -8.9285714286rem !important;
  }

  .g-mt-130--xl {
    margin-top: 9.2857142857rem !important;
  }

  .g-mt-minus-130--xl {
    margin-top: -9.2857142857rem !important;
  }

  .g-mt-135--xl {
    margin-top: 9.6428571429rem !important;
  }

  .g-mt-minus-135--xl {
    margin-top: -9.6428571429rem !important;
  }

  .g-mt-140--xl {
    margin-top: 10rem !important;
  }

  .g-mt-minus-140--xl {
    margin-top: -10rem !important;
  }

  .g-mt-145--xl {
    margin-top: 10.3571428571rem !important;
  }

  .g-mt-minus-145--xl {
    margin-top: -10.3571428571rem !important;
  }

  .g-mt-150--xl {
    margin-top: 10.7142857143rem !important;
  }

  .g-mt-minus-150--xl {
    margin-top: -10.7142857143rem !important;
  }

  .g-mt-155--xl {
    margin-top: 11.0714285714rem !important;
  }

  .g-mt-minus-155--xl {
    margin-top: -11.0714285714rem !important;
  }

  .g-mt-160--xl {
    margin-top: 11.4285714286rem !important;
  }

  .g-mt-minus-160--xl {
    margin-top: -11.4285714286rem !important;
  }

  .g-mt-165--xl {
    margin-top: 11.7857142857rem !important;
  }

  .g-mt-minus-165--xl {
    margin-top: -11.7857142857rem !important;
  }

  .g-mt-170--xl {
    margin-top: 12.1428571429rem !important;
  }

  .g-mt-minus-170--xl {
    margin-top: -12.1428571429rem !important;
  }

  .g-mb-1--xl {
    margin-bottom: 0.0714285714rem !important;
  }

  .g-mb-minus-1--xl {
    margin-bottom: -0.0714285714rem !important;
  }

  .g-mb-2--xl {
    margin-bottom: 0.1428571429rem !important;
  }

  .g-mb-minus-2--xl {
    margin-bottom: -0.1428571429rem !important;
  }

  .g-mb-3--xl {
    margin-bottom: 0.2142857143rem !important;
  }

  .g-mb-minus-3--xl {
    margin-bottom: -0.2142857143rem !important;
  }

  .g-mb-4--xl {
    margin-bottom: 0.2857142857rem !important;
  }

  .g-mb-minus-4--xl {
    margin-bottom: -0.2857142857rem !important;
  }

  .g-mb-5--xl {
    margin-bottom: 0.3571428571rem !important;
  }

  .g-mb-minus-5--xl {
    margin-bottom: -0.3571428571rem !important;
  }

  .g-mb-6--xl {
    margin-bottom: 0.4285714286rem !important;
  }

  .g-mb-minus-6--xl {
    margin-bottom: -0.4285714286rem !important;
  }

  .g-mb-7--xl {
    margin-bottom: 0.5rem !important;
  }

  .g-mb-minus-7--xl {
    margin-bottom: -0.5rem !important;
  }

  .g-mb-8--xl {
    margin-bottom: 0.5714285714rem !important;
  }

  .g-mb-minus-8--xl {
    margin-bottom: -0.5714285714rem !important;
  }

  .g-mb-9--xl {
    margin-bottom: 0.6428571429rem !important;
  }

  .g-mb-minus-9--xl {
    margin-bottom: -0.6428571429rem !important;
  }

  .g-mb-10--xl {
    margin-bottom: 0.7142857143rem !important;
  }

  .g-mb-minus-10--xl {
    margin-bottom: -0.7142857143rem !important;
  }

  .g-mb-11--xl {
    margin-bottom: 0.7857142857rem !important;
  }

  .g-mb-minus-11--xl {
    margin-bottom: -0.7857142857rem !important;
  }

  .g-mb-12--xl {
    margin-bottom: 0.8571428571rem !important;
  }

  .g-mb-minus-12--xl {
    margin-bottom: -0.8571428571rem !important;
  }

  .g-mb-13--xl {
    margin-bottom: 0.9285714286rem !important;
  }

  .g-mb-minus-13--xl {
    margin-bottom: -0.9285714286rem !important;
  }

  .g-mb-14--xl {
    margin-bottom: 1rem !important;
  }

  .g-mb-minus-14--xl {
    margin-bottom: -1rem !important;
  }

  .g-mb-15--xl {
    margin-bottom: 1.0714285714rem !important;
  }

  .g-mb-minus-15--xl {
    margin-bottom: -1.0714285714rem !important;
  }

  .g-mb-16--xl {
    margin-bottom: 1.1428571429rem !important;
  }

  .g-mb-minus-16--xl {
    margin-bottom: -1.1428571429rem !important;
  }

  .g-mb-17--xl {
    margin-bottom: 1.2142857143rem !important;
  }

  .g-mb-minus-17--xl {
    margin-bottom: -1.2142857143rem !important;
  }

  .g-mb-18--xl {
    margin-bottom: 1.2857142857rem !important;
  }

  .g-mb-minus-18--xl {
    margin-bottom: -1.2857142857rem !important;
  }

  .g-mb-19--xl {
    margin-bottom: 1.3571428571rem !important;
  }

  .g-mb-minus-19--xl {
    margin-bottom: -1.3571428571rem !important;
  }

  .g-mb-20--xl {
    margin-bottom: 1.4285714286rem !important;
  }

  .g-mb-minus-20--xl {
    margin-bottom: -1.4285714286rem !important;
  }

  .g-mb-10--xl {
    margin-bottom: 0.7142857143rem !important;
  }

  .g-mb-15--xl {
    margin-bottom: 1.0714285714rem !important;
  }

  .g-mb-20--xl {
    margin-bottom: 1.4285714286rem !important;
  }

  .g-mb-25--xl {
    margin-bottom: 1.7857142857rem !important;
  }

  .g-mb-30--xl {
    margin-bottom: 2.1428571429rem !important;
  }

  .g-mb-35--xl {
    margin-bottom: 2.5rem !important;
  }

  .g-mb-40--xl {
    margin-bottom: 2.8571428571rem !important;
  }

  .g-mb-45--xl {
    margin-bottom: 3.2142857143rem !important;
  }

  .g-mb-50--xl {
    margin-bottom: 3.5714285714rem !important;
  }

  .g-mb-55--xl {
    margin-bottom: 3.9285714286rem !important;
  }

  .g-mb-60--xl {
    margin-bottom: 4.2857142857rem !important;
  }

  .g-mb-65--xl {
    margin-bottom: 4.6428571429rem !important;
  }

  .g-mb-70--xl {
    margin-bottom: 5rem !important;
  }

  .g-mb-75--xl {
    margin-bottom: 5.3571428571rem !important;
  }

  .g-mb-80--xl {
    margin-bottom: 5.7142857143rem !important;
  }

  .g-mb-85--xl {
    margin-bottom: 6.0714285714rem !important;
  }

  .g-mb-90--xl {
    margin-bottom: 6.4285714286rem !important;
  }

  .g-mb-95--xl {
    margin-bottom: 6.7857142857rem !important;
  }

  .g-mb-100--xl {
    margin-bottom: 7.1428571429rem !important;
  }

  .g-mb-105--xl {
    margin-bottom: 7.5rem !important;
  }

  .g-mb-110--xl {
    margin-bottom: 7.8571428571rem !important;
  }

  .g-mb-115--xl {
    margin-bottom: 8.2142857143rem !important;
  }

  .g-mb-120--xl {
    margin-bottom: 8.5714285714rem !important;
  }

  .g-mb-125--xl {
    margin-bottom: 8.9285714286rem !important;
  }

  .g-mb-130--xl {
    margin-bottom: 9.2857142857rem !important;
  }

  .g-mb-135--xl {
    margin-bottom: 9.6428571429rem !important;
  }

  .g-mb-140--xl {
    margin-bottom: 10rem !important;
  }

  .g-mb-145--xl {
    margin-bottom: 10.3571428571rem !important;
  }

  .g-mb-150--xl {
    margin-bottom: 10.7142857143rem !important;
  }

  .g-mb-155--xl {
    margin-bottom: 11.0714285714rem !important;
  }

  .g-mb-160--xl {
    margin-bottom: 11.4285714286rem !important;
  }

  .g-mb-165--xl {
    margin-bottom: 11.7857142857rem !important;
  }

  .g-mb-170--xl {
    margin-bottom: 12.1428571429rem !important;
  }

  .g-ml-1--xl {
    margin-left: 0.0714285714rem !important;
  }

  .g-ml-minus-1--xl {
    margin-left: -0.0714285714rem !important;
  }

  .g-ml-2--xl {
    margin-left: 0.1428571429rem !important;
  }

  .g-ml-minus-2--xl {
    margin-left: -0.1428571429rem !important;
  }

  .g-ml-3--xl {
    margin-left: 0.2142857143rem !important;
  }

  .g-ml-minus-3--xl {
    margin-left: -0.2142857143rem !important;
  }

  .g-ml-4--xl {
    margin-left: 0.2857142857rem !important;
  }

  .g-ml-minus-4--xl {
    margin-left: -0.2857142857rem !important;
  }

  .g-ml-5--xl {
    margin-left: 0.3571428571rem !important;
  }

  .g-ml-minus-5--xl {
    margin-left: -0.3571428571rem !important;
  }

  .g-ml-6--xl {
    margin-left: 0.4285714286rem !important;
  }

  .g-ml-minus-6--xl {
    margin-left: -0.4285714286rem !important;
  }

  .g-ml-7--xl {
    margin-left: 0.5rem !important;
  }

  .g-ml-minus-7--xl {
    margin-left: -0.5rem !important;
  }

  .g-ml-8--xl {
    margin-left: 0.5714285714rem !important;
  }

  .g-ml-minus-8--xl {
    margin-left: -0.5714285714rem !important;
  }

  .g-ml-9--xl {
    margin-left: 0.6428571429rem !important;
  }

  .g-ml-minus-9--xl {
    margin-left: -0.6428571429rem !important;
  }

  .g-ml-10--xl {
    margin-left: 0.7142857143rem !important;
  }

  .g-ml-minus-10--xl {
    margin-left: -0.7142857143rem !important;
  }

  .g-ml-5--xl {
    margin-left: 0.3571428571rem !important;
  }

  .g-ml-minus-5--xl {
    margin-left: -0.3571428571rem !important;
  }

  .g-ml-10--xl {
    margin-left: 0.7142857143rem !important;
  }

  .g-ml-minus-10--xl {
    margin-left: -0.7142857143rem !important;
  }

  .g-ml-15--xl {
    margin-left: 1.0714285714rem !important;
  }

  .g-ml-minus-15--xl {
    margin-left: -1.0714285714rem !important;
  }

  .g-ml-20--xl {
    margin-left: 1.4285714286rem !important;
  }

  .g-ml-minus-20--xl {
    margin-left: -1.4285714286rem !important;
  }

  .g-ml-25--xl {
    margin-left: 1.7857142857rem !important;
  }

  .g-ml-minus-25--xl {
    margin-left: -1.7857142857rem !important;
  }

  .g-ml-30--xl {
    margin-left: 2.1428571429rem !important;
  }

  .g-ml-minus-30--xl {
    margin-left: -2.1428571429rem !important;
  }

  .g-ml-35--xl {
    margin-left: 2.5rem !important;
  }

  .g-ml-minus-35--xl {
    margin-left: -2.5rem !important;
  }

  .g-ml-40--xl {
    margin-left: 2.8571428571rem !important;
  }

  .g-ml-minus-40--xl {
    margin-left: -2.8571428571rem !important;
  }

  .g-ml-45--xl {
    margin-left: 3.2142857143rem !important;
  }

  .g-ml-minus-45--xl {
    margin-left: -3.2142857143rem !important;
  }

  .g-ml-50--xl {
    margin-left: 3.5714285714rem !important;
  }

  .g-ml-minus-50--xl {
    margin-left: -3.5714285714rem !important;
  }

  .g-mr-1--xl {
    margin-right: 0.0714285714rem !important;
  }

  .g-mr-minus-1--xl {
    margin-right: -0.0714285714rem !important;
  }

  .g-mr-2--xl {
    margin-right: 0.1428571429rem !important;
  }

  .g-mr-minus-2--xl {
    margin-right: -0.1428571429rem !important;
  }

  .g-mr-3--xl {
    margin-right: 0.2142857143rem !important;
  }

  .g-mr-minus-3--xl {
    margin-right: -0.2142857143rem !important;
  }

  .g-mr-4--xl {
    margin-right: 0.2857142857rem !important;
  }

  .g-mr-minus-4--xl {
    margin-right: -0.2857142857rem !important;
  }

  .g-mr-5--xl {
    margin-right: 0.3571428571rem !important;
  }

  .g-mr-minus-5--xl {
    margin-right: -0.3571428571rem !important;
  }

  .g-mr-6--xl {
    margin-right: 0.4285714286rem !important;
  }

  .g-mr-minus-6--xl {
    margin-right: -0.4285714286rem !important;
  }

  .g-mr-7--xl {
    margin-right: 0.5rem !important;
  }

  .g-mr-minus-7--xl {
    margin-right: -0.5rem !important;
  }

  .g-mr-8--xl {
    margin-right: 0.5714285714rem !important;
  }

  .g-mr-minus-8--xl {
    margin-right: -0.5714285714rem !important;
  }

  .g-mr-9--xl {
    margin-right: 0.6428571429rem !important;
  }

  .g-mr-minus-9--xl {
    margin-right: -0.6428571429rem !important;
  }

  .g-mr-10--xl {
    margin-right: 0.7142857143rem !important;
  }

  .g-mr-minus-10--xl {
    margin-right: -0.7142857143rem !important;
  }

  .g-mr-5--xl {
    margin-right: 0.3571428571rem !important;
  }

  .g-mr-10--xl {
    margin-right: 0.7142857143rem !important;
  }

  .g-mr-15--xl {
    margin-right: 1.0714285714rem !important;
  }

  .g-mr-20--xl {
    margin-right: 1.4285714286rem !important;
  }

  .g-mr-25--xl {
    margin-right: 1.7857142857rem !important;
  }

  .g-mr-30--xl {
    margin-right: 2.1428571429rem !important;
  }

  .g-mr-35--xl {
    margin-right: 2.5rem !important;
  }

  .g-mr-40--xl {
    margin-right: 2.8571428571rem !important;
  }

  .g-mr-45--xl {
    margin-right: 3.2142857143rem !important;
  }

  .g-mr-50--xl {
    margin-right: 3.5714285714rem !important;
  }
}

/* Margins Around
------------------------------------*/

.g-ma-1 {
  margin: 0.0714285714rem !important;
}

.g-ma-3 {
  margin: 0.2142857143rem !important;
}

.g-ma-5 {
  margin: 0.3571428571rem !important;
}

.g-ma-10 {
  margin: 0.7142857143rem !important;
}

.g-ma-20 {
  margin: 1.4285714286rem !important;
}

.g-ma-minus-1 {
  margin: -0.0714285714rem !important;
}

/* Minus Margins
------------------------------------*/

/* Minus Margin Top */

.g-mt-minus-1 {
  margin-top: -0.0714285714rem;
}

.g-mt-minus-20 {
  margin-top: -1.4285714286rem;
}

.g-mt-minus-25 {
  margin-top: -1.7857142857rem;
}

.g-mt-minus-30 {
  margin-top: -2.1428571429rem;
}

.g-mt-minus-40 {
  margin-top: -2.8571428571rem;
}

.g-mt-minus-70 {
  margin-top: -5rem;
}

.g-mt-minus-73 {
  margin-top: -5.1428571429rem !important;
}

.g-mt-minus-120 {
  margin-top: -8.5714285714rem;
}

.g-mt-minus-200 {
  margin-top: -14.2857142857rem;
}

.g-mt-minus-300 {
  margin-top: -21.4285714286rem;
}

/* Minus Margin Bottom */

.g-mb-minus-70 {
  margin-bottom: -5rem;
}

/* Minus Margin Left */

.g-ml-minus-20 {
  margin-left: -1.4285714286rem;
}

.g-ml-minus-23 {
  margin-left: -1.6428571429rem;
}

.g-ml-minus-35 {
  margin-left: -2.5rem;
}

.g-ml-minus-55 {
  margin-left: -3.9285714286rem;
}

.g-ml-minus-25 {
  margin-left: -1.7857142857rem;
}

.g-ml-minus-82 {
  margin-left: -5.8571428571rem;
}

.g-ml-minus-90 {
  margin-left: -6.4285714286rem;
}

.g-ml-minus-100 {
  margin-left: -7.1428571429rem;
}

.g-ml-minus-118 {
  margin-left: -8.4285714286rem;
}

.g-ml-minus-142 {
  margin-left: -10.1428571429rem;
}

/* Minus Margin Right */

.g-mr-minus-50 {
  margin-right: -3.5714285714rem;
}

.g-mr-minus-100 {
  margin-right: -7.1428571429rem;
}

/* Margin Left and Right */

.g-mx-minus-1 {
  margin-left: -0.0714285714rem;
  margin-right: -0.0714285714rem;
}

.g-mx-minus-2 {
  margin-left: -0.1428571429rem;
  margin-right: -0.1428571429rem;
}

.g-mx-minus-4 {
  margin-left: -0.2857142857rem;
  margin-right: -0.2857142857rem;
}

.g-mx-minus-5 {
  margin-left: -0.3571428571rem;
  margin-right: -0.3571428571rem;
}

.g-mx-minus-15 {
  margin-left: -1.0714285714rem;
  margin-right: -1.0714285714rem;
}

.g-mx-minus-25 {
  margin-left: -1.7857142857rem;
  margin-right: -1.7857142857rem;
}

.g-mx-minus-30 {
  margin-left: -2.1428571429rem;
  margin-right: -2.1428571429rem;
}

/* Custon Spaces
------------------------------------*/

/* Margin Top */

.g-mt-10x {
  margin-top: 10%;
  /* O */
}

.g-mt-21 {
  margin-top: 1.5rem;
}

.g-mt-22 {
  margin-top: 1.5714285714rem;
}

.g-mt-28 {
  margin-top: 2rem !important;
}

.g-mt-32 {
  margin-top: 2.2857142857rem !important;
}

.g-mt-57 {
  margin-top: 4.0714285714rem !important;
}

.g-mt-500 {
  margin-top: 35.7142857143rem;
}

/* Margin Bottom */

.g-mb-23 {
  margin-bottom: 1.6428571429rem;
}

.g-mb-28 {
  margin-bottom: 2rem;
}

.g-mb-500 {
  margin-bottom: 35.7142857143rem;
}

/* Margin Left */

.g-ml-10x {
  margin-left: 10%;
  /* O */
}

.g-ml-12 {
  margin-left: 0.8571428571rem;
}

.g-ml-13 {
  margin-left: 0.9285714286rem;
}

.g-ml-20 {
  margin-left: 1.4285714286rem;
}

.g-ml-25 {
  margin-left: 1.7857142857rem;
}

.g-ml-35 {
  margin-left: 2.5rem;
}

.g-ml-43 {
  margin-left: 3.0714285714rem;
}

.g-mr-43 {
  margin-right: 3.0714285714rem;
}

.g-ml-50 {
  margin-left: 3.5714285714rem;
}

.g-ml-60 {
  margin-left: 4.2857142857rem;
}

.g-ml-75 {
  margin-left: 5.3571428571rem;
}

.g-ml-82 {
  margin-left: 5.8571428571rem;
}

.g-ml-83 {
  margin-left: 5.9285714286rem;
}

.g-ml-85 {
  margin-left: 6.0714285714rem;
}

.g-ml-105 {
  margin-left: 7.5rem;
}

.g-ml-118 {
  margin-left: 8.4285714286rem;
}

.g-ml-142 {
  margin-left: 10.1428571429rem;
}

/* Margin Right */

.g-mr-12 {
  margin-right: 0.8571428571rem;
}

.g-mr-60 {
  margin-right: 4.2857142857rem;
}

.g-mr-63 {
  margin-right: 4.5rem;
}

.g-mr-75 {
  margin-right: 5.3571428571rem;
}

.g-mr-85 {
  margin-right: 6.0714285714rem !important;
}

.g-mr-minus-10 {
  margin-right: -0.7142857143rem;
}

.g-mr-minus-13 {
  margin-right: -0.9285714286rem;
}

.g-mr-minus-15 {
  margin-right: -1.0714285714rem;
}

.g-mr-minus-23 {
  margin-right: -1.6428571429rem;
}

.g-mr-minus-30 {
  margin-right: -2.1428571429rem;
}

/* Margin Left and Right */

.g-mx-minus-10 {
  margin-left: -0.7142857143rem;
  margin-right: -0.7142857143rem;
}

.g-mx-minus-15 {
  margin-left: -1.0714285714rem;
  margin-right: -1.0714285714rem;
}

.g-mx-minus-20 {
  margin-left: -1.4285714286rem;
  margin-right: -1.4285714286rem;
}

.g-mx-minus-25 {
  margin-left: -1.7857142857rem;
  margin-right: -1.7857142857rem;
}

.g-mx-minus-30 {
  margin-left: -2.1428571429rem;
  margin-right: -2.1428571429rem;
}

/* Margin Top and Bottom */

.g-my-minus-1 {
  margin-top: -0.0714285714rem;
  margin-bottom: -0.0714285714rem;
}

.g-my-minus-2 {
  margin-top: -0.1428571429rem;
  margin-bottom: -0.1428571429rem;
}

.g-my-minus-10 {
  margin-top: -0.7142857143rem;
  margin-bottom: -0.7142857143rem;
}

.g-m-reset {
  margin: 0 !important;
}

@media (min-width: 576px) {
  .g-mb-0--sm {
    margin-bottom: 0 !important;
  }

  .g-mx-minus-10--sm {
    margin-left: -0.7142857143rem;
    margin-right: -0.7142857143rem;
  }

  .g-my-minus-5--sm {
    margin-top: -0.3571428571rem;
    margin-bottom: -0.3571428571rem;
  }

  .g-my-minus-10--sm {
    margin-top: -0.7142857143rem;
    margin-bottom: -0.7142857143rem;
  }

  .g-mx-minus-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .g-my-minus-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .g-mr-12--sm {
    margin-right: 0.8571428571rem;
  }
}

@media (min-width: 768px) {
  .g-ml-0--md {
    margin-left: 0 !important;
  }

  .g-mr-0--md {
    margin-right: 0 !important;
  }

  .g-mx-12--md {
    margin-left: 0.8571428571rem !important;
    margin-right: 0.8571428571rem !important;
  }

  .g-ml-12--md {
    margin-left: 0.8571428571rem !important;
  }

  .g-mr-12--md {
    margin-right: 0.8571428571rem !important;
  }

  .g-mb-0--md {
    margin-bottom: 0 !important;
  }

  .g-ml-minus-1--md {
    margin-left: -0.0714285714rem;
  }

  .g-ml-minus-9--md {
    margin-left: -0.6428571429rem !important;
  }

  .g-ml-minus-15--md {
    margin-left: -1.0714285714rem !important;
  }

  .g-ml-minus-23--md {
    margin-left: -1.6428571429rem !important;
  }

  .g-ml-minus-25--md {
    margin-left: -1.7857142857rem !important;
  }

  .g-mr-minus-1--md {
    margin-right: -0.0714285714rem;
  }

  .g-mr-minus-9--md {
    margin-right: -0.6428571429rem !important;
  }

  .g-mr-minus-13--md {
    margin-right: -0.9285714286rem;
  }

  .g-mr-minus-23--md {
    margin-right: -1.6428571429rem;
  }

  .g-ml-minus-82--md {
    margin-left: -5.8571428571rem !important;
  }

  .g-mr-minus-82--md {
    margin-right: -5.8571428571rem !important;
  }

  .g-mr-60--md {
    margin-right: 4.2857142857rem !important;
  }

  .g-mr-63--md {
    margin-right: 4.5rem !important;
  }

  .g-ml-63--md {
    margin-left: 4.5rem !important;
  }

  .g-ml-85--md {
    margin-left: 6.0714285714rem !important;
  }

  .g-ml-250--md {
    margin-left: 17.8571428571rem !important;
  }

  .g-ml-minus-90--md {
    margin-left: -6.4285714286rem !important;
  }

  .g-m-reset--md {
    margin: 0 !important;
  }

  .g-mt-130--md {
    margin-top: 9.2857142857rem !important;
  }

  .g-mt-minus-76--md {
    margin-top: -5.4285714286rem !important;
  }

  .g-my-30--md {
    margin-top: 2.1428571429rem !important;
    margin-bpttpm: 2.1428571429rem !important;
  }
}

@media (min-width: 992px) {
  .g-mx-12--lg {
    margin-left: 0.8571428571rem !important;
    margin-right: 0.8571428571rem !important;
  }

  .g-mb-60--lg {
    margin-bottom: 4.2857142857rem !important;
  }

  .g-ml-12--lg {
    margin-left: 0.8571428571rem !important;
  }

  .g-mr-12--lg {
    margin-right: 0.8571428571rem !important;
  }

  .g-mr-minus-1--lg {
    margin-right: -0.0714285714rem;
  }

  .g-mr-minus-50--lg {
    margin-right: -3.5714285714rem;
  }

  .g-mr-minus-100--lg {
    margin-right: -7.1428571429rem;
  }

  .g-ml-minus-100--lg {
    margin-left: -7.1428571429rem;
  }

  .g-ml-minus-100 {
    margin-left: -7.1428571429rem;
  }

  .g-mx-minus-5--lg {
    margin-left: -0.3571428571rem;
    margin-right: -0.3571428571rem;
  }

  .g-mx-minus-10--lg {
    margin-left: -0.7142857143rem;
    margin-right: -0.7142857143rem;
  }

  .g-mx-minus-15--lg {
    margin-left: -1.0714285714rem;
    margin-right: -1.0714285714rem;
  }

  .g-ml-minus-50--lg {
    margin-left: -3.5714285714rem;
  }

  .g-m-reset--lg {
    margin: 0 !important;
  }

  .g-ml-100--lg {
    margin-left: 7.1428571429rem;
  }
}

@media (min-width: 1200px) {
  .g-mx-minus-10--xl {
    margin-left: -0.7142857143rem;
    margin-right: -0.7142857143rem;
  }

  .g-mx-minus-15--xl {
    margin-left: -1.0714285714rem;
    margin-right: -1.0714285714rem;
  }

  .g-m-reset--xl {
    margin: 0 !important;
  }

  .g-mr-0--xl {
    margin-right: 0px !important;
  }
}

/*------------------------------------
  Margins Extended
------------------------------------*/

@media (min-width: 992px) {
  /* Margin Top */

  .js-header-change-moment .g-mt-1--lg--scrolling {
    margin-top: 0.0714285714rem !important;
  }

  .js-header-change-moment .g-mt-2--lg--scrolling {
    margin-top: 0.1428571429rem !important;
  }

  .js-header-change-moment .g-mt-3--lg--scrolling {
    margin-top: 0.2142857143rem !important;
  }

  .js-header-change-moment .g-mt-4--lg--scrolling {
    margin-top: 0.2857142857rem !important;
  }

  .js-header-change-moment .g-mt-5--lg--scrolling {
    margin-top: 0.3571428571rem !important;
  }

  .js-header-change-moment .g-mt-6--lg--scrolling {
    margin-top: 0.4285714286rem !important;
  }

  .js-header-change-moment .g-mt-7--lg--scrolling {
    margin-top: 0.5rem !important;
  }

  .js-header-change-moment .g-mt-8--lg--scrolling {
    margin-top: 0.5714285714rem !important;
  }

  .js-header-change-moment .g-mt-9--lg--scrolling {
    margin-top: 0.6428571429rem !important;
  }

  .js-header-change-moment .g-mt-10--lg--scrolling {
    margin-top: 0.7142857143rem !important;
  }

  .js-header-change-moment .g-mt-11--lg--scrolling {
    margin-top: 0.7857142857rem !important;
  }

  .js-header-change-moment .g-mt-12--lg--scrolling {
    margin-top: 0.8571428571rem !important;
  }

  .js-header-change-moment .g-mt-13--lg--scrolling {
    margin-top: 0.9285714286rem !important;
  }

  .js-header-change-moment .g-mt-14--lg--scrolling {
    margin-top: 1rem !important;
  }

  .js-header-change-moment .g-mt-15--lg--scrolling {
    margin-top: 1.0714285714rem !important;
  }

  .js-header-change-moment .g-mt-16--lg--scrolling {
    margin-top: 1.1428571429rem !important;
  }

  .js-header-change-moment .g-mt-17--lg--scrolling {
    margin-top: 1.2142857143rem !important;
  }

  .js-header-change-moment .g-mt-18--lg--scrolling {
    margin-top: 1.2857142857rem !important;
  }

  .js-header-change-moment .g-mt-19--lg--scrolling {
    margin-top: 1.3571428571rem !important;
  }

  .js-header-change-moment .g-mt-20--lg--scrolling {
    margin-top: 1.4285714286rem !important;
  }

  .js-header-change-moment .g-mt-21--lg--scrolling {
    margin-top: 1.5rem !important;
  }

  .js-header-change-moment .g-mt-22--lg--scrolling {
    margin-top: 1.5714285714rem !important;
  }

  .js-header-change-moment .g-mt-23--lg--scrolling {
    margin-top: 1.6428571429rem !important;
  }

  .js-header-change-moment .g-mt-24--lg--scrolling {
    margin-top: 1.7142857143rem !important;
  }

  .js-header-change-moment .g-mt-25--lg--scrolling {
    margin-top: 1.7857142857rem !important;
  }
}

/*------------------------------------
  Padding Spaces
------------------------------------*/

/* Padding Spaces (xs)
------------------------------------*/

@media (min-width: 0) {
  .g-pa-0 {
    padding: 0 !important;
  }

  .g-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .g-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .g-pt-0 {
    padding-top: 0 !important;
  }

  .g-pr-0 {
    padding-right: 0 !important;
  }

  .g-pb-0 {
    padding-bottom: 0 !important;
  }

  .g-pl-0 {
    padding-left: 0 !important;
  }

  /* Padding Around */

  .g-pa-2 {
    padding: 0.1428571429rem !important;
  }

  .g-pa-3 {
    padding: 0.2142857143rem !important;
  }

  .g-pa-5 {
    padding: 0.3571428571rem !important;
  }

  .g-pa-7 {
    padding: 0.5rem !important;
  }

  .g-pa-10 {
    padding: 0.7142857143rem !important;
  }

  .g-pa-15 {
    padding: 1.0714285714rem !important;
  }

  .g-pa-20 {
    padding: 1.4285714286rem !important;
  }

  .g-pa-25 {
    padding: 1.7857142857rem !important;
  }

  .g-pa-30 {
    padding: 2.1428571429rem !important;
  }

  .g-pa-35 {
    padding: 2.5rem !important;
  }

  .g-pa-40 {
    padding: 2.8571428571rem !important;
  }

  .g-pa-45 {
    padding: 3.2142857143rem !important;
  }

  .g-pa-50 {
    padding: 3.5714285714rem !important;
  }

  .g-pa-55 {
    padding: 3.9285714286rem !important;
  }

  .g-pa-60 {
    padding: 4.2857142857rem !important;
  }

  .g-pa-65 {
    padding: 4.6428571429rem !important;
  }

  .g-pa-70 {
    padding: 5rem !important;
  }

  .g-pa-75 {
    padding: 5.3571428571rem !important;
  }

  .g-pa-80 {
    padding: 5.7142857143rem !important;
  }

  .g-pa-85 {
    padding: 6.0714285714rem !important;
  }

  .g-pa-90 {
    padding: 6.4285714286rem !important;
  }

  .g-pa-95 {
    padding: 6.7857142857rem !important;
  }

  .g-pa-100 {
    padding: 7.1428571429rem !important;
  }

  .g-pa-105 {
    padding: 7.5rem !important;
  }

  .g-pa-110 {
    padding: 7.8571428571rem !important;
  }

  .g-pa-115 {
    padding: 8.2142857143rem !important;
  }

  .g-pa-120 {
    padding: 8.5714285714rem !important;
  }

  .g-pa-125 {
    padding: 8.9285714286rem !important;
  }

  .g-pa-130 {
    padding: 9.2857142857rem !important;
  }

  .g-pa-135 {
    padding: 9.6428571429rem !important;
  }

  .g-pa-140 {
    padding: 10rem !important;
  }

  .g-pa-145 {
    padding: 10.3571428571rem !important;
  }

  .g-pa-150 {
    padding: 10.7142857143rem !important;
  }

  /* Padding X */

  .g-px-1 {
    padding-left: 0.0714285714rem !important;
    padding-right: 0.0714285714rem !important;
  }

  .g-px-2 {
    padding-left: 0.1428571429rem !important;
    padding-right: 0.1428571429rem !important;
  }

  .g-px-3 {
    padding-left: 0.2142857143rem !important;
    padding-right: 0.2142857143rem !important;
  }

  .g-px-4 {
    padding-left: 0.2857142857rem !important;
    padding-right: 0.2857142857rem !important;
  }

  .g-px-5 {
    padding-left: 0.3571428571rem !important;
    padding-right: 0.3571428571rem !important;
  }

  .g-px-6 {
    padding-left: 0.4285714286rem !important;
    padding-right: 0.4285714286rem !important;
  }

  .g-px-7 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .g-px-8 {
    padding-left: 0.5714285714rem !important;
    padding-right: 0.5714285714rem !important;
  }

  .g-px-9 {
    padding-left: 0.6428571429rem !important;
    padding-right: 0.6428571429rem !important;
  }

  .g-px-10 {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }

  .g-px-11 {
    padding-left: 0.7857142857rem !important;
    padding-right: 0.7857142857rem !important;
  }

  .g-px-12 {
    padding-left: 0.8571428571rem !important;
    padding-right: 0.8571428571rem !important;
  }

  .g-px-13 {
    padding-left: 0.9285714286rem !important;
    padding-right: 0.9285714286rem !important;
  }

  .g-px-14 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .g-px-15 {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }

  .g-px-16 {
    padding-left: 1.1428571429rem !important;
    padding-right: 1.1428571429rem !important;
  }

  .g-px-17 {
    padding-left: 1.2142857143rem !important;
    padding-right: 1.2142857143rem !important;
  }

  .g-px-18 {
    padding-left: 1.2857142857rem !important;
    padding-right: 1.2857142857rem !important;
  }

  .g-px-19 {
    padding-left: 1.3571428571rem !important;
    padding-right: 1.3571428571rem !important;
  }

  .g-px-10 {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }

  .g-px-15 {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }

  .g-px-20 {
    padding-left: 1.4285714286rem !important;
    padding-right: 1.4285714286rem !important;
  }

  .g-px-25 {
    padding-left: 1.7857142857rem !important;
    padding-right: 1.7857142857rem !important;
  }

  .g-px-30 {
    padding-left: 2.1428571429rem !important;
    padding-right: 2.1428571429rem !important;
  }

  .g-px-35 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .g-px-40 {
    padding-left: 2.8571428571rem !important;
    padding-right: 2.8571428571rem !important;
  }

  .g-px-45 {
    padding-left: 3.2142857143rem !important;
    padding-right: 3.2142857143rem !important;
  }

  .g-px-50 {
    padding-left: 3.5714285714rem !important;
    padding-right: 3.5714285714rem !important;
  }

  .g-px-55 {
    padding-left: 3.9285714286rem !important;
    padding-right: 3.9285714286rem !important;
  }

  .g-px-60 {
    padding-left: 4.2857142857rem !important;
    padding-right: 4.2857142857rem !important;
  }

  .g-px-65 {
    padding-left: 4.6428571429rem !important;
    padding-right: 4.6428571429rem !important;
  }

  .g-px-70 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .g-px-75 {
    padding-left: 5.3571428571rem !important;
    padding-right: 5.3571428571rem !important;
  }

  .g-px-80 {
    padding-left: 5.7142857143rem !important;
    padding-right: 5.7142857143rem !important;
  }

  .g-px-85 {
    padding-left: 6.0714285714rem !important;
    padding-right: 6.0714285714rem !important;
  }

  .g-px-90 {
    padding-left: 6.4285714286rem !important;
    padding-right: 6.4285714286rem !important;
  }

  .g-px-95 {
    padding-left: 6.7857142857rem !important;
    padding-right: 6.7857142857rem !important;
  }

  .g-px-100 {
    padding-left: 7.1428571429rem !important;
    padding-right: 7.1428571429rem !important;
  }

  .g-px-105 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .g-px-110 {
    padding-left: 7.8571428571rem !important;
    padding-right: 7.8571428571rem !important;
  }

  .g-px-115 {
    padding-left: 8.2142857143rem !important;
    padding-right: 8.2142857143rem !important;
  }

  .g-px-120 {
    padding-left: 8.5714285714rem !important;
    padding-right: 8.5714285714rem !important;
  }

  .g-px-125 {
    padding-left: 8.9285714286rem !important;
    padding-right: 8.9285714286rem !important;
  }

  .g-px-130 {
    padding-left: 9.2857142857rem !important;
    padding-right: 9.2857142857rem !important;
  }

  .g-px-135 {
    padding-left: 9.6428571429rem !important;
    padding-right: 9.6428571429rem !important;
  }

  .g-px-140 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .g-px-145 {
    padding-left: 10.3571428571rem !important;
    padding-right: 10.3571428571rem !important;
  }

  .g-px-150 {
    padding-left: 10.7142857143rem !important;
    padding-right: 10.7142857143rem !important;
  }

  /* Padding Y */

  .g-py-1 {
    padding-top: 0.0714285714rem !important;
    padding-bottom: 0.0714285714rem !important;
  }

  .g-py-2 {
    padding-top: 0.1428571429rem !important;
    padding-bottom: 0.1428571429rem !important;
  }

  .g-py-3 {
    padding-top: 0.2142857143rem !important;
    padding-bottom: 0.2142857143rem !important;
  }

  .g-py-4 {
    padding-top: 0.2857142857rem !important;
    padding-bottom: 0.2857142857rem !important;
  }

  .g-py-5 {
    padding-top: 0.3571428571rem !important;
    padding-bottom: 0.3571428571rem !important;
  }

  .g-py-6 {
    padding-top: 0.4285714286rem !important;
    padding-bottom: 0.4285714286rem !important;
  }

  .g-py-7 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .g-py-8 {
    padding-top: 0.5714285714rem !important;
    padding-bottom: 0.5714285714rem !important;
  }

  .g-py-9 {
    padding-top: 0.6428571429rem !important;
    padding-bottom: 0.6428571429rem !important;
  }

  .g-py-10 {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }

  .g-py-11 {
    padding-top: 0.7857142857rem !important;
    padding-bottom: 0.7857142857rem !important;
  }

  .g-py-12 {
    padding-top: 0.8571428571rem !important;
    padding-bottom: 0.8571428571rem !important;
  }

  .g-py-13 {
    padding-top: 0.9285714286rem !important;
    padding-bottom: 0.9285714286rem !important;
  }

  .g-py-14 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .g-py-15 {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }

  .g-py-16 {
    padding-top: 1.1428571429rem !important;
    padding-bottom: 1.1428571429rem !important;
  }

  .g-py-17 {
    padding-top: 1.2142857143rem !important;
    padding-bottom: 1.2142857143rem !important;
  }

  .g-py-18 {
    padding-top: 1.2857142857rem !important;
    padding-bottom: 1.2857142857rem !important;
  }

  .g-py-19 {
    padding-top: 1.3571428571rem !important;
    padding-bottom: 1.3571428571rem !important;
  }

  /* P */

  .g-py-10 {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }

  /* P */

  .g-py-15 {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }

  /* P */

  .g-py-20 {
    padding-top: 1.4285714286rem !important;
    padding-bottom: 1.4285714286rem !important;
  }

  /* P */

  .g-py-25 {
    padding-top: 1.7857142857rem !important;
    padding-bottom: 1.7857142857rem !important;
  }

  /* P */

  .g-py-30 {
    padding-top: 2.1428571429rem !important;
    padding-bottom: 2.1428571429rem !important;
  }

  /* P */

  .g-py-35 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  /* P */

  .g-py-40 {
    padding-top: 2.8571428571rem !important;
    padding-bottom: 2.8571428571rem !important;
  }

  /* P */

  .g-py-45 {
    padding-top: 3.2142857143rem !important;
    padding-bottom: 3.2142857143rem !important;
  }

  /* P */

  .g-py-50 {
    padding-top: 3.5714285714rem !important;
    padding-bottom: 3.5714285714rem !important;
  }

  /* P */

  .g-py-55 {
    padding-top: 3.9285714286rem !important;
    padding-bottom: 3.9285714286rem !important;
  }

  /* P */

  .g-py-60 {
    padding-top: 4.2857142857rem !important;
    padding-bottom: 4.2857142857rem !important;
  }

  /* P */

  .g-py-65 {
    padding-top: 4.6428571429rem !important;
    padding-bottom: 4.6428571429rem !important;
  }

  /* P */

  .g-py-70 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  /* P */

  .g-py-75 {
    padding-top: 5.3571428571rem !important;
    padding-bottom: 5.3571428571rem !important;
  }

  /* P */

  .g-py-80 {
    padding-top: 5.7142857143rem !important;
    padding-bottom: 5.7142857143rem !important;
  }

  /* P */

  .g-py-85 {
    padding-top: 6.0714285714rem !important;
    padding-bottom: 6.0714285714rem !important;
  }

  /* P */

  .g-py-90 {
    padding-top: 6.4285714286rem !important;
    padding-bottom: 6.4285714286rem !important;
  }

  /* P */

  .g-py-95 {
    padding-top: 6.7857142857rem !important;
    padding-bottom: 6.7857142857rem !important;
  }

  /* P */

  .g-py-100 {
    padding-top: 7.1428571429rem !important;
    padding-bottom: 7.1428571429rem !important;
  }

  /* P */

  .g-py-105 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  /* P */

  .g-py-110 {
    padding-top: 7.8571428571rem !important;
    padding-bottom: 7.8571428571rem !important;
  }

  /* P */

  .g-py-115 {
    padding-top: 8.2142857143rem !important;
    padding-bottom: 8.2142857143rem !important;
  }

  /* P */

  .g-py-120 {
    padding-top: 8.5714285714rem !important;
    padding-bottom: 8.5714285714rem !important;
  }

  /* P */

  .g-py-125 {
    padding-top: 8.9285714286rem !important;
    padding-bottom: 8.9285714286rem !important;
  }

  /* P */

  .g-py-130 {
    padding-top: 9.2857142857rem !important;
    padding-bottom: 9.2857142857rem !important;
  }

  /* P */

  .g-py-135 {
    padding-top: 9.6428571429rem !important;
    padding-bottom: 9.6428571429rem !important;
  }

  /* P */

  .g-py-140 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  /* P */

  .g-py-145 {
    padding-top: 10.3571428571rem !important;
    padding-bottom: 10.3571428571rem !important;
  }

  /* P */

  .g-py-150 {
    padding-top: 10.7142857143rem !important;
    padding-bottom: 10.7142857143rem !important;
  }

  /* P */

  .g-py-155 {
    padding-top: 11.0714285714rem !important;
    padding-bottom: 11.0714285714rem !important;
  }

  /* P */

  .g-py-160 {
    padding-top: 11.4285714286rem !important;
    padding-bottom: 11.4285714286rem !important;
  }

  /* P */

  .g-py-165 {
    padding-top: 11.7857142857rem !important;
    padding-bottom: 11.7857142857rem !important;
  }

  /* P */

  .g-py-170 {
    padding-top: 12.1428571429rem !important;
    padding-bottom: 12.1428571429rem !important;
  }

  /* P */

  .g-py-175 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }

  /* P */

  .g-py-180 {
    padding-top: 12.8571428571rem !important;
    padding-bottom: 12.8571428571rem !important;
  }

  /* P */

  .g-py-185 {
    padding-top: 13.2142857143rem !important;
    padding-bottom: 13.2142857143rem !important;
  }

  /* P */

  .g-py-190 {
    padding-top: 13.5714285714rem !important;
    padding-bottom: 13.5714285714rem !important;
  }

  /* P */

  .g-py-195 {
    padding-top: 13.9285714286rem !important;
    padding-bottom: 13.9285714286rem !important;
  }

  /* P */

  .g-py-200 {
    padding-top: 14.2857142857rem !important;
    padding-bottom: 14.2857142857rem !important;
  }

  /* P */

  .g-py-205 {
    padding-top: 14.6428571429rem !important;
    padding-bottom: 14.6428571429rem !important;
  }

  /* P */

  .g-py-210 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  /* Padding Top */

  .g-pt-0 {
    padding-top: 0px !important;
  }

  .g-pt-1 {
    padding-top: 0.0714285714rem !important;
  }

  .g-pt-2 {
    padding-top: 0.1428571429rem !important;
  }

  .g-pt-3 {
    padding-top: 0.2142857143rem !important;
  }

  .g-pt-4 {
    padding-top: 0.2857142857rem !important;
  }

  .g-pt-5 {
    padding-top: 0.3571428571rem !important;
  }

  .g-pt-6 {
    padding-top: 0.4285714286rem !important;
  }

  .g-pt-7 {
    padding-top: 0.5rem !important;
  }

  .g-pt-8 {
    padding-top: 0.5714285714rem !important;
  }

  .g-pt-9 {
    padding-top: 0.6428571429rem !important;
  }

  .g-pt-10 {
    padding-top: 0.7142857143rem !important;
  }

  .g-pt-11 {
    padding-top: 0.7857142857rem !important;
  }

  .g-pt-12 {
    padding-top: 0.8571428571rem !important;
  }

  .g-pt-13 {
    padding-top: 0.9285714286rem !important;
  }

  .g-pt-14 {
    padding-top: 1rem !important;
  }

  .g-pt-15 {
    padding-top: 1.0714285714rem !important;
  }

  .g-pt-16 {
    padding-top: 1.1428571429rem !important;
  }

  .g-pt-17 {
    padding-top: 1.2142857143rem !important;
  }

  .g-pt-18 {
    padding-top: 1.2857142857rem !important;
  }

  .g-pt-19 {
    padding-top: 1.3571428571rem !important;
  }

  .g-pt-10 {
    padding-top: 0.7142857143rem !important;
  }

  .g-pt-15 {
    padding-top: 1.0714285714rem !important;
  }

  .g-pt-20 {
    padding-top: 1.4285714286rem !important;
  }

  .g-pt-25 {
    padding-top: 1.7857142857rem !important;
  }

  .g-pt-30 {
    padding-top: 2.1428571429rem !important;
  }

  .g-pt-35 {
    padding-top: 2.5rem !important;
  }

  .g-pt-40 {
    padding-top: 2.8571428571rem !important;
  }

  .g-pt-45 {
    padding-top: 3.2142857143rem !important;
  }

  .g-pt-50 {
    padding-top: 3.5714285714rem !important;
  }

  .g-pt-55 {
    padding-top: 3.9285714286rem !important;
  }

  .g-pt-60 {
    padding-top: 4.2857142857rem !important;
  }

  .g-pt-65 {
    padding-top: 4.6428571429rem !important;
  }

  .g-pt-70 {
    padding-top: 5rem !important;
  }

  .g-pt-75 {
    padding-top: 5.3571428571rem !important;
  }

  .g-pt-80 {
    padding-top: 5.7142857143rem !important;
  }

  .g-pt-85 {
    padding-top: 6.0714285714rem !important;
  }

  .g-pt-90 {
    padding-top: 6.4285714286rem !important;
  }

  .g-pt-95 {
    padding-top: 6.7857142857rem !important;
  }

  .g-pt-100 {
    padding-top: 7.1428571429rem !important;
  }

  .g-pt-105 {
    padding-top: 7.5rem !important;
  }

  .g-pt-110 {
    padding-top: 7.8571428571rem !important;
  }

  .g-pt-115 {
    padding-top: 8.2142857143rem !important;
  }

  .g-pt-120 {
    padding-top: 8.5714285714rem !important;
  }

  .g-pt-125 {
    padding-top: 8.9285714286rem !important;
  }

  .g-pt-130 {
    padding-top: 9.2857142857rem !important;
  }

  .g-pt-135 {
    padding-top: 9.6428571429rem !important;
  }

  .g-pt-140 {
    padding-top: 10rem !important;
  }

  .g-pt-145 {
    padding-top: 10.3571428571rem !important;
  }

  .g-pt-150 {
    padding-top: 10.7142857143rem !important;
  }

  /* Padding Right */

  .g-pr-0 {
    padding-right: 0px !important;
  }

  .g-pr-1 {
    padding-right: 0.0714285714rem !important;
  }

  .g-pr-2 {
    padding-right: 0.1428571429rem !important;
  }

  .g-pr-3 {
    padding-right: 0.2142857143rem !important;
  }

  .g-pr-4 {
    padding-right: 0.2857142857rem !important;
  }

  .g-pr-5 {
    padding-right: 0.3571428571rem !important;
  }

  .g-pr-6 {
    padding-right: 0.4285714286rem !important;
  }

  .g-pr-7 {
    padding-right: 0.5rem !important;
  }

  .g-pr-8 {
    padding-right: 0.5714285714rem !important;
  }

  .g-pr-9 {
    padding-right: 0.6428571429rem !important;
  }

  /* P */

  .g-pr-10 {
    padding-right: 0.7142857143rem !important;
  }

  /* P */

  .g-pr-15 {
    padding-right: 1.0714285714rem !important;
  }

  /* P */

  .g-pr-20 {
    padding-right: 1.4285714286rem !important;
  }

  /* P */

  .g-pr-25 {
    padding-right: 1.7857142857rem !important;
  }

  /* P */

  .g-pr-30 {
    padding-right: 2.1428571429rem !important;
  }

  /* P */

  .g-pr-35 {
    padding-right: 2.5rem !important;
  }

  /* P */

  .g-pr-40 {
    padding-right: 2.8571428571rem !important;
  }

  /* P */

  .g-pr-45 {
    padding-right: 3.2142857143rem !important;
  }

  /* P */

  .g-pr-50 {
    padding-right: 3.5714285714rem !important;
  }

  /* P */

  .g-pr-55 {
    padding-right: 3.9285714286rem !important;
  }

  /* P */

  .g-pr-60 {
    padding-right: 4.2857142857rem !important;
  }

  /* P */

  .g-pr-65 {
    padding-right: 4.6428571429rem !important;
  }

  /* P */

  .g-pr-70 {
    padding-right: 5rem !important;
  }

  /* P */

  .g-pr-75 {
    padding-right: 5.3571428571rem !important;
  }

  /* P */

  .g-pr-80 {
    padding-right: 5.7142857143rem !important;
  }

  /* P */

  .g-pr-85 {
    padding-right: 6.0714285714rem !important;
  }

  /* P */

  .g-pr-90 {
    padding-right: 6.4285714286rem !important;
  }

  /* P */

  .g-pr-95 {
    padding-right: 6.7857142857rem !important;
  }

  /* P */

  .g-pr-100 {
    padding-right: 7.1428571429rem !important;
  }

  /* P */

  .g-pr-105 {
    padding-right: 7.5rem !important;
  }

  /* P */

  .g-pr-110 {
    padding-right: 7.8571428571rem !important;
  }

  /* P */

  .g-pr-115 {
    padding-right: 8.2142857143rem !important;
  }

  /* P */

  .g-pr-120 {
    padding-right: 8.5714285714rem !important;
  }

  /* P */

  .g-pr-125 {
    padding-right: 8.9285714286rem !important;
  }

  /* P */

  .g-pr-130 {
    padding-right: 9.2857142857rem !important;
  }

  /* P */

  .g-pr-135 {
    padding-right: 9.6428571429rem !important;
  }

  /* P */

  .g-pr-140 {
    padding-right: 10rem !important;
  }

  /* P */

  .g-pr-145 {
    padding-right: 10.3571428571rem !important;
  }

  /* P */

  .g-pr-150 {
    padding-right: 10.7142857143rem !important;
  }

  /* P */

  .g-pr-155 {
    padding-right: 11.0714285714rem !important;
  }

  /* P */

  .g-pr-160 {
    padding-right: 11.4285714286rem !important;
  }

  /* P */

  .g-pr-165 {
    padding-right: 11.7857142857rem !important;
  }

  /* P */

  .g-pr-170 {
    padding-right: 12.1428571429rem !important;
  }

  /* Padding Bottom */

  .g-pb-0 {
    padding-bottom: 0px !important;
  }

  .g-pb-1 {
    padding-bottom: 0.0714285714rem !important;
  }

  .g-pb-2 {
    padding-bottom: 0.1428571429rem !important;
  }

  .g-pb-3 {
    padding-bottom: 0.2142857143rem !important;
  }

  .g-pb-4 {
    padding-bottom: 0.2857142857rem !important;
  }

  .g-pb-5 {
    padding-bottom: 0.3571428571rem !important;
  }

  .g-pb-6 {
    padding-bottom: 0.4285714286rem !important;
  }

  .g-pb-7 {
    padding-bottom: 0.5rem !important;
  }

  .g-pb-8 {
    padding-bottom: 0.5714285714rem !important;
  }

  .g-pb-9 {
    padding-bottom: 0.6428571429rem !important;
  }

  .g-pb-10 {
    padding-bottom: 0.7142857143rem !important;
  }

  .g-pb-15 {
    padding-bottom: 1.0714285714rem !important;
  }

  .g-pb-20 {
    padding-bottom: 1.4285714286rem !important;
  }

  .g-pb-25 {
    padding-bottom: 1.7857142857rem !important;
  }

  .g-pb-30 {
    padding-bottom: 2.1428571429rem !important;
  }

  .g-pb-35 {
    padding-bottom: 2.5rem !important;
  }

  .g-pb-40 {
    padding-bottom: 2.8571428571rem !important;
  }

  .g-pb-45 {
    padding-bottom: 3.2142857143rem !important;
  }

  .g-pb-50 {
    padding-bottom: 3.5714285714rem !important;
  }

  .g-pb-55 {
    padding-bottom: 3.9285714286rem !important;
  }

  .g-pb-60 {
    padding-bottom: 4.2857142857rem !important;
  }

  .g-pb-65 {
    padding-bottom: 4.6428571429rem !important;
  }

  .g-pb-70 {
    padding-bottom: 5rem !important;
  }

  .g-pb-75 {
    padding-bottom: 5.3571428571rem !important;
  }

  .g-pb-80 {
    padding-bottom: 5.7142857143rem !important;
  }

  .g-pb-85 {
    padding-bottom: 6.0714285714rem !important;
  }

  .g-pb-90 {
    padding-bottom: 6.4285714286rem !important;
  }

  .g-pb-95 {
    padding-bottom: 6.7857142857rem !important;
  }

  .g-pb-100 {
    padding-bottom: 7.1428571429rem !important;
  }

  .g-pb-105 {
    padding-bottom: 7.5rem !important;
  }

  .g-pb-110 {
    padding-bottom: 7.8571428571rem !important;
  }

  .g-pb-115 {
    padding-bottom: 8.2142857143rem !important;
  }

  .g-pb-120 {
    padding-bottom: 8.5714285714rem !important;
  }

  .g-pb-125 {
    padding-bottom: 8.9285714286rem !important;
  }

  .g-pb-130 {
    padding-bottom: 9.2857142857rem !important;
  }

  .g-pb-135 {
    padding-bottom: 9.6428571429rem !important;
  }

  .g-pb-140 {
    padding-bottom: 10rem !important;
  }

  .g-pb-145 {
    padding-bottom: 10.3571428571rem !important;
  }

  .g-pb-150 {
    padding-bottom: 10.7142857143rem !important;
  }

  /* Padding Left */

  .g-pl-0 {
    padding-left: 0px !important;
  }

  .g-pl-1 {
    padding-left: 0.0714285714rem !important;
  }

  .g-pl-2 {
    padding-left: 0.1428571429rem !important;
  }

  .g-pl-3 {
    padding-left: 0.2142857143rem !important;
  }

  .g-pl-4 {
    padding-left: 0.2857142857rem !important;
  }

  .g-pl-5 {
    padding-left: 0.3571428571rem !important;
  }

  .g-pl-6 {
    padding-left: 0.4285714286rem !important;
  }

  .g-pl-7 {
    padding-left: 0.5rem !important;
  }

  .g-pl-8 {
    padding-left: 0.5714285714rem !important;
  }

  .g-pl-9 {
    padding-left: 0.6428571429rem !important;
  }

  /* P */

  .g-pl-10 {
    padding-left: 0.7142857143rem !important;
  }

  /* P */

  .g-pl-15 {
    padding-left: 1.0714285714rem !important;
  }

  /* P */

  .g-pl-20 {
    padding-left: 1.4285714286rem !important;
  }

  /* P */

  .g-pl-25 {
    padding-left: 1.7857142857rem !important;
  }

  /* P */

  .g-pl-30 {
    padding-left: 2.1428571429rem !important;
  }

  /* P */

  .g-pl-35 {
    padding-left: 2.5rem !important;
  }

  /* P */

  .g-pl-40 {
    padding-left: 2.8571428571rem !important;
  }

  /* P */

  .g-pl-45 {
    padding-left: 3.2142857143rem !important;
  }

  /* P */

  .g-pl-50 {
    padding-left: 3.5714285714rem !important;
  }

  /* P */

  .g-pl-55 {
    padding-left: 3.9285714286rem !important;
  }

  /* P */

  .g-pl-60 {
    padding-left: 4.2857142857rem !important;
  }

  /* P */

  .g-pl-65 {
    padding-left: 4.6428571429rem !important;
  }

  /* P */

  .g-pl-70 {
    padding-left: 5rem !important;
  }

  /* P */

  .g-pl-75 {
    padding-left: 5.3571428571rem !important;
  }

  /* P */

  .g-pl-80 {
    padding-left: 5.7142857143rem !important;
  }

  /* P */

  .g-pl-85 {
    padding-left: 6.0714285714rem !important;
  }

  /* P */

  .g-pl-90 {
    padding-left: 6.4285714286rem !important;
  }

  /* P */

  .g-pl-95 {
    padding-left: 6.7857142857rem !important;
  }

  /* P */

  .g-pl-100 {
    padding-left: 7.1428571429rem !important;
  }

  /* P */

  .g-pl-105 {
    padding-left: 7.5rem !important;
  }

  /* P */

  .g-pl-110 {
    padding-left: 7.8571428571rem !important;
  }

  /* P */

  .g-pl-115 {
    padding-left: 8.2142857143rem !important;
  }

  /* P */

  .g-pl-120 {
    padding-left: 8.5714285714rem !important;
  }

  /* P */

  .g-pl-125 {
    padding-left: 8.9285714286rem !important;
  }

  /* P */

  .g-pl-130 {
    padding-left: 9.2857142857rem !important;
  }

  /* P */

  .g-pl-135 {
    padding-left: 9.6428571429rem !important;
  }

  /* P */

  .g-pl-140 {
    padding-left: 10rem !important;
  }

  /* P */

  .g-pl-145 {
    padding-left: 10.3571428571rem !important;
  }

  /* P */

  .g-pl-150 {
    padding-left: 10.7142857143rem !important;
  }

  /* P */

  .g-pl-155 {
    padding-left: 11.0714285714rem !important;
  }

  /* P */

  .g-pl-160 {
    padding-left: 11.4285714286rem !important;
  }

  /* P */

  .g-pl-165 {
    padding-left: 11.7857142857rem !important;
  }

  /* P */

  .g-pl-170 {
    padding-left: 12.1428571429rem !important;
  }
}

/* Padding Spaces (sm)
------------------------------------*/

@media (min-width: 576px) {
  .g-pa-0--sm {
    padding: 0 !important;
  }

  .g-px-0--sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .g-py-0--sm {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .g-pt-0--sm {
    padding-top: 0 !important;
  }

  .g-pr-0--sm {
    padding-right: 0 !important;
  }

  .g-pb-0--sm {
    padding-bottom: 0 !important;
  }

  .g-pl-0--sm {
    padding-left: 0 !important;
  }

  /* Padding Around */

  .g-pa-2--sm {
    padding: 0.1428571429rem !important;
  }

  .g-pa-3--sm {
    padding: 0.2142857143rem !important;
  }

  .g-pa-5--sm {
    padding: 0.3571428571rem !important;
  }

  .g-pa-7--sm {
    padding: 0.5rem !important;
  }

  .g-pa-10--sm {
    padding: 0.7142857143rem !important;
  }

  .g-pa-15--sm {
    padding: 1.0714285714rem !important;
  }

  .g-pa-20--sm {
    padding: 1.4285714286rem !important;
  }

  .g-pa-25--sm {
    padding: 1.7857142857rem !important;
  }

  .g-pa-30--sm {
    padding: 2.1428571429rem !important;
  }

  .g-pa-35--sm {
    padding: 2.5rem !important;
  }

  .g-pa-40--sm {
    padding: 2.8571428571rem !important;
  }

  .g-pa-45--sm {
    padding: 3.2142857143rem !important;
  }

  .g-pa-50--sm {
    padding: 3.5714285714rem !important;
  }

  .g-pa-55--sm {
    padding: 3.9285714286rem !important;
  }

  .g-pa-60--sm {
    padding: 4.2857142857rem !important;
  }

  .g-pa-65--sm {
    padding: 4.6428571429rem !important;
  }

  .g-pa-70--sm {
    padding: 5rem !important;
  }

  .g-pa-75--sm {
    padding: 5.3571428571rem !important;
  }

  .g-pa-80--sm {
    padding: 5.7142857143rem !important;
  }

  .g-pa-85--sm {
    padding: 6.0714285714rem !important;
  }

  .g-pa-90--sm {
    padding: 6.4285714286rem !important;
  }

  .g-pa-95--sm {
    padding: 6.7857142857rem !important;
  }

  .g-pa-100--sm {
    padding: 7.1428571429rem !important;
  }

  .g-pa-105--sm {
    padding: 7.5rem !important;
  }

  .g-pa-110--sm {
    padding: 7.8571428571rem !important;
  }

  .g-pa-115--sm {
    padding: 8.2142857143rem !important;
  }

  .g-pa-120--sm {
    padding: 8.5714285714rem !important;
  }

  .g-pa-125--sm {
    padding: 8.9285714286rem !important;
  }

  .g-pa-130--sm {
    padding: 9.2857142857rem !important;
  }

  .g-pa-135--sm {
    padding: 9.6428571429rem !important;
  }

  .g-pa-140--sm {
    padding: 10rem !important;
  }

  .g-pa-145--sm {
    padding: 10.3571428571rem !important;
  }

  .g-pa-150--sm {
    padding: 10.7142857143rem !important;
  }

  /* Padding X */

  .g-px-1--sm {
    padding-left: 0.0714285714rem !important;
    padding-right: 0.0714285714rem !important;
  }

  .g-px-2--sm {
    padding-left: 0.1428571429rem !important;
    padding-right: 0.1428571429rem !important;
  }

  .g-px-3--sm {
    padding-left: 0.2142857143rem !important;
    padding-right: 0.2142857143rem !important;
  }

  .g-px-4--sm {
    padding-left: 0.2857142857rem !important;
    padding-right: 0.2857142857rem !important;
  }

  .g-px-5--sm {
    padding-left: 0.3571428571rem !important;
    padding-right: 0.3571428571rem !important;
  }

  .g-px-6--sm {
    padding-left: 0.4285714286rem !important;
    padding-right: 0.4285714286rem !important;
  }

  .g-px-7--sm {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .g-px-8--sm {
    padding-left: 0.5714285714rem !important;
    padding-right: 0.5714285714rem !important;
  }

  .g-px-9--sm {
    padding-left: 0.6428571429rem !important;
    padding-right: 0.6428571429rem !important;
  }

  .g-px-10--sm {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }

  .g-px-11--sm {
    padding-left: 0.7857142857rem !important;
    padding-right: 0.7857142857rem !important;
  }

  .g-px-12--sm {
    padding-left: 0.8571428571rem !important;
    padding-right: 0.8571428571rem !important;
  }

  .g-px-13--sm {
    padding-left: 0.9285714286rem !important;
    padding-right: 0.9285714286rem !important;
  }

  .g-px-14--sm {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .g-px-15--sm {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }

  .g-px-16--sm {
    padding-left: 1.1428571429rem !important;
    padding-right: 1.1428571429rem !important;
  }

  .g-px-17--sm {
    padding-left: 1.2142857143rem !important;
    padding-right: 1.2142857143rem !important;
  }

  .g-px-18--sm {
    padding-left: 1.2857142857rem !important;
    padding-right: 1.2857142857rem !important;
  }

  .g-px-19--sm {
    padding-left: 1.3571428571rem !important;
    padding-right: 1.3571428571rem !important;
  }

  .g-px-10--sm {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }

  .g-px-15--sm {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }

  .g-px-20--sm {
    padding-left: 1.4285714286rem !important;
    padding-right: 1.4285714286rem !important;
  }

  .g-px-25--sm {
    padding-left: 1.7857142857rem !important;
    padding-right: 1.7857142857rem !important;
  }

  .g-px-30--sm {
    padding-left: 2.1428571429rem !important;
    padding-right: 2.1428571429rem !important;
  }

  .g-px-35--sm {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .g-px-40--sm {
    padding-left: 2.8571428571rem !important;
    padding-right: 2.8571428571rem !important;
  }

  .g-px-45--sm {
    padding-left: 3.2142857143rem !important;
    padding-right: 3.2142857143rem !important;
  }

  .g-px-50--sm {
    padding-left: 3.5714285714rem !important;
    padding-right: 3.5714285714rem !important;
  }

  .g-px-55--sm {
    padding-left: 3.9285714286rem !important;
    padding-right: 3.9285714286rem !important;
  }

  .g-px-60--sm {
    padding-left: 4.2857142857rem !important;
    padding-right: 4.2857142857rem !important;
  }

  .g-px-65--sm {
    padding-left: 4.6428571429rem !important;
    padding-right: 4.6428571429rem !important;
  }

  .g-px-70--sm {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .g-px-75--sm {
    padding-left: 5.3571428571rem !important;
    padding-right: 5.3571428571rem !important;
  }

  .g-px-80--sm {
    padding-left: 5.7142857143rem !important;
    padding-right: 5.7142857143rem !important;
  }

  .g-px-85--sm {
    padding-left: 6.0714285714rem !important;
    padding-right: 6.0714285714rem !important;
  }

  .g-px-90--sm {
    padding-left: 6.4285714286rem !important;
    padding-right: 6.4285714286rem !important;
  }

  .g-px-95--sm {
    padding-left: 6.7857142857rem !important;
    padding-right: 6.7857142857rem !important;
  }

  .g-px-100--sm {
    padding-left: 7.1428571429rem !important;
    padding-right: 7.1428571429rem !important;
  }

  .g-px-105--sm {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .g-px-110--sm {
    padding-left: 7.8571428571rem !important;
    padding-right: 7.8571428571rem !important;
  }

  .g-px-115--sm {
    padding-left: 8.2142857143rem !important;
    padding-right: 8.2142857143rem !important;
  }

  .g-px-120--sm {
    padding-left: 8.5714285714rem !important;
    padding-right: 8.5714285714rem !important;
  }

  .g-px-125--sm {
    padding-left: 8.9285714286rem !important;
    padding-right: 8.9285714286rem !important;
  }

  .g-px-130--sm {
    padding-left: 9.2857142857rem !important;
    padding-right: 9.2857142857rem !important;
  }

  .g-px-135--sm {
    padding-left: 9.6428571429rem !important;
    padding-right: 9.6428571429rem !important;
  }

  .g-px-140--sm {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .g-px-145--sm {
    padding-left: 10.3571428571rem !important;
    padding-right: 10.3571428571rem !important;
  }

  .g-px-150--sm {
    padding-left: 10.7142857143rem !important;
    padding-right: 10.7142857143rem !important;
  }

  /* Padding Y */

  .g-py-1--sm {
    padding-top: 0.0714285714rem !important;
    padding-bottom: 0.0714285714rem !important;
  }

  .g-py-2--sm {
    padding-top: 0.1428571429rem !important;
    padding-bottom: 0.1428571429rem !important;
  }

  .g-py-3--sm {
    padding-top: 0.2142857143rem !important;
    padding-bottom: 0.2142857143rem !important;
  }

  .g-py-4--sm {
    padding-top: 0.2857142857rem !important;
    padding-bottom: 0.2857142857rem !important;
  }

  .g-py-5--sm {
    padding-top: 0.3571428571rem !important;
    padding-bottom: 0.3571428571rem !important;
  }

  .g-py-6--sm {
    padding-top: 0.4285714286rem !important;
    padding-bottom: 0.4285714286rem !important;
  }

  .g-py-7--sm {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .g-py-8--sm {
    padding-top: 0.5714285714rem !important;
    padding-bottom: 0.5714285714rem !important;
  }

  .g-py-9--sm {
    padding-top: 0.6428571429rem !important;
    padding-bottom: 0.6428571429rem !important;
  }

  .g-py-10--sm {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }

  .g-py-11--sm {
    padding-top: 0.7857142857rem !important;
    padding-bottom: 0.7857142857rem !important;
  }

  .g-py-12--sm {
    padding-top: 0.8571428571rem !important;
    padding-bottom: 0.8571428571rem !important;
  }

  .g-py-13--sm {
    padding-top: 0.9285714286rem !important;
    padding-bottom: 0.9285714286rem !important;
  }

  .g-py-14--sm {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .g-py-15--sm {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }

  .g-py-16--sm {
    padding-top: 1.1428571429rem !important;
    padding-bottom: 1.1428571429rem !important;
  }

  .g-py-17--sm {
    padding-top: 1.2142857143rem !important;
    padding-bottom: 1.2142857143rem !important;
  }

  .g-py-18--sm {
    padding-top: 1.2857142857rem !important;
    padding-bottom: 1.2857142857rem !important;
  }

  .g-py-19--sm {
    padding-top: 1.3571428571rem !important;
    padding-bottom: 1.3571428571rem !important;
  }

  /* P */

  .g-py-10--sm {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }

  /* P */

  .g-py-15--sm {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }

  /* P */

  .g-py-20--sm {
    padding-top: 1.4285714286rem !important;
    padding-bottom: 1.4285714286rem !important;
  }

  /* P */

  .g-py-25--sm {
    padding-top: 1.7857142857rem !important;
    padding-bottom: 1.7857142857rem !important;
  }

  /* P */

  .g-py-30--sm {
    padding-top: 2.1428571429rem !important;
    padding-bottom: 2.1428571429rem !important;
  }

  /* P */

  .g-py-35--sm {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  /* P */

  .g-py-40--sm {
    padding-top: 2.8571428571rem !important;
    padding-bottom: 2.8571428571rem !important;
  }

  /* P */

  .g-py-45--sm {
    padding-top: 3.2142857143rem !important;
    padding-bottom: 3.2142857143rem !important;
  }

  /* P */

  .g-py-50--sm {
    padding-top: 3.5714285714rem !important;
    padding-bottom: 3.5714285714rem !important;
  }

  /* P */

  .g-py-55--sm {
    padding-top: 3.9285714286rem !important;
    padding-bottom: 3.9285714286rem !important;
  }

  /* P */

  .g-py-60--sm {
    padding-top: 4.2857142857rem !important;
    padding-bottom: 4.2857142857rem !important;
  }

  /* P */

  .g-py-65--sm {
    padding-top: 4.6428571429rem !important;
    padding-bottom: 4.6428571429rem !important;
  }

  /* P */

  .g-py-70--sm {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  /* P */

  .g-py-75--sm {
    padding-top: 5.3571428571rem !important;
    padding-bottom: 5.3571428571rem !important;
  }

  /* P */

  .g-py-80--sm {
    padding-top: 5.7142857143rem !important;
    padding-bottom: 5.7142857143rem !important;
  }

  /* P */

  .g-py-85--sm {
    padding-top: 6.0714285714rem !important;
    padding-bottom: 6.0714285714rem !important;
  }

  /* P */

  .g-py-90--sm {
    padding-top: 6.4285714286rem !important;
    padding-bottom: 6.4285714286rem !important;
  }

  /* P */

  .g-py-95--sm {
    padding-top: 6.7857142857rem !important;
    padding-bottom: 6.7857142857rem !important;
  }

  /* P */

  .g-py-100--sm {
    padding-top: 7.1428571429rem !important;
    padding-bottom: 7.1428571429rem !important;
  }

  /* P */

  .g-py-105--sm {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  /* P */

  .g-py-110--sm {
    padding-top: 7.8571428571rem !important;
    padding-bottom: 7.8571428571rem !important;
  }

  /* P */

  .g-py-115--sm {
    padding-top: 8.2142857143rem !important;
    padding-bottom: 8.2142857143rem !important;
  }

  /* P */

  .g-py-120--sm {
    padding-top: 8.5714285714rem !important;
    padding-bottom: 8.5714285714rem !important;
  }

  /* P */

  .g-py-125--sm {
    padding-top: 8.9285714286rem !important;
    padding-bottom: 8.9285714286rem !important;
  }

  /* P */

  .g-py-130--sm {
    padding-top: 9.2857142857rem !important;
    padding-bottom: 9.2857142857rem !important;
  }

  /* P */

  .g-py-135--sm {
    padding-top: 9.6428571429rem !important;
    padding-bottom: 9.6428571429rem !important;
  }

  /* P */

  .g-py-140--sm {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  /* P */

  .g-py-145--sm {
    padding-top: 10.3571428571rem !important;
    padding-bottom: 10.3571428571rem !important;
  }

  /* P */

  .g-py-150--sm {
    padding-top: 10.7142857143rem !important;
    padding-bottom: 10.7142857143rem !important;
  }

  /* P */

  .g-py-155--sm {
    padding-top: 11.0714285714rem !important;
    padding-bottom: 11.0714285714rem !important;
  }

  /* P */

  .g-py-160--sm {
    padding-top: 11.4285714286rem !important;
    padding-bottom: 11.4285714286rem !important;
  }

  /* P */

  .g-py-165--sm {
    padding-top: 11.7857142857rem !important;
    padding-bottom: 11.7857142857rem !important;
  }

  /* P */

  .g-py-170--sm {
    padding-top: 12.1428571429rem !important;
    padding-bottom: 12.1428571429rem !important;
  }

  /* P */

  .g-py-175--sm {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }

  /* P */

  .g-py-180--sm {
    padding-top: 12.8571428571rem !important;
    padding-bottom: 12.8571428571rem !important;
  }

  /* P */

  .g-py-185--sm {
    padding-top: 13.2142857143rem !important;
    padding-bottom: 13.2142857143rem !important;
  }

  /* P */

  .g-py-190--sm {
    padding-top: 13.5714285714rem !important;
    padding-bottom: 13.5714285714rem !important;
  }

  /* P */

  .g-py-195--sm {
    padding-top: 13.9285714286rem !important;
    padding-bottom: 13.9285714286rem !important;
  }

  /* P */

  .g-py-200--sm {
    padding-top: 14.2857142857rem !important;
    padding-bottom: 14.2857142857rem !important;
  }

  /* P */

  .g-py-205--sm {
    padding-top: 14.6428571429rem !important;
    padding-bottom: 14.6428571429rem !important;
  }

  /* P */

  .g-py-210--sm {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  /* Padding Top */

  .g-pt-0--sm {
    padding-top: 0px !important;
  }

  .g-pt-1--sm {
    padding-top: 0.0714285714rem !important;
  }

  .g-pt-2--sm {
    padding-top: 0.1428571429rem !important;
  }

  .g-pt-3--sm {
    padding-top: 0.2142857143rem !important;
  }

  .g-pt-4--sm {
    padding-top: 0.2857142857rem !important;
  }

  .g-pt-5--sm {
    padding-top: 0.3571428571rem !important;
  }

  .g-pt-6--sm {
    padding-top: 0.4285714286rem !important;
  }

  .g-pt-7--sm {
    padding-top: 0.5rem !important;
  }

  .g-pt-8--sm {
    padding-top: 0.5714285714rem !important;
  }

  .g-pt-9--sm {
    padding-top: 0.6428571429rem !important;
  }

  .g-pt-10--sm {
    padding-top: 0.7142857143rem !important;
  }

  .g-pt-11--sm {
    padding-top: 0.7857142857rem !important;
  }

  .g-pt-12--sm {
    padding-top: 0.8571428571rem !important;
  }

  .g-pt-13--sm {
    padding-top: 0.9285714286rem !important;
  }

  .g-pt-14--sm {
    padding-top: 1rem !important;
  }

  .g-pt-15--sm {
    padding-top: 1.0714285714rem !important;
  }

  .g-pt-16--sm {
    padding-top: 1.1428571429rem !important;
  }

  .g-pt-17--sm {
    padding-top: 1.2142857143rem !important;
  }

  .g-pt-18--sm {
    padding-top: 1.2857142857rem !important;
  }

  .g-pt-19--sm {
    padding-top: 1.3571428571rem !important;
  }

  .g-pt-10--sm {
    padding-top: 0.7142857143rem !important;
  }

  .g-pt-15--sm {
    padding-top: 1.0714285714rem !important;
  }

  .g-pt-20--sm {
    padding-top: 1.4285714286rem !important;
  }

  .g-pt-25--sm {
    padding-top: 1.7857142857rem !important;
  }

  .g-pt-30--sm {
    padding-top: 2.1428571429rem !important;
  }

  .g-pt-35--sm {
    padding-top: 2.5rem !important;
  }

  .g-pt-40--sm {
    padding-top: 2.8571428571rem !important;
  }

  .g-pt-45--sm {
    padding-top: 3.2142857143rem !important;
  }

  .g-pt-50--sm {
    padding-top: 3.5714285714rem !important;
  }

  .g-pt-55--sm {
    padding-top: 3.9285714286rem !important;
  }

  .g-pt-60--sm {
    padding-top: 4.2857142857rem !important;
  }

  .g-pt-65--sm {
    padding-top: 4.6428571429rem !important;
  }

  .g-pt-70--sm {
    padding-top: 5rem !important;
  }

  .g-pt-75--sm {
    padding-top: 5.3571428571rem !important;
  }

  .g-pt-80--sm {
    padding-top: 5.7142857143rem !important;
  }

  .g-pt-85--sm {
    padding-top: 6.0714285714rem !important;
  }

  .g-pt-90--sm {
    padding-top: 6.4285714286rem !important;
  }

  .g-pt-95--sm {
    padding-top: 6.7857142857rem !important;
  }

  .g-pt-100--sm {
    padding-top: 7.1428571429rem !important;
  }

  .g-pt-105--sm {
    padding-top: 7.5rem !important;
  }

  .g-pt-110--sm {
    padding-top: 7.8571428571rem !important;
  }

  .g-pt-115--sm {
    padding-top: 8.2142857143rem !important;
  }

  .g-pt-120--sm {
    padding-top: 8.5714285714rem !important;
  }

  .g-pt-125--sm {
    padding-top: 8.9285714286rem !important;
  }

  .g-pt-130--sm {
    padding-top: 9.2857142857rem !important;
  }

  .g-pt-135--sm {
    padding-top: 9.6428571429rem !important;
  }

  .g-pt-140--sm {
    padding-top: 10rem !important;
  }

  .g-pt-145--sm {
    padding-top: 10.3571428571rem !important;
  }

  .g-pt-150--sm {
    padding-top: 10.7142857143rem !important;
  }

  /* Padding Right */

  .g-pr-0--sm {
    padding-right: 0px !important;
  }

  .g-pr-1--sm {
    padding-right: 0.0714285714rem !important;
  }

  .g-pr-2--sm {
    padding-right: 0.1428571429rem !important;
  }

  .g-pr-3--sm {
    padding-right: 0.2142857143rem !important;
  }

  .g-pr-4--sm {
    padding-right: 0.2857142857rem !important;
  }

  .g-pr-5--sm {
    padding-right: 0.3571428571rem !important;
  }

  .g-pr-6--sm {
    padding-right: 0.4285714286rem !important;
  }

  .g-pr-7--sm {
    padding-right: 0.5rem !important;
  }

  .g-pr-8--sm {
    padding-right: 0.5714285714rem !important;
  }

  .g-pr-9--sm {
    padding-right: 0.6428571429rem !important;
  }

  /* P */

  .g-pr-10--sm {
    padding-right: 0.7142857143rem !important;
  }

  /* P */

  .g-pr-15--sm {
    padding-right: 1.0714285714rem !important;
  }

  /* P */

  .g-pr-20--sm {
    padding-right: 1.4285714286rem !important;
  }

  /* P */

  .g-pr-25--sm {
    padding-right: 1.7857142857rem !important;
  }

  /* P */

  .g-pr-30--sm {
    padding-right: 2.1428571429rem !important;
  }

  /* P */

  .g-pr-35--sm {
    padding-right: 2.5rem !important;
  }

  /* P */

  .g-pr-40--sm {
    padding-right: 2.8571428571rem !important;
  }

  /* P */

  .g-pr-45--sm {
    padding-right: 3.2142857143rem !important;
  }

  /* P */

  .g-pr-50--sm {
    padding-right: 3.5714285714rem !important;
  }

  /* P */

  .g-pr-55--sm {
    padding-right: 3.9285714286rem !important;
  }

  /* P */

  .g-pr-60--sm {
    padding-right: 4.2857142857rem !important;
  }

  /* P */

  .g-pr-65--sm {
    padding-right: 4.6428571429rem !important;
  }

  /* P */

  .g-pr-70--sm {
    padding-right: 5rem !important;
  }

  /* P */

  .g-pr-75--sm {
    padding-right: 5.3571428571rem !important;
  }

  /* P */

  .g-pr-80--sm {
    padding-right: 5.7142857143rem !important;
  }

  /* P */

  .g-pr-85--sm {
    padding-right: 6.0714285714rem !important;
  }

  /* P */

  .g-pr-90--sm {
    padding-right: 6.4285714286rem !important;
  }

  /* P */

  .g-pr-95--sm {
    padding-right: 6.7857142857rem !important;
  }

  /* P */

  .g-pr-100--sm {
    padding-right: 7.1428571429rem !important;
  }

  /* P */

  .g-pr-105--sm {
    padding-right: 7.5rem !important;
  }

  /* P */

  .g-pr-110--sm {
    padding-right: 7.8571428571rem !important;
  }

  /* P */

  .g-pr-115--sm {
    padding-right: 8.2142857143rem !important;
  }

  /* P */

  .g-pr-120--sm {
    padding-right: 8.5714285714rem !important;
  }

  /* P */

  .g-pr-125--sm {
    padding-right: 8.9285714286rem !important;
  }

  /* P */

  .g-pr-130--sm {
    padding-right: 9.2857142857rem !important;
  }

  /* P */

  .g-pr-135--sm {
    padding-right: 9.6428571429rem !important;
  }

  /* P */

  .g-pr-140--sm {
    padding-right: 10rem !important;
  }

  /* P */

  .g-pr-145--sm {
    padding-right: 10.3571428571rem !important;
  }

  /* P */

  .g-pr-150--sm {
    padding-right: 10.7142857143rem !important;
  }

  /* P */

  .g-pr-155--sm {
    padding-right: 11.0714285714rem !important;
  }

  /* P */

  .g-pr-160--sm {
    padding-right: 11.4285714286rem !important;
  }

  /* P */

  .g-pr-165--sm {
    padding-right: 11.7857142857rem !important;
  }

  /* P */

  .g-pr-170--sm {
    padding-right: 12.1428571429rem !important;
  }

  /* Padding Bottom */

  .g-pb-0--sm {
    padding-bottom: 0px !important;
  }

  .g-pb-1--sm {
    padding-bottom: 0.0714285714rem !important;
  }

  .g-pb-2--sm {
    padding-bottom: 0.1428571429rem !important;
  }

  .g-pb-3--sm {
    padding-bottom: 0.2142857143rem !important;
  }

  .g-pb-4--sm {
    padding-bottom: 0.2857142857rem !important;
  }

  .g-pb-5--sm {
    padding-bottom: 0.3571428571rem !important;
  }

  .g-pb-6--sm {
    padding-bottom: 0.4285714286rem !important;
  }

  .g-pb-7--sm {
    padding-bottom: 0.5rem !important;
  }

  .g-pb-8--sm {
    padding-bottom: 0.5714285714rem !important;
  }

  .g-pb-9--sm {
    padding-bottom: 0.6428571429rem !important;
  }

  .g-pb-10--sm {
    padding-bottom: 0.7142857143rem !important;
  }

  .g-pb-15--sm {
    padding-bottom: 1.0714285714rem !important;
  }

  .g-pb-20--sm {
    padding-bottom: 1.4285714286rem !important;
  }

  .g-pb-25--sm {
    padding-bottom: 1.7857142857rem !important;
  }

  .g-pb-30--sm {
    padding-bottom: 2.1428571429rem !important;
  }

  .g-pb-35--sm {
    padding-bottom: 2.5rem !important;
  }

  .g-pb-40--sm {
    padding-bottom: 2.8571428571rem !important;
  }

  .g-pb-45--sm {
    padding-bottom: 3.2142857143rem !important;
  }

  .g-pb-50--sm {
    padding-bottom: 3.5714285714rem !important;
  }

  .g-pb-55--sm {
    padding-bottom: 3.9285714286rem !important;
  }

  .g-pb-60--sm {
    padding-bottom: 4.2857142857rem !important;
  }

  .g-pb-65--sm {
    padding-bottom: 4.6428571429rem !important;
  }

  .g-pb-70--sm {
    padding-bottom: 5rem !important;
  }

  .g-pb-75--sm {
    padding-bottom: 5.3571428571rem !important;
  }

  .g-pb-80--sm {
    padding-bottom: 5.7142857143rem !important;
  }

  .g-pb-85--sm {
    padding-bottom: 6.0714285714rem !important;
  }

  .g-pb-90--sm {
    padding-bottom: 6.4285714286rem !important;
  }

  .g-pb-95--sm {
    padding-bottom: 6.7857142857rem !important;
  }

  .g-pb-100--sm {
    padding-bottom: 7.1428571429rem !important;
  }

  .g-pb-105--sm {
    padding-bottom: 7.5rem !important;
  }

  .g-pb-110--sm {
    padding-bottom: 7.8571428571rem !important;
  }

  .g-pb-115--sm {
    padding-bottom: 8.2142857143rem !important;
  }

  .g-pb-120--sm {
    padding-bottom: 8.5714285714rem !important;
  }

  .g-pb-125--sm {
    padding-bottom: 8.9285714286rem !important;
  }

  .g-pb-130--sm {
    padding-bottom: 9.2857142857rem !important;
  }

  .g-pb-135--sm {
    padding-bottom: 9.6428571429rem !important;
  }

  .g-pb-140--sm {
    padding-bottom: 10rem !important;
  }

  .g-pb-145--sm {
    padding-bottom: 10.3571428571rem !important;
  }

  .g-pb-150--sm {
    padding-bottom: 10.7142857143rem !important;
  }

  /* Padding Left */

  .g-pl-0--sm {
    padding-left: 0px !important;
  }

  .g-pl-1--sm {
    padding-left: 0.0714285714rem !important;
  }

  .g-pl-2--sm {
    padding-left: 0.1428571429rem !important;
  }

  .g-pl-3--sm {
    padding-left: 0.2142857143rem !important;
  }

  .g-pl-4--sm {
    padding-left: 0.2857142857rem !important;
  }

  .g-pl-5--sm {
    padding-left: 0.3571428571rem !important;
  }

  .g-pl-6--sm {
    padding-left: 0.4285714286rem !important;
  }

  .g-pl-7--sm {
    padding-left: 0.5rem !important;
  }

  .g-pl-8--sm {
    padding-left: 0.5714285714rem !important;
  }

  .g-pl-9--sm {
    padding-left: 0.6428571429rem !important;
  }

  /* P */

  .g-pl-10--sm {
    padding-left: 0.7142857143rem !important;
  }

  /* P */

  .g-pl-15--sm {
    padding-left: 1.0714285714rem !important;
  }

  /* P */

  .g-pl-20--sm {
    padding-left: 1.4285714286rem !important;
  }

  /* P */

  .g-pl-25--sm {
    padding-left: 1.7857142857rem !important;
  }

  /* P */

  .g-pl-30--sm {
    padding-left: 2.1428571429rem !important;
  }

  /* P */

  .g-pl-35--sm {
    padding-left: 2.5rem !important;
  }

  /* P */

  .g-pl-40--sm {
    padding-left: 2.8571428571rem !important;
  }

  /* P */

  .g-pl-45--sm {
    padding-left: 3.2142857143rem !important;
  }

  /* P */

  .g-pl-50--sm {
    padding-left: 3.5714285714rem !important;
  }

  /* P */

  .g-pl-55--sm {
    padding-left: 3.9285714286rem !important;
  }

  /* P */

  .g-pl-60--sm {
    padding-left: 4.2857142857rem !important;
  }

  /* P */

  .g-pl-65--sm {
    padding-left: 4.6428571429rem !important;
  }

  /* P */

  .g-pl-70--sm {
    padding-left: 5rem !important;
  }

  /* P */

  .g-pl-75--sm {
    padding-left: 5.3571428571rem !important;
  }

  /* P */

  .g-pl-80--sm {
    padding-left: 5.7142857143rem !important;
  }

  /* P */

  .g-pl-85--sm {
    padding-left: 6.0714285714rem !important;
  }

  /* P */

  .g-pl-90--sm {
    padding-left: 6.4285714286rem !important;
  }

  /* P */

  .g-pl-95--sm {
    padding-left: 6.7857142857rem !important;
  }

  /* P */

  .g-pl-100--sm {
    padding-left: 7.1428571429rem !important;
  }

  /* P */

  .g-pl-105--sm {
    padding-left: 7.5rem !important;
  }

  /* P */

  .g-pl-110--sm {
    padding-left: 7.8571428571rem !important;
  }

  /* P */

  .g-pl-115--sm {
    padding-left: 8.2142857143rem !important;
  }

  /* P */

  .g-pl-120--sm {
    padding-left: 8.5714285714rem !important;
  }

  /* P */

  .g-pl-125--sm {
    padding-left: 8.9285714286rem !important;
  }

  /* P */

  .g-pl-130--sm {
    padding-left: 9.2857142857rem !important;
  }

  /* P */

  .g-pl-135--sm {
    padding-left: 9.6428571429rem !important;
  }

  /* P */

  .g-pl-140--sm {
    padding-left: 10rem !important;
  }

  /* P */

  .g-pl-145--sm {
    padding-left: 10.3571428571rem !important;
  }

  /* P */

  .g-pl-150--sm {
    padding-left: 10.7142857143rem !important;
  }

  /* P */

  .g-pl-155--sm {
    padding-left: 11.0714285714rem !important;
  }

  /* P */

  .g-pl-160--sm {
    padding-left: 11.4285714286rem !important;
  }

  /* P */

  .g-pl-165--sm {
    padding-left: 11.7857142857rem !important;
  }

  /* P */

  .g-pl-170--sm {
    padding-left: 12.1428571429rem !important;
  }
}

/* Padding Spaces (md)
------------------------------------*/

/* P */

@media (min-width: 768px) {
  .g-pa-0--md {
    padding: 0 !important;
  }

  .g-px-0--md {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .g-py-0--md {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .g-pt-0--md {
    padding-top: 0 !important;
  }

  .g-pr-0--md {
    padding-right: 0 !important;
  }

  .g-pb-0--md {
    padding-bottom: 0 !important;
  }

  .g-pl-0--md {
    padding-left: 0 !important;
  }

  /* Padding Around */

  .g-pa-2--md {
    padding: 0.1428571429rem !important;
  }

  .g-pa-3--md {
    padding: 0.2142857143rem !important;
  }

  .g-pa-5--md {
    padding: 0.3571428571rem !important;
  }

  .g-pa-7--md {
    padding: 0.5rem !important;
  }

  .g-pa-10--md {
    padding: 0.7142857143rem !important;
  }

  .g-pa-15--md {
    padding: 1.0714285714rem !important;
  }

  .g-pa-20--md {
    padding: 1.4285714286rem !important;
  }

  .g-pa-25--md {
    padding: 1.7857142857rem !important;
  }

  .g-pa-30--md {
    padding: 2.1428571429rem !important;
  }

  .g-pa-35--md {
    padding: 2.5rem !important;
  }

  .g-pa-40--md {
    padding: 2.8571428571rem !important;
  }

  .g-pa-45--md {
    padding: 3.2142857143rem !important;
  }

  .g-pa-50--md {
    padding: 3.5714285714rem !important;
  }

  .g-pa-55--md {
    padding: 3.9285714286rem !important;
  }

  .g-pa-60--md {
    padding: 4.2857142857rem !important;
  }

  .g-pa-65--md {
    padding: 4.6428571429rem !important;
  }

  .g-pa-70--md {
    padding: 5rem !important;
  }

  .g-pa-75--md {
    padding: 5.3571428571rem !important;
  }

  .g-pa-80--md {
    padding: 5.7142857143rem !important;
  }

  .g-pa-85--md {
    padding: 6.0714285714rem !important;
  }

  .g-pa-90--md {
    padding: 6.4285714286rem !important;
  }

  .g-pa-95--md {
    padding: 6.7857142857rem !important;
  }

  .g-pa-100--md {
    padding: 7.1428571429rem !important;
  }

  .g-pa-105--md {
    padding: 7.5rem !important;
  }

  .g-pa-110--md {
    padding: 7.8571428571rem !important;
  }

  .g-pa-115--md {
    padding: 8.2142857143rem !important;
  }

  .g-pa-120--md {
    padding: 8.5714285714rem !important;
  }

  .g-pa-125--md {
    padding: 8.9285714286rem !important;
  }

  .g-pa-130--md {
    padding: 9.2857142857rem !important;
  }

  .g-pa-135--md {
    padding: 9.6428571429rem !important;
  }

  .g-pa-140--md {
    padding: 10rem !important;
  }

  .g-pa-145--md {
    padding: 10.3571428571rem !important;
  }

  .g-pa-150--md {
    padding: 10.7142857143rem !important;
  }

  /* Padding X */

  .g-px-1--md {
    padding-left: 0.0714285714rem !important;
    padding-right: 0.0714285714rem !important;
  }

  .g-px-2--md {
    padding-left: 0.1428571429rem !important;
    padding-right: 0.1428571429rem !important;
  }

  .g-px-3--md {
    padding-left: 0.2142857143rem !important;
    padding-right: 0.2142857143rem !important;
  }

  .g-px-4--md {
    padding-left: 0.2857142857rem !important;
    padding-right: 0.2857142857rem !important;
  }

  .g-px-5--md {
    padding-left: 0.3571428571rem !important;
    padding-right: 0.3571428571rem !important;
  }

  .g-px-6--md {
    padding-left: 0.4285714286rem !important;
    padding-right: 0.4285714286rem !important;
  }

  .g-px-7--md {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .g-px-8--md {
    padding-left: 0.5714285714rem !important;
    padding-right: 0.5714285714rem !important;
  }

  .g-px-9--md {
    padding-left: 0.6428571429rem !important;
    padding-right: 0.6428571429rem !important;
  }

  .g-px-10--md {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }

  .g-px-11--md {
    padding-left: 0.7857142857rem !important;
    padding-right: 0.7857142857rem !important;
  }

  .g-px-12--md {
    padding-left: 0.8571428571rem !important;
    padding-right: 0.8571428571rem !important;
  }

  .g-px-13--md {
    padding-left: 0.9285714286rem !important;
    padding-right: 0.9285714286rem !important;
  }

  .g-px-14--md {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .g-px-15--md {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }

  .g-px-16--md {
    padding-left: 1.1428571429rem !important;
    padding-right: 1.1428571429rem !important;
  }

  .g-px-17--md {
    padding-left: 1.2142857143rem !important;
    padding-right: 1.2142857143rem !important;
  }

  .g-px-18--md {
    padding-left: 1.2857142857rem !important;
    padding-right: 1.2857142857rem !important;
  }

  .g-px-19--md {
    padding-left: 1.3571428571rem !important;
    padding-right: 1.3571428571rem !important;
  }

  .g-px-10--md {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }

  .g-px-15--md {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }

  .g-px-20--md {
    padding-left: 1.4285714286rem !important;
    padding-right: 1.4285714286rem !important;
  }

  .g-px-25--md {
    padding-left: 1.7857142857rem !important;
    padding-right: 1.7857142857rem !important;
  }

  .g-px-30--md {
    padding-left: 2.1428571429rem !important;
    padding-right: 2.1428571429rem !important;
  }

  .g-px-35--md {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .g-px-40--md {
    padding-left: 2.8571428571rem !important;
    padding-right: 2.8571428571rem !important;
  }

  .g-px-45--md {
    padding-left: 3.2142857143rem !important;
    padding-right: 3.2142857143rem !important;
  }

  .g-px-50--md {
    padding-left: 3.5714285714rem !important;
    padding-right: 3.5714285714rem !important;
  }

  .g-px-55--md {
    padding-left: 3.9285714286rem !important;
    padding-right: 3.9285714286rem !important;
  }

  .g-px-60--md {
    padding-left: 4.2857142857rem !important;
    padding-right: 4.2857142857rem !important;
  }

  .g-px-65--md {
    padding-left: 4.6428571429rem !important;
    padding-right: 4.6428571429rem !important;
  }

  .g-px-70--md {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .g-px-75--md {
    padding-left: 5.3571428571rem !important;
    padding-right: 5.3571428571rem !important;
  }

  .g-px-80--md {
    padding-left: 5.7142857143rem !important;
    padding-right: 5.7142857143rem !important;
  }

  .g-px-85--md {
    padding-left: 6.0714285714rem !important;
    padding-right: 6.0714285714rem !important;
  }

  .g-px-90--md {
    padding-left: 6.4285714286rem !important;
    padding-right: 6.4285714286rem !important;
  }

  .g-px-95--md {
    padding-left: 6.7857142857rem !important;
    padding-right: 6.7857142857rem !important;
  }

  .g-px-100--md {
    padding-left: 7.1428571429rem !important;
    padding-right: 7.1428571429rem !important;
  }

  .g-px-105--md {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .g-px-110--md {
    padding-left: 7.8571428571rem !important;
    padding-right: 7.8571428571rem !important;
  }

  .g-px-115--md {
    padding-left: 8.2142857143rem !important;
    padding-right: 8.2142857143rem !important;
  }

  .g-px-120--md {
    padding-left: 8.5714285714rem !important;
    padding-right: 8.5714285714rem !important;
  }

  .g-px-125--md {
    padding-left: 8.9285714286rem !important;
    padding-right: 8.9285714286rem !important;
  }

  .g-px-130--md {
    padding-left: 9.2857142857rem !important;
    padding-right: 9.2857142857rem !important;
  }

  .g-px-135--md {
    padding-left: 9.6428571429rem !important;
    padding-right: 9.6428571429rem !important;
  }

  .g-px-140--md {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .g-px-145--md {
    padding-left: 10.3571428571rem !important;
    padding-right: 10.3571428571rem !important;
  }

  .g-px-150--md {
    padding-left: 10.7142857143rem !important;
    padding-right: 10.7142857143rem !important;
  }

  /* Padding Y */

  .g-py-1--md {
    padding-top: 0.0714285714rem !important;
    padding-bottom: 0.0714285714rem !important;
  }

  .g-py-2--md {
    padding-top: 0.1428571429rem !important;
    padding-bottom: 0.1428571429rem !important;
  }

  .g-py-3--md {
    padding-top: 0.2142857143rem !important;
    padding-bottom: 0.2142857143rem !important;
  }

  .g-py-4--md {
    padding-top: 0.2857142857rem !important;
    padding-bottom: 0.2857142857rem !important;
  }

  .g-py-5--md {
    padding-top: 0.3571428571rem !important;
    padding-bottom: 0.3571428571rem !important;
  }

  .g-py-6--md {
    padding-top: 0.4285714286rem !important;
    padding-bottom: 0.4285714286rem !important;
  }

  .g-py-7--md {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .g-py-8--md {
    padding-top: 0.5714285714rem !important;
    padding-bottom: 0.5714285714rem !important;
  }

  .g-py-9--md {
    padding-top: 0.6428571429rem !important;
    padding-bottom: 0.6428571429rem !important;
  }

  .g-py-10--md {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }

  .g-py-11--md {
    padding-top: 0.7857142857rem !important;
    padding-bottom: 0.7857142857rem !important;
  }

  .g-py-12--md {
    padding-top: 0.8571428571rem !important;
    padding-bottom: 0.8571428571rem !important;
  }

  .g-py-13--md {
    padding-top: 0.9285714286rem !important;
    padding-bottom: 0.9285714286rem !important;
  }

  .g-py-14--md {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .g-py-15--md {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }

  .g-py-16--md {
    padding-top: 1.1428571429rem !important;
    padding-bottom: 1.1428571429rem !important;
  }

  .g-py-17--md {
    padding-top: 1.2142857143rem !important;
    padding-bottom: 1.2142857143rem !important;
  }

  .g-py-18--md {
    padding-top: 1.2857142857rem !important;
    padding-bottom: 1.2857142857rem !important;
  }

  .g-py-19--md {
    padding-top: 1.3571428571rem !important;
    padding-bottom: 1.3571428571rem !important;
  }

  /* P */

  .g-py-10--md {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }

  /* P */

  .g-py-15--md {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }

  /* P */

  .g-py-20--md {
    padding-top: 1.4285714286rem !important;
    padding-bottom: 1.4285714286rem !important;
  }

  /* P */

  .g-py-25--md {
    padding-top: 1.7857142857rem !important;
    padding-bottom: 1.7857142857rem !important;
  }

  /* P */

  .g-py-30--md {
    padding-top: 2.1428571429rem !important;
    padding-bottom: 2.1428571429rem !important;
  }

  /* P */

  .g-py-35--md {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  /* P */

  .g-py-40--md {
    padding-top: 2.8571428571rem !important;
    padding-bottom: 2.8571428571rem !important;
  }

  /* P */

  .g-py-45--md {
    padding-top: 3.2142857143rem !important;
    padding-bottom: 3.2142857143rem !important;
  }

  /* P */

  .g-py-50--md {
    padding-top: 3.5714285714rem !important;
    padding-bottom: 3.5714285714rem !important;
  }

  /* P */

  .g-py-55--md {
    padding-top: 3.9285714286rem !important;
    padding-bottom: 3.9285714286rem !important;
  }

  /* P */

  .g-py-60--md {
    padding-top: 4.2857142857rem !important;
    padding-bottom: 4.2857142857rem !important;
  }

  /* P */

  .g-py-65--md {
    padding-top: 4.6428571429rem !important;
    padding-bottom: 4.6428571429rem !important;
  }

  /* P */

  .g-py-70--md {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  /* P */

  .g-py-75--md {
    padding-top: 5.3571428571rem !important;
    padding-bottom: 5.3571428571rem !important;
  }

  /* P */

  .g-py-80--md {
    padding-top: 5.7142857143rem !important;
    padding-bottom: 5.7142857143rem !important;
  }

  /* P */

  .g-py-85--md {
    padding-top: 6.0714285714rem !important;
    padding-bottom: 6.0714285714rem !important;
  }

  /* P */

  .g-py-90--md {
    padding-top: 6.4285714286rem !important;
    padding-bottom: 6.4285714286rem !important;
  }

  /* P */

  .g-py-95--md {
    padding-top: 6.7857142857rem !important;
    padding-bottom: 6.7857142857rem !important;
  }

  /* P */

  .g-py-100--md {
    padding-top: 7.1428571429rem !important;
    padding-bottom: 7.1428571429rem !important;
  }

  /* P */

  .g-py-105--md {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  /* P */

  .g-py-110--md {
    padding-top: 7.8571428571rem !important;
    padding-bottom: 7.8571428571rem !important;
  }

  /* P */

  .g-py-115--md {
    padding-top: 8.2142857143rem !important;
    padding-bottom: 8.2142857143rem !important;
  }

  /* P */

  .g-py-120--md {
    padding-top: 8.5714285714rem !important;
    padding-bottom: 8.5714285714rem !important;
  }

  /* P */

  .g-py-125--md {
    padding-top: 8.9285714286rem !important;
    padding-bottom: 8.9285714286rem !important;
  }

  /* P */

  .g-py-130--md {
    padding-top: 9.2857142857rem !important;
    padding-bottom: 9.2857142857rem !important;
  }

  /* P */

  .g-py-135--md {
    padding-top: 9.6428571429rem !important;
    padding-bottom: 9.6428571429rem !important;
  }

  /* P */

  .g-py-140--md {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  /* P */

  .g-py-145--md {
    padding-top: 10.3571428571rem !important;
    padding-bottom: 10.3571428571rem !important;
  }

  /* P */

  .g-py-150--md {
    padding-top: 10.7142857143rem !important;
    padding-bottom: 10.7142857143rem !important;
  }

  /* P */

  .g-py-155--md {
    padding-top: 11.0714285714rem !important;
    padding-bottom: 11.0714285714rem !important;
  }

  /* P */

  .g-py-160--md {
    padding-top: 11.4285714286rem !important;
    padding-bottom: 11.4285714286rem !important;
  }

  /* P */

  .g-py-165--md {
    padding-top: 11.7857142857rem !important;
    padding-bottom: 11.7857142857rem !important;
  }

  /* P */

  .g-py-170--md {
    padding-top: 12.1428571429rem !important;
    padding-bottom: 12.1428571429rem !important;
  }

  /* P */

  .g-py-175--md {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }

  /* P */

  .g-py-180--md {
    padding-top: 12.8571428571rem !important;
    padding-bottom: 12.8571428571rem !important;
  }

  /* P */

  .g-py-185--md {
    padding-top: 13.2142857143rem !important;
    padding-bottom: 13.2142857143rem !important;
  }

  /* P */

  .g-py-190--md {
    padding-top: 13.5714285714rem !important;
    padding-bottom: 13.5714285714rem !important;
  }

  /* P */

  .g-py-195--md {
    padding-top: 13.9285714286rem !important;
    padding-bottom: 13.9285714286rem !important;
  }

  /* P */

  .g-py-200--md {
    padding-top: 14.2857142857rem !important;
    padding-bottom: 14.2857142857rem !important;
  }

  /* P */

  .g-py-205--md {
    padding-top: 14.6428571429rem !important;
    padding-bottom: 14.6428571429rem !important;
  }

  /* P */

  .g-py-210--md {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  /* Padding Top */

  .g-pt-0--md {
    padding-top: 0px !important;
  }

  .g-pt-1--md {
    padding-top: 0.0714285714rem !important;
  }

  .g-pt-2--md {
    padding-top: 0.1428571429rem !important;
  }

  .g-pt-3--md {
    padding-top: 0.2142857143rem !important;
  }

  .g-pt-4--md {
    padding-top: 0.2857142857rem !important;
  }

  .g-pt-5--md {
    padding-top: 0.3571428571rem !important;
  }

  .g-pt-6--md {
    padding-top: 0.4285714286rem !important;
  }

  .g-pt-7--md {
    padding-top: 0.5rem !important;
  }

  .g-pt-8--md {
    padding-top: 0.5714285714rem !important;
  }

  .g-pt-9--md {
    padding-top: 0.6428571429rem !important;
  }

  .g-pt-10--md {
    padding-top: 0.7142857143rem !important;
  }

  .g-pt-11--md {
    padding-top: 0.7857142857rem !important;
  }

  .g-pt-12--md {
    padding-top: 0.8571428571rem !important;
  }

  .g-pt-13--md {
    padding-top: 0.9285714286rem !important;
  }

  .g-pt-14--md {
    padding-top: 1rem !important;
  }

  .g-pt-15--md {
    padding-top: 1.0714285714rem !important;
  }

  .g-pt-16--md {
    padding-top: 1.1428571429rem !important;
  }

  .g-pt-17--md {
    padding-top: 1.2142857143rem !important;
  }

  .g-pt-18--md {
    padding-top: 1.2857142857rem !important;
  }

  .g-pt-19--md {
    padding-top: 1.3571428571rem !important;
  }

  .g-pt-10--md {
    padding-top: 0.7142857143rem !important;
  }

  .g-pt-15--md {
    padding-top: 1.0714285714rem !important;
  }

  .g-pt-20--md {
    padding-top: 1.4285714286rem !important;
  }

  .g-pt-25--md {
    padding-top: 1.7857142857rem !important;
  }

  .g-pt-30--md {
    padding-top: 2.1428571429rem !important;
  }

  .g-pt-35--md {
    padding-top: 2.5rem !important;
  }

  .g-pt-40--md {
    padding-top: 2.8571428571rem !important;
  }

  .g-pt-45--md {
    padding-top: 3.2142857143rem !important;
  }

  .g-pt-50--md {
    padding-top: 3.5714285714rem !important;
  }

  .g-pt-55--md {
    padding-top: 3.9285714286rem !important;
  }

  .g-pt-60--md {
    padding-top: 4.2857142857rem !important;
  }

  .g-pt-65--md {
    padding-top: 4.6428571429rem !important;
  }

  .g-pt-70--md {
    padding-top: 5rem !important;
  }

  .g-pt-75--md {
    padding-top: 5.3571428571rem !important;
  }

  .g-pt-80--md {
    padding-top: 5.7142857143rem !important;
  }

  .g-pt-85--md {
    padding-top: 6.0714285714rem !important;
  }

  .g-pt-90--md {
    padding-top: 6.4285714286rem !important;
  }

  .g-pt-95--md {
    padding-top: 6.7857142857rem !important;
  }

  .g-pt-100--md {
    padding-top: 7.1428571429rem !important;
  }

  .g-pt-105--md {
    padding-top: 7.5rem !important;
  }

  .g-pt-110--md {
    padding-top: 7.8571428571rem !important;
  }

  .g-pt-115--md {
    padding-top: 8.2142857143rem !important;
  }

  .g-pt-120--md {
    padding-top: 8.5714285714rem !important;
  }

  .g-pt-125--md {
    padding-top: 8.9285714286rem !important;
  }

  .g-pt-130--md {
    padding-top: 9.2857142857rem !important;
  }

  .g-pt-135--md {
    padding-top: 9.6428571429rem !important;
  }

  .g-pt-140--md {
    padding-top: 10rem !important;
  }

  .g-pt-145--md {
    padding-top: 10.3571428571rem !important;
  }

  .g-pt-150--md {
    padding-top: 10.7142857143rem !important;
  }

  /* Padding Right */

  .g-pr-0--md {
    padding-right: 0px !important;
  }

  .g-pr-1--md {
    padding-right: 0.0714285714rem !important;
  }

  .g-pr-2--md {
    padding-right: 0.1428571429rem !important;
  }

  .g-pr-3--md {
    padding-right: 0.2142857143rem !important;
  }

  .g-pr-4--md {
    padding-right: 0.2857142857rem !important;
  }

  .g-pr-5--md {
    padding-right: 0.3571428571rem !important;
  }

  .g-pr-6--md {
    padding-right: 0.4285714286rem !important;
  }

  .g-pr-7--md {
    padding-right: 0.5rem !important;
  }

  .g-pr-8--md {
    padding-right: 0.5714285714rem !important;
  }

  .g-pr-9--md {
    padding-right: 0.6428571429rem !important;
  }

  /* P */

  .g-pr-10--md {
    padding-right: 0.7142857143rem !important;
  }

  /* P */

  .g-pr-15--md {
    padding-right: 1.0714285714rem !important;
  }

  /* P */

  .g-pr-20--md {
    padding-right: 1.4285714286rem !important;
  }

  /* P */

  .g-pr-25--md {
    padding-right: 1.7857142857rem !important;
  }

  /* P */

  .g-pr-30--md {
    padding-right: 2.1428571429rem !important;
  }

  /* P */

  .g-pr-35--md {
    padding-right: 2.5rem !important;
  }

  /* P */

  .g-pr-40--md {
    padding-right: 2.8571428571rem !important;
  }

  /* P */

  .g-pr-45--md {
    padding-right: 3.2142857143rem !important;
  }

  /* P */

  .g-pr-50--md {
    padding-right: 3.5714285714rem !important;
  }

  /* P */

  .g-pr-55--md {
    padding-right: 3.9285714286rem !important;
  }

  /* P */

  .g-pr-60--md {
    padding-right: 4.2857142857rem !important;
  }

  /* P */

  .g-pr-65--md {
    padding-right: 4.6428571429rem !important;
  }

  /* P */

  .g-pr-70--md {
    padding-right: 5rem !important;
  }

  /* P */

  .g-pr-75--md {
    padding-right: 5.3571428571rem !important;
  }

  /* P */

  .g-pr-80--md {
    padding-right: 5.7142857143rem !important;
  }

  /* P */

  .g-pr-85--md {
    padding-right: 6.0714285714rem !important;
  }

  /* P */

  .g-pr-90--md {
    padding-right: 6.4285714286rem !important;
  }

  /* P */

  .g-pr-95--md {
    padding-right: 6.7857142857rem !important;
  }

  /* P */

  .g-pr-100--md {
    padding-right: 7.1428571429rem !important;
  }

  /* P */

  .g-pr-105--md {
    padding-right: 7.5rem !important;
  }

  /* P */

  .g-pr-110--md {
    padding-right: 7.8571428571rem !important;
  }

  /* P */

  .g-pr-115--md {
    padding-right: 8.2142857143rem !important;
  }

  /* P */

  .g-pr-120--md {
    padding-right: 8.5714285714rem !important;
  }

  /* P */

  .g-pr-125--md {
    padding-right: 8.9285714286rem !important;
  }

  /* P */

  .g-pr-130--md {
    padding-right: 9.2857142857rem !important;
  }

  /* P */

  .g-pr-135--md {
    padding-right: 9.6428571429rem !important;
  }

  /* P */

  .g-pr-140--md {
    padding-right: 10rem !important;
  }

  /* P */

  .g-pr-145--md {
    padding-right: 10.3571428571rem !important;
  }

  /* P */

  .g-pr-150--md {
    padding-right: 10.7142857143rem !important;
  }

  /* P */

  .g-pr-155--md {
    padding-right: 11.0714285714rem !important;
  }

  /* P */

  .g-pr-160--md {
    padding-right: 11.4285714286rem !important;
  }

  /* P */

  .g-pr-165--md {
    padding-right: 11.7857142857rem !important;
  }

  /* P */

  .g-pr-170--md {
    padding-right: 12.1428571429rem !important;
  }

  /* Padding Bottom */

  .g-pb-0--md {
    padding-bottom: 0px !important;
  }

  .g-pb-1--md {
    padding-bottom: 0.0714285714rem !important;
  }

  .g-pb-2--md {
    padding-bottom: 0.1428571429rem !important;
  }

  .g-pb-3--md {
    padding-bottom: 0.2142857143rem !important;
  }

  .g-pb-4--md {
    padding-bottom: 0.2857142857rem !important;
  }

  .g-pb-5--md {
    padding-bottom: 0.3571428571rem !important;
  }

  .g-pb-6--md {
    padding-bottom: 0.4285714286rem !important;
  }

  .g-pb-7--md {
    padding-bottom: 0.5rem !important;
  }

  .g-pb-8--md {
    padding-bottom: 0.5714285714rem !important;
  }

  .g-pb-9--md {
    padding-bottom: 0.6428571429rem !important;
  }

  .g-pb-10--md {
    padding-bottom: 0.7142857143rem !important;
  }

  .g-pb-15--md {
    padding-bottom: 1.0714285714rem !important;
  }

  .g-pb-20--md {
    padding-bottom: 1.4285714286rem !important;
  }

  .g-pb-25--md {
    padding-bottom: 1.7857142857rem !important;
  }

  .g-pb-30--md {
    padding-bottom: 2.1428571429rem !important;
  }

  .g-pb-35--md {
    padding-bottom: 2.5rem !important;
  }

  .g-pb-40--md {
    padding-bottom: 2.8571428571rem !important;
  }

  .g-pb-45--md {
    padding-bottom: 3.2142857143rem !important;
  }

  .g-pb-50--md {
    padding-bottom: 3.5714285714rem !important;
  }

  .g-pb-55--md {
    padding-bottom: 3.9285714286rem !important;
  }

  .g-pb-60--md {
    padding-bottom: 4.2857142857rem !important;
  }

  .g-pb-65--md {
    padding-bottom: 4.6428571429rem !important;
  }

  .g-pb-70--md {
    padding-bottom: 5rem !important;
  }

  .g-pb-75--md {
    padding-bottom: 5.3571428571rem !important;
  }

  .g-pb-80--md {
    padding-bottom: 5.7142857143rem !important;
  }

  .g-pb-85--md {
    padding-bottom: 6.0714285714rem !important;
  }

  .g-pb-90--md {
    padding-bottom: 6.4285714286rem !important;
  }

  .g-pb-95--md {
    padding-bottom: 6.7857142857rem !important;
  }

  .g-pb-100--md {
    padding-bottom: 7.1428571429rem !important;
  }

  .g-pb-105--md {
    padding-bottom: 7.5rem !important;
  }

  .g-pb-110--md {
    padding-bottom: 7.8571428571rem !important;
  }

  .g-pb-115--md {
    padding-bottom: 8.2142857143rem !important;
  }

  .g-pb-120--md {
    padding-bottom: 8.5714285714rem !important;
  }

  .g-pb-125--md {
    padding-bottom: 8.9285714286rem !important;
  }

  .g-pb-130--md {
    padding-bottom: 9.2857142857rem !important;
  }

  .g-pb-135--md {
    padding-bottom: 9.6428571429rem !important;
  }

  .g-pb-140--md {
    padding-bottom: 10rem !important;
  }

  .g-pb-145--md {
    padding-bottom: 10.3571428571rem !important;
  }

  .g-pb-150--md {
    padding-bottom: 10.7142857143rem !important;
  }

  /* Padding Left */

  .g-pl-0--md {
    padding-left: 0px !important;
  }

  .g-pl-1--md {
    padding-left: 0.0714285714rem !important;
  }

  .g-pl-2--md {
    padding-left: 0.1428571429rem !important;
  }

  .g-pl-3--md {
    padding-left: 0.2142857143rem !important;
  }

  .g-pl-4--md {
    padding-left: 0.2857142857rem !important;
  }

  .g-pl-5--md {
    padding-left: 0.3571428571rem !important;
  }

  .g-pl-6--md {
    padding-left: 0.4285714286rem !important;
  }

  .g-pl-7--md {
    padding-left: 0.5rem !important;
  }

  .g-pl-8--md {
    padding-left: 0.5714285714rem !important;
  }

  .g-pl-9--md {
    padding-left: 0.6428571429rem !important;
  }

  /* P */

  .g-pl-10--md {
    padding-left: 0.7142857143rem !important;
  }

  /* P */

  .g-pl-15--md {
    padding-left: 1.0714285714rem !important;
  }

  /* P */

  .g-pl-20--md {
    padding-left: 1.4285714286rem !important;
  }

  /* P */

  .g-pl-25--md {
    padding-left: 1.7857142857rem !important;
  }

  /* P */

  .g-pl-30--md {
    padding-left: 2.1428571429rem !important;
  }

  /* P */

  .g-pl-35--md {
    padding-left: 2.5rem !important;
  }

  /* P */

  .g-pl-40--md {
    padding-left: 2.8571428571rem !important;
  }

  /* P */

  .g-pl-45--md {
    padding-left: 3.2142857143rem !important;
  }

  /* P */

  .g-pl-50--md {
    padding-left: 3.5714285714rem !important;
  }

  /* P */

  .g-pl-55--md {
    padding-left: 3.9285714286rem !important;
  }

  /* P */

  .g-pl-60--md {
    padding-left: 4.2857142857rem !important;
  }

  /* P */

  .g-pl-65--md {
    padding-left: 4.6428571429rem !important;
  }

  /* P */

  .g-pl-70--md {
    padding-left: 5rem !important;
  }

  /* P */

  .g-pl-75--md {
    padding-left: 5.3571428571rem !important;
  }

  /* P */

  .g-pl-80--md {
    padding-left: 5.7142857143rem !important;
  }

  /* P */

  .g-pl-85--md {
    padding-left: 6.0714285714rem !important;
  }

  /* P */

  .g-pl-90--md {
    padding-left: 6.4285714286rem !important;
  }

  /* P */

  .g-pl-95--md {
    padding-left: 6.7857142857rem !important;
  }

  /* P */

  .g-pl-100--md {
    padding-left: 7.1428571429rem !important;
  }

  /* P */

  .g-pl-105--md {
    padding-left: 7.5rem !important;
  }

  /* P */

  .g-pl-110--md {
    padding-left: 7.8571428571rem !important;
  }

  /* P */

  .g-pl-115--md {
    padding-left: 8.2142857143rem !important;
  }

  /* P */

  .g-pl-120--md {
    padding-left: 8.5714285714rem !important;
  }

  /* P */

  .g-pl-125--md {
    padding-left: 8.9285714286rem !important;
  }

  /* P */

  .g-pl-130--md {
    padding-left: 9.2857142857rem !important;
  }

  /* P */

  .g-pl-135--md {
    padding-left: 9.6428571429rem !important;
  }

  /* P */

  .g-pl-140--md {
    padding-left: 10rem !important;
  }

  /* P */

  .g-pl-145--md {
    padding-left: 10.3571428571rem !important;
  }

  /* P */

  .g-pl-150--md {
    padding-left: 10.7142857143rem !important;
  }

  /* P */

  .g-pl-155--md {
    padding-left: 11.0714285714rem !important;
  }

  /* P */

  .g-pl-160--md {
    padding-left: 11.4285714286rem !important;
  }

  /* P */

  .g-pl-165--md {
    padding-left: 11.7857142857rem !important;
  }

  /* P */

  .g-pl-170--md {
    padding-left: 12.1428571429rem !important;
  }
}

/* Padding Spaces (lg) P
------------------------------------*/

/* P */

@media (min-width: 992px) {
  .g-pa-0--lg {
    padding: 0 !important;
  }

  .g-px-0--lg {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .g-py-0--lg {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .g-pt-0--lg {
    padding-top: 0 !important;
  }

  .g-pr-0--lg {
    padding-right: 0 !important;
  }

  .g-pb-0--lg {
    padding-bottom: 0 !important;
  }

  .g-pl-0--lg {
    padding-left: 0 !important;
  }

  /* Padding Around */

  .g-pa-2--lg {
    padding: 0.1428571429rem !important;
  }

  .g-pa-3--lg {
    padding: 0.2142857143rem !important;
  }

  .g-pa-5--lg {
    padding: 0.3571428571rem !important;
  }

  .g-pa-7--lg {
    padding: 0.5rem !important;
  }

  .g-pa-10--lg {
    padding: 0.7142857143rem !important;
  }

  .g-pa-15--lg {
    padding: 1.0714285714rem !important;
  }

  .g-pa-20--lg {
    padding: 1.4285714286rem !important;
  }

  .g-pa-25--lg {
    padding: 1.7857142857rem !important;
  }

  .g-pa-30--lg {
    padding: 2.1428571429rem !important;
  }

  .g-pa-35--lg {
    padding: 2.5rem !important;
  }

  .g-pa-40--lg {
    padding: 2.8571428571rem !important;
  }

  .g-pa-45--lg {
    padding: 3.2142857143rem !important;
  }

  .g-pa-50--lg {
    padding: 3.5714285714rem !important;
  }

  .g-pa-55--lg {
    padding: 3.9285714286rem !important;
  }

  .g-pa-60--lg {
    padding: 4.2857142857rem !important;
  }

  .g-pa-65--lg {
    padding: 4.6428571429rem !important;
  }

  .g-pa-70--lg {
    padding: 5rem !important;
  }

  .g-pa-75--lg {
    padding: 5.3571428571rem !important;
  }

  .g-pa-80--lg {
    padding: 5.7142857143rem !important;
  }

  .g-pa-85--lg {
    padding: 6.0714285714rem !important;
  }

  .g-pa-90--lg {
    padding: 6.4285714286rem !important;
  }

  .g-pa-95--lg {
    padding: 6.7857142857rem !important;
  }

  .g-pa-100--lg {
    padding: 7.1428571429rem !important;
  }

  .g-pa-105--lg {
    padding: 7.5rem !important;
  }

  .g-pa-110--lg {
    padding: 7.8571428571rem !important;
  }

  .g-pa-115--lg {
    padding: 8.2142857143rem !important;
  }

  .g-pa-120--lg {
    padding: 8.5714285714rem !important;
  }

  .g-pa-125--lg {
    padding: 8.9285714286rem !important;
  }

  .g-pa-130--lg {
    padding: 9.2857142857rem !important;
  }

  .g-pa-135--lg {
    padding: 9.6428571429rem !important;
  }

  .g-pa-140--lg {
    padding: 10rem !important;
  }

  .g-pa-145--lg {
    padding: 10.3571428571rem !important;
  }

  .g-pa-150--lg {
    padding: 10.7142857143rem !important;
  }

  /* Padding X */

  .g-px-1--lg {
    padding-left: 0.0714285714rem !important;
    padding-right: 0.0714285714rem !important;
  }

  .g-px-2--lg {
    padding-left: 0.1428571429rem !important;
    padding-right: 0.1428571429rem !important;
  }

  .g-px-3--lg {
    padding-left: 0.2142857143rem !important;
    padding-right: 0.2142857143rem !important;
  }

  .g-px-4--lg {
    padding-left: 0.2857142857rem !important;
    padding-right: 0.2857142857rem !important;
  }

  .g-px-5--lg {
    padding-left: 0.3571428571rem !important;
    padding-right: 0.3571428571rem !important;
  }

  .g-px-6--lg {
    padding-left: 0.4285714286rem !important;
    padding-right: 0.4285714286rem !important;
  }

  .g-px-7--lg {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .g-px-8--lg {
    padding-left: 0.5714285714rem !important;
    padding-right: 0.5714285714rem !important;
  }

  .g-px-9--lg {
    padding-left: 0.6428571429rem !important;
    padding-right: 0.6428571429rem !important;
  }

  .g-px-10--lg {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }

  .g-px-11--lg {
    padding-left: 0.7857142857rem !important;
    padding-right: 0.7857142857rem !important;
  }

  .g-px-12--lg {
    padding-left: 0.8571428571rem !important;
    padding-right: 0.8571428571rem !important;
  }

  .g-px-13--lg {
    padding-left: 0.9285714286rem !important;
    padding-right: 0.9285714286rem !important;
  }

  .g-px-14--lg {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .g-px-15--lg {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }

  .g-px-16--lg {
    padding-left: 1.1428571429rem !important;
    padding-right: 1.1428571429rem !important;
  }

  .g-px-17--lg {
    padding-left: 1.2142857143rem !important;
    padding-right: 1.2142857143rem !important;
  }

  .g-px-18--lg {
    padding-left: 1.2857142857rem !important;
    padding-right: 1.2857142857rem !important;
  }

  .g-px-19--lg {
    padding-left: 1.3571428571rem !important;
    padding-right: 1.3571428571rem !important;
  }

  .g-px-10--lg {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }

  .g-px-15--lg {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }

  .g-px-20--lg {
    padding-left: 1.4285714286rem !important;
    padding-right: 1.4285714286rem !important;
  }

  .g-px-25--lg {
    padding-left: 1.7857142857rem !important;
    padding-right: 1.7857142857rem !important;
  }

  .g-px-30--lg {
    padding-left: 2.1428571429rem !important;
    padding-right: 2.1428571429rem !important;
  }

  .g-px-35--lg {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .g-px-40--lg {
    padding-left: 2.8571428571rem !important;
    padding-right: 2.8571428571rem !important;
  }

  .g-px-45--lg {
    padding-left: 3.2142857143rem !important;
    padding-right: 3.2142857143rem !important;
  }

  .g-px-50--lg {
    padding-left: 3.5714285714rem !important;
    padding-right: 3.5714285714rem !important;
  }

  .g-px-55--lg {
    padding-left: 3.9285714286rem !important;
    padding-right: 3.9285714286rem !important;
  }

  .g-px-60--lg {
    padding-left: 4.2857142857rem !important;
    padding-right: 4.2857142857rem !important;
  }

  .g-px-65--lg {
    padding-left: 4.6428571429rem !important;
    padding-right: 4.6428571429rem !important;
  }

  .g-px-70--lg {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .g-px-75--lg {
    padding-left: 5.3571428571rem !important;
    padding-right: 5.3571428571rem !important;
  }

  .g-px-80--lg {
    padding-left: 5.7142857143rem !important;
    padding-right: 5.7142857143rem !important;
  }

  .g-px-85--lg {
    padding-left: 6.0714285714rem !important;
    padding-right: 6.0714285714rem !important;
  }

  .g-px-90--lg {
    padding-left: 6.4285714286rem !important;
    padding-right: 6.4285714286rem !important;
  }

  .g-px-95--lg {
    padding-left: 6.7857142857rem !important;
    padding-right: 6.7857142857rem !important;
  }

  .g-px-100--lg {
    padding-left: 7.1428571429rem !important;
    padding-right: 7.1428571429rem !important;
  }

  .g-px-105--lg {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .g-px-110--lg {
    padding-left: 7.8571428571rem !important;
    padding-right: 7.8571428571rem !important;
  }

  .g-px-115--lg {
    padding-left: 8.2142857143rem !important;
    padding-right: 8.2142857143rem !important;
  }

  .g-px-120--lg {
    padding-left: 8.5714285714rem !important;
    padding-right: 8.5714285714rem !important;
  }

  .g-px-125--lg {
    padding-left: 8.9285714286rem !important;
    padding-right: 8.9285714286rem !important;
  }

  .g-px-130--lg {
    padding-left: 9.2857142857rem !important;
    padding-right: 9.2857142857rem !important;
  }

  .g-px-135--lg {
    padding-left: 9.6428571429rem !important;
    padding-right: 9.6428571429rem !important;
  }

  .g-px-140--lg {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .g-px-145--lg {
    padding-left: 10.3571428571rem !important;
    padding-right: 10.3571428571rem !important;
  }

  .g-px-150--lg {
    padding-left: 10.7142857143rem !important;
    padding-right: 10.7142857143rem !important;
  }

  /* Padding Y */

  .g-py-1--lg {
    padding-top: 0.0714285714rem !important;
    padding-bottom: 0.0714285714rem !important;
  }

  .g-py-2--lg {
    padding-top: 0.1428571429rem !important;
    padding-bottom: 0.1428571429rem !important;
  }

  .g-py-3--lg {
    padding-top: 0.2142857143rem !important;
    padding-bottom: 0.2142857143rem !important;
  }

  .g-py-4--lg {
    padding-top: 0.2857142857rem !important;
    padding-bottom: 0.2857142857rem !important;
  }

  .g-py-5--lg {
    padding-top: 0.3571428571rem !important;
    padding-bottom: 0.3571428571rem !important;
  }

  .g-py-6--lg {
    padding-top: 0.4285714286rem !important;
    padding-bottom: 0.4285714286rem !important;
  }

  .g-py-7--lg {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .g-py-8--lg {
    padding-top: 0.5714285714rem !important;
    padding-bottom: 0.5714285714rem !important;
  }

  .g-py-9--lg {
    padding-top: 0.6428571429rem !important;
    padding-bottom: 0.6428571429rem !important;
  }

  .g-py-10--lg {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }

  .g-py-11--lg {
    padding-top: 0.7857142857rem !important;
    padding-bottom: 0.7857142857rem !important;
  }

  .g-py-12--lg {
    padding-top: 0.8571428571rem !important;
    padding-bottom: 0.8571428571rem !important;
  }

  .g-py-13--lg {
    padding-top: 0.9285714286rem !important;
    padding-bottom: 0.9285714286rem !important;
  }

  .g-py-14--lg {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .g-py-15--lg {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }

  .g-py-16--lg {
    padding-top: 1.1428571429rem !important;
    padding-bottom: 1.1428571429rem !important;
  }

  .g-py-17--lg {
    padding-top: 1.2142857143rem !important;
    padding-bottom: 1.2142857143rem !important;
  }

  .g-py-18--lg {
    padding-top: 1.2857142857rem !important;
    padding-bottom: 1.2857142857rem !important;
  }

  .g-py-19--lg {
    padding-top: 1.3571428571rem !important;
    padding-bottom: 1.3571428571rem !important;
  }

  /* P */

  .g-py-10--lg {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }

  /* P */

  .g-py-15--lg {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }

  /* P */

  .g-py-20--lg {
    padding-top: 1.4285714286rem !important;
    padding-bottom: 1.4285714286rem !important;
  }

  /* P */

  .g-py-25--lg {
    padding-top: 1.7857142857rem !important;
    padding-bottom: 1.7857142857rem !important;
  }

  /* P */

  .g-py-30--lg {
    padding-top: 2.1428571429rem !important;
    padding-bottom: 2.1428571429rem !important;
  }

  /* P */

  .g-py-35--lg {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  /* P */

  .g-py-40--lg {
    padding-top: 2.8571428571rem !important;
    padding-bottom: 2.8571428571rem !important;
  }

  /* P */

  .g-py-45--lg {
    padding-top: 3.2142857143rem !important;
    padding-bottom: 3.2142857143rem !important;
  }

  /* P */

  .g-py-50--lg {
    padding-top: 3.5714285714rem !important;
    padding-bottom: 3.5714285714rem !important;
  }

  /* P */

  .g-py-55--lg {
    padding-top: 3.9285714286rem !important;
    padding-bottom: 3.9285714286rem !important;
  }

  /* P */

  .g-py-60--lg {
    padding-top: 4.2857142857rem !important;
    padding-bottom: 4.2857142857rem !important;
  }

  /* P */

  .g-py-65--lg {
    padding-top: 4.6428571429rem !important;
    padding-bottom: 4.6428571429rem !important;
  }

  /* P */

  .g-py-70--lg {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  /* P */

  .g-py-75--lg {
    padding-top: 5.3571428571rem !important;
    padding-bottom: 5.3571428571rem !important;
  }

  /* P */

  .g-py-80--lg {
    padding-top: 5.7142857143rem !important;
    padding-bottom: 5.7142857143rem !important;
  }

  /* P */

  .g-py-85--lg {
    padding-top: 6.0714285714rem !important;
    padding-bottom: 6.0714285714rem !important;
  }

  /* P */

  .g-py-90--lg {
    padding-top: 6.4285714286rem !important;
    padding-bottom: 6.4285714286rem !important;
  }

  /* P */

  .g-py-95--lg {
    padding-top: 6.7857142857rem !important;
    padding-bottom: 6.7857142857rem !important;
  }

  /* P */

  .g-py-100--lg {
    padding-top: 7.1428571429rem !important;
    padding-bottom: 7.1428571429rem !important;
  }

  /* P */

  .g-py-105--lg {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  /* P */

  .g-py-110--lg {
    padding-top: 7.8571428571rem !important;
    padding-bottom: 7.8571428571rem !important;
  }

  /* P */

  .g-py-115--lg {
    padding-top: 8.2142857143rem !important;
    padding-bottom: 8.2142857143rem !important;
  }

  /* P */

  .g-py-120--lg {
    padding-top: 8.5714285714rem !important;
    padding-bottom: 8.5714285714rem !important;
  }

  /* P */

  .g-py-125--lg {
    padding-top: 8.9285714286rem !important;
    padding-bottom: 8.9285714286rem !important;
  }

  /* P */

  .g-py-130--lg {
    padding-top: 9.2857142857rem !important;
    padding-bottom: 9.2857142857rem !important;
  }

  /* P */

  .g-py-135--lg {
    padding-top: 9.6428571429rem !important;
    padding-bottom: 9.6428571429rem !important;
  }

  /* P */

  .g-py-140--lg {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  /* P */

  .g-py-145--lg {
    padding-top: 10.3571428571rem !important;
    padding-bottom: 10.3571428571rem !important;
  }

  /* P */

  .g-py-150--lg {
    padding-top: 10.7142857143rem !important;
    padding-bottom: 10.7142857143rem !important;
  }

  /* P */

  .g-py-155--lg {
    padding-top: 11.0714285714rem !important;
    padding-bottom: 11.0714285714rem !important;
  }

  /* P */

  .g-py-160--lg {
    padding-top: 11.4285714286rem !important;
    padding-bottom: 11.4285714286rem !important;
  }

  /* P */

  .g-py-165--lg {
    padding-top: 11.7857142857rem !important;
    padding-bottom: 11.7857142857rem !important;
  }

  /* P */

  .g-py-170--lg {
    padding-top: 12.1428571429rem !important;
    padding-bottom: 12.1428571429rem !important;
  }

  /* P */

  .g-py-175--lg {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }

  /* P */

  .g-py-180--lg {
    padding-top: 12.8571428571rem !important;
    padding-bottom: 12.8571428571rem !important;
  }

  /* P */

  .g-py-185--lg {
    padding-top: 13.2142857143rem !important;
    padding-bottom: 13.2142857143rem !important;
  }

  /* P */

  .g-py-190--lg {
    padding-top: 13.5714285714rem !important;
    padding-bottom: 13.5714285714rem !important;
  }

  /* P */

  .g-py-195--lg {
    padding-top: 13.9285714286rem !important;
    padding-bottom: 13.9285714286rem !important;
  }

  /* P */

  .g-py-200--lg {
    padding-top: 14.2857142857rem !important;
    padding-bottom: 14.2857142857rem !important;
  }

  /* P */

  .g-py-205--lg {
    padding-top: 14.6428571429rem !important;
    padding-bottom: 14.6428571429rem !important;
  }

  /* P */

  .g-py-210--lg {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  /* Padding Top */

  .g-pt-0--lg {
    padding-top: 0px !important;
  }

  .g-pt-1--lg {
    padding-top: 0.0714285714rem !important;
  }

  .g-pt-2--lg {
    padding-top: 0.1428571429rem !important;
  }

  .g-pt-3--lg {
    padding-top: 0.2142857143rem !important;
  }

  .g-pt-4--lg {
    padding-top: 0.2857142857rem !important;
  }

  .g-pt-5--lg {
    padding-top: 0.3571428571rem !important;
  }

  .g-pt-6--lg {
    padding-top: 0.4285714286rem !important;
  }

  .g-pt-7--lg {
    padding-top: 0.5rem !important;
  }

  .g-pt-8--lg {
    padding-top: 0.5714285714rem !important;
  }

  .g-pt-9--lg {
    padding-top: 0.6428571429rem !important;
  }

  .g-pt-10--lg {
    padding-top: 0.7142857143rem !important;
  }

  .g-pt-11--lg {
    padding-top: 0.7857142857rem !important;
  }

  .g-pt-12--lg {
    padding-top: 0.8571428571rem !important;
  }

  .g-pt-13--lg {
    padding-top: 0.9285714286rem !important;
  }

  .g-pt-14--lg {
    padding-top: 1rem !important;
  }

  .g-pt-15--lg {
    padding-top: 1.0714285714rem !important;
  }

  .g-pt-16--lg {
    padding-top: 1.1428571429rem !important;
  }

  .g-pt-17--lg {
    padding-top: 1.2142857143rem !important;
  }

  .g-pt-18--lg {
    padding-top: 1.2857142857rem !important;
  }

  .g-pt-19--lg {
    padding-top: 1.3571428571rem !important;
  }

  .g-pt-10--lg {
    padding-top: 0.7142857143rem !important;
  }

  .g-pt-15--lg {
    padding-top: 1.0714285714rem !important;
  }

  .g-pt-20--lg {
    padding-top: 1.4285714286rem !important;
  }

  .g-pt-25--lg {
    padding-top: 1.7857142857rem !important;
  }

  .g-pt-30--lg {
    padding-top: 2.1428571429rem !important;
  }

  .g-pt-35--lg {
    padding-top: 2.5rem !important;
  }

  .g-pt-40--lg {
    padding-top: 2.8571428571rem !important;
  }

  .g-pt-45--lg {
    padding-top: 3.2142857143rem !important;
  }

  .g-pt-50--lg {
    padding-top: 3.5714285714rem !important;
  }

  .g-pt-55--lg {
    padding-top: 3.9285714286rem !important;
  }

  .g-pt-60--lg {
    padding-top: 4.2857142857rem !important;
  }

  .g-pt-65--lg {
    padding-top: 4.6428571429rem !important;
  }

  .g-pt-70--lg {
    padding-top: 5rem !important;
  }

  .g-pt-75--lg {
    padding-top: 5.3571428571rem !important;
  }

  .g-pt-80--lg {
    padding-top: 5.7142857143rem !important;
  }

  .g-pt-85--lg {
    padding-top: 6.0714285714rem !important;
  }

  .g-pt-90--lg {
    padding-top: 6.4285714286rem !important;
  }

  .g-pt-95--lg {
    padding-top: 6.7857142857rem !important;
  }

  .g-pt-100--lg {
    padding-top: 7.1428571429rem !important;
  }

  .g-pt-105--lg {
    padding-top: 7.5rem !important;
  }

  .g-pt-110--lg {
    padding-top: 7.8571428571rem !important;
  }

  .g-pt-115--lg {
    padding-top: 8.2142857143rem !important;
  }

  .g-pt-120--lg {
    padding-top: 8.5714285714rem !important;
  }

  .g-pt-125--lg {
    padding-top: 8.9285714286rem !important;
  }

  .g-pt-130--lg {
    padding-top: 9.2857142857rem !important;
  }

  .g-pt-135--lg {
    padding-top: 9.6428571429rem !important;
  }

  .g-pt-140--lg {
    padding-top: 10rem !important;
  }

  .g-pt-145--lg {
    padding-top: 10.3571428571rem !important;
  }

  .g-pt-150--lg {
    padding-top: 10.7142857143rem !important;
  }

  /* Padding Right */

  .g-pr-0--lg {
    padding-right: 0px !important;
  }

  .g-pr-1--lg {
    padding-right: 0.0714285714rem !important;
  }

  .g-pr-2--lg {
    padding-right: 0.1428571429rem !important;
  }

  .g-pr-3--lg {
    padding-right: 0.2142857143rem !important;
  }

  .g-pr-4--lg {
    padding-right: 0.2857142857rem !important;
  }

  .g-pr-5--lg {
    padding-right: 0.3571428571rem !important;
  }

  .g-pr-6--lg {
    padding-right: 0.4285714286rem !important;
  }

  .g-pr-7--lg {
    padding-right: 0.5rem !important;
  }

  .g-pr-8--lg {
    padding-right: 0.5714285714rem !important;
  }

  .g-pr-9--lg {
    padding-right: 0.6428571429rem !important;
  }

  /* P */

  .g-pr-10--lg {
    padding-right: 0.7142857143rem !important;
  }

  /* P */

  .g-pr-15--lg {
    padding-right: 1.0714285714rem !important;
  }

  /* P */

  .g-pr-20--lg {
    padding-right: 1.4285714286rem !important;
  }

  /* P */

  .g-pr-25--lg {
    padding-right: 1.7857142857rem !important;
  }

  /* P */

  .g-pr-30--lg {
    padding-right: 2.1428571429rem !important;
  }

  /* P */

  .g-pr-35--lg {
    padding-right: 2.5rem !important;
  }

  /* P */

  .g-pr-40--lg {
    padding-right: 2.8571428571rem !important;
  }

  /* P */

  .g-pr-45--lg {
    padding-right: 3.2142857143rem !important;
  }

  /* P */

  .g-pr-50--lg {
    padding-right: 3.5714285714rem !important;
  }

  /* P */

  .g-pr-55--lg {
    padding-right: 3.9285714286rem !important;
  }

  /* P */

  .g-pr-60--lg {
    padding-right: 4.2857142857rem !important;
  }

  /* P */

  .g-pr-65--lg {
    padding-right: 4.6428571429rem !important;
  }

  /* P */

  .g-pr-70--lg {
    padding-right: 5rem !important;
  }

  /* P */

  .g-pr-75--lg {
    padding-right: 5.3571428571rem !important;
  }

  /* P */

  .g-pr-80--lg {
    padding-right: 5.7142857143rem !important;
  }

  /* P */

  .g-pr-85--lg {
    padding-right: 6.0714285714rem !important;
  }

  /* P */

  .g-pr-90--lg {
    padding-right: 6.4285714286rem !important;
  }

  /* P */

  .g-pr-95--lg {
    padding-right: 6.7857142857rem !important;
  }

  /* P */

  .g-pr-100--lg {
    padding-right: 7.1428571429rem !important;
  }

  /* P */

  .g-pr-105--lg {
    padding-right: 7.5rem !important;
  }

  /* P */

  .g-pr-110--lg {
    padding-right: 7.8571428571rem !important;
  }

  /* P */

  .g-pr-115--lg {
    padding-right: 8.2142857143rem !important;
  }

  /* P */

  .g-pr-120--lg {
    padding-right: 8.5714285714rem !important;
  }

  /* P */

  .g-pr-125--lg {
    padding-right: 8.9285714286rem !important;
  }

  /* P */

  .g-pr-130--lg {
    padding-right: 9.2857142857rem !important;
  }

  /* P */

  .g-pr-135--lg {
    padding-right: 9.6428571429rem !important;
  }

  /* P */

  .g-pr-140--lg {
    padding-right: 10rem !important;
  }

  /* P */

  .g-pr-145--lg {
    padding-right: 10.3571428571rem !important;
  }

  /* P */

  .g-pr-150--lg {
    padding-right: 10.7142857143rem !important;
  }

  /* P */

  .g-pr-155--lg {
    padding-right: 11.0714285714rem !important;
  }

  /* P */

  .g-pr-160--lg {
    padding-right: 11.4285714286rem !important;
  }

  /* P */

  .g-pr-165--lg {
    padding-right: 11.7857142857rem !important;
  }

  /* P */

  .g-pr-170--lg {
    padding-right: 12.1428571429rem !important;
  }

  /* Padding Bottom */

  .g-pb-0--lg {
    padding-bottom: 0px !important;
  }

  .g-pb-1--lg {
    padding-bottom: 0.0714285714rem !important;
  }

  .g-pb-2--lg {
    padding-bottom: 0.1428571429rem !important;
  }

  .g-pb-3--lg {
    padding-bottom: 0.2142857143rem !important;
  }

  .g-pb-4--lg {
    padding-bottom: 0.2857142857rem !important;
  }

  .g-pb-5--lg {
    padding-bottom: 0.3571428571rem !important;
  }

  .g-pb-6--lg {
    padding-bottom: 0.4285714286rem !important;
  }

  .g-pb-7--lg {
    padding-bottom: 0.5rem !important;
  }

  .g-pb-8--lg {
    padding-bottom: 0.5714285714rem !important;
  }

  .g-pb-9--lg {
    padding-bottom: 0.6428571429rem !important;
  }

  .g-pb-10--lg {
    padding-bottom: 0.7142857143rem !important;
  }

  .g-pb-15--lg {
    padding-bottom: 1.0714285714rem !important;
  }

  .g-pb-20--lg {
    padding-bottom: 1.4285714286rem !important;
  }

  .g-pb-25--lg {
    padding-bottom: 1.7857142857rem !important;
  }

  .g-pb-30--lg {
    padding-bottom: 2.1428571429rem !important;
  }

  .g-pb-35--lg {
    padding-bottom: 2.5rem !important;
  }

  .g-pb-40--lg {
    padding-bottom: 2.8571428571rem !important;
  }

  .g-pb-45--lg {
    padding-bottom: 3.2142857143rem !important;
  }

  .g-pb-50--lg {
    padding-bottom: 3.5714285714rem !important;
  }

  .g-pb-55--lg {
    padding-bottom: 3.9285714286rem !important;
  }

  .g-pb-60--lg {
    padding-bottom: 4.2857142857rem !important;
  }

  .g-pb-65--lg {
    padding-bottom: 4.6428571429rem !important;
  }

  .g-pb-70--lg {
    padding-bottom: 5rem !important;
  }

  .g-pb-75--lg {
    padding-bottom: 5.3571428571rem !important;
  }

  .g-pb-80--lg {
    padding-bottom: 5.7142857143rem !important;
  }

  .g-pb-85--lg {
    padding-bottom: 6.0714285714rem !important;
  }

  .g-pb-90--lg {
    padding-bottom: 6.4285714286rem !important;
  }

  .g-pb-95--lg {
    padding-bottom: 6.7857142857rem !important;
  }

  .g-pb-100--lg {
    padding-bottom: 7.1428571429rem !important;
  }

  .g-pb-105--lg {
    padding-bottom: 7.5rem !important;
  }

  .g-pb-110--lg {
    padding-bottom: 7.8571428571rem !important;
  }

  .g-pb-115--lg {
    padding-bottom: 8.2142857143rem !important;
  }

  .g-pb-120--lg {
    padding-bottom: 8.5714285714rem !important;
  }

  .g-pb-125--lg {
    padding-bottom: 8.9285714286rem !important;
  }

  .g-pb-130--lg {
    padding-bottom: 9.2857142857rem !important;
  }

  .g-pb-135--lg {
    padding-bottom: 9.6428571429rem !important;
  }

  .g-pb-140--lg {
    padding-bottom: 10rem !important;
  }

  .g-pb-145--lg {
    padding-bottom: 10.3571428571rem !important;
  }

  .g-pb-150--lg {
    padding-bottom: 10.7142857143rem !important;
  }

  /* Padding Left */

  .g-pl-0--lg {
    padding-left: 0px !important;
  }

  .g-pl-1--lg {
    padding-left: 0.0714285714rem !important;
  }

  .g-pl-2--lg {
    padding-left: 0.1428571429rem !important;
  }

  .g-pl-3--lg {
    padding-left: 0.2142857143rem !important;
  }

  .g-pl-4--lg {
    padding-left: 0.2857142857rem !important;
  }

  .g-pl-5--lg {
    padding-left: 0.3571428571rem !important;
  }

  .g-pl-6--lg {
    padding-left: 0.4285714286rem !important;
  }

  .g-pl-7--lg {
    padding-left: 0.5rem !important;
  }

  .g-pl-8--lg {
    padding-left: 0.5714285714rem !important;
  }

  .g-pl-9--lg {
    padding-left: 0.6428571429rem !important;
  }

  /* P */

  .g-pl-10--lg {
    padding-left: 0.7142857143rem !important;
  }

  /* P */

  .g-pl-15--lg {
    padding-left: 1.0714285714rem !important;
  }

  /* P */

  .g-pl-20--lg {
    padding-left: 1.4285714286rem !important;
  }

  /* P */

  .g-pl-25--lg {
    padding-left: 1.7857142857rem !important;
  }

  /* P */

  .g-pl-30--lg {
    padding-left: 2.1428571429rem !important;
  }

  /* P */

  .g-pl-35--lg {
    padding-left: 2.5rem !important;
  }

  /* P */

  .g-pl-40--lg {
    padding-left: 2.8571428571rem !important;
  }

  /* P */

  .g-pl-45--lg {
    padding-left: 3.2142857143rem !important;
  }

  /* P */

  .g-pl-50--lg {
    padding-left: 3.5714285714rem !important;
  }

  /* P */

  .g-pl-55--lg {
    padding-left: 3.9285714286rem !important;
  }

  /* P */

  .g-pl-60--lg {
    padding-left: 4.2857142857rem !important;
  }

  /* P */

  .g-pl-65--lg {
    padding-left: 4.6428571429rem !important;
  }

  /* P */

  .g-pl-70--lg {
    padding-left: 5rem !important;
  }

  /* P */

  .g-pl-75--lg {
    padding-left: 5.3571428571rem !important;
  }

  /* P */

  .g-pl-80--lg {
    padding-left: 5.7142857143rem !important;
  }

  /* P */

  .g-pl-85--lg {
    padding-left: 6.0714285714rem !important;
  }

  /* P */

  .g-pl-90--lg {
    padding-left: 6.4285714286rem !important;
  }

  /* P */

  .g-pl-95--lg {
    padding-left: 6.7857142857rem !important;
  }

  /* P */

  .g-pl-100--lg {
    padding-left: 7.1428571429rem !important;
  }

  /* P */

  .g-pl-105--lg {
    padding-left: 7.5rem !important;
  }

  /* P */

  .g-pl-110--lg {
    padding-left: 7.8571428571rem !important;
  }

  /* P */

  .g-pl-115--lg {
    padding-left: 8.2142857143rem !important;
  }

  /* P */

  .g-pl-120--lg {
    padding-left: 8.5714285714rem !important;
  }

  /* P */

  .g-pl-125--lg {
    padding-left: 8.9285714286rem !important;
  }

  /* P */

  .g-pl-130--lg {
    padding-left: 9.2857142857rem !important;
  }

  /* P */

  .g-pl-135--lg {
    padding-left: 9.6428571429rem !important;
  }

  /* P */

  .g-pl-140--lg {
    padding-left: 10rem !important;
  }

  /* P */

  .g-pl-145--lg {
    padding-left: 10.3571428571rem !important;
  }

  /* P */

  .g-pl-150--lg {
    padding-left: 10.7142857143rem !important;
  }

  /* P */

  .g-pl-155--lg {
    padding-left: 11.0714285714rem !important;
  }

  /* P */

  .g-pl-160--lg {
    padding-left: 11.4285714286rem !important;
  }

  /* P */

  .g-pl-165--lg {
    padding-left: 11.7857142857rem !important;
  }

  /* P */

  .g-pl-170--lg {
    padding-left: 12.1428571429rem !important;
  }
}

/* Padding Spaces (xl) P
------------------------------------*/

@media (min-width: 1200px) {
  .g-pa-0--xl {
    padding: 0 !important;
  }

  .g-px-0--xl {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .g-py-0--xl {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .g-pt-0--xl {
    padding-top: 0 !important;
  }

  .g-pr-0--xl {
    padding-right: 0 !important;
  }

  .g-pb-0--xl {
    padding-bottom: 0 !important;
  }

  .g-pl-0--xl {
    padding-left: 0 !important;
  }

  /* Padding Around */

  .g-pa-2--xl {
    padding: 0.1428571429rem !important;
  }

  .g-pa-3--xl {
    padding: 0.2142857143rem !important;
  }

  .g-pa-5--xl {
    padding: 0.3571428571rem !important;
  }

  .g-pa-7--xl {
    padding: 0.5rem !important;
  }

  .g-pa-10--xl {
    padding: 0.7142857143rem !important;
  }

  .g-pa-15--xl {
    padding: 1.0714285714rem !important;
  }

  .g-pa-20--xl {
    padding: 1.4285714286rem !important;
  }

  .g-pa-25--xl {
    padding: 1.7857142857rem !important;
  }

  .g-pa-30--xl {
    padding: 2.1428571429rem !important;
  }

  .g-pa-35--xl {
    padding: 2.5rem !important;
  }

  .g-pa-40--xl {
    padding: 2.8571428571rem !important;
  }

  .g-pa-45--xl {
    padding: 3.2142857143rem !important;
  }

  .g-pa-50--xl {
    padding: 3.5714285714rem !important;
  }

  .g-pa-55--xl {
    padding: 3.9285714286rem !important;
  }

  .g-pa-60--xl {
    padding: 4.2857142857rem !important;
  }

  .g-pa-65--xl {
    padding: 4.6428571429rem !important;
  }

  .g-pa-70--xl {
    padding: 5rem !important;
  }

  .g-pa-75--xl {
    padding: 5.3571428571rem !important;
  }

  .g-pa-80--xl {
    padding: 5.7142857143rem !important;
  }

  .g-pa-85--xl {
    padding: 6.0714285714rem !important;
  }

  .g-pa-90--xl {
    padding: 6.4285714286rem !important;
  }

  .g-pa-95--xl {
    padding: 6.7857142857rem !important;
  }

  .g-pa-100--xl {
    padding: 7.1428571429rem !important;
  }

  .g-pa-105--xl {
    padding: 7.5rem !important;
  }

  .g-pa-110--xl {
    padding: 7.8571428571rem !important;
  }

  .g-pa-115--xl {
    padding: 8.2142857143rem !important;
  }

  .g-pa-120--xl {
    padding: 8.5714285714rem !important;
  }

  .g-pa-125--xl {
    padding: 8.9285714286rem !important;
  }

  .g-pa-130--xl {
    padding: 9.2857142857rem !important;
  }

  .g-pa-135--xl {
    padding: 9.6428571429rem !important;
  }

  .g-pa-140--xl {
    padding: 10rem !important;
  }

  .g-pa-145--xl {
    padding: 10.3571428571rem !important;
  }

  .g-pa-150--xl {
    padding: 10.7142857143rem !important;
  }

  /* Padding X */

  .g-px-1--xl {
    padding-left: 0.0714285714rem !important;
    padding-right: 0.0714285714rem !important;
  }

  .g-px-2--xl {
    padding-left: 0.1428571429rem !important;
    padding-right: 0.1428571429rem !important;
  }

  .g-px-3--xl {
    padding-left: 0.2142857143rem !important;
    padding-right: 0.2142857143rem !important;
  }

  .g-px-4--xl {
    padding-left: 0.2857142857rem !important;
    padding-right: 0.2857142857rem !important;
  }

  .g-px-5--xl {
    padding-left: 0.3571428571rem !important;
    padding-right: 0.3571428571rem !important;
  }

  .g-px-6--xl {
    padding-left: 0.4285714286rem !important;
    padding-right: 0.4285714286rem !important;
  }

  .g-px-7--xl {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .g-px-8--xl {
    padding-left: 0.5714285714rem !important;
    padding-right: 0.5714285714rem !important;
  }

  .g-px-9--xl {
    padding-left: 0.6428571429rem !important;
    padding-right: 0.6428571429rem !important;
  }

  .g-px-10--xl {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }

  .g-px-11--xl {
    padding-left: 0.7857142857rem !important;
    padding-right: 0.7857142857rem !important;
  }

  .g-px-12--xl {
    padding-left: 0.8571428571rem !important;
    padding-right: 0.8571428571rem !important;
  }

  .g-px-13--xl {
    padding-left: 0.9285714286rem !important;
    padding-right: 0.9285714286rem !important;
  }

  .g-px-14--xl {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .g-px-15--xl {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }

  .g-px-16--xl {
    padding-left: 1.1428571429rem !important;
    padding-right: 1.1428571429rem !important;
  }

  .g-px-17--xl {
    padding-left: 1.2142857143rem !important;
    padding-right: 1.2142857143rem !important;
  }

  .g-px-18--xl {
    padding-left: 1.2857142857rem !important;
    padding-right: 1.2857142857rem !important;
  }

  .g-px-19--xl {
    padding-left: 1.3571428571rem !important;
    padding-right: 1.3571428571rem !important;
  }

  .g-px-10--xl {
    padding-left: 0.7142857143rem !important;
    padding-right: 0.7142857143rem !important;
  }

  .g-px-15--xl {
    padding-left: 1.0714285714rem !important;
    padding-right: 1.0714285714rem !important;
  }

  .g-px-20--xl {
    padding-left: 1.4285714286rem !important;
    padding-right: 1.4285714286rem !important;
  }

  .g-px-25--xl {
    padding-left: 1.7857142857rem !important;
    padding-right: 1.7857142857rem !important;
  }

  .g-px-30--xl {
    padding-left: 2.1428571429rem !important;
    padding-right: 2.1428571429rem !important;
  }

  .g-px-35--xl {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .g-px-40--xl {
    padding-left: 2.8571428571rem !important;
    padding-right: 2.8571428571rem !important;
  }

  .g-px-45--xl {
    padding-left: 3.2142857143rem !important;
    padding-right: 3.2142857143rem !important;
  }

  .g-px-50--xl {
    padding-left: 3.5714285714rem !important;
    padding-right: 3.5714285714rem !important;
  }

  .g-px-55--xl {
    padding-left: 3.9285714286rem !important;
    padding-right: 3.9285714286rem !important;
  }

  .g-px-60--xl {
    padding-left: 4.2857142857rem !important;
    padding-right: 4.2857142857rem !important;
  }

  .g-px-65--xl {
    padding-left: 4.6428571429rem !important;
    padding-right: 4.6428571429rem !important;
  }

  .g-px-70--xl {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .g-px-75--xl {
    padding-left: 5.3571428571rem !important;
    padding-right: 5.3571428571rem !important;
  }

  .g-px-80--xl {
    padding-left: 5.7142857143rem !important;
    padding-right: 5.7142857143rem !important;
  }

  .g-px-85--xl {
    padding-left: 6.0714285714rem !important;
    padding-right: 6.0714285714rem !important;
  }

  .g-px-90--xl {
    padding-left: 6.4285714286rem !important;
    padding-right: 6.4285714286rem !important;
  }

  .g-px-95--xl {
    padding-left: 6.7857142857rem !important;
    padding-right: 6.7857142857rem !important;
  }

  .g-px-100--xl {
    padding-left: 7.1428571429rem !important;
    padding-right: 7.1428571429rem !important;
  }

  .g-px-105--xl {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .g-px-110--xl {
    padding-left: 7.8571428571rem !important;
    padding-right: 7.8571428571rem !important;
  }

  .g-px-115--xl {
    padding-left: 8.2142857143rem !important;
    padding-right: 8.2142857143rem !important;
  }

  .g-px-120--xl {
    padding-left: 8.5714285714rem !important;
    padding-right: 8.5714285714rem !important;
  }

  .g-px-125--xl {
    padding-left: 8.9285714286rem !important;
    padding-right: 8.9285714286rem !important;
  }

  .g-px-130--xl {
    padding-left: 9.2857142857rem !important;
    padding-right: 9.2857142857rem !important;
  }

  .g-px-135--xl {
    padding-left: 9.6428571429rem !important;
    padding-right: 9.6428571429rem !important;
  }

  .g-px-140--xl {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .g-px-145--xl {
    padding-left: 10.3571428571rem !important;
    padding-right: 10.3571428571rem !important;
  }

  .g-px-150--xl {
    padding-left: 10.7142857143rem !important;
    padding-right: 10.7142857143rem !important;
  }

  /* Padding Y */

  .g-py-1--xl {
    padding-top: 0.0714285714rem !important;
    padding-bottom: 0.0714285714rem !important;
  }

  .g-py-2--xl {
    padding-top: 0.1428571429rem !important;
    padding-bottom: 0.1428571429rem !important;
  }

  .g-py-3--xl {
    padding-top: 0.2142857143rem !important;
    padding-bottom: 0.2142857143rem !important;
  }

  .g-py-4--xl {
    padding-top: 0.2857142857rem !important;
    padding-bottom: 0.2857142857rem !important;
  }

  .g-py-5--xl {
    padding-top: 0.3571428571rem !important;
    padding-bottom: 0.3571428571rem !important;
  }

  .g-py-6--xl {
    padding-top: 0.4285714286rem !important;
    padding-bottom: 0.4285714286rem !important;
  }

  .g-py-7--xl {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .g-py-8--xl {
    padding-top: 0.5714285714rem !important;
    padding-bottom: 0.5714285714rem !important;
  }

  .g-py-9--xl {
    padding-top: 0.6428571429rem !important;
    padding-bottom: 0.6428571429rem !important;
  }

  .g-py-10--xl {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }

  .g-py-11--xl {
    padding-top: 0.7857142857rem !important;
    padding-bottom: 0.7857142857rem !important;
  }

  .g-py-12--xl {
    padding-top: 0.8571428571rem !important;
    padding-bottom: 0.8571428571rem !important;
  }

  .g-py-13--xl {
    padding-top: 0.9285714286rem !important;
    padding-bottom: 0.9285714286rem !important;
  }

  .g-py-14--xl {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .g-py-15--xl {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }

  .g-py-16--xl {
    padding-top: 1.1428571429rem !important;
    padding-bottom: 1.1428571429rem !important;
  }

  .g-py-17--xl {
    padding-top: 1.2142857143rem !important;
    padding-bottom: 1.2142857143rem !important;
  }

  .g-py-18--xl {
    padding-top: 1.2857142857rem !important;
    padding-bottom: 1.2857142857rem !important;
  }

  .g-py-19--xl {
    padding-top: 1.3571428571rem !important;
    padding-bottom: 1.3571428571rem !important;
  }

  /* P */

  .g-py-10--xl {
    padding-top: 0.7142857143rem !important;
    padding-bottom: 0.7142857143rem !important;
  }

  /* P */

  .g-py-15--xl {
    padding-top: 1.0714285714rem !important;
    padding-bottom: 1.0714285714rem !important;
  }

  /* P */

  .g-py-20--xl {
    padding-top: 1.4285714286rem !important;
    padding-bottom: 1.4285714286rem !important;
  }

  /* P */

  .g-py-25--xl {
    padding-top: 1.7857142857rem !important;
    padding-bottom: 1.7857142857rem !important;
  }

  /* P */

  .g-py-30--xl {
    padding-top: 2.1428571429rem !important;
    padding-bottom: 2.1428571429rem !important;
  }

  /* P */

  .g-py-35--xl {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  /* P */

  .g-py-40--xl {
    padding-top: 2.8571428571rem !important;
    padding-bottom: 2.8571428571rem !important;
  }

  /* P */

  .g-py-45--xl {
    padding-top: 3.2142857143rem !important;
    padding-bottom: 3.2142857143rem !important;
  }

  /* P */

  .g-py-50--xl {
    padding-top: 3.5714285714rem !important;
    padding-bottom: 3.5714285714rem !important;
  }

  /* P */

  .g-py-55--xl {
    padding-top: 3.9285714286rem !important;
    padding-bottom: 3.9285714286rem !important;
  }

  /* P */

  .g-py-60--xl {
    padding-top: 4.2857142857rem !important;
    padding-bottom: 4.2857142857rem !important;
  }

  /* P */

  .g-py-65--xl {
    padding-top: 4.6428571429rem !important;
    padding-bottom: 4.6428571429rem !important;
  }

  /* P */

  .g-py-70--xl {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  /* P */

  .g-py-75--xl {
    padding-top: 5.3571428571rem !important;
    padding-bottom: 5.3571428571rem !important;
  }

  /* P */

  .g-py-80--xl {
    padding-top: 5.7142857143rem !important;
    padding-bottom: 5.7142857143rem !important;
  }

  /* P */

  .g-py-85--xl {
    padding-top: 6.0714285714rem !important;
    padding-bottom: 6.0714285714rem !important;
  }

  /* P */

  .g-py-90--xl {
    padding-top: 6.4285714286rem !important;
    padding-bottom: 6.4285714286rem !important;
  }

  /* P */

  .g-py-95--xl {
    padding-top: 6.7857142857rem !important;
    padding-bottom: 6.7857142857rem !important;
  }

  /* P */

  .g-py-100--xl {
    padding-top: 7.1428571429rem !important;
    padding-bottom: 7.1428571429rem !important;
  }

  /* P */

  .g-py-105--xl {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  /* P */

  .g-py-110--xl {
    padding-top: 7.8571428571rem !important;
    padding-bottom: 7.8571428571rem !important;
  }

  /* P */

  .g-py-115--xl {
    padding-top: 8.2142857143rem !important;
    padding-bottom: 8.2142857143rem !important;
  }

  /* P */

  .g-py-120--xl {
    padding-top: 8.5714285714rem !important;
    padding-bottom: 8.5714285714rem !important;
  }

  /* P */

  .g-py-125--xl {
    padding-top: 8.9285714286rem !important;
    padding-bottom: 8.9285714286rem !important;
  }

  /* P */

  .g-py-130--xl {
    padding-top: 9.2857142857rem !important;
    padding-bottom: 9.2857142857rem !important;
  }

  /* P */

  .g-py-135--xl {
    padding-top: 9.6428571429rem !important;
    padding-bottom: 9.6428571429rem !important;
  }

  /* P */

  .g-py-140--xl {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  /* P */

  .g-py-145--xl {
    padding-top: 10.3571428571rem !important;
    padding-bottom: 10.3571428571rem !important;
  }

  /* P */

  .g-py-150--xl {
    padding-top: 10.7142857143rem !important;
    padding-bottom: 10.7142857143rem !important;
  }

  /* P */

  .g-py-155--xl {
    padding-top: 11.0714285714rem !important;
    padding-bottom: 11.0714285714rem !important;
  }

  /* P */

  .g-py-160--xl {
    padding-top: 11.4285714286rem !important;
    padding-bottom: 11.4285714286rem !important;
  }

  /* P */

  .g-py-165--xl {
    padding-top: 11.7857142857rem !important;
    padding-bottom: 11.7857142857rem !important;
  }

  /* P */

  .g-py-170--xl {
    padding-top: 12.1428571429rem !important;
    padding-bottom: 12.1428571429rem !important;
  }

  /* P */

  .g-py-175--xl {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }

  /* P */

  .g-py-180--xl {
    padding-top: 12.8571428571rem !important;
    padding-bottom: 12.8571428571rem !important;
  }

  /* P */

  .g-py-185--xl {
    padding-top: 13.2142857143rem !important;
    padding-bottom: 13.2142857143rem !important;
  }

  /* P */

  .g-py-190--xl {
    padding-top: 13.5714285714rem !important;
    padding-bottom: 13.5714285714rem !important;
  }

  /* P */

  .g-py-195--xl {
    padding-top: 13.9285714286rem !important;
    padding-bottom: 13.9285714286rem !important;
  }

  /* P */

  .g-py-200--xl {
    padding-top: 14.2857142857rem !important;
    padding-bottom: 14.2857142857rem !important;
  }

  /* P */

  .g-py-205--xl {
    padding-top: 14.6428571429rem !important;
    padding-bottom: 14.6428571429rem !important;
  }

  /* P */

  .g-py-210--xl {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  /* Padding Top */

  .g-pt-0--xl {
    padding-top: 0px !important;
  }

  .g-pt-1--xl {
    padding-top: 0.0714285714rem !important;
  }

  .g-pt-2--xl {
    padding-top: 0.1428571429rem !important;
  }

  .g-pt-3--xl {
    padding-top: 0.2142857143rem !important;
  }

  .g-pt-4--xl {
    padding-top: 0.2857142857rem !important;
  }

  .g-pt-5--xl {
    padding-top: 0.3571428571rem !important;
  }

  .g-pt-6--xl {
    padding-top: 0.4285714286rem !important;
  }

  .g-pt-7--xl {
    padding-top: 0.5rem !important;
  }

  .g-pt-8--xl {
    padding-top: 0.5714285714rem !important;
  }

  .g-pt-9--xl {
    padding-top: 0.6428571429rem !important;
  }

  .g-pt-10--xl {
    padding-top: 0.7142857143rem !important;
  }

  .g-pt-11--xl {
    padding-top: 0.7857142857rem !important;
  }

  .g-pt-12--xl {
    padding-top: 0.8571428571rem !important;
  }

  .g-pt-13--xl {
    padding-top: 0.9285714286rem !important;
  }

  .g-pt-14--xl {
    padding-top: 1rem !important;
  }

  .g-pt-15--xl {
    padding-top: 1.0714285714rem !important;
  }

  .g-pt-16--xl {
    padding-top: 1.1428571429rem !important;
  }

  .g-pt-17--xl {
    padding-top: 1.2142857143rem !important;
  }

  .g-pt-18--xl {
    padding-top: 1.2857142857rem !important;
  }

  .g-pt-19--xl {
    padding-top: 1.3571428571rem !important;
  }

  .g-pt-10--xl {
    padding-top: 0.7142857143rem !important;
  }

  .g-pt-15--xl {
    padding-top: 1.0714285714rem !important;
  }

  .g-pt-20--xl {
    padding-top: 1.4285714286rem !important;
  }

  .g-pt-25--xl {
    padding-top: 1.7857142857rem !important;
  }

  .g-pt-30--xl {
    padding-top: 2.1428571429rem !important;
  }

  .g-pt-35--xl {
    padding-top: 2.5rem !important;
  }

  .g-pt-40--xl {
    padding-top: 2.8571428571rem !important;
  }

  .g-pt-45--xl {
    padding-top: 3.2142857143rem !important;
  }

  .g-pt-50--xl {
    padding-top: 3.5714285714rem !important;
  }

  .g-pt-55--xl {
    padding-top: 3.9285714286rem !important;
  }

  .g-pt-60--xl {
    padding-top: 4.2857142857rem !important;
  }

  .g-pt-65--xl {
    padding-top: 4.6428571429rem !important;
  }

  .g-pt-70--xl {
    padding-top: 5rem !important;
  }

  .g-pt-75--xl {
    padding-top: 5.3571428571rem !important;
  }

  .g-pt-80--xl {
    padding-top: 5.7142857143rem !important;
  }

  .g-pt-85--xl {
    padding-top: 6.0714285714rem !important;
  }

  .g-pt-90--xl {
    padding-top: 6.4285714286rem !important;
  }

  .g-pt-95--xl {
    padding-top: 6.7857142857rem !important;
  }

  .g-pt-100--xl {
    padding-top: 7.1428571429rem !important;
  }

  .g-pt-105--xl {
    padding-top: 7.5rem !important;
  }

  .g-pt-110--xl {
    padding-top: 7.8571428571rem !important;
  }

  .g-pt-115--xl {
    padding-top: 8.2142857143rem !important;
  }

  .g-pt-120--xl {
    padding-top: 8.5714285714rem !important;
  }

  .g-pt-125--xl {
    padding-top: 8.9285714286rem !important;
  }

  .g-pt-130--xl {
    padding-top: 9.2857142857rem !important;
  }

  .g-pt-135--xl {
    padding-top: 9.6428571429rem !important;
  }

  .g-pt-140--xl {
    padding-top: 10rem !important;
  }

  .g-pt-145--xl {
    padding-top: 10.3571428571rem !important;
  }

  .g-pt-150--xl {
    padding-top: 10.7142857143rem !important;
  }

  /* Padding Right */

  .g-pr-0--xl {
    padding-right: 0px !important;
  }

  .g-pr-1--xl {
    padding-right: 0.0714285714rem !important;
  }

  .g-pr-2--xl {
    padding-right: 0.1428571429rem !important;
  }

  .g-pr-3--xl {
    padding-right: 0.2142857143rem !important;
  }

  .g-pr-4--xl {
    padding-right: 0.2857142857rem !important;
  }

  .g-pr-5--xl {
    padding-right: 0.3571428571rem !important;
  }

  .g-pr-6--xl {
    padding-right: 0.4285714286rem !important;
  }

  .g-pr-7--xl {
    padding-right: 0.5rem !important;
  }

  .g-pr-8--xl {
    padding-right: 0.5714285714rem !important;
  }

  .g-pr-9--xl {
    padding-right: 0.6428571429rem !important;
  }

  /* P */

  .g-pr-10--xl {
    padding-right: 0.7142857143rem !important;
  }

  /* P */

  .g-pr-15--xl {
    padding-right: 1.0714285714rem !important;
  }

  /* P */

  .g-pr-20--xl {
    padding-right: 1.4285714286rem !important;
  }

  /* P */

  .g-pr-25--xl {
    padding-right: 1.7857142857rem !important;
  }

  /* P */

  .g-pr-30--xl {
    padding-right: 2.1428571429rem !important;
  }

  /* P */

  .g-pr-35--xl {
    padding-right: 2.5rem !important;
  }

  /* P */

  .g-pr-40--xl {
    padding-right: 2.8571428571rem !important;
  }

  /* P */

  .g-pr-45--xl {
    padding-right: 3.2142857143rem !important;
  }

  /* P */

  .g-pr-50--xl {
    padding-right: 3.5714285714rem !important;
  }

  /* P */

  .g-pr-55--xl {
    padding-right: 3.9285714286rem !important;
  }

  /* P */

  .g-pr-60--xl {
    padding-right: 4.2857142857rem !important;
  }

  /* P */

  .g-pr-65--xl {
    padding-right: 4.6428571429rem !important;
  }

  /* P */

  .g-pr-70--xl {
    padding-right: 5rem !important;
  }

  /* P */

  .g-pr-75--xl {
    padding-right: 5.3571428571rem !important;
  }

  /* P */

  .g-pr-80--xl {
    padding-right: 5.7142857143rem !important;
  }

  /* P */

  .g-pr-85--xl {
    padding-right: 6.0714285714rem !important;
  }

  /* P */

  .g-pr-90--xl {
    padding-right: 6.4285714286rem !important;
  }

  /* P */

  .g-pr-95--xl {
    padding-right: 6.7857142857rem !important;
  }

  /* P */

  .g-pr-100--xl {
    padding-right: 7.1428571429rem !important;
  }

  /* P */

  .g-pr-105--xl {
    padding-right: 7.5rem !important;
  }

  /* P */

  .g-pr-110--xl {
    padding-right: 7.8571428571rem !important;
  }

  /* P */

  .g-pr-115--xl {
    padding-right: 8.2142857143rem !important;
  }

  /* P */

  .g-pr-120--xl {
    padding-right: 8.5714285714rem !important;
  }

  /* P */

  .g-pr-125--xl {
    padding-right: 8.9285714286rem !important;
  }

  /* P */

  .g-pr-130--xl {
    padding-right: 9.2857142857rem !important;
  }

  /* P */

  .g-pr-135--xl {
    padding-right: 9.6428571429rem !important;
  }

  /* P */

  .g-pr-140--xl {
    padding-right: 10rem !important;
  }

  /* P */

  .g-pr-145--xl {
    padding-right: 10.3571428571rem !important;
  }

  /* P */

  .g-pr-150--xl {
    padding-right: 10.7142857143rem !important;
  }

  /* P */

  .g-pr-155--xl {
    padding-right: 11.0714285714rem !important;
  }

  /* P */

  .g-pr-160--xl {
    padding-right: 11.4285714286rem !important;
  }

  /* P */

  .g-pr-165--xl {
    padding-right: 11.7857142857rem !important;
  }

  /* P */

  .g-pr-170--xl {
    padding-right: 12.1428571429rem !important;
  }

  /* Padding Bottom */

  .g-pb-0--xl {
    padding-bottom: 0px !important;
  }

  .g-pb-1--xl {
    padding-bottom: 0.0714285714rem !important;
  }

  .g-pb-2--xl {
    padding-bottom: 0.1428571429rem !important;
  }

  .g-pb-3--xl {
    padding-bottom: 0.2142857143rem !important;
  }

  .g-pb-4--xl {
    padding-bottom: 0.2857142857rem !important;
  }

  .g-pb-5--xl {
    padding-bottom: 0.3571428571rem !important;
  }

  .g-pb-6--xl {
    padding-bottom: 0.4285714286rem !important;
  }

  .g-pb-7--xl {
    padding-bottom: 0.5rem !important;
  }

  .g-pb-8--xl {
    padding-bottom: 0.5714285714rem !important;
  }

  .g-pb-9--xl {
    padding-bottom: 0.6428571429rem !important;
  }

  .g-pb-10--xl {
    padding-bottom: 0.7142857143rem !important;
  }

  .g-pb-15--xl {
    padding-bottom: 1.0714285714rem !important;
  }

  .g-pb-20--xl {
    padding-bottom: 1.4285714286rem !important;
  }

  .g-pb-25--xl {
    padding-bottom: 1.7857142857rem !important;
  }

  .g-pb-30--xl {
    padding-bottom: 2.1428571429rem !important;
  }

  .g-pb-35--xl {
    padding-bottom: 2.5rem !important;
  }

  .g-pb-40--xl {
    padding-bottom: 2.8571428571rem !important;
  }

  .g-pb-45--xl {
    padding-bottom: 3.2142857143rem !important;
  }

  .g-pb-50--xl {
    padding-bottom: 3.5714285714rem !important;
  }

  .g-pb-55--xl {
    padding-bottom: 3.9285714286rem !important;
  }

  .g-pb-60--xl {
    padding-bottom: 4.2857142857rem !important;
  }

  .g-pb-65--xl {
    padding-bottom: 4.6428571429rem !important;
  }

  .g-pb-70--xl {
    padding-bottom: 5rem !important;
  }

  .g-pb-75--xl {
    padding-bottom: 5.3571428571rem !important;
  }

  .g-pb-80--xl {
    padding-bottom: 5.7142857143rem !important;
  }

  .g-pb-85--xl {
    padding-bottom: 6.0714285714rem !important;
  }

  .g-pb-90--xl {
    padding-bottom: 6.4285714286rem !important;
  }

  .g-pb-95--xl {
    padding-bottom: 6.7857142857rem !important;
  }

  .g-pb-100--xl {
    padding-bottom: 7.1428571429rem !important;
  }

  .g-pb-105--xl {
    padding-bottom: 7.5rem !important;
  }

  .g-pb-110--xl {
    padding-bottom: 7.8571428571rem !important;
  }

  .g-pb-115--xl {
    padding-bottom: 8.2142857143rem !important;
  }

  .g-pb-120--xl {
    padding-bottom: 8.5714285714rem !important;
  }

  .g-pb-125--xl {
    padding-bottom: 8.9285714286rem !important;
  }

  .g-pb-130--xl {
    padding-bottom: 9.2857142857rem !important;
  }

  .g-pb-135--xl {
    padding-bottom: 9.6428571429rem !important;
  }

  .g-pb-140--xl {
    padding-bottom: 10rem !important;
  }

  .g-pb-145--xl {
    padding-bottom: 10.3571428571rem !important;
  }

  .g-pb-150--xl {
    padding-bottom: 10.7142857143rem !important;
  }

  /* Padding Left */

  .g-pl-0--xl {
    padding-left: 0px !important;
  }

  .g-pl-1--xl {
    padding-left: 0.0714285714rem !important;
  }

  .g-pl-2--xl {
    padding-left: 0.1428571429rem !important;
  }

  .g-pl-3--xl {
    padding-left: 0.2142857143rem !important;
  }

  .g-pl-4--xl {
    padding-left: 0.2857142857rem !important;
  }

  .g-pl-5--xl {
    padding-left: 0.3571428571rem !important;
  }

  .g-pl-6--xl {
    padding-left: 0.4285714286rem !important;
  }

  .g-pl-7--xl {
    padding-left: 0.5rem !important;
  }

  .g-pl-8--xl {
    padding-left: 0.5714285714rem !important;
  }

  .g-pl-9--xl {
    padding-left: 0.6428571429rem !important;
  }

  /* P */

  .g-pl-10--xl {
    padding-left: 0.7142857143rem !important;
  }

  /* P */

  .g-pl-15--xl {
    padding-left: 1.0714285714rem !important;
  }

  /* P */

  .g-pl-20--xl {
    padding-left: 1.4285714286rem !important;
  }

  /* P */

  .g-pl-25--xl {
    padding-left: 1.7857142857rem !important;
  }

  /* P */

  .g-pl-30--xl {
    padding-left: 2.1428571429rem !important;
  }

  /* P */

  .g-pl-35--xl {
    padding-left: 2.5rem !important;
  }

  /* P */

  .g-pl-40--xl {
    padding-left: 2.8571428571rem !important;
  }

  /* P */

  .g-pl-45--xl {
    padding-left: 3.2142857143rem !important;
  }

  /* P */

  .g-pl-50--xl {
    padding-left: 3.5714285714rem !important;
  }

  /* P */

  .g-pl-55--xl {
    padding-left: 3.9285714286rem !important;
  }

  /* P */

  .g-pl-60--xl {
    padding-left: 4.2857142857rem !important;
  }

  /* P */

  .g-pl-65--xl {
    padding-left: 4.6428571429rem !important;
  }

  /* P */

  .g-pl-70--xl {
    padding-left: 5rem !important;
  }

  /* P */

  .g-pl-75--xl {
    padding-left: 5.3571428571rem !important;
  }

  /* P */

  .g-pl-80--xl {
    padding-left: 5.7142857143rem !important;
  }

  /* P */

  .g-pl-85--xl {
    padding-left: 6.0714285714rem !important;
  }

  /* P */

  .g-pl-90--xl {
    padding-left: 6.4285714286rem !important;
  }

  /* P */

  .g-pl-95--xl {
    padding-left: 6.7857142857rem !important;
  }

  /* P */

  .g-pl-100--xl {
    padding-left: 7.1428571429rem !important;
  }

  /* P */

  .g-pl-105--xl {
    padding-left: 7.5rem !important;
  }

  /* P */

  .g-pl-110--xl {
    padding-left: 7.8571428571rem !important;
  }

  /* P */

  .g-pl-115--xl {
    padding-left: 8.2142857143rem !important;
  }

  /* P */

  .g-pl-120--xl {
    padding-left: 8.5714285714rem !important;
  }

  /* P */

  .g-pl-125--xl {
    padding-left: 8.9285714286rem !important;
  }

  /* P */

  .g-pl-130--xl {
    padding-left: 9.2857142857rem !important;
  }

  /* P */

  .g-pl-135--xl {
    padding-left: 9.6428571429rem !important;
  }

  /* P */

  .g-pl-140--xl {
    padding-left: 10rem !important;
  }

  /* P */

  .g-pl-145--xl {
    padding-left: 10.3571428571rem !important;
  }

  /* P */

  .g-pl-150--xl {
    padding-left: 10.7142857143rem !important;
  }

  /* P */

  .g-pl-155--xl {
    padding-left: 11.0714285714rem !important;
  }

  /* P */

  .g-pl-160--xl {
    padding-left: 11.4285714286rem !important;
  }

  /* P */

  .g-pl-165--xl {
    padding-left: 11.7857142857rem !important;
  }

  /* P */

  .g-pl-170--xl {
    padding-left: 12.1428571429rem !important;
  }
}

/* Custom Paddings (xs)
------------------------------------*/

.g-pa-0-20-20 {
  padding: 0 1.4285714286rem 1.4285714286rem !important;
}

.g-pa-4-11 {
  padding: 0.2857142857rem 0.7857142857rem !important;
}

.g-pa-4-13 {
  padding: 0.2857142857rem 0.9285714286rem !important;
}

.g-pa-5-10 {
  padding: 0.3571428571rem 0.7142857143rem !important;
}

.g-pa-5-15 {
  padding: 0.3571428571rem 1.0714285714rem !important;
}

.g-pa-6-8 {
  padding: 0.4285714286rem 0.5714285714rem !important;
}

.g-pa-7-14 {
  padding: 0.5rem 1rem !important;
}

.g-pa-7-16 {
  padding: 0.5rem 1.1428571429rem !important;
}

.g-pa-10-5-5 {
  padding: 0.7142857143rem 0.3571428571rem 0.3571428571rem !important;
}

.g-pa-10-15 {
  padding: 0.7142857143rem 1.0714285714rem !important;
}

.g-pa-10-16 {
  padding: 0.7142857143rem 1.1428571429rem !important;
}

.g-pa-10-20 {
  padding: 0.7142857143rem 1.4285714286rem !important;
}

.g-pa-10-30 {
  padding: 0.7142857143rem 2.1428571429rem !important;
}

.g-pa-12 {
  padding: 0.8571428571rem !important;
}

.g-pa-12-19 {
  padding: 0.8571428571rem 1.3571428571rem !important;
}

.g-pa-12-21 {
  padding: 0.8571428571rem 1.5rem !important;
}

.g-pa-12-5-7 {
  padding: 0.8571428571rem 0.3571428571rem 0.5rem !important;
}

.g-pa-14-18 {
  padding: 1rem 1.2857142857rem !important;
}

.g-pa-15-0 {
  padding: 1.0714285714rem 0 !important;
}

.g-pa-15-5 {
  padding: 1.0714285714rem 0.3571428571rem !important;
}

.g-pa-15-20-10 {
  padding: 1.0714285714rem 1.4285714286rem 0.7142857143rem !important;
}

.g-pa-15-20 {
  padding: 1.0714285714rem 1.4285714286rem !important;
}

.g-pa-15-30 {
  padding: 1.0714285714rem 2.1428571429rem !important;
}

.g-pa-17-23 {
  padding: 1.2142857143rem 1.6428571429rem !important;
}

.g-pa-20-10 {
  padding: 1.4285714286rem 0.7142857143rem !important;
}

.g-pa-20-20-15 {
  padding: 1.4285714286rem 1.4285714286rem 1.0714285714rem !important;
}

.g-pa-20-20-20-55 {
  padding: 1.4285714286rem 1.4285714286rem 1.4285714286rem 3.9285714286rem !important;
}

.g-pa-20-30-0 {
  padding: 1.4285714286rem 2.1428571429rem 0 !important;
}

.g-pa-20-40-25 {
  padding: 1.4285714286rem 2.8571428571rem 1.7857142857rem !important;
}

.g-pa-24 {
  padding: 1.7142857143rem !important;
}

.g-pa-25-20-30 {
  padding: 1.7857142857rem 1.4285714286rem 2.1428571429rem !important;
}

.g-pa-25-30-20 {
  padding: 1.7857142857rem 2.1428571429rem 1.4285714286rem !important;
}

.g-pa-25-30 {
  padding: 1.7857142857rem 2.1428571429rem !important;
}

.g-pa-30-10 {
  padding: 2.1428571429rem 0.7142857143rem !important;
}

.g-pa-30-15 {
  padding: 2.1428571429rem 1.0714285714rem !important;
}

.g-pa-30-20 {
  padding: 2.1428571429rem 1.4285714286rem !important;
}

.g-pa-30-30-20 {
  padding: 2.1428571429rem 2.1428571429rem 1.4285714286rem !important;
}

.g-pa-40 {
  padding: 2.8571428571rem !important;
}

.g-pa-40-20 {
  padding: 2.8571428571rem 1.4285714286rem !important;
}

.g-pa-40-30-30 {
  padding: 2.8571428571rem 2.1428571429rem 2.1428571429rem !important;
}

.g-pa-50-15-0 {
  padding: 3.5714285714rem 1.0714285714rem 0 !important;
}

.g-pa-15x {
  padding: 15% !important;
}

.g-px-17 {
  padding-left: 1.2142857143rem !important;
  padding-right: 1.2142857143rem !important;
}

.g-px-18 {
  padding-left: 1.2857142857rem !important;
  /* O */
  padding-right: 1.2857142857rem !important;
}

.g-py-23 {
  padding-top: 1.6428571429rem !important;
  /* O */
  padding-bottom: 1.6428571429rem !important;
}

.g-py-235 {
  padding-top: 16.7857142857rem !important;
  /* O */
  padding-bottom: 16.7857142857rem !important;
}

.g-pl-12 {
  padding-left: 0.8571428571rem !important;
}

.g-pl-17 {
  padding-left: 1.2142857143rem !important;
}

.g-pl-7--hover:hover {
  padding-left: 0.5rem !important;
}

.g-pl-25--hover:hover {
  padding-left: 1.7857142857rem !important;
}

.active.g-pl-25--active {
  padding-left: 1.7857142857rem !important;
}

.g-pl-60--xs {
  padding-left: 4.2857142857rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.g-pt-11 {
  padding-top: 0.7857142857rem !important;
}

.g-pt-12 {
  padding-top: 0.8571428571rem !important;
}

.g-parent:hover .g-pt-25--parent-hover {
  padding-top: 1.7857142857rem !important;
}

.g-pt-130 {
  padding-top: 9.2857142857rem !important;
}

.g-pt-150 {
  padding-top: 10.7142857143rem !important;
}

.g-pt-170 {
  padding-top: 12.1428571429rem !important;
}

.g-pt-195 {
  padding-top: 13.9285714286rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.g-pb-170 {
  padding-bottom: 12.1428571429rem !important;
}

.g-pb-180 {
  padding-bottom: 12.8571428571rem !important;
}

.g-pb-200 {
  padding-bottom: 14.2857142857rem !important;
}

.g-pb-250 {
  padding-bottom: 17.8571428571rem !important;
}

.g-pb-13 {
  padding-bottom: 0.9285714286rem !important;
}

.g-pb-16 {
  padding-bottom: 1.1428571429rem !important;
}

.g-pa-9 {
  padding: 0.6428571429rem !important;
}

.g-pa-11 {
  padding: 0.7857142857rem !important;
}

.g-pa-12 {
  padding: 0.8571428571rem !important;
}

.g-pl-30--hover:hover {
  padding-left: 2.1428571429rem !important;
}

/* Custom Paddings (sm)
------------------------------------*/

@media (min-width: 576px) {
  .g-px-10x--sm {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }

  .g-px-0--sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .g-py-0--sm {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .g-py-5--sm {
    padding-top: 0.3571428571rem !important;
    padding-bottom: 0.3571428571rem !important;
  }

  .g-pa-0-10x--sm {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }

  .g-pa-0-35-35--sm {
    padding: 0 2.5rem 2.5rem !important;
  }

  .g-pa-5-10--sm {
    padding: 0.3571428571rem 0.7142857143rem !important;
  }

  .g-pa-8-16--sm {
    padding: 0.5714285714rem 1.1428571429rem !important;
  }

  .g-pa-10-20--sm {
    padding: 0.7142857143rem 1.4285714286rem !important;
  }

  .g-pa-15-20-10--sm {
    padding: 1.0714285714rem 1.4285714286rem 0.7142857143rem !important;
  }

  .g-pa-20-20-0--sm {
    padding: 1.4285714286rem 1.4285714286rem 0 !important;
  }

  .g-pa-20-20-10--sm {
    padding: 1.4285714286rem 1.4285714286rem 0.7142857143rem !important;
  }

  .g-pa-20-30-0--sm {
    padding: 1.4285714286rem 2.1428571429rem 0 !important;
  }

  .g-pa-20-30--sm {
    padding: 1.4285714286rem 2.1428571429rem !important;
  }

  .g-pa-30-20--sm {
    padding: 2.1428571429rem 1.4285714286rem !important;
  }

  .g-pa-30-25-20--sm {
    padding: 2.1428571429rem 1.7857142857rem 1.4285714286rem !important;
  }

  .g-pa-30-30-20--sm {
    padding: 2.1428571429rem 2.1428571429rem 1.4285714286rem !important;
  }

  .g-pa-30-50-40--sm {
    padding: 2.1428571429rem 3.5714285714rem 2.8571428571rem !important;
  }

  .g-pa-30-80--sm {
    padding: 2.1428571429rem 5.7142857143rem !important;
  }

  .g-pa-40-30-30--sm {
    padding: 2.8571428571rem 2.1428571429rem 2.1428571429rem !important;
  }

  .g-pa-60-30--sm {
    padding: 4.2857142857rem 2.1428571429rem !important;
  }

  .g-pa-70-50-50-30--sm {
    padding: 5rem 3.5714285714rem 3.5714285714rem 2.1428571429rem !important;
  }

  .g-pa-80-40--sm {
    padding: 5.7142857143rem 2.8571428571rem !important;
  }

  .g-pa-85-0-40--sm {
    padding: 6.0714285714rem 0 2.8571428571rem !important;
  }

  .g-pa-100-30--sm {
    padding: 7.1428571429rem 2.1428571429rem !important;
  }

  .g-pa-170-0-150--sm {
    padding: 12.1428571429rem 0 10.7142857143rem !important;
  }

  .g-pl-300--sm {
    padding-left: 21.4285714286rem !important;
  }
}

/* Custom Paddings (md)
------------------------------------*/

@media (min-width: 768px) {
  .g-pa-25-30--md {
    padding: 1.7857142857rem 2.1428571429rem !important;
  }

  .g-pa-30-10--md {
    padding: 2.1428571429rem 0.7142857143rem !important;
  }

  .g-pa-30-35--md {
    padding: 2.1428571429rem 2.5rem !important;
  }

  .g-py-0--md {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .g-py-23--md {
    padding-top: 1.6428571429rem !important;
    padding-bottom: 1.6428571429rem !important;
  }

  .g-px-18--md {
    padding-left: 1.2857142857rem !important;
    padding-right: 1.2857142857rem !important;
  }

  .g-pt-135--md {
    padding-top: 9.6428571429rem !important;
  }

  .g-pt-145--md {
    padding-top: 10.3571428571rem !important;
  }

  .g-pt-150--md {
    padding-top: 10.7142857143rem !important;
  }

  .g-pt-170--md {
    padding-top: 12.1428571429rem !important;
  }

  .g-pt-175--md {
    padding-top: 12.5rem !important;
  }

  .g-pb-70--md {
    padding-bottom: 5rem !important;
  }

  .g-pb-170--md {
    padding-bottom: 12.1428571429rem !important;
  }

  .g-pb-250--md {
    padding-bottom: 17.8571428571rem !important;
  }

  .g-pl-300--md {
    padding-left: 21.4285714286rem !important;
  }
}

/* Custom Paddings (lg)
------------------------------------*/

@media (min-width: 992px) {
  .g-py-0--lg {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .g-py-100--lg {
    padding-top: 7.1428571429rem !important;
    padding-bottom: 7.1428571429rem !important;
  }

  .g-px-200--lg {
    padding-left: 14.2857142857rem !important;
    padding-right: 14.2857142857rem !important;
  }

  .g-pt-170--lg {
    padding-top: 12.1428571429rem !important;
  }

  .g-pb-200--lg {
    padding-bottom: 14.2857142857rem !important;
  }
}

/* Custom Paddings (xl)
------------------------------------*/

@media (min-width: 1200px) {
  .g-py-0--xl {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .g-px-23--xl {
    padding-left: 1.6428571429rem !important;
    padding-right: 1.6428571429rem !important;
  }
}

/*------------------------------------
  Block Hidden
------------------------------------*/

.g-hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .g-hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .g-hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .g-hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .g-hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .g-hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .g-hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .g-hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .g-hidden-xl-up {
    display: none !important;
  }
}

.g-hidden-xl-down {
  display: none !important;
}