/*------------------------------------
  Background Position
------------------------------------*/
.g-bg-pos-center {
  background-position: center;
}

.g-bg-pos-left-center {
  background-position: left center;
}

.g-bg-pos-top-left {
  background-position: top left;
}

.g-bg-pos-top-right {
  background-position: top right;
}

.g-bg-pos-top-center {
  background-position: top center;
}

.g-bg-pos-bottom-center {
  background-position: bottom center;
}