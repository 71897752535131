/*------------------------------------
  Components
------------------------------------*/


// Variables
@import "custom/custom.variables";
@import "settings/variables";

// Mixins
@import "custom/custom.mixins";
@import "tools/mixins";



//
// Bases
//

/*@import "base/alerts";*/
@import "base/arrows";

//
// Badges
/*@import "base/badges/badges";
@import "base/badges/badges-v1";
@import "base/badges/badges-v2";
@import "base/badges/badges-v3";*/

//
// Dividers
/*@import "base/dividers/dividers";
@import "base/dividers/divider-style-solid";
@import "base/dividers/divider-style-dotted";
@import "base/dividers/divider-style-dashed";
@import "base/dividers/divider-style-db-solid";
@import "base/dividers/divider-style-db-dashed";
@import "base/dividers/divider-style-db-dotted";
@import "base/dividers/divider-style-linear-gradient";
@import "base/dividers/divider-type-icon";*/

//
// Go To
/*@import "base/go-to/go-to-v1";
@import "base/go-to/go-to-v2";
@import "base/go-to/go-to-v3";
@import "base/go-to/go-to-v4";*/

//
// Headers
/*@import "base/headers/headers";
@import "base/headers/header-togglers";
@import "base/headers/header-fullscreen";
@import "base/headers/header-side";*/

// Buttons
@import "base/buttons/buttons";

// Icons
/*@import "base/icons/icons";
@import "base/icons/icon-v1";
@import "base/icons/icon-v2";
@import "base/icons/icon-v3";
@import "base/icons/icon-v4";
@import "base/icons/icon-v5";
@import "base/icons/icons-image";
@import "base/icons/icons-block-hover";
@import "base/icons/icon-hover-scale";
@import "base/icons/icon-hover-shadow";
@import "base/icons/icon-hover-slide-v1";
@import "base/icons/icon-hover-slide-v2";
@import "base/icons/icon-hover-zoom";
@import "base/icons/icon-hover-shape";
@import "base/icons/icon-hover-effect-1";
@import "base/icons/icon-hover-effect-2";
@import "base/icons/icon-hover-effect-3";
@import "base/icons/icon-hover-effect-4";
@import "base/icons/icon-hover-effect-5";*/

//
// Box shadows
/*@import "base/box-shadows/box-shadows";
@import "base/box-shadows/box-shadows-v1";
@import "base/box-shadows/box-shadows-v2";
@import "base/box-shadows/box-shadows-v3-v4-v5";
@import "base/box-shadows/box-shadows-v6";
@import "base/box-shadows/box-shadows-v7";
@import "base/box-shadows/box-shadows-v8";
@import "base/box-shadows/box-shadows-v9";
@import "base/box-shadows/box-shadows-v10";
@import "base/box-shadows/box-shadows-v11";
@import "base/box-shadows/box-shadows-v12";
@import "base/box-shadows/box-shadows-v13";
@import "base/box-shadows/box-shadows-v14";
@import "base/box-shadows/box-shadows-v15";
@import "base/box-shadows/box-shadows-v16";
@import "base/box-shadows/box-shadows-v17";
@import "base/box-shadows/box-shadows-v18";
@import "base/box-shadows/box-shadows-v19";
@import "base/box-shadows/box-shadows-v20";
@import "base/box-shadows/box-shadows-v21";
@import "base/box-shadows/box-shadows-v22";
@import "base/box-shadows/box-shadows-v23";
@import "base/box-shadows/box-shadows-v24";
@import "base/box-shadows/box-shadows-v25";
@import "base/box-shadows/box-shadows-v26";
@import "base/box-shadows/box-shadows-v27";
@import "base/box-shadows/box-shadows-v28";
@import "base/box-shadows/box-shadows-v29";
@import "base/box-shadows/box-shadows-v30";
@import "base/box-shadows/box-shadows-v31";
@import "base/box-shadows/box-shadows-v32";
@import "base/box-shadows/box-shadows-v33";
@import "base/box-shadows/box-shadows-v34";
@import "base/box-shadows/box-shadows-v35";
@import "base/box-shadows/box-shadows-v36";
@import "base/box-shadows/box-shadows-v37";
@import "base/box-shadows/box-shadows-v38";
@import "base/box-shadows/box-shadows-v39";
@import "base/box-shadows/box-shadows-v40";
@import "base/box-shadows/box-shadows-v41";*/

//
// Forms
@import "base/form-controls/style";
/*@import "base/form-controls/states/states";*/
@import "base/form-controls/fields/fields";
@import "base/form-controls/checkboxes/checkboxes";
/*@import "base/form-controls/file-attachments/file-attachments";*/
@import "base/form-controls/selects/selects";
/*@import "base/form-controls/sliders/sliders";*/
/*@import "base/form-controls/datepickers/datepickers";*/
/*@import "base/form-controls/quantity";*/

//
// Carousel indicators
/*@import "base/carousel-indicators/carousel-indicators";
@import "base/carousel-indicators/carousel-indicators-v1";
@import "base/carousel-indicators/carousel-indicators-v2";
@import "base/carousel-indicators/carousel-indicators-v3";
@import "base/carousel-indicators/carousel-indicators-v4";
@import "base/carousel-indicators/carousel-indicators-v5";
@import "base/carousel-indicators/carousel-indicators-v6";
@import "base/carousel-indicators/carousel-indicators-v7";
@import "base/carousel-indicators/carousel-indicators-v8";
@import "base/carousel-indicators/carousel-indicators-v9";
@import "base/carousel-indicators/carousel-indicators-v10";
@import "base/carousel-indicators/carousel-indicators-v11";
@import "base/carousel-indicators/carousel-indicators-v12";
@import "base/carousel-indicators/carousel-indicators-v13";
@import "base/carousel-indicators/carousel-indicators-v14";
@import "base/carousel-indicators/carousel-indicators-v15";
@import "base/carousel-indicators/carousel-indicators-v16";
@import "base/carousel-indicators/carousel-indicators-v17";
@import "base/carousel-indicators/carousel-indicators-v18";
@import "base/carousel-indicators/carousel-indicators-v19";
@import "base/carousel-indicators/carousel-indicators-v20";
@import "base/carousel-indicators/carousel-indicators-v21";
@import "base/carousel-indicators/carousel-indicators-v22";
@import "base/carousel-indicators/carousel-indicators-v23";
@import "base/carousel-indicators/carousel-indicators-v24";
@import "base/carousel-indicators/carousel-indicators-v25";
@import "base/carousel-indicators/carousel-indicators-v26";
@import "base/carousel-indicators/carousel-indicators-v27";
@import "base/carousel-indicators/carousel-indicators-v28";
@import "base/carousel-indicators/carousel-indicators-v29";
@import "base/carousel-indicators/carousel-indicators-v30";
@import "base/carousel-indicators/carousel-indicators-v31";
@import "base/carousel-indicators/carousel-indicators-v32";
@import "base/carousel-indicators/carousel-indicators-v33";
@import "base/carousel-indicators/carousel-indicators-v34";
@import "base/carousel-indicators/carousel-indicators-v35";*/

//
// Breadcrumbs
/*@import "base/breadcrumbs/breadcrumbs-v1";
@import "base/breadcrumbs/breadcrumbs-v2";*/

//
// Dot line
/*@import "base/dot-line/dot-line-v1";
@import "base/dot-line/dot-line-v2";*/

//
// Headings
/*@import "base/headings/heading-v1";
@import "base/headings/heading-v2";
@import "base/headings/heading-v3";
@import "base/headings/heading-v4";
@import "base/headings/heading-v5";
@import "base/headings/heading-v6";
@import "base/headings/heading-v7";
@import "base/headings/heading-v8";
@import "base/headings/heading-v9";
@import "base/headings/heading-v10";
@import "base/headings/headings";*/

//
// Bg-nonstandarts
/*@import "base/bg-nonstandarts";*/

//
// Nonstandard-bg
/*@import "base/nonstandard-bg/nonstandard-bg";
@import "base/nonstandard-bg/nonstandard-bg-v1";
@import "base/nonstandard-bg/nonstandard-bg-v2";
@import "base/nonstandard-bg/nonstandard-bg-v3";
@import "base/nonstandard-bg/nonstandard-bg-v4";
@import "base/nonstandard-bg/nonstandard-bg-v5";
@import "base/nonstandard-bg/nonstandard-bg-v6";
@import "base/nonstandard-bg/nonstandard-bg-v7";*/

//
// Progress bars
/*@import "base/progress-bars/progress-bars";*/
// @import "base/progress-bars/tag-progress-bars";
/*@import "base/progress-bars/progress-bars-sizes";*/

//
// Ratings
/*@import "base/rating/rating-v1";*/

//
// Searchforms
/*@import "base/searchforms/searchform-v1";
@import "base/searchforms/searchform-v2";
@import "base/searchforms/searchform-v3";
@import "base/searchforms/searchform-v4";
@import "base/searchforms/searchform-v5";*/

//
// Steps
/*@import "base/steps/steps-v1";
@import "base/steps/steps-v2";
@import "base/steps/steps-v3";*/

//
// Time Lines
/*@import "base/timelines/timelines";
@import "base/timelines/timelines-v1";
@import "base/timelines/timelines-v2";
@import "base/timelines/timelines-v3";*/


@import "base/labels";
@import "base/links";
@import "base/lists";
@import "base/media";
/*@import "base/orders";*/
@import "base/paginations";
/*@import "base/ribbons";*/
/*@import "base/scroll-animations";*/
/*@import "base/stickers";*/
@import "base/sticky-blocks";
@import "base/tables";
/*@import "base/tabs/tabs";*/
@import "base/tags";
/*@import "base/text-animations";*/
/*@import "base/text-shadows";*/
/*@import "base/bg-markers";*/
/*@import "base/tooltips";*/
@import "base/typography";
/*@import "base/video-audio";*/
/*@import "base/navigation/navigation";*/
/*@import "base/basket";*/
/*@import "base/dropdown";*/
/*@import "base/compressed-form";*/
/*@import "base/dropcaps";*/
/*@import "base/bg-angles";*/
/*@import "base/block-hovers";*/
@import "base/blockquotes";
@import "base/accordions/accordions";
/*@import "base/carousels";
@import "base/carousels/effects";*/
/*@import "base/chart-pies";
@import "base/charts";*/
/*@import "base/counters";*/
/*@import "base/credit-cards";*/

//
// Dropdowns
@import "base/dropdowns/dropdowns-v1";
@import "base/bg-overlays";

//
// Material
/*@import "base/waves";
@import "base/fab/fab";
@import "base/chips";*/



//
// Helpers
//

/*@import "base/outer-spaces-helper";*/



//
// Blocks
//

/*@import "blocks/info";*/



//
// Vendors
//

/*@import "vendors/vendor";*/



//
// JS
//

/*@import "js/js";*/



//
// IE
//

@import "ie/ie";



//
// Demo
//

/*@import "demo/demo.icons";*/



//
// Custom Styles
//

@import "custom/custom";
