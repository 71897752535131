/* Add here all your css styles (customizations) */

// Variables
@import "settings/variables";
@import "custom.variables";

// Mixins
@import "custom.mixins";

/* work-sans-regular - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/Content/fonts/work-sans-v18-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
    src: url('/Content/fonts/work-sans-v18-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/Content/fonts/work-sans-v18-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/Content/fonts/work-sans-v18-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
    url('/Content/fonts/work-sans-v18-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/Content/fonts/work-sans-v18-latin_latin-ext-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-500 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    src: url('/Content/fonts/work-sans-v18-latin_latin-ext-500.eot'); /* IE9 Compat Modes */
    src: url('/Content/fonts/work-sans-v18-latin_latin-ext-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/Content/fonts/work-sans-v18-latin_latin-ext-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('/Content/fonts/work-sans-v18-latin_latin-ext-500.woff') format('woff'), /* Modern Browsers */
    url('/Content/fonts/work-sans-v18-latin_latin-ext-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/Content/fonts/work-sans-v18-latin_latin-ext-500.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-600 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    src: url('/Content/fonts/work-sans-v18-latin_latin-ext-600.eot'); /* IE9 Compat Modes */
    src: url('/Content/fonts/work-sans-v18-latin_latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/Content/fonts/work-sans-v18-latin_latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('/Content/fonts/work-sans-v18-latin_latin-ext-600.woff') format('woff'), /* Modern Browsers */
    url('/Content/fonts/work-sans-v18-latin_latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/Content/fonts/work-sans-v18-latin_latin-ext-600.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-700 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/Content/fonts/work-sans-v18-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
    src: url('/Content/fonts/work-sans-v18-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/Content/fonts/work-sans-v18-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/Content/fonts/work-sans-v18-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
    url('/Content/fonts/work-sans-v18-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/Content/fonts/work-sans-v18-latin_latin-ext-700.svg#WorkSans') format('svg'); /* Legacy iOS */
}


body {
    font-family: 'Work Sans', sans-serif !important;
    font-weight: 500;
    color: #2b2e36;
}

label, h2, h3, h4 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
}

h2.h4 {
    color: #c6a171;
    font-family: 'Work Sans', sans-serif;
}

.content-column h2, .content-column h3, .content-column h4 {
    font-family: 'Work Sans';
}

dl, ol, p, ul, h2, h3, h4 {
    margin-bottom: 1.5rem;
}

a {
    color: #21347b;
}

a:hover {
    color: #21347b;
}

.staticpage h2, .staticpage h3, .staticpage h4 {
    margin-bottom: 20px;
}

.staticpage h2 {
    padding-top: 20px;
}

hr {
    margin-bottom: 20px;
}

.home-events .time {
    min-height: 43px;
}

.form-control {
    height: auto;
    font-family: 'Work Sans', sans-serif !important;
}

.form-control::placeholder {
    color: #535354 !important;
}

.file-box {
    background: #f4f4f4;
    padding: 10px 15px;
    margin-bottom: 15px;
}

#cconsent-bar {
    color: #fff !important;
    background-color: #21347b !important;
}

#cconsent-bar button {
    line-height: normal;
    font-size: 14px;
    border: none;
    padding: 10px 10px;
    color: #ffffff;
    background-color: #c6a171 !important;
}


.filter-label {
    font-size: 15px;
}

.slick-list {
    margin-left: -16px;
    margin-right: -16px;
}

.h4, h4 {
    font-size: 1.3rem;
}

.g-font-size-21 {
    font-size: 21px;
}

.g-top-22 {
    top: 22px;
}

.navbar-brand {
    margin: 8px 0 8px 8px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    /*width: 290px;*/
    width: 205px;
}

.g-right-165 {
    right: 145px;
}

.g-right-220 {
    right: 300px;
}

.g-top-12 {
    top: 12px;
}

.g-color-main {
    color: #242620;
}

.g-px-31 {
    padding-left: 2.3rem !important;
    padding-right: 2.3rem !important;
}

.u-arrow-custom-v7:hover .u-arrow-icon, a, table.table td a, ul.ui-autocomplete li .text {
    color: #21347b;
}

.language-header a {
    background: url("../../../assets/img/icons/arrow_right.svg") no-repeat 0 center;
    background-size: 15px auto;
    padding-left: 23px;
}

.phone {
    background: url("../../../assets/img/icons/contact_phone.svg") no-repeat 0 center;
    background-size: auto 17px;
    padding-left: 28px;
}

.mail {
    background: url("../../../assets/img/icons/contact_mail.svg") no-repeat 0 center;
    background-size: 17px auto;
    padding-left: 28px;
}

.slick-next.slick-disabled::before, .slick-prev.slick-disabled::before {
    opacity: 1;
}

.language-header {
    top: 43px;
    right: 43px;
}

.card {
    border: 0;
    background: #f8f8f4;
    padding: 10px 0;
}

.u-accordion__header a {
    border-bottom: 1px solid #e5ecd0 !important;
    margin-bottom: 15px !important;
}

.u-accordion__header a:hover {
    background: #f0f0ed !important;
}

.staticpage table tfoot td {
    font-weight: 600;
    text-transform: uppercase;
}

.u-accordion i {
    line-height: 20px !important;
}

.home-info {
    background: #e4ebfb;
}

.home-info .col-sm-12 {
    background: url("../../../assets/img/homeinfo.svg") no-repeat 0 bottom;
}

.home-info h2 {
    font-weight: 700;
    font-size: 68px;
    color: #c6a171;
    margin-bottom: 20px;
}


.home-info a {
    display: block;
    font-size: 25px;
    color: #21347b;
    font-weight: 700;
    padding-left: 35px;
    margin-bottom: 8px;
    background: url("../../../assets/img/arrow3.svg") no-repeat left center;
    background-size: 24px 14px;
}

.staticpage .u-accordion table th, .staticpage .u-accordion table td {
    border-bottom: 1px solid #e5ecd0 !important;
}

.navbar-collapse.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
}

h3 {
    font-size: 1.45rem;
}

.navbar {
    padding: 0 !important;
}

.search-top {
    position: absolute;
    right: 25px;
    top: 22px
}

.header-search-btn {
    cursor: pointer;
}

.header-search-btn-close {
    cursor: pointer;
}

.btn.btn-link.search-btn.btn-top {
    transition: none;
}

.ophours td {
    padding: 3px 10px;
    vertical-align: top !important;
}

.ophours tr td:first-child {
    padding-left: 0;
}

.ophours tr td:last-child {
    min-width: 250px !important;
}

.box {
    background: #f1f5fd;
    padding: 20px 15px;
    margin-bottom: 8px;
}

.footer-logo img {
    width: 300px !important;
}

.right-section a {
    font-size: 18px;
    font-weight: 600;
}

.r-news-list li {
    margin: 0 0 20px !important;
}

.right-section .fa.fa-angle-right {
    position: absolute;
    top: 5px;
    left: 0px;
}

.staticpage a[href^="http://"]::before, .staticpage a[href^="https://"]::before {
    display: none;
}

.staticpage p, .staticpage ul, .staticpage ol {
    margin-bottom: 1.6rem;
}

.staticpage a[href^="http://"]::after, .staticpage a[href^="https://"]::after {
    font-weight: 400;
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-variant: normal;
    margin-right: 8px;
    margin-left: 8px;
    font-size: 14px !important;
    color: #008bd2;
    content: "\f35d";
    text-decoration: none;
    display: inline-block;
}

.staticpage a.box-link[href^="http://"]::after, .staticpage a.box-link[href^="https://"]::after {
    display: none;
}

.staticpage .downloads-png a[href$=".png"], .staticpage .downloads-eps a[href$=".eps"], .staticpage a[href$=".pdf"], .staticpage a[href$=".zip"], .staticpage a[href$=".doc"], .staticpage a[href$=".docx"], .staticpage a[href$=".rtf"], .staticpage a[href$=".xls"], .staticpage a[href$=".xlsx"], .staticpage a[href$=".XLSX"] {
    padding-left: 28px;
    display: block;
}

.staticpage .downloads-png a[href$=".png"]:before {
    font-weight: 400;
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-variant: normal;
    margin-right: 10px;
    font-size: 17px;
    color: #f4ae36;
    content: "\f1c5";
    text-decoration: none;
    margin-left: -24px;
}

.staticpage .downloads-eps a[href$=".eps"]:before {
    font-weight: 400;
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-variant: normal;
    margin-right: 10px;
    font-size: 17px;
    color: #f4ae36;
    content: "\f31c";
    text-decoration: none;
    margin-left: -24px;
}


.staticpage a[href$=".pdf"]:before {
    font-weight: 400;
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-variant: normal;
    margin-right: 10px;
    font-size: 17px;
    color: #f4ae36;
    content: "\f1c1";
    text-decoration: none;
    margin-left: -24px;
}

.staticpage a[href$=".zip"]:before {
    font-weight: 400;
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-variant: normal;
    margin-right: 10px;
    font-size: 17px;
    color: #f4ae36;
    content: "\f1c6";
    text-decoration: none;
    margin-left: -24px;
}


.staticpage a[href$=".doc"]:before, .staticpage a[href$=".docx"]:before, .staticpage a[href$=".rtf"]:before {
    font-weight: 400;
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-variant: normal;
    margin-right: 10px;
    font-size: 17px;
    color: #f4ae36;
    content: "\f1c2";
    text-decoration: none;
    margin-left: -24px;
}

.staticpage a[href$=".xls"]:before, .staticpage a[href$=".xlsx"]:before, .staticpage a[href$=".XLSX"]:before {
    font-weight: 400;
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-variant: normal;
    margin-right: 10px;
    font-size: 17px;
    color: #f4ae36;
    content: "\f65b";
    text-decoration: none;
    margin-left: -24px;
}

.staticpage a[href$=".pdf"], .staticpage a[href^="http://"], .staticpage a[href^="https://"], .staticpage a[href$=".doc"], .staticpage a[href$=".docx"], .staticpage a[href$=".xls"], .staticpage a[href$=".xlsx"] {
    border-bottom: 0;
    text-decoration: none !important;
}

.staticpage a[href$=".pdf"]:hover, .staticpage a[href^="http://"]:hover, .staticpage a[href^="https://"]:hover, .staticpage a[href$=".doc"]:hover, .staticpage a[href$=".docx"]:hover, .staticpage a[href$=".xls"]:hover, .staticpage a[href$=".xlsx"]:hover {
    border-bottom: 0;
    text-decoration: underline !important;
    background: none;
}

.box-object3 p .btn.btn-download {
    margin-top: 6px;
    vertical-align: baseline;
}

.h2, h2 {
    font-size: 1.8rem;
}

.g-width-230 {
    width: 230px;
}

.g-width-210 {
    width: 210px;
}

.g-top-28 {
    top: 28px;
}

.modal-open {
    overflow: hidden;
}

.home-info-indend {
    padding: 160px 0 30px 40px;
}

.radnica td {
    width: 25%;
    text-align: center;
}

.staticpage .radnica a[href$=".pdf"]::before {
    display: none !important;
}

.btn-primary {
    background-color: #c6a171;
    border-color: #c6a171;
    color: #fff;
}

.btn-primary:hover {
    background-color: #ba9462;
    border-color: #ba9462;
    color: #fff;
}

.u-accordion i {
    font-size: 12px;
    line-height: 24px;
    vertical-align: text-bottom;
    text-decoration: none !important;
}

.u-accordion h5 a:focus {
    border: 0 !important;
}

.u-accordion ul, .u-accordion ul li {
    list-style: none;
    padding: 0;
    margin: 0;
}

.u-accordion ul {
    margin: 0 0 0 26px;
}

.u-accordion i:hover {
    text-decoration: none !important;
}

.u-accordion-color-primary .u-accordion__header [aria-expanded="true"] {
    color: #242620 !important;
}

.u-accordion-color-primary .u-accordion__header [aria-expanded="true"] span {
    text-decoration: underline !important;
}

.u-accordion-color-primary .u-accordion__header [aria-expanded="true"] span.u-accordion__control-icon {
    text-decoration: none !important;
}

.u-accordion-color-primary .u-accordion__header:focus {
    border: 0 !important;
}

.fa-file-rtf::before {
    content: "\f1c2";
}

.mgx0 {
    margin-left: 0;
    margin-right: 0;
}

.bg-light {
    background: #f9f4ea !important;
}

.bg-medium {
    background: #f3e8d5;
}

#contacts-section strong {
    font-family: 'Work Sans';
    font-weight: normal;
}

.bgheader, .bgheader .u-header {
    background: url("../../../assets/img/bg/bgsub.png") no-repeat center top #334583;
    position: relative;
    z-index: 1050;
}

.layout-home .bgheader {
    background: url("../../../assets/img/bg/bgheader.jpg") no-repeat center top #334583;
}


.layout-home .bgheader .u-header {
    background: none;
}

/*.layout-home .bgheader, .layout-home .bgheader .u-header {
    background: url("../../../assets/img/bg/bgheader.jpg") no-repeat center top #334583;
}*/
.lines {
    background: url("../../../assets/img/lines.svg") repeat-x;
    height: 30px;
    font-size: 11px;
    margin-top: 40px;
}

.navbar-toggler.navbar-toggler-right {
    background: url("../../../assets/img/m_menu_icon1.svg") no-repeat;
    top: 21px;
    right: 15px;
    font-size: 21px;
    padding: 0;
    width: 34px;
    height: 30px;
    font-family: 'Work Sans';
    color: #2b2822;
    border: 0;
    border-radius: 0;
}

.navbar-toggler.navbar-toggler-right[aria-expanded="true"] {
    background: url("../../../assets/img/m_menu_icon2.svg") no-repeat;
    color: #fff;
    border: 0 !important;
    height: 30px;
}

.navbar-toggler.navbar-toggler-right:focus {
    border: 0 !important;
    box-shadow: none !important;
}


.modal-backdrop {
    top: 88px !important;
}

.navbar-collapse .col-sm-12 {
    padding-right: 0 !important;
}

.navbar-toggler.navbar-toggler-right.collapsed {
    background: url("../../../assets/img/m_menu_icon1.svg") no-repeat;
    color: #2b2822;
}

.navbar-collapse.collapse.show {
    display: block !important;
    position: absolute;
    top: 86px;
    width: 100%;
}

.els {
    background: url("../../../assets/img/arrow.png") no-repeat 0 6px;
    padding-left: 13px;
    font-family: 'Work Sans';
}

.els:hover {
    color: #d9be86;
    text-decoration: none;
}

.els.mobile-els {
    background: url("../../../assets/img/arrow.png") no-repeat 0 6px;
    padding-left: 13px;
    color: #22211f;
}

.home-alerts, .top-search {
    background: url("../../../assets/img/bg.png") repeat-x #f7fafd;
}

.input-group-addon .fa-search {
    background: url("../../../assets/img/search.svg") no-repeat;
    width: 27px;
    height: 27px;
}

.search-btn {
    padding: 10px 7px 4px 7px;
}

.search-header .search-btn.btn-top {
    padding: 5px 7px 0 7px;
}

.input-group-addon .fa-search::before {
    content: none;
}

#searchform-6 .input-group {
    width: 100%;
    margin: 0 0 0 auto;
}

.home-motive #searchform-6 .input-group {
    margin: 0 auto;
}

.home-motive .row {
    align-items: center;
}

.home-motive .col-sm-6 {
    padding-left: 0;
    padding-right: 0;
    min-height: 400px;
    position: relative;
}

.home-motive .text {
    color: #c6a171;
    font-size: 96px;
    line-height: 90px;
    font-weight: 700;
    margin: 85px 0 0 0;
    text-transform: uppercase;
}

.home-motive .col-sm-6 label {
    font-size: 36px;
    color: #656565;
}

.home-motive .header-label {
    font-size: 36px;
    color: #fff;
    font-weight: 700;
    margin: 0 0 25px 0;
}

.welcome p {
    font-size: 22px;
    color: #c6a171;
    font-weight: 700;
    margin: 0 auto 75px auto;
    width: 630px;
}

.welcome p a {
    color: #fff;
}

.home-links {
    background: #f8f8f8;
    padding: 40px 0 0 0;
}

.home-links .col-md-2 {
    text-align: center;
}

.home-links .col-md-2 a {
    color: #2b2e80;
    font-weight: 600;
    font-size: 19px;
}

.home-links .col-md-2 a img {
    margin-bottom: 20px;
    width: 138px;
}

.footer-social {
    text-align: center;
}

.footer-social img {
    width: 60px;
}


.home-news {
    padding: 70px 0 50px;
}

.home-events h2, .home-news h2, .home-notes h2, .home-partners h2 {
    font-size: 42px !important;
    font-weight: 700;
}

.home-partners {
    padding: 20px 0 ;
}

.home-partners h2 {
    text-align: center !important;
    width: 100%;
}

.home-partners img {
    height: 150px;
}

.article-detail {
    background: #21347b;
    padding: 0 15px 30px 26px;
    position: relative;
}

.sub-news .article-detail {
    background: #f8f8f8;
    padding: 30px 20px;
    position: relative;
}


.sub-news .slick-list .date {
    padding-top: 0;
}

.article-detail a {
    text-decoration: none;
}

.article-detail .news-image {
    top: -30px;
    right: 0;
    position: absolute;
}

#searchform-6 .btn.btn-link {
    background-color: #fff !important;
    color: #000 !important;
}

.home-alerts .col-sm-6:hover {
    background: #f7fbff !important;
}

.home-motive {
    background: #dceaf8 !important;
}

.home-motive .col-sm-6 #search-input {
    border: 0 !important;
    font-size: 16px
}

.home-motive .col-sm-6 p a {
    font-size: 20px;
    color: #5f6063;
}


.g-font-size-19 {
    font-size: 19px;
}


.home-info h3 {
    font-family: 'Work Sans';
}
.dropdown-item {
    font-weight: 500;
}

.u-link-v5:focus, .u-link-v5:hover {
    box-shadow: none !important;
}

.home-notes .date {
    display: block;
}

ul.ui-autocomplete {
    padding: 10px 15px !important;
    background: #f5f5f5 !important;
}

ul.ui-autocomplete li {
    padding: 4px 15px 4px 15px;
    color: #000;
    font-size: 0.9em;
}

ul.ui-autocomplete li.ui-menu-focus, ul.ui-autocomplete li.ui-menu-item:hover {
    background: #f5f5f5 !important;
    color: #000 !important;
    text-decoration: underline;
}

/*
.slick-track .slick-slide:first-child .col-sm-12, .slick-track .slick-slide:nth-child(4) .col-sm-12 {
    padding-left: 0;
}

.slick-track .slick-slide:nth-child(9) .col-sm-12, .slick-track .slick-slide:nth-child(6) .col-sm-12, .slick-track .slick-slide:nth-child(3) .col-sm-12 {
    padding-right: 0;
}*/





.social a {
    display: inline-block;
    margin-right: 5px;
    width: 32px;
    height: 32px;
    text-align: center;
    background: #343726;
    color: #fff;
    border-radius: 50%;
}

.social a i {
    margin-top: 9px;
    display: inline-block;
}

#contacts-section h3 {
    font-family: Work Sans;
}

.home-alerts .col-sm-6 {
    background: #e4f0fc;
    margin-left: 15px;
    flex: 0 0 48.6%;
}

.home-alerts .col-sm-6:first-child {
    margin-left: 0;
    margin-right: 15px;
}

.home-alerts .col-sm-6 a {
    display: block;
    font-size: 18px;
    text-decoration: underline;
}

.home-alerts .col-sm-6 p {
    margin-bottom: 0;
    font-size: 14px;
    color: #999690;
}

.navbar-expand-lg .navbar-toggler {
    display: none;
}


.bg-abstract {
    background-image: url("../../../content/assets/img/bg/mirrored-squares.png");
}

.fa-pull-right, .pull-right, .fa-pull-right li, .pull-right li, .loginbar li {
    list-style-type: none;
}

.loginbar {
    margin-top: 8px;
    margin-left: 15px
}

.g-hidden-md-up {
    .loginbar {
        & > li > a {
            color: #fff;
        }

        & li a {
            padding: 1.07143rem;
            display: inline-block;
        }

        & .dropdown-menu {
            width: 100%;

            & li {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        padding: 0;
        margin: 0;
    }
}

.img-responsive.full-width {
    width: 100%;
}

@media (min-width: 1025px) {
    .min-height-article {
        min-height: 380px !important;
    }
}

    .input-validation-error,
    .input-validation-error:focus {
        border-color: $danger-color !important;
    }

    .field-validation-error {
        color: $danger-color !important;
    }

    .validation-summary-errors {
        position: relative;
        padding: .75rem 1.25rem;
        margin: 0 0 .75rem 0 !important;
        border: 1px solid transparent;
        border-radius: .25rem;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb
    }

    .validation-summary-errors ul {
        margin: 0;
        padding: 0 0 0 15px;
        list-style: none
    }

    .dropdown-menu {
        padding: 40px 15px 30px;
        border-radius: 0
    }

    .user-logged:hover {
        text-decoration: none
    }

    @include breakpoint(992up) {
 /*       .min-height-article {
            min-height: 280px;
        }*/

        .masonry-grid-item > article {
            height: 100%
        }
    }

    @include breakpoint(phone) {
        /*.navbar-collapse {
        background-color: #274065;
    }*/
        .navbar-collapse .hs-sub-menu {
            background-color: rgba(255, 255, 255, 0.2);
        }

        .g-color-white-xs, .nav-link.g-color-white-xs {
            color: #fff !important;
        }
    }

    .highlighted {
        font-weight: bold;
        background: #F0F0F0;
    }

    /* loader for jobs list loading - copied from stengl*/
    .ajax-preloader {
        display: block;
        width: 100%;
        float: left;
        text-align: center;
        position: relative;
        height: 100px;
        margin: 15px 0
    }

    @keyframes lds-rolling {
        0% {
            transform: translate(-50%,-50%) rotate(0)
        }

        100% {
            transform: translate(-50%,-50%) rotate(360deg)
        }
    }

    .lds-rolling {
        position: relative;
        display: inline-block
    }

    .lds-rolling div, .lds-rolling div:after {
        position: absolute;
        width: 90px;
        height: 90px;
        border: 10px solid #213769;
        border-top-color: transparent;
        border-radius: 50%
    }

    .lds-rolling div {
        animation: lds-rolling 1s linear infinite;
        top: 100px;
        left: 100px
    }

    .lds-rolling div:after {
        -ms-transform: rotate(90deg);
        transform: rotate(90deg)
    }

    .lds-rolling {
        width: 100px !important;
        height: 100px !important;
        -ms-transform: translate(-50px,-50px) scale(.5) translate(50px,50px);
        transform: translate(-50px,-50px) scale(.5) translate(50px,50px)
    }

    .hidden {
        display: none;
    }

    .dropdown-menu li {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    .alert > ul {
        margin-bottom: 0
    }

    .alert .fa {
        margin-right: 3px
    }

    .home-events article .date {
        font-weight: 700;
        display: block;
    }

    .event-page .col-sm-4 {
        margin-bottom: 30px;
    }

    ul.ui-autocomplete {
        position: absolute;
        z-index: 510;
        background: white;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.09), -2px 2px 3px rgba(0, 0, 0, 0.09);
        border-radius: 0 0 .25rem .25rem !important;
        text-align: left;
        list-style: none;
        padding-left: 0;

        li {
            margin: 0;
            cursor: pointer;

            .text {
                color: $g-color-primary;
            }

            .category {
                color: lightgray;
            }

            .annotation {
                color: #555;
                word-wrap: normal;
            }

            &.ui-state-hover,
            &.ui-state-focus,
            &.ui-menu-item:hover {
                background: $g-color-gray-light-v5 !important;
                border-top: none !important;
                border-left: none !important;
                border-right: none !important;
                font-weight: normal !important;
            }
        }
    }

    .navbar-expand-lg {
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar .container {
        position: relative;
    }

    .navbar .container, .navbar .container-fluid {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: center;
        align-items: start;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    @media (min-width: 992px) {
        .navbar-expand-lg .container, .navbar-expand-lg .container-fluid {
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        }

        .navbar-expand-lg .navbar-collapse {
            display: block !important;
        }
    }



    .navbar {
        position: relative;
        padding: .5rem 0 0 0;
        display: block;
    }

    .hs-sub-menu {
        left: 0;
        top: 150px;
        z-index: 2;
        margin-top: 5px;
        visibility: hidden;
        background-color: #274065;
        opacity: 1 !important;
        width: 100%;
    }

    .g-ml-80 {
        margin-left: 80px;
    }
.sub-news .article-detail h3 {
    line-height: 1.2em !important;
    min-height: 120px !important;
    -webkit-line-clamp: 4;
}

    .language button.dropdown-toggle {
        border: 0;
        background-color: #fff;
    }

    .language button.dropdown-toggle:active, .language button.dropdown-toggle:focus {
        border: 0 !important;
    }

    .language .dropdown-menu {
        min-width: initial;
        padding: 0;
        -webkit-border-bottom-right-radius: 3px;
        -webkit-border-bottom-left-radius: 3px;
        -moz-border-radius-bottomright: 3px;
        -moz-border-radius-bottomleft: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .language .dropdown-item {
        padding: 0 28px 0 8px;
    }



    #search-input {
        border-top: 0 !important;
        border-left: 0 !important;
        border-bottom: 0 !important;
        border-right: 0 !important;
        background-color: #fff;
        color: #647489;
        -webkit-border-top-left-radius: 5px !important;
        -webkit-border-bottom-left-radius: 5px !important;
        -moz-border-radius-topleft: 5px !important;
        -moz-border-radius-bottomleft: 5px !important;
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
        -webkit-border-top-right-radius: 0 !important;
        -webkit-border-bottom-right-radius: 0 !important;
        -moz-border-radius-topright: 0 !important;
        -moz-border-radius-bottomright: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    #searchform-6 .btn.btn-link {
        background-color: #2b2822;
        color: #fff;
        -webkit-border-top-right-radius: 5px !important;
        -webkit-border-bottom-right-radius: 5px !important;
        -moz-border-radius-topright: 5px !important;
        -moz-border-radius-bottomright: 5px !important;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
        -webkit-border-top-left-radius: 0 !important;
        -webkit-border-bottom-left-radius: 0 !important;
        -moz-border-radius-topleft: 0 !important;
        -moz-border-radius-bottomleft: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .newsletter span {
        display: inline-block;
    }

    .newsletter .form-control {
        border-radius: 0 !important;
        border: 0 !important;
        font-size: 16px !important;
        -webkit-border-top-left-radius: 3px !important;
        -webkit-border-bottom-left-radius: 3px !important;
        -moz-border-radius-topleft: 3px !important;
        -moz-border-radius-bottomleft: 3px !important;
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
    }

    .newsletter .btn {
        border-radius: 0 !important;
        -webkit-border-top-right-radius: 3px !important;
        -webkit-border-bottom-right-radius: 3px !important;
        -moz-border-radius-topright: 3px !important;
        -moz-border-radius-bottomright: 3px !important;
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
        background-color: #202414;
        color: #fff;
    }

.file-card .download-icon, .file-card a:hover {
    color: #21347b !important;
}


    .home-links .box {
        padding: 35px 15px 35px 70px;
        font-size: 19px;
        background-color: #f3f9ff;
        line-height: 1.2em;
        display: block;
        vertical-align: middle;
        border-radius: 2px;
        background-repeat: no-repeat;
        background-position: left center;
        font-family: 'Work Sans',sans-serif !important;
    }

    .home-links .bx-24h {
        background-image: url("../../../assets/img/bx24h.png");
    }

    .home-links .bx-mp {
        background-image: url("../../../assets/img/bxmp.png");
    }

    .home-links .bx-ps {
        background-image: url("../../../assets/img/bxps.png");
    }

    .home-links .bx-vt {
        background-image: url("../../../assets/img/bxvt.png");
    }

    .home-links .bx-sa {
        background-image: url("../../../assets/img/bxsa.png");
    }

    .home-links .bx-pat {
        background-image: url("../../../assets/img/bxpat.png");
    }

    .home-links .bx-su {
        background-image: url("../../../assets/img/bxsu.png");
    }

    .home-links .bx-mpg {
        background-image: url("../../../assets/img/bxmpg.png");
    }

    .home-links .bx-zp {
        background-image: url("../../../assets/img/bxzp.png");
    }

    .home-links .bx-mm {
        background-image: url("../../../assets/img/bxmm.png");
    }

    .home-links .bx-rm {
        background-image: url("../../../assets/img/bxrm.png");
    }

    .home-links .bx-um {
        background-image: url("../../../assets/img/bxum.png");
    }

    .home-links .box:hover {
        color: #000000;
        text-decoration: none;
        background-color: #f3f9ff;
    }

    .bg {
        background-color: #f0faff;
    }

    .staticpage a {
        text-decoration: none !important;
    }

    .staticpage a:hover {
        text-decoration: underline !important;
    }

    .contact-item {
        padding: 20px 20px;
        background: #f8f8f4;
        border-radius: 4px;
        margin-bottom: 15px;
    }

    .contact-item p:last-child {
        margin-bottom: 0;
    }

    .article-detail img {
        max-width: 100%;
        -webkit-border-top-left-radius: 4px;
        -webkit-border-top-right-radius: 4px;
        -moz-border-radius-topleft: 4px;
        -moz-border-radius-topright: 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .article-detail .media-body {
        color: #5e6a78;
        font-weight: 100;
    }

    .newsletter, #contacts-section, .footer-copy, footer {
        background-color: #21347b;
        color: #fff;
    }

    .newsletter a, #contacts-section a, .footer-copy a, footer a {
        color: #fff;
    }

.fmail {
    margin-left: 2px;
    font-family: "Work Sans",sans-serif !important;
}

.fphone {
    margin-left: 2px;
    font-family: "Work Sans",sans-serif !important;
}

.faddress, .fmail, .fphone {
    font-size: 18px;
    line-height: 1.5;
}

.faddress::before {
    content: "\f015";
    margin-right: 6px;
    font-weight: 900;
    font-size: 20px;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
}

.fmail::before {
    content: "\f0e0";
    margin-right: 8px;
    font-weight: 900;
    font-size: 18px;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
}



#topcontrol {
    bottom: 0 !important;
    width: 100% !important;
    padding: 14px 0 !important;
    position: static !important;
    right: 0 !important;
    z-index: 200;
    background: #1d2d6b !important;
    background-size: 27px 17px !important;
}

#topcontrol:hover {
    background: #1d2d6b !important;
}

#topcontrol span {
    bottom: 0 !important;
    width: 100% !important;
    height: 27px !important;
    position: static !important;
    right: 0 !important;
    z-index: 200;
    display: block;
    background: url(../../../assets/img/arrowtop.svg) no-repeat top center !important;
}

.fphone::before {
    content: "\f095";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    margin-right: 6px;
    font-weight: 900;
    font-size: 18px;
}

.fpl {
    padding-left: 28px;
    font-size: 20px;
    line-height: 1.5;
}
li::marker {
    color: #c6a171;
}
    footer {
        border-top: 1px solid #41518e;
        padding: 20px 0;
    }

    /*.home-services h2 {
    color: #000;
    font-weight: 300;
}

.home-services h2 span {
    color: #008bd2;
}

.home-services .box {
    border: 1px solid #DFE6EA;
    padding: 20px;
}

.home-services .col-lg-3:last-child h3 {
    color: #C7034A;
}

.home-services ul li::marker {
    font-size: 10px;
}

.home-services ul {
    margin-left: 0;
    padding-left: 10px;
}

.home-services ul li {
    margin-bottom: 4px;
}

.home-services ul li a {
    color: #455262;
}*/

    /*.home-events .home-events-title {
    border-left: 1px solid #DFE6EA;
    border-right: 1px solid #DFE6EA;
}

.home-events .home-events-date {
    border-left: 1px solid #DFE6EA;
    border-right: 1px solid #DFE6EA;
    border-bottom: 1px solid #DFE6EA;
    -webkit-border-bottom-right-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.home-events {
    border-top: 1px solid #DFE6EA;
    border-bottom: 1px solid #DFE6EA;
}*/

    #ui-datepicker-div {
        background: #fff;
        padding: 15px;
        border: 1px solid #dfe6ea;
        border-radius: 4px;
        display: none;
    }

    .hasDatepicker {
        border: 1px solid #DFE6EA;
        -webkit-border-top-left-radius: 4px;
        -webkit-border-top-right-radius: 4px;
        -moz-border-radius-topleft: 4px;
        -moz-border-radius-topright: 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 20px 20px 10px 20px;
    }

    .ui-datepicker-header {
        padding-bottom: 10px;
        padding-top: 0;
        margin-bottom: 20px;
        border-bottom: 1px solid #e9e9e8;
    }

    .ui-datepicker-calendar {
        width: 100%;
        margin-bottom: 10px;
    }

    .ui-datepicker-calendar th {
        color: #22211f;
        text-align: center;
        text-transform: uppercase;
    }

    .ui-datepicker-calendar td {
        color: #455262;
        text-align: center;
        padding: 0;
    }

    .ui-datepicker-calendar td a, .ui-datepicker-calendar td span {
        padding: 3px 7px;
        width: 30px;
        display: inline-block;
    }

    .ui-datepicker-calendar td .ui-state-active {
        color: #fff;
        background: #027cb9;
        border-radius: 15px;
    }

    /*.all-events {
    padding: 16px 20px 16px 20px;
    border-bottom: 1px solid #DFE6EA;
    border-left: 1px solid #DFE6EA;
    border-right: 1px solid #DFE6EA;
    -webkit-border-bottom-right-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.all-events .btn {
    width: 100%;
    text-align: center;
    border: 1px solid #DFE6EA;
    border-radius: 3px;
    padding: 10px 0;
}*/

    .ui-datepicker-prev {
        float: left;
        cursor: pointer;
        margin-top: 0;
    }

    .ui-datepicker-next {
        float: right;
        cursor: pointer;
        margin-top: 0;
    }

    .ui-datepicker-title {
        text-align: center;
        font-size: 18px;
        margin-top: 4px;
    }

    /*.home-quicklinks {
    text-align: center;
}

.home-quicklinks a {
    text-align: center;
    color: #000;
    font-size: 16px;
    display: block;
    font-weight: 600;
}

.home-quicklinks a:hover {
    color: #008bd2;
}

.home-quicklinks span {
    text-align: center;
    display: inline-block;
    width: 61px;
    height: 61px;
}*/


    .right-section.right-menu.panel.panel-default.panel-search .float-right a {
        font-size: 17px;
    }

    .custom-control-label span {
        font-size: 15px;
        font-weight: 400;
        color: #8c8c8c;
    }

    @media (max-width: 767px) {
        /* .home-quicklinks .col-xs-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        margin-bottom: 30px;
    }
*/
        .newsletter span, .newsletter .input-group {
            display: block;
        }

        .newsletter {
            border-bottom: 0;
            margin-bottom: 0;
        }

        .newsletter span {
            margin-bottom: 20px;
        }

        .newsletter .input-group, .newsletter .input-group span {
            text-align: center;
        }

        .newsletter .btn {
            width: 100%;
            display: block;
        }

        .newsletter .input-group-addon {
            width: 100%;
        }

        .newsletter .input-group > .form-control {
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 100%;
            margin-bottom: 20px;
        }

        #contacts-section .col-lg-3.col-md-6 {
            margin-bottom: 35px;
        }

        #contacts-section .col-lg-3.col-md-6.g-mt-40 {
            margin-bottom: 0;
        }

        .footer-copy .d-none {
            display: block !important;
        }
    }

    @media (min-width: 400px) and (max-width: 767px) {
        .home-links .box b {
            width: 130px;
        }
    }

    @media (max-width: 960px) {
        .home-services .col-md-12 {
            text-align: center;
        }
    }

    @media (min-width: 992px) {
        .navbar-nav > .hs-mega-menu-opened > a:after {
            content: " ";
            display: inline-block;
            border: 10px solid #fff;
            border-bottom-color: #024f93;
            bottom: -36px;
            height: 0;
            left: 50%;
            margin-left: -11px;
            position: absolute;
            width: 0;
        }

        .hs-mega-menu {
            height: 400px !important;
        }

        .hs-mega-menu:after {
            content: " ";
            display: block;
            position: absolute;
            left: 50%;
            top: 0;
            height: 100%;
            width: 100vw;
            transform: translateX(-50%);
            z-index: -10;
            box-sizing: border-box;
            background: #274065;
        }

        .g-bg-kpas-megamenu-lightblue > .hs-has-sub-menu > ul.list-unstyled {
            height: 390px;
        }
    }

    .hs-mega-menu, .hs-sub-menu {
        margin-top: 0 !important;
    }

    .navbar-nav .nav-item > .nav-link {
        font-size: 20px;
    }


    #navBar {
        border-bottom: 3px solid #c6a171;
        margin-bottom: -1px;
    }

    .navbar-nav li {
        color: #fff;
        padding-bottom: 10px;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .navbar-nav li.active a {
        color: #c6a171 !important;
    }

    .navbar-nav li:hover {
        color: #c6a171;
    }

    .navbar-nav li.active .dropdown-menu a {
        color: #fff !important;
    }

    .navbar-nav li.active .dropdown-menu a:hover {
        color: #fff !important;
        text-decoration: underline !important;
    }

    .navbar-nav li ul li:hover {
        border-bottom: 0;
    }

    .navbar-nav li ul li.active {
        width: auto;
        text-align: left;
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }




    .navbar-nav li ul li {
        width: auto;
        border-right: 0;
        border-bottom: 0;
        text-align: left;
    }

    .navbar-nav li ul li a {
        width: auto;
        border-right: 0;
        text-align: left;
    }

    .navbar-nav li:last-child {
        border-right: 0;
    }

    /*.slider-border {
    border: 1px solid #DFE6EA;
    border-radius: 4px;
    margin-top: 25px;
}

.slider-border .col-sm-3 ul li {
    border-bottom: 1px solid #DFE6EA;
    padding: 15px 25px;
    display: block;
}

.slider-border .col-sm-3 a {
    font-size: 16px;
    font-weight: 600;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.slider-border .col-sm-3 a:hover {
    color: #008bd2;
}

.slider-border ul li:last-child {
    border-bottom: 0;
}*/

    .carousel-inner img {
        -webkit-border-top-left-radius: 4px;
        -webkit-border-bottom-left-radius: 4px;
        -moz-border-radius-topleft: 4px;
        -moz-border-radius-bottomleft: 4px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    h1 {
        font-size: 40px;
        font-weight: 700 !important;
        color: #2b3b73;
        margin-bottom: 35px;
        font-family: 'Work Sans';
    }

    .bread-crumb {
        font-size: 16px;
        color: #2b2e36;
        margin-bottom: 50px;
    }

    .bread-crumb li {
        background: url(../../../assets/img/arrowbc.svg) no-repeat right center;
        background-size: 11px 7px;
        padding-right: 22px;
        margin-right: 6px !important;
    }

    .bread-crumb li:last-child {
        background: none;
        padding-right: 22px;
    }

    article .h5 {
        font-weight: 600;
    }

    .bread-crumb a {
        color: #2b2e36 !important;
        text-decoration: none !important;
    }

    .bread-crumb span {
        color: #d7dad1 !important;
        margin-left: 3px;
    }

    .bread-crumb a:hover {
        text-decoration: underline !important;
    }

    .rounded-4 {
        border-radius: 4px;
    }

    .articles h2 {
        font-size: 24px;
    }



    .date {
        color: #575766;
        display: inline-block;
        padding-bottom: 3px;
        font-size: 14px !important;
    }

    .slick-next.slick-arrow {
        background: url("../../../assets/img/icons/slider_right.svg") no-repeat;
        width: 46px;
        height: 46px;
        top: -47px;
        right: 211px;
    }

    .slick-next.slick-arrow.slick-disabled {
        background: url("../../../assets/img/icons/slider_right_off.svg") no-repeat;
        cursor: default;
    }

    .slick-prev.slick-arrow {
        background: url("../../../assets/img/icons/slider_left.svg") no-repeat;
        width: 46px;
        height: 46px;
        top: -47px;
        right: 266px;
        left: auto;
    }

    .slick-prev.slick-arrow.slick-disabled {
        background: url("../../../assets/img/icons/slider_left_off.svg") no-repeat;
        cursor: default;
    }

    .home-notes .slick-next.slick-arrow {
        right: 195px;
    }

    .home-notes .slick-prev.slick-arrow {
        right: 251px;
    }

    .home-events .slick-next.slick-arrow {
        right: 215px;
    }

    .home-events .slick-prev.slick-arrow {
        right: 270px;
    }

    .articles .col-lg-4 {
        margin-bottom: 60px;
    }

    .event-page.articles .col-lg-4 {
        margin-bottom: 30px;
    }


    .articles .col-lg-4 .date {
        padding-top: 214px;
        color: #c6a171;
        font-weight: 700;
        font-size: 20px !important;
        display: block;
    }

    .event-page.articles .col-lg-4 .date {
        padding-top: 0 !important;
        color: inherit !important;
        font-size: 36px !important;
    }

    .home-events article.event-page .date {
        font-size: 36px !important;
        line-height: 36px !important;
    }


    #ui-datepicker-div {
        background: #f2f2f2;
    }

    .ui-datepicker-calendar th, .ui-datepicker-calendar td {
        background: #fff;
        padding: 1px 3px;
        border-top: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
    }

    .ui-datepicker-calendar th {
        font-weight: 600;
        font-size: 16px;
    }

    .ui-datepicker-calendar td:hover {
        background: #c6a171;
    }

    .ui-datepicker-prev.ui-corner-all span, .ui-datepicker-next.ui-corner-all span {
        display: none;
    }

    .ui-datepicker-prev.ui-corner-all:after {
        content: '<';
        font-weight: 600;
        font-size: 19px;
    }

    .ui-datepicker-next.ui-corner-all:after {
        content: '>';
        font-weight: 600;
        font-size: 19px;
    }

    .home-events .slick-next.slick-arrow, .home-events .slick-prev.slick-arrow, .home-notes .slick-next.slick-arrow, .home-notes .slick-prev.slick-arrow {
        top: -62px;
    }

    .slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
        border: 0 !important;
    }

    .home-notes {
        background: #f8f8f8;
        padding: 70px;
    }

    .home-notes article {
        background: #fff;
        padding: 20px;
        min-height: 200px !important;
    }

    .home-notes article a {
        font-size: 20px;
        display: block;
        font-weight: 600;
        line-height: 1.4em;
        min-height: 108px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .home-notes article a:hover {
        color: #21347b;
    }

    .home-events {
        background: #fff;
        padding: 70px 0 30px 0;
    }

    .home-events article {
        background: #f8f8f8;
        padding: 20px;
        min-height: 200px !important;
    }

    .home-events article a {
        font-size: 24px;
        display: block;
        font-weight: 600;
        color: inherit;
        line-height: 1.4em;
        min-height: 101px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .event-culture, .event-culture-sport {
        color: #62c2d0;
    }

.event-samosprava, .event-government {
    color: #73a4f5;
}

.event-udalosti, .event-events {
    color: #f58273;
}

    .home-events article .date, .home-events article a {
        color: inherit;
    }

    .home-events span {
        display: block;
    }

    .home-events article .time {
        color: #5a5a5a;
        padding-top: 15px;
        font-size: 18px;
    }

    .event-culture article {
        border-top: 3px solid #62c2d0;
    }

    .event-samosprava article {
        border-top: 3px solid #f58373;
    }

    .event-udalosti article {
        border-top: 3px solid #88bb96;
    }

    .home-events article .date {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    .home-events article .category {
        font-size: 16px;
        padding-bottom: 15px;
        font-weight: 500;
        text-transform: uppercase;
    }

    .home-events article a {
        font-weight: 700;
    }

    .slick-next.slick-disabled, .slick-prev.slick-disabled {
        opacity: 1;
    }

    .slick-dots li button::before, .slick-next::before, .slick-prev::before {
        display: none;
    }

    header .float-right a {
        text-decoration: none;
        line-height: 1.0em;
        display: block;
        font-size: 24px;
        font-weight: 700;
        margin-top: 21px;
    }

    header .float-right a {
        text-decoration: none;
        border-bottom: 0;
    }

    header .float-right a:hover {
        text-decoration: underline;
        color: #21347b;
    }

    .file-doc:hover {
        text-decoration: none;
    }

    .event-list .date {
        vertical-align: middle;
    }

    .search-term {
        font-size: 18px;
    }

    .event-type {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3em;
        border-radius: 3px;
        text-transform: uppercase;
        padding: 1px 7px;
        margin-left: 10px;
        vertical-align: top;
        margin-top: 5px;
        display: inline-block;
    }

    .articles p {
        color: #455262;
        font-size: 16px;
        line-height: 1.8em;
    }

    .right-section {
        margin-bottom: 30px;
    }

    .right-section .panel-body {
        padding: 10px 0 10px 10px !important;
    }


    .right-section ul li .dropdown-toggle::after {
        content: "\f107";
        font: 14px/1 FontAwesome !important;
        margin-top: 0;
        float: right;
        text-rendering: auto;
    }

    .right-section ul li.show.active .dropdown-toggle::after {
        content: "\f106";
    }

    .right-section ul li.show .dropdown-toggle::after {
        content: "\f106";
    }


    .right-section ul li.show ul li .dropdown-toggle::after {
        content: "\f107" !important;
    }

    .right-section ul li.show ul li.show .dropdown-toggle::after {
        content: "\f106" !important;
    }



    .right-section ul li ul li .dropdown-toggle::after {
        margin-right: -15px;
    }

    .right-section ul li {
        margin: 0;
        padding: 0;
    }

    .right-section ul li.level3 {
        margin: 0;
        padding: 0;
        list-style: square disc !important;
    }

    .right-section ul li ul li a {
        padding-top: 0 !important;
        padding-bottom: .57143rem !important;
    }

    .side-menu {
        font-size: 16px;
    }


    .side-menu a:hover {
        text-decoration: underline !important;
    }

    .side-menu ul li, .side-menu ul {
    }

    .side-menu ul {
        padding-left: 20px;
    }

    .panel-search {
        padding: 0 0 20px 0;
    }

    .panel-search .btn, .panel-search select, .panel-search input {
        margin-right: 20px;
    }

    .btn {
        text-transform: uppercase;
    }

    .input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after, .input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .panel-search .btn, .panel-search .form-control {
        padding: .575rem .65rem;
    }

    .panel-search .btn {
        font-weight: 700;
    }

    .panel-search select.form-control {
        padding: .775rem .65rem;
    }

    .panel-search input.form-control {
        padding: .675rem .65rem .505rem;
    }

    .form-control.col-sm-4 {
        height: auto;
    }

    .panel-search .btn {
        padding: .475rem .65rem;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-decoration: none !important;
    }

    .form-control {
        border-color: #bebebe;
        font-size: 16px;
    }

    .line-item {
        border-bottom: 1px solid #DFE6EA;
        padding: 15px 0;
        font-size: 18px;
    }

    .line-item span {
        text-transform: uppercase;
    }



    .description {
        border-bottom: 1px solid #DFE6EA;
        padding: 0 0 10px 0;
        margin-bottom: 25px;
    }


    .staticpage ol, .staticpage ul {
        margin-bottom: 25px;
    }

    .staticpage ol li, .staticpage ul li {
        margin-bottom: 13px;
    }

.staticpage ul li::marker {
    font-size: 13px;
    color: #c6a171;
}

    .hs-has-sub-menu div {
        width: 25%;
        text-align: left;
        color: #fff;
    }

    .hs-has-sub-menu div strong {
        font-size: 16px;
        display: block;
        margin-bottom: 6px;
        padding-top: 15px;
    }

    .navbar-expand-lg .navbar-nav ul li .nav-link {
        padding: 5px 25px 5px 0 !important;
        font-size: 15px;
    }

.u-header {
    width: 100%;
    position: relative;
    z-index: 1050;
}

    .control-label {
        color: #22211f;
        text-transform: uppercase;
    }

.align-bottom .col-sm-4, .align-bottom .col-sm-6 {
    align-items: flex-end;
    align-self: flex-end;
}

    table.table th {
        background: #f1f5fd;
        text-transform: uppercase;
        border-top: 0;
        border-bottom: 0;
        font-size: 18px;
        font-weight: 600;
        padding: 9px 20px;
    }


    table.table td {
        border-top: 0;
        padding: 9px 20px;
        font-size: 18px;
        font-weight: 500;
        border-bottom: 0;
    }

    table.table a {
        color: #2b3b73;
        text-decoration: none;
    }

    table.table a:hover {
        color: #2b3b73;
        text-decoration: underline;
    }

    .table-striped tbody tr:nth-of-type(2n+1) {
        background-color: #fff;
    }

    table.table tr:nth-child(even) {
        background: #f4f4f4;
    }

    .right-section.right-menu .nav {
        display: block;
    }

    .right-section.right-menu .nav .active {
        font-weight: 600 !important;
    }

    .ui-datepicker-title select {
        margin-left: 4px;
        margin-right: 4px;
    }

    body {
        font-size: 18px;
    }

    .right-section ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .right-section ul li {
        margin: 0 0 6px 0;
        padding: 0;
    }

    .slider-tabs {
        height: 50px;
        margin-bottom: 30px;
        text-transform: uppercase;
        border-bottom: 4px solid #f8f8f4;
    }

    .slider-tabs .slick-active {
    }

    .slider-tabs div a {
        padding: 7px 20px;
        font-family: 'Work Sans';
        font-size: 1.1em;
        height: 50px;
    }

    .slider-tabs div a:focus {
        border-left: 0 !important;
        border-right: 0 !important;
        border-top: 0 !important;
        border-bottom: 4px solid #e7f2fd !important;
    }

    .slider-tabs div a.active {
        font-family: 'Work Sans';
        height: 50px;
        border-bottom: 4px solid #e7f2fd;
    }

.staticpage a.box-link, .box-link {
    font-size: 20px;
    font-weight: 700;
    color: #21347b;
    font-family: 'Work Sans';
    display: block;
    padding: 32px 28px 22px 18px;
    background: #f1f5fd;
    height: 100%;
    text-decoration: none !important;
    border-left: 4px solid #f1f5fd;
    line-height: 1.3em;
    width: 100%;
    text-decoration: none !important;
}

.staticpage a.box-link p {
    line-height: 1.1em;
}

.staticpage a.box-link:hover, .box-link:hover {
    border-left: 4px solid #2b3b73;
    background: #f1f5fd;
}

    .staticpage a.box-link span, .box-link span {
        display: inline-block;
        text-decoration: none !important;
    }

    .staticpage a.box-link span, .box-link:hover span {
        text-decoration: none !important;
    }

    .box-link p {
        font-family: Work Sans;
        font-size: 16px;
        margin-bottom: 0;
        margin-top: 6px;
        font-weight: 400;
    }

    .box-link:hover {
        text-decoration: none;
        background: #f5f5e4;
    }
    /*.box-link::after {
    content: "\f054";
    font-weight: 400;
    float: right;
    font-size: 14px;
    margin-top: 4px;
    font-family: "Font Awesome 5 Pro";
}
*/

    .pagination .disabled svg .arrowpath {
        fill: #f4f4f4 !important;
    }

    .paginationBox {
        text-align: center;
        font-size: 16px;
        margin: 0 0 25px 0;
        padding-top: 30px;
    }

    .paginationBox a {
        display: inline-block;
        padding: 0px 15px;
        color: #2b3b73;
        margin: 0 5px;
        font-weight: 600;
        font-size: 20px;
        text-decoration: none !important;
        border-radius: 4px;
    }

    .paginationBox .arrow.prev.disabled, .disabled.arrow.prev {
        padding: 0px 15px;
    }

    .paginationBox .active a {
        background: #f4f4f4;
        border-radius: 3px;
        color: #2b3b73;
    }

    .paginationBox a:hover {
        color: #2b3b73;
        text-decoration: underline;
    }

    .paginationBox .active a:hover {
        color: #2b3b73;
        text-decoration: underline;
    }

    .pagination, .pagination li {
        display: inline-block;
    }

    .pagination {
        svg {
            width: 25px;

            .arrowpath {
                fill: #2b3b73;
            }
        }

        .disabled {
            svg {
                .arrowpath {
                    fill: gray;
                }
            }
        }
    }

    .box-object {
        display: block;
        padding: 40px 30px;
        background: #f8f8f4;
        border-radius: 4px;
    }

    .box-object3 {
        display: block;
        padding: 10px 15px;
        background: #f8f8f4;
        border-radius: 4px;
    }

    .box-object3 .btn-download {
        display: block;
        padding: 1px 10px;
        display: inline-block;
        background-color: #e7f2fd;
        border-radius: 4px;
        border-color: #e7f2fd;
        color: #22211f;
        width: auto !important;
        text-transform: uppercase;
        font-size: 14px;
    }

    .box-object3 p {
        margin-bottom: 6px;
        font-size: 14px;
    }

    .box-object3 p a {
        font-size: 14px;
    }

    .box-object3 a {
        font-size: 18px;
    }

    .navbar-expand-lg .navbar-collapse {
        position: relative;
        z-index: 500;
    }

    .hs-mega-menu::after {
        left: calc(50% - 9px);
    }


    .w-100.hs-mega-menu.u-shadow-v2.g-text-transform-none.hs-position-right {
        top: 56px;
    }

    .navbar-nav li:first-child div div div div div:last-child {
        width: 50%;
    }

    .navbar-nav li:first-child div div div div div:last-child li {
        width: 50%;
        float: left;
    }

    .related-content table.table td {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }

   /* .related-content h4 {
        margin-bottom: 0 !important;
    }*/

    .related-content table {
        margin-bottom: 10px !important;
    }

    .divimage {
        -webkit-box-shadow: inset 0px -119px 21px -27px rgba(0,0,0,0.56);
        -moz-box-shadow: inset 0px -119px 21px -27px rgba(0,0,0,0.56);
        box-shadow: inset 0px -119px 21px -27px rgba(0,0,0,0.56);
    }

    .g-pt-200 {
        padding-top: 260px !important;
    }

    .carousel-item h2 {
        line-height: 1.2em !important;
        font-weight: 600 !important;
        padding-left: 20px !important;
    }

    .news-image {
        background-repeat: no-repeat;
        background-size: cover;
        width: 325px;
        height: 230px;
        background-position: center;
    }

    .home-news .col-sm-12 {
        padding-top: 30px;
    }

    .staticpage img {
        max-width: 100%;
    }

blockquote.hero {
    border-left: 2px solid #2b3b73;
    color: #2b3b73;
    font-style: normal;
    padding: 0 0 0 15px;
    margin: 0 0 0 30px;
}

    .carousel-indicators {
        left: auto;
        margin-right: 25px;
        margin-left: 0;
    }

    .slide-mobile {
        padding-bottom: 50px;
    }

    .slide-mobile a {
        display: block;
        padding: 10px 0 0 0;
        font-size: 18px;
        text-decoration: underline;
    }

    .slide-mobile .carousel-indicators {
        left: 0;
        margin-right: 0;
        margin-left: 0;
        bottom: 0;
    }

    .slide-mobile .carousel-indicators li {
        background-color: #ccd7de;
        height: 8px;
    }

    .slide-mobile .carousel-indicators .active {
        background-color: #274065;
    }

    .slick-list .date {
        padding-top: 214px;
        color: #c6a171;
        font-weight: 700;
        font-size: 20px !important;
        display: block;
    }

    .home-events .slick-list .date, .home-notes .slick-list .date {
        padding-top: 0px;
    }

    .sitemap ul {
        list-style-type: none;
        padding-left: 0;
        margin-left: 0;
    }

    .sitemap ul li {
        background-color: #f3e8d5;
        border-bottom: 1px solid #fff;
    }

    .sitemap ul li:last-child {
        border-bottom: 0;
    }

    .sitemap ul li a {
        display: inline-block;
        font-weight: 600;
        padding: 6px 20px 6px 20px;
    }

    .sitemap ul li ul li a {
        font-weight: 300;
    }

    .sitemap ul li ul li ul li a {
        font-weight: 300;
    }

    .sitemap ul li ul li a {
        padding: 6px 20px 6px 40px;
    }

    .sitemap ul li ul li ul li a {
        padding: 6px 20px 6px 60px;
    }

    .sitemap ul li ul li ul li ul li a {
        padding: 6px 20px 6px 80px;
    }

    .sitemap ul li ul li ul li ul li ul li a {
        padding: 6px 20px 6px 100px;
    }

    .sitemap ul ul li {
        background-color: #f4f4f4;
    }

    .sitemap ul ul ul li {
        background-color: #fff;
    }

    .sitemap ul ul ul ul li {
        background-color: #fff;
    }

    .sitemap ul ul ul ul ul li {
        background-color: #fff;
    }

    .file-doc i {
        color: #565656;
    }

    .file-doc i.fa-file-pdf {
        color: #e30613;
    }

    .file-doc i.fa-file-rtf, .file-doc i.fa-file-doc, .file-doc i.fa-file-docx {
        color: #2b2e80;
    }

    .file-doc i.fa-file-xls, .file-doc i.fa-file-xlsx {
        color: #2b802d;
    }

    .fa-file-docx::before, .fa-file-doc::before {
        content: "\f1c2";
    }

    .fa-file-xlsx::before, .fa-file-xls::before {
        content: "\f1c3";
    }

    .fa-file-pptx::before, .fa-file-ppt::before {
        content: "\f1c3";
    }

    .advanced.btn {
        color: #212529;
        background-color: #f8f9fa;
        border-color: #f8f9fa;
    }

    @media (max-width: 401px) {
        .home-links .box {
            padding: 21px 15px;
            height: 90px;
        }

        .image-slider {
            background-color: #f3f3f3 !important;
            text-align: center;
        }

        .panel-search .btn, .panel-search .form-control {
            padding: .475rem .95rem;
        }

        .form-group {
            margin-bottom: 0.4rem;
        }

        .home-links .g-mb-30 {
            margin-bottom: 1.44286rem !important;
        }

        .home-links .box strong {
            margin-top: 0.8rem !important;
            display: inline-block;
        }

        .article-detail h3 {
            min-height: inherit;
        }

        .panel-search .form-control {
            margin-bottom: 8px;
            margin-right: 0;
        }

        .panel-search .btn.btn-primary {
            margin-bottom: 8px;
            margin-right: 0;
        }

        .panel-search .btn.btn-light {
            width: 70%;
            display: inline-block;
            margin-bottom: 8px;
            margin-right: 20px;
            padding: .375rem .95rem;
        }

        .panel-search .advanced.btn {
            width: 23%;
            display: inline-block;
            margin-bottom: 8px;
            margin-right: 0;
            padding: .375rem .95rem;
        }

        .panel-search .advancedSearchBox .form-control {
            width: 100%;
            display: block;
            margin-bottom: 8px;
        }
    }

    .show-mobile, .show-mobile .right-menu {
        display: none;
    }

    @media (max-width: 767px) {

        .show-mobile, .show-mobile .right-menu {
            display: block !important;
        }

        .bread-crumb {
            margin-bottom: 10px;
        }

        .wrapper.g-pt-45 {
            padding-top: 2.21429rem !important;
        }

        h1 {
            margin-bottom: 15px;
        }

        .panel-search {
            padding: 0 0 0;
        }

        .filter-label {
            padding: 0 !important;
        }

        .article-detail h3 {
            line-height: 1.1em !important;
            min-height: 1px !important;
            overflow: unset !important;
            text-overflow: unset !important;
            display: block !important;
            -webkit-line-clamp: unset !important;
            -webkit-box-orient: unset !important;
            margin-bottom: 10px !important;
        }

        .table-responsive {
            z-index: 1;
            position: relative;
            padding-top: 30px;
        }

        .table-responsive::after {
            display: block;
            width: 24px;
            height: 24px;
            content: " ";
            background: url(/Content/assets/img/icons/swipe.png) 0 0 no-repeat;
            position: absolute;
            top: 1px;
            right: 5px;
            z-index: 300;
        }

        .navbar-brand {
            margin-left: 15px !important;
        }
        /*.bread-crumb,*/
        .right-menu {
            display: none !important;
        }

        .bg-menu .container {
            padding-right: 0;
            padding-left: 0;
        }

        .bg-menu {
            border-bottom: 0;
            height: 0;
        }

        .navbar-nav li {
            width: 100%;
            text-align: left !important;
            padding: 0 15px !important;
            border-bottom: 1px solid #3b577f;
            border-right: 0 !important;
        }


        .hs-mega-menu, .hs-sub-menu {
            background: rgba(51, 77, 115, 1.0) !important;
        }

        .hs-has-sub-menu div {
            width: 100%;
            display: block;
            float: none;
            background: rgba(51, 77, 115, 1.0) !important;
        }

        .hs-mobile-state .hs-mega-menu, .hs-mobile-state .hs-sub-menu {
            position: relative !important;
            visibility: visible;
            top: 0 !important;
            background: rgba(51, 77, 115, 1.0) !important;
            left: 0 !important;
        }

        .hs-has-sub-menu.d-flex {
            display: block !important;
        }

        .navbar-nav li:first-child div div div div div:last-child {
            width: 100%;
        }

        .navbar-nav li:first-child div div div div div:last-child li {
            width: 100%;
            float: none;
        }

        .g-bg-size-cover {
            display: none !important;
        }

        .hs-mega-menu .col-lg-3.g-mb-30 {
            margin-bottom: 0 !important;
        }

        #contacts-section {
            padding-top: 25px !important;
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }

    .masonry-grid-item img.u-block-hover__main--mover-down {
        min-height: 220px;
    }

    .header-search {
        width: 705px;
    }

    .share-icons {
        border-top: 1px solid #f1e7db;
        border-bottom: 1px solid #f1e7db;
        padding: 25px 0 25px 0;
        margin-top: 20px;
    }

    .share-icons b {
        vertical-align: top;
        padding-right: 10px;
        margin-top: 5px;
        display: inline-block;
        color: #000;
    }

    .flag-icon-en {
        background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.2.1/flags/4x3/us.svg) !important;
    }

    .share-icons a {
        display: inline-block;
    }

    .side-menu li.active > a {
        font-weight: 600 !important;
    }

    @media (max-width: 1100px) {
        .header-search {
            width: 270px;
        }
    }

    @media (max-width: 991px) {


        #navBar {
            background: #2c407d;
            margin-top: 1px;
        }

        .navbar-expand-lg .navbar-toggler {
            display: block;
        }

        .language-header {
            top: 40px;
            right: 93px;
        }

        .u-header {
            border-bottom: 1px solid #555b7e;
        }
        /*.bread-crumb {
        display: none !important;
    }*/
        .bg-menu {
            border-bottom: 0;
            background: #2c407d;
            height: auto !important;
        }

        .bg-menu .container {
            padding-right: 0;
            padding-left: 0;
        }

        .bg-menu {
            border-bottom: 0;
            height: 0;
        }

        .navbar-nav li {
            width: 100%;
            text-align: left !important;
            padding: 0 15px !important;
            border-bottom: 1px solid #3b577f;
            border-right: 0 !important;
        }

        .hs-mega-menu, .hs-sub-menu {
            background: rgba(51, 77, 115, 1.0) !important;
        }

        .hs-has-sub-menu div {
            width: 100%;
            display: block;
            float: none;
            background: rgba(51, 77, 115, 1.0) !important;
        }

        .hs-mobile-state .hs-mega-menu, .hs-mobile-state .hs-sub-menu {
            position: relative !important;
            visibility: visible;
            top: 0 !important;
            background: rgba(51, 77, 115, 1.0) !important;
            left: 0 !important;
        }

        .hs-has-sub-menu.d-flex {
            display: block !important;
        }

        .navbar-nav li:first-child div div div div div:last-child {
            width: 100%;
        }

        .navbar-nav li:first-child div div div div div:last-child li {
            width: 100%;
            float: none;
        }

        .g-bg-size-cover {
            display: none !important;
        }

        .hs-mega-menu .col-lg-3.g-mb-30 {
            margin-bottom: 0 !important;
        }

        .hs-mobile-state .hs-mega-menu, .hs-mobile-state .hs-sub-menu {
            position: relative !important;
            visibility: visible;
            top: 0 !important;
            background: #334d73 !important;
            left: 0 !important;
        }

        .navbar-nav li:hover {
            border-bottom: 1px solid #3b577f;
        }

        .dropdown-menu {
            min-width: auto;
        }

        .articles h2 {
            font-size: 17px;
        }

        .masonry-grid-item img.u-block-hover__main--mover-down {
            min-height: 184px;
        }
    }

    .navbar-collapse.collapse.show .form-control {
        height: 50px !important;
    }


    @media (max-width: 574px) {

        .language {
            position: absolute !important;
            margin: 0 !important;
            top: 15px !important;
            right: 57px !important;
        }

        .print-text span {
            display: none !important;
        }
    }

    @media (max-width: 991px) {


        .hs-has-sub-menu div strong {
            padding-left: 15px !important;
        }

        .navbar-nav li.active {
            color: #c6a171;
        }

        .navbar-nav li, .navbar-nav li.active {
            width: 100%;
        }

        .navbar-collapse.collapse.show .form-control {
            height: 39px !important;
        }
    }

    @media (max-width: 991px) {

        .navbar-nav > li > a {
            padding-left: 0 !important;
            padding-right: 15px !important;
        }

        .dropdown-menu {
            padding: 15px 15px 0;
            border-radius: 0;
        }

        .navbar-nav li a {
            font-size: 1.2em !important;
        }

        .u-header {
            border: 0 !important;
        }

        


        .dropdown-item, .dropdown-item:hover {
            margin-bottom: 13px !important;
        }

        .navbar-nav li li a {
            font-size: 1.1em !important;
        }

        .navbar-nav ul {
            min-width: 100% !important;
        }

        .hs-has-sub-menu .hs-has-sub-menu.hs-sub-menu-opened > a::after {
            content: "\e900";
            position: absolute;
            top: 50%;
            right: 0;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            color: inherit;
            background: 0 0 !important;
        }



        .dropdown-item:focus, .dropdown-item:hover {
            background: inherit;
        }
    }





    @media (min-width: 768px) and (max-width: 991px) {
        .navbar-toggler.g-top-10 {
            top: 24px !important;
        }

        .min-height-article {
            min-height: 280px;
        }

        .article-detail h3 {
            line-height: 1.1em !important;
            min-height: 87px !important;
        }

        .sub-news .article-detail h3 {
            line-height: 0.9em !important;
            min-height: 90px !important;
            -webkit-line-clamp: 4;
        }
    }

    @media (min-width: 992px) {
        .navbar-nav li ul li a {
            background: #274065;
        }
    }

    @media (max-width: 400px) {
        .box-object img {
            margin-bottom: 20px;
        }
    }

    @media (max-width: 767px) {


        h1 {
            font-size: 36px;
        }

        .sub-news .article-detail h3 {
            line-height: 0.9em !important;
            min-height: 1px !important;
            -webkit-line-clamp: unset;
        }

        .sub-news .article-detail {
            padding: 20px 20px;
        }

        .sub-news.home-news .col-sm-12 {
            padding-top: 0 !important;
        }

        .sub-news.home-news .container {
            padding-right: 0;
            padding-left: 0;
        }
    }

.sub-news.home-news .col-sm-12 {
    padding-top: 0 !important;
}

    .collapse.navbar-collapse label {
        display: none;
    }

    .collapse.navbar-collapse label {
        display: none;
    }

    .hamburger-box {
        width: 30px;
        height: 25px;
    }

    .list-block label {
        display: block !important;
        font-size: 18px !important;
    }

    .list-block label input {
        margin-right: 10px;
    }

    .custom-control {
        padding-left: 2rem;
    }

    .custom-control-label {
        font-size: 17px !important;
    }

    .custom-control-label::before {
        border: 1px solid #b9b9b9;
        background-color: #fff;
    }

    .custom-control-label::after, .custom-control-label::before {
        position: absolute;
        top: .25rem;
        left: -2rem;
        display: block;
        width: 1.4rem;
        height: 1.4rem;
        content: "";
    }

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
        background-color: #2b3b73;
        border: 0;
    }

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
        background-image: none;
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        font-size: 12px;
        color: #fff;
        margin-left: 4px;
    }

    .ui-datepicker-prev, .ui-datepicker-next {
        font-size: 14px;
    }

    @media (min-width: 576px) and (max-width: 768px) {
        .navbar-toggler.g-top-10 {
            top: 21px !important;
        }

        .navbar-brand {
            margin: 15px 0 0 8px;
        }

        .logo-mobile.g-width-150 {
            width: 190px !important;
        }

        .home-links {
            padding: 40px 0 20px;
        }

        .language-header {
            top: 35px;
        }

        
    }

    .event-mobile {
        display: none;
    }

    @media (min-width: 575px) and (max-width: 991px) {
        .language {
            top: 26px !important;
            right: 57px !important;
            position: absolute !important;
        }

        .hasDatepicker {
            padding: 4px 20px;
        }


        #contacts-section.g-py-30--md {
            padding-bottom: 1.14286rem !important;
        }



        .newsletter.g-py-0 {
            padding-bottom: 1.54286rem !important;
        }


        .home-quicklinks .col-xs-6 {
            padding: 0 0 25px 0;
        }

        .slider-border .col-sm-3 ul li {
            padding: 15px 20px;
        }

        .carousel-inner .g-pt-200 {
            padding-top: 200px !important;
        }
    }

    @media (max-width: 575px) {
        .event-mobile {
            display: block !important;
        }

        .search-top {
            display: none !important;
        }

        .header-search {
            visibility: visible !important;
        }

        .header-search {
            width: 69% !important;
            margin-left: 0 !important;
            padding-left: 20px !important;
            padding-right: 30px !important;
        }

        .navbar-brand {
            display: flex !important;
        }

        .slick-list .container {
            width: 100%;
            padding-right: 0;
            padding-left: 0;
            margin-right: auto;
            margin-left: auto;
        }

        .btn-more, .home-info a, .home-notes article a, .home-events article a {
            font-size: 24px !important;
        }

        .home-events article .date {
            font-size: 29px !important;
            line-height: 29px !important;
        }

        .home-events article .time {
            padding-top: 11px;
            font-size: 20px;
        }

        .slide-mobile a {
            min-height: 91px !important;
        }

        .releted-contacts {
            text-align: center !important;
        }

        .releted-contacts .box-object {
            padding: 20px 10px;
        }

        .releted-contacts .box-object h3 {
            margin-top: 10px;
        }

        .navbar-toggler.g-top-10 {
            top: 31px !important;
        }

        .navbar-brand {
            margin: 15px 0 0 8px;
        }

        .logo-mobile.g-width-150 {
            width: 190px !important;
        }

        .language-header {
            top: 35px;
        }

   
    }


    @media print {
        header, .content .col-md-4, .newsletter, #contacts-section, #disqus_thread {
            display: none !important;
        }

        .content .col-md-8 {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }

        .paginationBox a {
            border: 0;
            border-radius: 0;
        }
    }
    /*fix menucka*/
    @media (min-width: 992px ) {

        .js-mega-menu.navbar.navbar-expand-lg {
            color: white;

            .dropdown-item:focus, .dropdown-item:hover {
                background: transparent;
            }

            .g-bg-kpas-megamenu-lightblue {
                flex: none;
                max-width: unset;
            }

            .hs-has-sub-menu .hs-has-sub-menu > a::after {
                content: none;
            }

            ul[data-level="0"] {
                display: flex;
                pointer-events: none;

                & > li {
                    flex: 1;
                    //max-width: 25%;
                    & > a.nav-link {
                        font-size: 16px !important;
                        display: block;
                        margin-bottom: 3px;
                        padding-top: 15px !important;
                        font-weight: bolder;

                        &:active {
                            color: red;
                        }
                    }
                }
            }

            li.hs-mega-menu-opened[data-level="0"] ul[data-level="1"] {
                pointer-events: all;
                display: flex !important;
                flex-direction: column;
                flex-wrap: wrap;
                position: relative !important;
                left: auto !important;
                right: auto !important;
                visibility: visible;
                max-height: 350px;

                & > li {
                    //width: 100%;
                    min-width: 50%;
                }
            }

            ul[data-level="2"], ul[data-level="3"], ul[data-level="4"] {
                display: none !important;
            }
        }
    }

    .footer-copy a {
        text-decoration: underline !important;
    }


    .language span {
        text-transform: uppercase;
        font-size: 0.8em !important;
        color: #274065;
    }

    .language .dropdown-toggle::after {
        color: #274065;
        margin-left: .2rem;
    }

    .staticpage table {
        width: 99%;
        margin-bottom: 1rem;
        background-color: transparent;
    }

    .staticpage table td {
        border-top: 0;
        padding: 10px 20px;
        font-size: 16px;
        border-bottom: 1px solid #dfe6ea;
    }

    .staticpage table th {
        background: #e7f2fd;
        color: #242620;
        text-transform: uppercase;
        border-top: 0;
        border-bottom: 0;
        font-size: 14px;
        padding: 10px 20px;
    }

    .staticpage table tr:nth-child(2n) {
        background: #f8f8f4;
    }

    .article-detail h3 {
        line-height: 1.4em;
        font-size: 24px;
        min-height: 73px;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .article-detail h3 a:hover {
        color: #fff !important;
        text-decoration: underline !important;
    }

    .slick-dots li button::before {
        font-size: 14px !important;
        color: #e2cfa6;
        opacity: .45;
    }

    .slick-dots li.slick-active button::before {
        opacity: 1;
        color: #e2cfa6;
    }

    .home-events .article-detail h3 {
        min-height: 68px;
        -webkit-line-clamp: 2;
        line-height: 1.1em !important;
    }

    .pagination li {
        margin-bottom: 10px;
    }

    @media (max-width: 700px ) {

        .contact-item .col-xs-3 .g-width-100 {
            height: 50px !important;
            width: 50px !important;
        }

        .contact-item .col-xs-9 {
            width: 76% !important;
        }
    }

    .staticpage img {
        height: auto !important;
        max-width: 100% !important;
    }

    .hs-has-sub-menu a:hover, .right-menu .dropdown-menu a:hover {
        text-decoration: underline !important;
    }



    .event-type {
        background: #f8f8f8 !important;
        color: #a6a7a9 !important;
    }



    @media print {
        #cconsent-bar, .share-icons, footer {
            display: none !important;
        }

        .header-print {
            display: block !important;
            border-bottom: 1px solid #000 !important;
            margin-bottom: 15px !important;
            padding-bottom: 15px !important;
            border-bottom: 2px solid #ddd !important;
        }

        .header-print p {
            margin-bottom: 0 !important;
        }

        footer .col-md-12, footer .col-md-12 a {
            color: #000 !important;
        }

        .header-print h1 {
            font-size: 22px !important;
            padding-top: 10px !important;
        }
    }


    @media (max-width: 700px ) {

        

        .pagination li.arrow a, .pagination li.active a {
            display: inline-block !important;
        }
    }

    .btn-primary:focus {
        color: #22211f;
    }

    .articles h2 a.u-link-v5 {
        text-decoration: none !important;
    }

    .articles h2 a.u-link-v5:hover {
        text-decoration: underline !important;
    }

    .staticpage a {
        text-decoration: underline;
    }

    .right-section h3 {
        padding-bottom: 3px;
        font-size: 24px !important;
        color: #2b3b73;
        margin-bottom: 15px !important;
    }

    .right-section h3.subtitle {
        padding-bottom: 3px;
        margin-top: 68px;
        font-size: 24px !important;
        color: #c6a171;
        font-weight: 700;
        margin-bottom: 35px !important;
    }

    .right-section h3 .float-right {
        font-size: 16px !important;
        margin-top: 5px !important;
    }

    .right-section h3 i {
        font-size: 14px !important;
        color: #2b3b73;
        padding-left: 7px;
        vertical-align: baseline;
    }


    .right-section .fa.fa-angle-right {
        font-size: 14px !important;
    }

    .staticpage a[href^="http://"]:before, .staticpage a[href^="https://"]:before {
        font-weight: 400;
        font-family: "Font Awesome 5 Pro";
        font-style: normal;
        font-variant: normal;
        content: "\f15b";
        margin-right: 8px;
        margin-left: 8px;
        font-size: 15px;
        color: #008bd2;
        content: "\f35d";
    }

.staticpage a[href^="www.trnava.sk"]:before, .staticpage a[href^="http://www.trnava.sk"]:before, .staticpage a[href^="https://www.trnava.sk"]:before, .staticpage a[href^="http: //www.trnava.sk"]:before, .staticpage a[href^="https: //www.trnava.sk"]:before, .staticpage a.box-link[href^="http://"]:before, .staticpage a.box-link[href^="https://"]:before {
    display: none;
}


.staticpage a.box-link[href^="http://"]:after, .staticpage a.box-link[href^="https://"]:after {
    background: url("../../../assets/img/external.svg") no-repeat top right !important;
    background-size: 23px auto !important;
    display: block !important;
    position: absolute !important;
    top: 7px;
    right: 14px;
    width: 23px;
    height: 23px;
    content: "";
}


#map {
    z-index: 1;
}

.lifes a[href$=".pdf"]:before, .lifes a[href$=".doc"]:before, .lifes a[href$=".docx"]:before {
    font-weight: 400;
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-variant: normal;
}

.file:before {
    font-weight: 400;
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-variant: normal;
    content: "\f15b";
    margin-right: 8px;
    margin-left: 8px;
    color: #f4ae36;
    display: inline-block;
}

.file.file-pdf:before, .lifes a[href$=".pdf"]:before {
    content: "\f1c1";
}

.file.file-word:before, .lifes a[href$=".doc"]:before, .lifes a[href$=".docx"]:before {
    content: "\f1c2";
}

    .file.file-powerpoint:before {
        content: "\f1c4";
    }

    .file.file-excel:before {
        content: "\f1c3";
    }

    .file.file-event:before {
        content: "\f133";
    }

    .file.file-link:before {
        content: "\f0c1";
    }

    .file.file-gallery:before {
        content: "\f1c5";
    }

    .file.file-video:before {
        content: "\f1c8";
    }

    .file.file-file:before {
        content: "\f0c5";
    }

    .file.file-life:before {
        content: "\f183";
    }

    .file.file-ordinance:before {
        content: "\f15c";
    }

    .file.file-articles:before {
        content: "\f1ea";
    }

    @media (max-width: 767px ) {

        #contacts-section h3 {
            margin-bottom: 0.8rem !important;
        }

        #contacts-section .col-lg-3 {
            margin-bottom: 0 !important;
            margin-top: 1.2rem !important;
        }

        #contacts-section.g-py-30--md {
            padding-bottom: 0 !important;
        }
    }



    table a, .content-column a, .home-services a, .footer-menu a {
        text-decoration: none;
    }

    table a:hover, .content-column a:hover, .home-services a:hover, .footer-menu a:hover {
        text-decoration: underline;
    }

    .footer-menu a:hover {
        text-decoration: underline;
    }

    .footer-menu {
        line-height: 21px;
    }

    .footer-menu a {
        font-size: 15px;
    }


    .form-control::placeholder {
        color: #a9a8a8;
        opacity: 1 !important;
    }





    a:focus, button:focus, img:focus {
        border: 0 !important;
        box-shadow: 0 0 0 .1rem #e7f2fd !important;
    }

    #navBar a:focus {
        border: 0 !important;
        box-shadow: none !important;
        color: #c6a171 !important;
    }

.u-header {
    border-bottom: 1px solid #4e5678 !important;
}

    .dropdown-menu {
        border: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .dropdown-item, .dropdown-item:hover {
        padding: .25rem 35px;
        font-size: 20px;
        margin-bottom: 20px;
        background: url("../../../assets/img/icons/arrow_right.svg") no-repeat 1px 13px !important;
        background-size: 23px 13px !important;
    }

    .dropdown-item:hover {
        color: #fff !important;
        text-decoration: underline !important;
    }


    .form-control:focus, #search-input:focus, #searchform-6 .btn.btn-link:focus {
        border-color: #000 !important;
    }

    .navbar-nav .nav-link:focus {
        border: 0 !important;
    }

    .filter-label {
        color: #000;
        padding: 8px 15px 0 0;
        text-transform: uppercase;
        display: block;
        width: 100%;
    }

    .header-label {
        padding: 0;
        margin-bottom: 0;
    }

    .btn {
        font-size: 1.4rem;
    }

    #search-input {
        color: #252525;
    }

    .collapse.navbar-collapse label {
        color: #fff;
        font-weight: 600;
        padding: 14px 15px 0 0;
        text-transform: uppercase;
        display: block;
    }

    .footer-breadcrumb {
        background: #e7f2fd;
    }

    .footer-breadcrumb .container .col-md-12 {
        border-bottom: 1px solid #c3def9;
    }



    .form-control {
        color: #535354;
    }



    @media (min-width: 992px ) {





        .navbar-nav li:focus {
            color: #fff !important;
        }
    }

    .skip-link {
        display: none;
    }



    @media (min-width: 1201px ) {
        #searchform-6 .input-group {
            width: 503px;
        }

        .home-motive #searchform-6 .input-group {
            width: 700px;
        }

        .navbar-brand {
            margin: 17px 0 17px 8px !important;
        }
    }

    @media (min-width: 992px ) and (max-width: 1200px ) {
        .event-page.articles .col-lg-4 .date {
            font-size: 34px !important;
        }

        .navbar-brand {
            margin: 17px 0 20px 8px !important;
        }

        .home-links .box {
            display: table;
            height: 100%;
            width: 100%;
        }

        .navbar-nav .g-px-31 {
            padding-left: 1.35rem !important;
            padding-right: 1.35rem !important;
        }

        .faddress, .fmail, .fphone, .fpl {
            font-size: 16px;
        }

        .layout-home .bgheader {
            background-size: auto 100%;
        }

        .layout-home .bgheader .u-header {
            background-size: auto 418%;
        }

        #searchform-6 .input-group {
            width: 525px;
        }

        .header-search {
            width: 525px;
        }

        .navbar-nav .nav-item > .nav-link {
            font-size: 19px;
        }

        .home-links .col-md-2 a img {
            width: 100%;
        }

        .news-image {
            width: 262px;
        }

        .social a {
            margin-right: 0px;
        }

        .home-info h2 {
            font-size: 56px;
        }

        .home-info .col-sm-12 {
            background-size: 100% auto;
        }

        .home-info a {
            font-size: 19px;
        }

        .home-info-indend {
            padding: 120px 0 30px 40px;
        }

        #contacts-section .col-lg-4:first-child img {
            width: 100% !important;
        }
    }

    @media (min-width: 768px ) and (max-width: 991px ) {

        .social a {
            margin-right: 0px;
        }

        .search-top {
            right: 80px;
            top: 22px;
        }

        .header-search .input-group > .form-control {
            display: block;
            width: auto;
        }

        .header-search {
            width: 286px !important;
        }


        .layout-home .bgheader {
            background-size: auto 100%;
        }

        .layout-home .bgheader .u-header {
            background-size: auto 656%;
        }

        .home-motive .text {
            font-size: 76px;
            margin: 25px 0 0;
        }

        .welcome p {
            font-size: 19px;
            width: 530px;
        }

        .welcome .btn {
            font-size: 1.5rem;
        }

        #searchform-6 .input-group {
            width: 380px;
        }

        .logo-mobile {
            display: block !important;
        }

        .navbar-brand {
            width: 191px;
        }

        .logo-desktop {
            display: none !important;
        }

        .header-search.g-ml-80 {
            margin-left: 40px;
        }

        .home-links .col-md-2 a img {
            width: 100%;
        }

        .news-image {
            width: 94%;
            /*height: 350px;*/
        }

        .slick-list .date {
            padding-top: 220px;
            font-size: 18px !important;
        }

        .min-height-article.article-detail .g-font-size-24 {
            font-size: 1.21429rem !important;
        }

        .home-info h2 {
            font-size: 45px;
        }

        .home-info .col-sm-12 {
            background-size: 100% auto;
        }

        .home-info a {
            font-size: 16px;
        }

        .home-info-indend {
            padding: 50px 0 30px 40px;
        }

        .article-detail {
            padding: 0 15px 15px 15px;
        }

        .home-links .box {
            display: table;
            height: 100%;
            width: 100%;
        }

        .home-links .box {
            padding: 20px 15px 20px 45px;
            font-size: 15px;
            background-size: auto 86%;
        }

        .home-motive .col-sm-6 p a {
            font-size: 16px;
        }

        header .float-right a i {
            line-height: 13px;
        }

        .home-alerts .col-sm-6 {
            flex: 0 0 47.7%;
        }

        .home-alerts .col-sm-6 a {
            font-size: 16px;
        }

        .home-notes article {
            padding: 15px;
        }

        .home-notes article a {
            font-size: 17px;
        }

        .slick-list .date {
            font-size: 15px !important;
        }

        .home-events article .date {
            font-size: 41px !important;
            line-height: 41px !important;
        }

        .home-events article .category {
            font-size: 27px;
        }

        .home-events article a {
            font-size: 19px;
            -webkit-line-clamp: 3;
        }

        .home-events article .time {
            padding-top: 11px;
            font-size: 18px;
        }



        #contacts-section .col-lg-4:first-child img {
            width: 100% !important;
        }

        #contacts-section .col-lg-2 a img {
            width: 40px;
        }

        #contacts-section .col-lg-2 a {
            margin-top: 8px;
        }

        .faddress, .fmail, .fphone, .fpl {
            font-size: 15px;
        }

        .footer-social img {
            width: 40px;
            margin-bottom: 4px;
        }

        .footer-menu a {
            font-size: 14px;
        }

        .faddress::before, .fmail::before, .fphone::before {
            font-size: 15px;
        }

        .home-events h2, .home-news h2, .home-notes h2 {
            font-size: 34px !important;
        }

        header .float-right a {
            font-size: 21px;
            margin-top: 11px;
        }

        .home-events, .home-notes {
            padding: 40px;
        }

        .home-news {
            padding: 40px 0 20px;
        }
    }

    @media (min-width: 576px ) and (max-width: 767px ) {


        .news-image {
            height: 230px;
        }

        .panel-search .btn {
            font-size: 14px;
        }
    }

    #menu-btn {
        z-index: 1050;
    }

    .navbar-collapse.collapse.show {
        overflow-y: auto;
        max-height: calc(100vh - 106px);
        z-index: 999;
    }


    @media (min-width: 992px ) {

        .navbar-collapse.collapse.show {
            position: absolute;
            right: 0;
            top: 88px;
            width: 100%;
        }

        .navbar-nav li, .navbar-nav li.active {
            width: auto;
            float: left;
        }

        .navbar-collapse h2 {
            font-size: 17px;
            font-family: 'Work Sans';
            text-transform: uppercase;
            padding-left: 10px;
        }

        .navbar-collapse ul li {
            padding-bottom: 4px;
            padding-left: 10px;
        }

        .navbar-collapse .bg-medium {
            border-top: 1px solid #eaebe6;
        }

        .navbar-collapse .bg-medium p {
            margin-bottom: 0;
        }

        .navbar-collapse .first-level {
            font-size: 18px;
        }

        .navbar-collapse .first-level a {
            display: block;
            padding-left: 53px;
            padding-top: 11px;
            padding-bottom: 16px;
        }

        .navbar-collapse .first-level a.samosprava {
            background: url("../../../assets/img/samosprava.png") no-repeat 3px 0;
        }

        .navbar-collapse .first-level a.transparentnost {
            background: url("../../../assets/img/transparentnost.png") no-repeat 9px 0;
        }

        .navbar-collapse .first-level a.volby {
            background: url("../../../assets/img/volby.png") no-repeat 18px 0;
        }

        .navbar-collapse .first-level a.aktualne {
            background: url("../../../assets/img/aktualne.png") no-repeat 12px 0;
        }

        .navbar-collapse .first-level a.ako {
            background: url("../../../assets/img/ako.png") no-repeat 12px 0;
        }

        .navbar-collapse .first-level a.omeste {
            background: url("../../../assets/img/omeste.png") no-repeat 3px 0;
        }

        .navbar-collapse .first-level a.adresare {
            background: url("../../../assets/img/adresare.png") no-repeat 18px 0;
        }

        .navbar-collapse .first-level a.trnava {
            background: url("../../../assets/img/trnava.png") no-repeat 16px 0;
        }
    }

    @media (max-width: 767px ) {

        .social a {
            margin-right: 0px;
        }


        .home-links .g-mb-20 {
            margin-bottom: 25px !important;
        }

        .home-links .col-md-2 a img {
            margin-bottom: 10px;
        }

        .search-top {
            right: 80px;
            top: 32px;
        }

        .footer-social {
            text-align: left;
        }

        .navbar-brand, .navbar-brand img {
            width: auto;
        }

        .logo-mobileSmall {
            display: block !important;
            height: 46px !important;
            width: auto;
            margin-left: 30px !important;
        }

        .navbar-brand {
            margin: 13px 0 0 8px;
        }

        .logo-mobile {
            display: none !important;
        }

        .home-info .col-sm-6 {
            padding-left: 15px;
        }

        .bgheader {
            background: url("../../../assets/img/bg/bgsub.png") no-repeat right top #334583;
        }

        .layout-home .bgheader {
            background: url("../../../assets/img/bg/bgheader.jpg") no-repeat right top #334583;
            background-size: auto 100%;
        }

     

        .home-motive .text {
            font-size: 46px;
            margin: 0 0 0;
            line-height: 55px;
        }

        .slick-slide {
            float: none;
            margin-bottom: 30px;
        }

        .news-image {
            width: 94%;
            /*height: 350px;*/
        }

        .article-detail .news-image {
            top: -30px;
        }



        .welcome p {
            font-size: 16px;
            width: 100%;
            margin: 0 auto 25px;
        }

        .welcome .btn {
            font-size: 1.5rem;
        }

        #searchform-6 .input-group {
            width: 100%;
        }

        .home-links .col-md-2 {
            width: 50%;
        }

        .home-motive .header-label {
            font-size: 26px;
        }


        .slick-list .date {
            font-size: 18px !important;
        }

        .min-height-article.article-detail .g-font-size-24 {
            font-size: 1.21429rem !important;
        }

        .home-info h2 {
            font-size: 47px;
        }

        .home-info .col-sm-12 {
            background-size: 100% auto;
        }



        .home-info-indend {
            padding: 40px 0 0 0;
        }

        .article-detail {
            padding: 0 15px 15px 27px;
        }

        .home-links .box {
            display: table;
            height: 100%;
            width: 100%;
        }

        .home-links .box {
            padding: 20px 15px 20px 45px;
            font-size: 15px;
            background-size: auto 86%;
        }

        .home-motive .col-sm-6 p a {
            font-size: 16px;
        }

        header .float-right a i {
            line-height: 13px;
        }

        .home-alerts .col-sm-6 {
            flex: 0 0 47.7%;
        }

        .home-alerts .col-sm-6 a {
            font-size: 16px;
        }

        .home-notes article {
            padding: 15px;
        }

        .home-notes article a {
            font-size: 34px;
        }

        .home-info a {
            font-size: 40px;
        }

        .home-info .col-sm-3 {
            display: none;
        }

        .slick-list .date {
            font-size: 30px !important;
            margin-bottom: 0;
        }

        .home-events article .date {
            font-size: 61px !important;
            line-height: 61px !important;
        }

        .home-events article .category {
            font-size: 27px;
        }

        .home-events article a {
            font-size: 41px;
            -webkit-line-clamp: 3;
        }

        .home-events article .time {
            padding-top: 11px;
            font-size: 30px;
        }

        #contacts-section .col-lg-4:first-child img {
            margin: 0 auto 20px auto;
        }

        #contacts-section .col-lg-2 a img {
            width: 40px;
        }

        #contacts-section .col-lg-2 a {
            display: inline-block;
        }

        .faddress, .fmail, .fphone, .fpl {
            font-size: 15px;
        }

        .footer-menu a {
            font-size: 14px;
        }

        .faddress::before, .fmail::before, .fphone::before {
            font-size: 15px;
        }

        .home-events h2, .home-news h2, .home-notes h2 {
            font-size: 34px !important;
        }

        header .float-right a {
            font-size: 21px;
            margin-top: 11px;
        }

        .home-events, .home-notes {
            padding: 40px 0;
        }

        .home-news {
            padding: 40px 0 20px;
        }

        .navbar-brand {
            margin-left: 0 !important;
        }

        .home-alerts .col-sm-6 {
            margin-left: 0;
            margin-right: 0;
            flex: 0 0 100%;
        }

        #contacts-section .col-lg-4 img {
            max-width: 250px;
            margin: 0 auto 20px auto;
        }

        .home-links .box {
            display: table;
            height: 100%;
            width: 100%;
        }

        .home-links .box {
            padding: 20px 15px 20px 50px;
            font-size: 18px;
            background-size: auto 86%;
        }

        h2.h4 {
            font-size: 46px !important;
        }

        .home-events .float-right, .home-notes .float-right, #skiplink-news .float-right {
            display: none !important;
        }

        .home-events .d-none, .home-notes .d-none, #skiplink-news .d-none {
            display: block !important;
        }

        .slick-next.slick-arrow {
            right: 0;
            display: none !important;
        }

        .slick-prev.slick-arrow {
            right: 53px;
            display: none !important;
        }

        .image-mobile {
            display: block !important;
            margin-bottom: 0 !important;
        }

        .image-mobile img {
            width: 100% !important;
        }

        .home-info .col-sm-12 {
            background: none;
        }

        .slick-dots {
            bottom: -5px;
        }

        .home-events .slick-next.slick-arrow, .home-events .slick-prev.slick-arrow, .home-notes .slick-next.slick-arrow, .home-notes .slick-prev.slick-arrow {
            top: -44px;
        }

        .newsletter span {
            margin-bottom: 3px;
        }

        .newsletter .input-group {
            display: -ms-flexbox;
            display: flex;
        }

        .newsletter .input-group > .form-control {
            position: relative;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            width: 1%;
            margin-bottom: 0;
        }

        .newsletter .input-group-addon {
            width: auto;
        }


        #contacts-section h3 {
            font-size: 1.54286rem !important;
        }

        .home-notes .g-mb-30, .home-events .g-mb-30 {
            margin-bottom: 1.0rem !important;
        }

        .date {
            padding-bottom: 0;
        }

        .home-notes a {
            margin-bottom: 12px;
        }

        #contacts-section .col-lg-3.footer-menu {
            margin-bottom: 1rem !important;
        }
    }

    @media (min-width: 576px ) and (max-width: 768px ) {

        .home-alerts .col-sm-6 {
            max-width: 48.4%;
        }

        .home-motive .col-sm-6 {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }

        .home-links .col-sm-3 {
            -ms-flex: 0 0 33%;
            flex: 0 0 33%;
            max-width: 33%;
        }

        .home-info h2 {
            font-size: 26px;
        }

        .home-info-indend {
            padding: 30px 0 30px 0;
        }

        .home-info .col-sm-12 {
            background-size: 50% auto;
        }

        .home-info a {
            font-weight: 600;
        }
    }

    .btn-more {
        background: #fff !important;
        border-radius: 2px;
        font-weight: 700;
        font-size: 38px;
        text-transform: none;
        border: 1px solid #21347b;
        text-decoration: none !important;
    }

    .btn-more:hover {
        background: #d5dff7;
    }

    @media (min-width: 992px ) {


        .modal-open {
            overflow: scroll;
        }
    }

    @media (max-width: 991px ) {

        .header-search #search-input {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        .search-header .search-btn.btn-top {
            padding: 3px 7px 0px 0 !important;
            line-height: 23px !important;
        }

        .input-group-addon .fa-search {
            width: 22px;
            height: 22px;
        }

        .layout-home .input-group-addon .fa-search {
            width: 27px;
            height: 27px;
        }

        .g-mt-23 {
            margin-top: 23px;
        }

        .dropdown-item, .dropdown-item:hover {
            background: url("../../../assets/img/icons/arrow_right.svg") no-repeat 1px 11px !important;
            background-size: 23px 13px !important;
        }

            .u-header .container.position-relative {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

            .navbar-expand-lg .navbar-nav .dropdown-menu {
                background: #293a72;
                margin-top: 1px;
                box-shadow: none !important;
            }

            .u-header .navbar-brand, .navbar-collapse .col-sm-12 {
                padding-left: 0 !important;
            }
        }

    @media (min-width: 992px ) and (max-width: 1200px ) {

        .home-alerts .col-sm-6 {
            background: #f9f4ea;
            margin-left: 15px;
            flex: 0 0 48.2%;
        }
    }

    @media (max-width: 460px ) {

        .navbar-brand img {
            width: 190px;
        }

        .navbar-toggler.navbar-toggler-right {
            top: 12px;
        }

        h2.h4 {
            font-size: 30px !important;
        }

        .slick-next.slick-arrow {
            right: 0;
        }

        .slick-prev.slick-arrow {
            right: 53px;
        }

        .home-info-indend {
            padding: 10px 0 0;
        }

        .home-info h2 {
            font-size: 30px;
        }

        .home-info .col-sm-12 {
            background: none;
        }

        .home-events, .home-notes {
            padding: 30px 0 10px 0;
        }

        .home-events .slick-next.slick-arrow, .home-events .slick-prev.slick-arrow, .home-notes .slick-next.slick-arrow, .home-notes .slick-prev.slick-arrow {
            top: -44px;
        }

        .home-links {
            padding: 30px 0 10px;
        }

        .g-mb-20.home-news {
            margin-bottom: 0 !important;
        }

        .home-news {
            padding: 30px 0 20px;
        }
    }

    .logo-mobile {
        display: none;
    }

    .logo-desktop {
        display: block;
    }




    @media (max-width: 768px ) {

        .mobile-els {
            display: block !important;
        }

        .navbar .g-top-12 {
            top: 4px;
        }

        .panel h3 {
            padding: 5px 15px !important;
            background: #f8f8f4;
            cursor: pointer;
            /*margin-top: 10px !important;*/
        }

        .paginationBox {
            margin: 0 0 0;
            padding-top: 30px;
        }

        .paginationBox ul {
            margin: 0 0 0;
        }

        .panel h3 .float-right {
            margin-top: 4px !important;
        }

        .validation-summary-errors ul {
            padding-left: 0 !important;
        }

        .panel .card {
            padding: 0 15px !important;
            /*margin-top: 10px !important;*/
        }

        .panel .card .g-mb-30 {
            margin-bottom: 15px !important;
        }

        .header-search.g-mt-35 {
            margin-top: 1.9rem !important;
            flex: 1 !important;
        }

        .search-top {
            display: none !important;
        }

        .header-search {
            visibility: visible !important;
            opacity: 1 !important;
            width: auto !important;
            padding-right: 90px !important;
            transform: translateY(0px) !important;
        }

        .header-search {
            width: 340px;
        }

        .header-search.g-ml-80 {
            margin-left: 40px;
        }

        .g-right-165 {
            right: 33px;
        }

        #menu-btn {
            text-indent: -9999px;
        }

        .navbar-toggler.navbar-toggler-right {
            padding: 12px 15px 10px 15px;
        }


        .logo-mobile {
            margin-left: 20px !important;
            margin-bottom: 20px !important;
            width: 190px !important;
        }

        .logo-desktop, .els {
            display: none;
        }

        #skiplink-header .g-pos-abs.g-right-220 {
            right: 145px;
            top: 20px;
        }

        .els.mobile-els {
            background: url(../../../assets/img/arrow.png) no-repeat 0 11px;
        }

        .navbar-toggler.navbar-toggler-right {
            top: 13px;
        }

        .navbar-collapse.collapse.show {
            top: 1px;
        }

        body.modal-open {
            padding-right: 0 !important;
        }

        .top-search.g-mb-30 {
            margin-bottom: 1.57143rem !important;
        }

        .home-alerts.g-mb-30 {
            margin-bottom: 15px !important;
        }

        .home-motive {
            box-shadow: 0 2px 3px rgba(0,0,0,0.1);
            padding-bottom: 15px;
        }

        .home-links.g-pb-10 {
            box-shadow: 0 2px 3px rgba(0,0,0,0.1);
            margin-bottom: 20px;
            padding-bottom: 0 !important;
        }

        .home-notes .text-center.g-pb-20 {
            padding-bottom: 0 !important;
        }

        .home-notes .text-center.g-pb-20 a {
            margin-bottom: 0;
        }

        .home-notes.g-pb-10 {
            box-shadow: 0 2px 3px rgba(0,0,0,0.1);
            padding-bottom: 15px !important;
            margin-bottom: 15px;
        }

        .home-info.g-mb-40 {
            box-shadow: 0 2px 3px rgba(0,0,0,0.1);
            padding-bottom: 15px;
            margin-bottom: 15px !important;
        }

        .slick-dots {
            display: none !important;
        }

        .slick-dotted.slick-slider {
            margin-bottom: 0;
        }
    }

    @media (max-width: 400px ) {

        #menu-btn {
            text-indent: -9999px;
        }

        .navbar-toggler.navbar-toggler-right {
            padding: 12px 15px 10px 29px;
        }

        #skiplink-header .g-pos-abs.g-right-220 {
            right: 145px;
        }
    }

    .g-font-weight-600, td.g-font-weight-600 {
        font-weight: 700 !important;
        font-family: Work Sans;
    }

    strong, b, p.g-font-weight-600 {
        font-weight: 600 !important;
        font-family: Work Sans;
    }

    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        font-weight: 600;
    }

    @media (min-width: 768px) and (max-width: 991px) {

        .right-menu {
            font-size: 0.9em !important;
        }

        .right-section h3 {
            font-size: 1.4em !important;
            border-bottom: 1px solid #e5ecd0;
            padding-bottom: 6px;
            margin-bottom: 11px !important;
        }


        .g-right-165 {
            right: 105px;
        }

        .g-right-220 {
            right: 221px;
        }
    }


    #lang-dropdown {
        z-index: 1100;
    }

details summary::marker, details summary::-webkit-details-marker {
    display: none;
    content: "";
}

details summary {
    text-transform: uppercase;
}

details {
    background: #f4f4f4;
    padding: 10px 16px 10px 16px;
    margin-bottom: 5px;
    position: relative;

    &[open] {
        & > summary {
            margin-bottom: 20px;
        }

        & > *:not(summary):not(ul) {
            padding-left: 1em;
        }

        & > details {
            padding: 0;
        }
        /*experiment - pasik*/
        /*     &::before {
            width: 10px;
            border-left: 1em solid #efefef;
            content: ' ';
            position: absolute;
            top: 2em;
            left: 0;
            bottom: 0;
        }*/
    }



    summary {
        position: relative;
    }
}



    summary::marker {
        color: #f4f4f4;
    }



    summary:after {
        content: "\f107";
        font-family: 'Font Awesome 5 Pro';
        font-weight: 400;
        font-size: 22px;
        display: block;
        float: right;
        color: #25397d;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        margin-top: 3px;
    }

    details[open] > summary:after {
        content: "\f106";
    }

.file-card {
    /*background: #f8f8f4;*/
    border-radius: 8px;
    border: 1px solid #e9e9e8;

    a:hover {
        color: #82B383
    }

    .file-icon {
        color: #c6a171;
    }

    .download-icon {
        color: #82B383;
    }

    .media-body {
        overflow: hidden;
        white-space: nowrap;
    }
}

    .hs-mega-menu {
        display: none;
    }

    .home-motive {
        background: none !important;
    }


    .u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:hover {
        color: #c6a171 !important;
    }

    .fade-default {
        opacity: 0;
    }

    .come-in {
        -webkit-transform: translateY(30px);
        transform: translateY(30px);
        -webkit-animation: come-in 0.6s ease forwards;
        animation: come-in 0.6s ease forwards;
    }

    .come-in.from-right {
        -webkit-transform: translateY(0) translateX(30px);
        transform: translateY(0) translateX(30px);
    }

    .come-in.from-left {
        -webkit-transform: translateY(0) translateX(-30px);
        transform: translateY(0) translateX(-30px);
    }

    .come-in.from-top {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    .come-in:nth-child(odd) {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        /* So they look staggered */
    }

    @-webkit-keyframes come-in {
        to {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
        }
    }

    @keyframes come-in {
        to {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
        }
    }

    @media (max-width: 575px) {

        .logo-mobile {
            margin-left: 30px !important;
        }

        #navBar {
            margin-left: -1px;
            margin-right: -1px;
        }

        .share-icons b {
            display: none;
        }
    }

    .js-mega-menu .dropdown, .dropleft, .dropright, .dropup {
        position: unset;

        .dropdown-item {
            color: white;
            white-space: unset;
        }
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        background: #23366f;
        margin-top: 1px;
        box-shadow: 0px 40px 14px -3px rgba(0,0,0,0.27);
    }

    .home-motive .form-control {
        font-size: 1.5rem;
    }

    @media (min-width: 992px) {


        .navbar-expand-lg .navbar-nav .dropdown-menu {
            left: 0;
            right: 0;
            color: white;

            .container {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
                /*grid-gap: 1em;*/
            }
        }
    }

    .navbar .container {
        position: unset;
    }

#navBar {
    position: unset;
}

.content-column .content-column {
    padding-top: 0px;
}

    @media (max-width: 575px) {

        .home-events article .date {
            font-size: 31px !important;
            line-height: 31px !important;
        }

        .home-events article .time {
            font-size: 20px;
            min-height: 1px;
        }

        .home-events article {
            min-height: 1px !important;
        }

        .home-events article a, .home-notes article a {
            min-height: 1px;
            overflow: visible;
            -webkit-line-clamp: unset;
        }

        .home-notes article {
            min-height: 2px !important;
        }

        .slick-list .date {
            font-size: 23px !important;
            margin-bottom: 0px;
        }

        .home-events article .category {
            font-size: 19px;
        }
    }

    @media (max-width: 400px) {
        h1 {
            font-size: 26px;
        }

        .bread-crumb {
            margin-bottom: 20px;
        }

        .right-section h3.subtitle {
            margin-top: 18px;
            margin-bottom: 20px !important;
        }

        .box-object, .box-object3 {
            padding: 20px 15px;
        }
    }

    @media (max-width: 575px) {

        .logo-mobile {
            display: none !important;
        }

        .logo-mobileSmall {
            display: block !important;
            height: 46px !important;
            width: auto;
            margin-left: 30px !important;
        }

        .navbar-brand, .navbar-brand img {
            width: auto;
        }

        .navbar-toggler.g-top-10 {
            top: 21px !important;
        }
    }

    .modal-backdrop.show {
        opacity: .8;
    }

    .modal-backdrop.fade {
        opacity: 0;
    }

    .modal-backdrop {
        top: 0px !important;
    }

    .modal-backdrop {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 900;
        background-color: #000;
    }


    @media (min-width: 1200px ) {
        .form-horizontal .form-control.col-sm-4, .filter-line .form-control.col-sm-4 {
            max-width: 31.7%;
        }

        .panel-search .btn, .panel-search input, .panel-search select {
            margin-right: 30px !important;
        }

        .panel-search input.filter-fulltext {
            margin-right: 20px !important;
        }

        .panel-search .align-bottom input, .panel-search .align-bottom select {
            margin-right: 0 !important;
        }

        .panel-search .align-bottom .btn {
            padding: .465rem 1.65rem;
        }

        .panel-search .align-bottom .btn.btn-primary {
            width: 100%;
        }

        .panel-search .align-bottom .btn.btn-primary.autow {
            width: auto;
        }

        .filter-line .form-control.col-sm-6 {
            max-width: 48.1%;
        }

        .filter-line .form-control.col-sm-4 {
            max-width: 30.8% !important;
        }

        .filter-line-short .form-control.col-sm-6 {
            max-width: 47.1%;
        }
    }

    @media (min-width: 992px ) and (max-width: 1199px ) {
        .form-horizontal .form-control.col-sm-4 {
            max-width: 31.3%;
        }

        .panel-search .btn, .panel-search input, .panel-search select {
            margin-right: 30px !important;
        }

        .filter-line .form-control.col-sm-6 {
            max-width: 47.8%;
        }

        .filter-line .form-control.col-sm-4 {
            max-width: 30.1% !important;
        }

        .panel-search .align-bottom input, .panel-search .align-bottom select {
            margin-right: 0 !important;
        }

        .panel-search .align-bottom .btn {
            padding: .475rem 1.65rem;
        }
    }

    @media (min-width: 768px ) and (max-width: 991px ) {
        .form-horizontal .form-control.col-sm-4 {
            max-width: 30.3%;
        }

        .bread-crumb {
            margin-bottom: 25px;
        }

        .right-section.panel-search {
            margin-top: 103px;
        }

        .panel-search .btn, .panel-search input, .panel-search select {
            margin-right: 30px !important;
        }

        .right-section.panel-search .btn {
            margin-right: 0 !important;
            font-size: 16px !important;
        }

        .right-section.panel-search .custom-control-label {
            font-size: 14px !important;
        }

        .filter-line .form-control.col-sm-6 {
            max-width: 47.0%;
        }

        .filter-line .form-control.col-sm-4 {
            max-width: 29.1% !important;
        }

        .panel-search .align-bottom input, .panel-search .align-bottom select {
            margin-right: 0 !important;
        }

        .panel-search .align-bottom .btn {
            padding: .475rem 1.65rem;
        }
    }

    @media (min-width: 576px ) and (max-width: 767px ) {
        .form-horizontal .form-control.col-sm-4 {
            max-width: 29.5%;
        }

        .panel-search .btn, .panel-search input, .panel-search select {
            margin-right: 30px !important;
        }

        .filter-line .form-control.col-sm-6 {
            max-width: 47.0%;
        }
    }

    @media (max-width: 575px ) {
        .panel-search .btn, .panel-search input, .panel-search select {
            margin-right: 0 !important;
        }
    }



    select.form-control {
        background: url("../../../assets/img/icons/selectarrow.png") no-repeat right center #fff !important;
        -webkit-appearance: none;
        padding: .585rem .65rem;
    }

    .panel-search select.form-control {
        padding: .585rem .65rem;
    }

    .print-text {
        cursor: pointer;
        float: right;
        margin-top: 3px;
        display: block;
        text-decoration: none !important;
        color: #242620;
    }

.print-text:hover {
    
    color: #242620;
}

    .sub-news .article-detail h3 a {
        color: #21347b !important;
        font-size: 20px !important;
        font-weight: 600 !important;
    }

    .sub-news .min-height-article {
        min-height: 1px !important;
    }




#navBar .container {
    position: relative !important;
}
[data-category=elektronicke-sluzby] {
    grid-column: 1;
    margin-top: 20px;
}

[data-category=formulare-a-tlaciva] {
    margin-top: 20px;
}



[data-category=elektronicke-sluzby]:after {
    display: inline-block;
    content: ' ';
    height: 1px;
    background: #4c5478;
    opacity: 1;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: -21px;
    margin-right: 15px;
    margin-left: 15px;
}
    

@media (min-width: 411px) and (max-width: 510px) {

    #cconsent-bar button {
        padding: 6px 6px !important;
        font-size: 12px !important
    }

    #cconsent-bar {
        text-align: left !important;
    }
}

@media (max-width: 410px) {

    #cconsent-bar .ccb__right {
        white-space: normal !important;
    }

    #cconsent-bar {
        text-align: left !important;
    }

    #cconsent-bar button {
        margin-right: 0;
        margin-bottom: 5px;
        width: 100%;
    }
}

@media (min-width: 511px) and (max-width: 800px) {

    #cconsent-bar {
        text-align: left !important;
    }
}

.in-page-navigation {
    ul {
        padding-left: 0;
        list-style-type: none;

        li {
            margin-bottom: 15px;
            position: relative;
            padding-left: 15px;

            &.in-page-navigation__list-item--active a {
                font-weight: bold;
            }

            &.in-page-navigation__list-item--active::before {
                display: inline-block;
                color: #21347b;
                width: 0;
                height: 0;
                border-style: solid;
                border-color: transparent;
                -webkit-clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
                clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
                border-width: 5px 0 5px 8.66px;
                border-left-color: inherit;
                content: "";
                position: absolute;
                top: 10px;
                left: 0;
            }
        }
    }
}

.in-page-navigation--sticky {
    position: sticky;
    top: 160px;
    overflow: auto;
    height: 100vh;
}

.in-page-navigation.mobile {

    li, a {
        color: white;
    }

    &.in-page-navigation--sticky {
        position: fixed;
        height: auto;
        overflow: unset;
        top: 62px;
        left: 0;
        right: 0;
        width: 100%;
    }



    .idsk-in-page-navigation__link-panel-button {
        font-weight: 400;
        font-size: 18px;
        font-size: 1.125rem;
        line-height: 1.11111;
        border: none;
        color: #ffffff;
        font-weight: bold;
        padding: 15px 50px 15px 15px;
        text-align: left;
        width: 100%;
        background: none;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &::before {
            border-style: solid;
            border-width: 0.25em 0.25em 0 0;
            color: #ffffff;
            content: "";
            display: inline-block;
            height: 0.45em;
            right: 25px;
            position: absolute;
            top: 18px;
            -webkit-transform: rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: rotate(135deg);
            -webkit-transition: all 0.2s ease-out;
            transition: all 0.2s ease-out;
            vertical-align: top;
            width: 0.45em;
        }
    }

    .idsk-in-page-navigation__link-panel {
        cursor: pointer;
        display: inline-block;
        position: relative;
        width: 100%;
    }

    .idsk-in-page-navigation__list {
        transition: all 0.2s ease-out;
        overflow-y: hidden;
        max-height: 0;

        li:first-child {
            margin-top: 15px;
        }
    }

    &.idsk-in-page-navigation--expand {
        .idsk-in-page-navigation__list {
            transition: all 0.2s ease-in;
            overflow-y: auto;
            max-height: 300px;
        }

        .idsk-in-page-navigation__link-panel-button {
            &::before {
                top: 20px;
                -webkit-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
                -webkit-transition: all 0.2s ease-in;
                transition: all 0.2s ease-in;
            }
        }
    }
}

.bg-main {
    background-color: #21347b;
}


@media (max-width: 768px) {
    .header-search {
        visibility: visible !important;
        opacity: 1 !important;
        width: auto !important;
        display: flex;
        transform: translateY(0) !important;
        flex: 1 !important;
        padding-right: 0 !important;
    }

    .navbar-toggler.g-top-10 {
        top: 25px !important;
    }

    .navbar-toggler.navbar-toggler-right {
        width: 32px;
        height: 27px;
    }
}

@media (max-width: 476px) {
    .header-search {
        visibility: visible !important;
        opacity: 1 !important;
        width: auto !important;
        display: flex;
        transform: translateY(0) !important;
        flex: 1 !important;
        padding-right: 80px !important;
    }
}

@media (max-width: 376px) {
    .search-btn {
        padding: 10px 7px 5px;
    }
}




@media (max-width: 991px) {
    #navBar {
        position: absolute;
        
    }

    .modal-backdrop {
        top: 63px !important;
    }

    .layout-home .modal-backdrop {
        top: 67px !important;
    }

    .navbar-collapse.collapse.show {
        top: 0;
    }

    .navbar .container {
        position: relative;
    }

}

@media (max-width: 768px) {


    .layout-home .modal-backdrop {
        top: 64px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .modal-backdrop {
        top: 67px !important;
    }
}

@media (min-width: 992px) {
    .modal-backdrop {
        top: 137px !important;
    }

    .search-top {
        top: 29px;
    }

    .header-search {
        margin-top: 25px;
    }
}

@media (min-width: 1200px) {
    .modal-backdrop {
        top: 139px !important;
    }

    
}

.bgheader {
    position: sticky !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

@media (max-width: 992px) {
    .bgheader {
        position: fixed !important;
    }


    .content-column{
        padding-top: 50px;
    }
}   


.layout-home .home-motive {
    margin-top: 136px !important;
}

.layout-home .bgheader {
    position: static !important;
    background: url("../../../assets/img/bg/bgheader.jpg") no-repeat center -138px #334583 !important;
}

@media (max-width: 992px) {
    .layout-home .bgheader {
        background: url("../../../assets/img/bg/bgheader.jpg") no-repeat center -67px #334583 !important;
    }

    .layout-home .home-motive {
        margin-top: 67px !important;
    }
}

@media (max-width: 767px) {
    .layout-home .bgheader {
        background: url("../../../assets/img/bg/bgheader.jpg") no-repeat center -63px #334583 !important;
    }

    .layout-home .home-motive {
        margin-top: 63px !important;
        padding-top: 30px;
    }
}

.layout-home .bgheader header {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background: url("../../../assets/img/bg/bgheader.jpg") no-repeat center top #334583 !important;
}

.tab-content .card {
    padding: 0 !important;
}

.u-accordion__header a {
    margin-bottom: 0 !important;
}

/*fix for h2 anchors, when header is fixed*/
.staticpage h2[id], .container h1[id] {
    scroll-margin-top: 160px;
}

.container h1[id] {
    scroll-margin-top: 170px;
}

@media (max-width: 991px) {
    .staticpage h2[id], .container h1[id] {
        scroll-margin-top: 100px;
    }

    .container h1[id] {
        scroll-margin-top: 170px;
    }
}




.card .vote {
    font-size: 0.7em !important;
    color: #b5b5af !important;
    text-transform: uppercase !important;
}

.card .status-not_voting {
    color: #d8d8d6;
    margin-left: 10px;
}

.card .status-aye {
    color: #38ad00;
    margin-left: 10px;
}

.card .status-no {
    color: #e90000;
    margin-left: 10px;
}

.card .status-abstained {
    color: #f6c96c;
    margin-left: 10px;
}

.card .status-missing {
    color: #c0b498;
    margin-left: 10px;
}

.card .col-lg-6 {
    padding-bottom: 10px !important;
}

.card .col-sm-12 {
    border-top: 1px solid #eff2e3 !important;
    padding-top: 10px !important;
    text-transform: uppercase !important;
}

.staticpage h5 > a:hover  {
    text-decoration: none !important;
}

.staticpage h5 > a:hover > span:not(:first-child) {
    text-decoration: underline !important;
}

@media (max-width: 767px) {
    .mobile-search, .mobile-search .search-top {
        display: block !important;
    }

    .mobile-search .search-top {
        position: absolute;
        right: 65px;
        top: 23px;
        z-index: 4111;
    }

    .mobile-search .home-motive .row {
        display: block;
        width: 100%;
    }

    .mobile-search .close {
        float: right;
        font-size: 2.2rem;
        margin-right: 20px;
        margin-top: 10px;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        text-shadow: none !important;
        opacity: 1;
    }

    .header-search-btn, .header-search-btn-close {
        cursor: pointer;
    }

    .desktop-search {
        display: none !important;
    }

    .mobile-search .fas.fa-search {
        color: #fff !important;
        position: relative;
        z-index: 1111;
    }

    .mobile-search .modal-body {
        position: relative;
        display: block;
        padding: 0;
    }

    .modal-content {
        background-color: none;
        border: 0;
        display: block;
        border-radius: 0;
    }

    .mobile-search .modal-dialog {
        max-width: inherit;
        width: 100%;
        margin: 1.75rem auto;
    }

    .panel h3[aria-expanded="false"]:before {
            content: "\f107";
            font-weight: 400 !important;
            font-family: 'Font Awesome 5 Pro';
        }

    .panel h3[aria-expanded="true"]:before {
        content: "\f106";
        font-weight: 400 !important;
        font-family: 'Font Awesome 5 Pro';
    }
    }


.ophours-mobile {
    display: none;
}

@media (max-width: 768px) {
    .ophours-mobile {
        display: block;
    }

    .ophours-desktop {
        display: none;
    }
}

@media (min-width: 768px) {
    #cconsent-bar .ccb__left {
        padding-right: 15px;
    }
}


#tag-list .row {
    margin-right: -25px;
    margin-left: -25px;
    
}

#tag-list .row .col-sm-6 {
    margin-bottom: 20px;
}

#tag-list .row .col-sm-6 div {
    background: #f8f8f8;
    padding: 20px 15px;
}

#tag-list .row .col-sm-6 a {
    color: #21347b !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}

#tag-list .date {
    color: #c6a171;
    padding-bottom: 0;
    font-size: 15px !important;
}

#tag-list h3 {
    margin-bottom: 9px;
    line-height: 1.2;
}

@media (min-width: 576px) {
    #tag-list h3 {
        min-height: 85px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-height: 1.4;
        -webkit-box-orient: vertical;
    }

    #tag-list .row .col-sm-6 div {
        padding: 30px 20px;
        height: 100%;
    }

    #tag-list .row .col-sm-6 {
        margin-bottom: 30px;
    }

    #tag-list .row .col-sm-6 a {
        font-size: 20px !important;
    }
}

.staticpage a.box-link:focus {
    border-left: 4px solid #2b3b73 !important;
}

.g-bg-gray-light-v4 {
    background: #f9f9f9 !important;
}

.filter-dnone {
    display: block;
}

@media (max-width: 575px) {

    .filter-dnone {
        display: none;
    }

    .btn-filter5 {
        margin-left: 15px;
        margin-right: 15px;
        width: 100%;
    }

    .btn-filter5 .btn {
        width: 100%;
        display: block;
    }
}

@media (min-width: 576px) {
    .filter-fulltext.col-sm-8 {
        flex: 0 0 65.666667%;
        max-width: 65.666667%;
    }
}


@media (min-width: 992px) {

    .btn-filter5 {
        margin-left: 15px;
    }
}

.home-events .news-image {
    top: -30px;
    right: 0;
    position: absolute;
}

.event-page.articles .col-lg-4 .date, .home-events .slick-list .date {
    padding-top: 214px !important;
    display: block;
}

.home-events article {
    position: relative;
    padding: 0 20px 20px 20px;
}

.home-events .col-sm-12 {
    padding-top: 30px;
}

.event-corner {
    top: 0;
    left: 0;
    width: 26px;
    height: 200px;
    background: #62c2d0;
    position: absolute;
}

.event-events .event-corner {
    background: #f58273;
}

.event-government .event-corner {
    background: #73a4f5;
}


@media (min-width: 992px) and (max-width: 1200px) {

    .event-corner {
        width: 29px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .event-corner {
        width: 13px;
    }
}


@media (max-width: 767px) {

    .event-corner {
        width: 31px;
    }
}

@media (max-width: 444px) {

    .event-corner {
        width: 25px;
    }
}

@media (max-width: 400px) {

    .event-corner {
        width: 22px;
    }
}


[data-category="odkaz-pre-starostu"] {
    margin-top: 20px;
}

.staticpage blockquote {
    margin: 0 0 1rem 25px;
    padding: 15px 25px 15px;
    font-style: italic;
    border-left: 4px solid #cccccc;
}

.staticpage blockquote p {
    margin: 0 !important;
}

.file::before, .lifes a[href$=".doc"]::before, .lifes a[href$=".docx"]::before, .lifes a[href$=".pdf"]::before {
    color: #c6a171;
    margin-right: 6px;
}