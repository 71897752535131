/*------------------------------------
  Background Colors
------------------------------------*/
/* Basic Colors
------------------------------------*/
/* Main Colors */
.g-bg-main {
  background-color: $g-bg-color-main !important;
}

/* Main Secondary */
.g-bg-secondary {
  background-color: $g-bg-color-secondary !important;
}

/* Primary Colors */
.g-bg-primary {
  background-color: $g-color-primary !important;

  // Hover
  &--hover:hover {
    background-color: $g-color-primary !important;
  }

  .u-block-hover:hover &--hover {
    background-color: $g-color-primary;
  }

  &--parent-hover {
    .g-parent:hover & {
      background-color: $g-color-primary !important;
    }
  }

  // Active
  &--active {
    &.active,
    .active & {
      background-color: $g-color-primary !important;
    }
  }

  &--parent-active {
    .g-parent.active & {
      background-color: $g-color-primary !important;
    }
  }

  //Pseudo-elements background color
  &--before::before,
  &--after::after {
    background-color: $g-color-primary !important;
  }

  // Primary dark
  &-dark {
    &-v1 {
      background-color: $g-color-primary-dark-v1 !important;
    }
    &-v2 {
      background-color: $g-color-primary-dark-v2 !important;
    }
    &-v3 {
      background-color: $g-color-primary-dark-v3 !important;
    }
  }

}

/* Black Colors */
.g-bg-black {
  background-color: $g-color-black !important;

  &--hover:hover {
    background-color: $g-color-black !important;
  }

  &--after::after {
    background-color: $g-color-black !important;
  }

  // Black opacity
  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-black, .1) !important;

      &--after::after {
        background-color: rgba($g-color-black, .1) !important;
      }
    }
    &-0_2 {
      background-color: rgba($g-color-black, .2) !important;

      &--after::after {
        background-color: rgba($g-color-black, .2) !important;
      }
    }
    &-0_3 {
      background-color: rgba($g-color-black, .3) !important;

      &--hover {
        &:hover {
          background-color: rgba($g-color-black, .3) !important;
        }
      }

      &--after::after,
      &--before::before {
        background-color: rgba($g-color-black, .3) !important;
      }
      &--hover--after:hover::after {
        background-color: rgba($g-color-black, .3) !important;
      }
    }
    &-0_4 {
      background-color: rgba($g-color-black, .4) !important;

      &--after::after {
        background-color: rgba($g-color-black, .4) !important;
      }
    }
    &-0_5 {
      background-color: rgba($g-color-black, .5) !important;

      &--hover {
        &:hover {
          background-color: rgba($g-color-black, .5) !important;
        }
      }

      &--after::after {
        background-color: rgba($g-color-black, .5) !important;
      }
    }
    &-0_6 {
      background-color: rgba($g-color-black, .6) !important;

      &--hover:hover {
        background-color: rgba($g-color-black, .7) !important;
      }

      &--after::after {
        background-color: rgba($g-color-black, .6) !important;
      }
    }
    &-0_7 {
      background-color: rgba($g-color-black, .7) !important;

      &--hover:hover {
        background-color: rgba($g-color-black, .7) !important;
      }

      &--after::after {
        background-color: rgba($g-color-black, .7) !important;
      }
    }
    &-0_8 {
      background-color: rgba($g-color-black, .8) !important;

      &--after::after {
        background-color: rgba($g-color-black, .8) !important;
      }

      &--hover {
        &:hover {
          background-color: rgba($g-color-black, .8) !important;
        }
      }
    }
    &-0_9 {
      background-color: rgba($g-color-black, .9) !important;

      &--after::after {
        background-color: rgba($g-color-black, .9) !important;
      }

      &--hover {
        &:hover {
          background-color: rgba($g-color-black, .9) !important;
        }
      }
    }
  }
}

.u-block-hover:hover .g-bg-black--hover {
  background-color: $g-color-black !important;
}

/* White Colors */
.g-bg-white {
  background-color: $g-color-white !important;

  //Pseudo-elements background color
  &--before::before,
  &--after::after {
    background-color: $g-color-white !important;
  }

  &--hover:hover,
  &--active.active {
    background-color: $g-color-white !important;
  }

  &--parent-hover {
    .g-parent:hover & {
      background-color: $g-color-white !important;
    }
  }

  &--disabled:disabled {
    background-color: $g-color-white !important;
  }

  //Pseudo-elements background color
  &--before::before,
  &--after::after {
    background-color: $g-color-white !important;
  }

  // White opacity
  &-opacity {
    &-0--after {
      &--parent-hover::after {
        .g-parent:hover & {
          background-color: rgba($g-color-white, 0) !important;
        }
      }
    }

    &-0_05 {
      background-color: rgba($g-color-white, .05) !important;
    }
    &-0_1 {
      background-color: rgba($g-color-white, .1) !important;

      &--after::after {
        background-color: rgba($g-color-white, .1) !important;
      }
    }
    &-0_2 {
      background-color: rgba($g-color-white, .2) !important;

      &--hover:hover {
        background-color: rgba($g-color-white, .2) !important;
      }
    }
    &-0_3 {
      background-color: rgba($g-color-white, .3) !important;

      &--hover:hover {
        background-color: rgba($g-color-white, .3) !important;
      }

      &--after::after {
        background-color: rgba($g-color-white, .3) !important;
      }
    }
    &-0_4 {
      background-color: rgba($g-color-white, .4) !important;

      &--hover:hover {
        background-color: rgba($g-color-white, .4) !important;
      }
    }
    &-0_5 {
      background-color: rgba($g-color-white, .5) !important;

      &--after::after {
        background-color: rgba($g-color-white, .5) !important;
      }
    }
    &-0_7 {
      background-color: rgba($g-color-white, .7) !important;

      &--hover:hover {
        background-color: rgba($g-color-white, .7) !important;
      }

      &--after::after {
        background-color: rgba($g-color-white, .7) !important;
      }
    }
    &-0_8 {
      background-color: rgba($g-color-white, .8) !important;

      &--hover:hover {
        background-color: rgba($g-color-white, .8) !important;
      }

      &--after::after {
        background-color: rgba($g-color-white, .8) !important;
      }

      &--hover--after:hover::after {
        background-color: rgba($g-color-primary, .8) !important;
      }
    }
    &-0_9 {
      background-color: rgba($g-color-white, .9) !important;

      &--hover:hover {
        background-color: rgba($g-color-white, .9) !important;
      }

      &--after::after {
        background-color: rgba($g-color-white, .9) !important;
      }
    }
  }
}

/* Transparent */
.g-bg-transparent {
  background-color: transparent !important;

  &--hover:hover {
    background-color: transparent !important;
  }

  &--hover--after:hover::after {
    background-color: transparent !important;
  }

  &--parent-hover {
    .g-parent:hover & {
      background-color: transparent !important;
    }
  }
}


//Grayscale
.g-grayscale {
  &-100x {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
    filter: gray;
    -webkit-filter: grayscale(100%);
  }

  &-0 {
    &--parent-hover {
      .g-parent:hover & {
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
        -webkit-filter: grayscale(0%);
      }
    }

    &--hover:hover {
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
      -webkit-filter: grayscale(0%);
    }
  }
}

/* O */
@media (min-width: $g-sm) {
  .g-bg-transparent--sm {
    background-color: transparent !important;
  }
}
